import { useQuery } from '@tanstack/react-query';

import EXTERNAL_URLS from '@/constants/external-urls';

const FIELDS = 'name,languages,capital,curency,flag,demonyms,flags';
const GET_COUNTRY_QUERY_KEY = 'GET_COUNTRY_QUERY_KEY';

type TCountry = {
  flags: {
    png: string;
    svg: string;
    alt: string;
  };
  name: {
    common: string;
    official: string;
  };
  demonyms: {
    eng: {
      f: string;
      m: string;
    };
  };
  cca2: string;
  flag: string;
};

export const useRestCountries = () => {
  const fetchCountries = async () => {
    const response = await fetch(`${EXTERNAL_URLS.REST_COUNTRIES_API_URL}?fields=${FIELDS}`);
    const data: TCountry[] = (await response.json()) as TCountry[];
    // sort by country name
    data.sort((a, b) => {
      if (a.name.common === 'Australia') {
        return -1; // Move Australia to the top
      }
      if (b.name.common === 'Australia') {
        return 1; // Keep Australia at the top
      }
      if (a.name.common === 'New Zealand') {
        return -1; // Move New Zealand to the second position
      }
      if (b.name.common === 'New Zealand') {
        return 1; // Keep New Zealand in the second position
      }

      return a.name.common.localeCompare(b.name.common);
    });
    return data;
  };

  return {
    fetchCountries,
  };
};

export function useCountryList() {
  const { fetchCountries } = useRestCountries();
  return useQuery({
    queryKey: [GET_COUNTRY_QUERY_KEY],
    queryFn: fetchCountries,
    staleTime: Infinity,
  });
}
