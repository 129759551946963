import React from 'react';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader } from '@/components/ui/card';

import { CultureName } from '@/services/candidate';

import { CULTURE_TO_EMOJI_MAP } from '@/constants/candidate';

interface IProps {
  onClick: () => void;
  cultureName: CultureName;
  thrust: string;
  means: string;
  ends: string;
}

const CultureResultCard: React.FC<IProps> = ({ cultureName, thrust, means, ends, onClick }) => {
  return (
    <Card className="flex flex-col gap-14 px-8 py-12 shadow-2xl">
      <CardHeader className="flex flex-row items-start justify-between">
        <span className="text-7xl 3xl:text-8xl">{CULTURE_TO_EMOJI_MAP[cultureName]}</span>
        <Button
          variant="tertiary"
          className="m-0 h-14"
          onClick={() => onClick()}
        >
          Score Breakdown
        </Button>
      </CardHeader>
      <CardContent className="flex flex-col gap-6">
        <div className="flex flex-col gap-1 text-xl font-semibold leading-[30px] text-primary-dark-100">
          <h3 className="text-sm font-semibold uppercase leading-tight text-primary-dark-40 after:content-[':']">
            CULTURE
          </h3>
          {cultureName}
        </div>
        <div className="flex flex-col gap-1 text-xl font-semibold leading-[30px] text-primary-dark-100">
          <h3 className="text-sm font-semibold uppercase leading-tight text-primary-dark-40 after:content-[':']">
            THRUST
          </h3>
          {thrust}
        </div>
        <div className="flex flex-col gap-1 text-xl font-semibold leading-[30px] text-primary-dark-100">
          <h3 className="text-sm font-semibold uppercase leading-tight text-primary-dark-40 after:content-[':']">
            MEANS
          </h3>
          {means}
        </div>
        <div className="flex flex-col gap-1 text-xl font-semibold leading-[30px] text-primary-dark-100">
          <h3 className="text-sm font-semibold uppercase leading-tight text-primary-dark-40 after:content-[':']">
            ENDS
          </h3>
          {ends}
        </div>
      </CardContent>
    </Card>
  );
};

export default CultureResultCard;
