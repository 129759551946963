import { createFileRoute } from '@tanstack/react-router';

import ProfileExperienceForm from '@/components/forms/candidate/experience-form';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/settings/profile/experience')({
  component: Experience,
});

function Experience() {
  return <ProfileExperienceForm />;
}
