import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/business/create-role/')({
  component: () => null,
  beforeLoad: ({ search }) => {
    throw redirect({
      to: '/business/create-role/step1',
      mask: {
        to: '/business/create-role',
        search,
      },
      search,
    });
  },
});
