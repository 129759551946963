import client, { TRequestOptions } from '@/lib/api';

import { SKILLSET_ENDPOINTS } from './skillset.endpoints';
import { TAllSkillsetsRequest } from './skillset.types';

const { GET_ALL_SKILLSETS } = SKILLSET_ENDPOINTS;

export const getAllSkillsets = async ({ signal, params }: TRequestOptions<TAllSkillsetsRequest>) => {
  const { data } = await client.GET(GET_ALL_SKILLSETS, {
    signal,
    params,
  });

  return data?.data;
};
