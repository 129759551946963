import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { UilClockThree, UilMapMarker, UilPen, UilUsdCircle } from '@iconscout/react-unicons';
import { useQueryClient } from '@tanstack/react-query';
import Fireworks from 'react-canvas-confetti/dist/presets/fireworks';
import { createPortal } from 'react-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import Avatar from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import ComboBoxInModal from '@/components/ui/combo-box-in-modal';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Select } from '@/components/ui/select-new';
import { toast } from '@/components/ui/toaster';

import { useGetCurrentCandidate, useUpdateCurrentCandidate } from '@/hooks/candidate';
import { useApplyForARole } from '@/hooks/post';
import { useCityList } from '@/hooks/useCityList';
import { useCountryList } from '@/hooks/useCountryList';
import { useGetCurrentUser } from '@/hooks/user';
import { useSearchable } from '@/hooks/useSearchable';
import { useGetValuesMapByGroup, useGetValuesMapByGroupAndCode } from '@/hooks/values-map';

import { CANDIDATE_ENDPOINTS, TCandidateJobPost } from '@/services/candidate';

import { currencyList, formatCurrency, parseCurrency } from '@/utils/currency';
import { getFormattedExpectationsForAPI } from '@/utils/format';
import { getExpectationRangeForCandidate } from '@/utils/role';
import { emojiToCountryCode, getFullName, getOptionsForSelectFromValuesMap } from '@/utils/string';

import { PAY_TYPE, PAY_TYPES } from '@/constants/post';
import { VALUES_GROUP } from '@/constants/values-map';

const expectationSchema = z.object({
  currency: z.string(),
  from: z.coerce.number().min(1),
  to: z.coerce.number().min(8),
  payType: z.string(),
});

export type TExpectationsData = z.infer<typeof expectationSchema>;

const CandidateExpectations: React.FC<{ onSuccess?: () => void; onCancel?: () => void; payType: string }> = ({
  onCancel,
  onSuccess,
  payType,
}) => {
  const { data: candidate } = useGetCurrentCandidate({});
  const { mutateAsync: updateCandidate, isPending: isUpdatingCandidate } = useUpdateCurrentCandidate({
    reactQueryOptions: {
      onSuccess: () => {
        onSuccess?.();
      },
    },
  });
  const payTypes = PAY_TYPES.map((payType) => ({
    value: payType.id,
    label: payType.name,
  }));

  const { expectationFrom, expectationTo } = getExpectationRangeForCandidate(candidate, payType as PAY_TYPE);

  const form = useForm<TExpectationsData>({
    resolver: zodResolver(expectationSchema),
    defaultValues: {
      currency: candidate?.expectation_currency as string,
      from: expectationFrom,
      to: expectationTo,
      payType: payType,
    },
    values: {
      currency: candidate?.expectation_currency as string,
      from: expectationFrom,
      to: expectationTo,
      payType: payType,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = form;

  const selectedCurrency = watch('currency');
  const selectedPayType = watch('payType');

  useEffect(() => {
    if (candidate) {
      const { expectationFrom, expectationTo } = getExpectationRangeForCandidate(
        candidate,
        selectedPayType as PAY_TYPE
      );

      setValue('from', expectationFrom);
      setValue('to', expectationTo);
    }
  }, [candidate, selectedPayType, setValue]);

  const onSubmit: SubmitHandler<TExpectationsData> = (data) => {
    updateCandidate({
      body: {
        ...getFormattedExpectationsForAPI(data),
      },
    });
  };

  return (
    <>
      <FormProvider {...form}>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col space-y-4"
        >
          <div className="flex space-x-4">
            <div className="inline-flex min-w-24">
              <FormField
                name="currency"
                control={control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Currency</FormLabel>
                    <FormControl>
                      <Select
                        className="flex"
                        options={currencyList.map((s) => ({ value: s.label, label: s.label }))}
                        selected={field.value}
                        placeholder="Currency"
                        onChange={(value) => field.onChange(value)}
                        error={!!errors?.currency?.message}
                      />
                    </FormControl>
                    <FormError />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              name="payType"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Pay type</FormLabel>
                  <FormControl>
                    <Select
                      options={payTypes}
                      placeholder="Pay type"
                      className="flex"
                      selected={field.value}
                      onChange={(value) => field.onChange(value)}
                      error={!!errors?.payType?.message}
                    />
                  </FormControl>
                  <FormError />
                </FormItem>
              )}
            />
          </div>
          <div className="flex space-x-8">
            <FormField
              name="from"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>From</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      className="w-full"
                      placeholder="Enter amount"
                      {...field}
                      value={formatCurrency(field.value, selectedCurrency)}
                      onChange={(event) => field.onChange(parseCurrency(event.target.value))}
                      error={!!errors.from?.message}
                    />
                  </FormControl>
                  <FormError />
                </FormItem>
              )}
            />
            <FormField
              name="to"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>To</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      className="w-full"
                      placeholder="Enter amount"
                      {...field}
                      value={formatCurrency(field.value, selectedCurrency)}
                      onChange={(event) => field.onChange(parseCurrency(event.target.value))}
                      error={!!errors.to?.message}
                    />
                  </FormControl>
                  <FormError />
                </FormItem>
              )}
            />
          </div>
        </Form>
      </FormProvider>

      <AlertDialogFooter className="pt-8">
        <Button
          onClick={onCancel}
          variant="tertiary"
        >
          Cancel
        </Button>

        <Button
          onClick={handleSubmit(onSubmit)}
          loading={isUpdatingCandidate}
          loadingText="Saving"
        >
          Save changes
        </Button>
      </AlertDialogFooter>
    </>
  );
};

const CandidateInfo: React.FC<{
  onSubmit: () => void;
  isSubmitting: boolean;
  setStep: (step: number) => void;
  post: TCandidateJobPost;
}> = ({ onSubmit, isSubmitting, setStep, post }) => {
  const { data: user } = useGetCurrentUser({});
  const { data: candidate } = useGetCurrentCandidate({});
  const [availability, setAvailability] = useState(candidate?.availability || '');
  const [isUpdated, setIsUpdated] = useState(false);
  const [selectedCity, setSelectedCity] = useState(candidate?.city);
  const { mutateAsync: updateCandidate, isPending: isUpdatingCandidate } = useUpdateCurrentCandidate({
    reactQueryOptions: {
      onSuccess: () => {
        setIsUpdated(false);
      },
    },
  });
  const onSave = async () => {
    if (!selectedCity || !availability) return;
    await updateCandidate({ body: { availability, city: selectedCity } });
  };

  const { data: availabilityList, isLoading: isAvailabilityLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.AVAILABILITY,
      },
    },
  });

  const { data: countryList } = useCountryList();

  const { data: cityList, isLoading: isCityListLoading } = useCityList(candidate?.country || '');
  const {
    setSearchText: setCitySearchText,
    filteredData: filteredCityList,
    searchText: citySearchText,
  } = useSearchable({
    data: cityList?.data || [],
    searchValue: selectedCity || '',
    limit: 10,
  });
  const selectedCountryCode = emojiToCountryCode(
    countryList?.find((country) => country.name.common?.toLowerCase() === candidate?.country?.toLowerCase())?.flag ||
      null
  );

  const { data: citiesList, isLoading: isCitiesListLoading } = useGetValuesMapByGroupAndCode({
    params: {
      query: {
        value_group: VALUES_GROUP.CITY,
        code: selectedCountryCode || '',
      },
    },
    reactQueryOptions: {
      enabled: selectedCountryCode === 'AU' || selectedCountryCode === 'NZ', // Enable only when country code is AU or NZ
    },
  });

  const formattedCitiesList =
    citiesList?.items.map((s) => s.description).filter((s): s is string => s !== undefined && s !== null) || [];

  const citiesOptions =
    selectedCountryCode === 'AU' || selectedCountryCode === 'NZ'
      ? formattedCitiesList.map((city) => ({
          value: city,
          label: city,
        })) || []
      : filteredCityList
          ?.filter((s): s is string => s !== undefined && s !== null)
          .map((city) => ({
            value: city,
            label: city,
          })) || [];
  const payType = PAY_TYPES.find((payType) => payType.id === post.pay_type);
  const { expectationRange } = getExpectationRangeForCandidate(candidate, payType?.id as PAY_TYPE);

  return (
    <div>
      <section className="space-y-3 rounded-lg border border-primary-dark-10 p-4 font-semibold">
        <div className="flex justify-between">
          <div className="flex items-center justify-between gap-2">
            <Avatar
              src={user?.image_url}
              size="sm"
            />
            <div className="max-w-[12rem] 3xl:max-w-[14rem]">
              <p className="truncate text-sm font-semibold">{getFullName(user)}</p>
              <p className="truncate text-xs text-primary-dark-40">{user?.email}</p>
            </div>
          </div>
          {isUpdated && (
            <Button
              size={'sm'}
              className="p-3"
              loading={isUpdatingCandidate}
              loadingText="Saving"
              onClick={onSave}
            >
              Save
            </Button>
          )}
        </div>
        <div className="grid grid-cols-[9.5rem_auto] items-center rounded-lg border border-primary-dark-10">
          <div className="flex items-center gap-2 border-r border-primary-dark-10 px-4 py-3">
            <UilClockThree className="!text-primary-dark-40 default-icon" />
            <span className="text-primary-dark-40">Availability</span>
          </div>
          <Select
            options={getOptionsForSelectFromValuesMap(availabilityList)}
            placeholder="Your Availability"
            selected={availability || null}
            onChange={(newAvailability) => {
              setAvailability(newAvailability);
              setIsUpdated(newAvailability !== candidate?.availability);
            }}
            isLoading={isAvailabilityLoading}
            disableAlphabeticalSorting
            className="border-none font-semibold shadow-none"
          />
        </div>
        <div className="grid grid-cols-[9.5rem_auto] items-center rounded-lg border border-primary-dark-10">
          <div className="flex items-center gap-2 border-r border-primary-dark-10 px-4 py-3">
            <UilUsdCircle className="!text-primary-dark-40 default-icon" />
            <span className="text-primary-dark-40">Pay</span>
          </div>
          <p className="inline-flex w-full items-center justify-between px-4 text-primary-dark-100">
            <span className="max-w-[12.5rem] truncate">
              {payType?.name} • {expectationRange}
            </span>
            <Button
              className="p-0"
              variant={'ghost'}
              onClick={() => setStep(2)}
            >
              <UilPen className="default-icon-md" />
            </Button>
          </p>
        </div>
        <div className="grid grid-cols-[9.5rem_auto] items-center rounded-lg border border-primary-dark-10">
          <div className="flex items-center gap-2 border-r border-primary-dark-10 px-4 py-3">
            <UilMapMarker className="!text-primary-dark-40 default-icon" />
            <span className="text-primary-dark-40">Location</span>
          </div>
          <ComboBoxInModal
            options={citiesOptions}
            selected={selectedCity ? { value: selectedCity, label: `${selectedCity}, ${candidate?.country}` } : null}
            onSelect={(value) => {
              setIsUpdated(value !== candidate?.city);
              setSelectedCity(value);
            }}
            isLoading={isCityListLoading || isCitiesListLoading}
            onQueryChange={setCitySearchText}
            searchTerm={citySearchText}
            placeholder="Start typing here…"
            className="border-none font-semibold shadow-none ring-0 focus:ring-0"
            error={!selectedCity}
          />
        </div>
        <p className="text-xs font-medium leading-[18px] text-primary-dark-40">
          I confirm that I have a valid right to work in country where I will be performing the work.
        </p>
      </section>
      <AlertDialogFooter className="pt-8">
        <AlertDialogCancel>Cancel</AlertDialogCancel>

        <Button
          onClick={onSubmit}
          loading={isSubmitting}
          loadingText="Applying"
          disabled={isSubmitting || isUpdated}
        >
          Apply
        </Button>
      </AlertDialogFooter>
    </div>
  );
};

const ApplyForRoleButton: React.FC<{
  post: TCandidateJobPost;
  disabled?: boolean;
  referrer?: string;
}> = ({ disabled, post, referrer }) => {
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();
  const { mutateAsync: applyForARole, isPending: isApplyLoading } = useApplyForARole({
    params: {
      path: {
        id: post.id.toString(),
      },
      query: {
        referrerToken: referrer,
      },
    },
    reactQueryOptions: {
      onSuccess: () => {
        setStep(3);
        toast.success('Application submitted successfully');
      },
    },
  });

  const handleApply = async () => {
    await applyForARole({
      params: {
        path: { id: post.id.toString() },
        query: {
          referrerToken: referrer,
        },
      },
    });
  };

  const renderContent = () => {
    if (step === 1) {
      return (
        <CandidateInfo
          post={post}
          onSubmit={handleApply}
          isSubmitting={isApplyLoading}
          setStep={setStep}
        />
      );
    }
    if (step === 2) {
      return (
        <CandidateExpectations
          payType={post.pay_type as PAY_TYPE}
          onSuccess={() => {
            setStep(1);
          }}
          onCancel={() => {
            setStep(1);
          }}
        />
      );
    }

    return null;
  };

  const renderDescription = () => {
    if (step === 1) {
      return 'Please make sure all of your profile information is up to date before applying.';
    }
    if (step === 2) {
      return 'Please make sure all of your profile information is up to date before applying.';
    }
    if (step === 3) {
      return (
        <p>
          {createPortal(
            <Fireworks
              autorun={{ speed: 3, duration: 900 }}
              className="fixed left-0 top-0 z-[99] h-full w-full"
            />,
            document.body
          )}
          You have successfully applied for {post.title} at {post.business.name}! We wish you the best of luck.
          <br />
          <br />
          Make sure to check your notifications for any updates!
        </p>
      );
    }
    return null;
  };

  const renderTitle = () => {
    if (step === 1) {
      return 'Before you apply!';
    }
    if (step === 2) {
      return 'Pay';
    }
    if (step === 3) {
      return 'Congratulations!';
    }
    return null;
  };

  const renderFooter = () => {
    if (step === 1) return;
    if (step === 2) return;
    return (
      <AlertDialogFooter>
        <AlertDialogAction
          onClick={() => {
            queryClient.invalidateQueries({
              queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_SINGLE_JOB_POST],
            });
          }}
        >
          Continue
        </AlertDialogAction>
      </AlertDialogFooter>
    );
  };

  return (
    <AlertDialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        setTimeout(() => setStep(1), 200);
      }}
    >
      <AlertDialogTrigger asChild>
        <Button
          disabled={disabled}
          className="w-full"
          size={'sm'}
        >
          Apply
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="gap-6">
        <AlertDialogHeader>
          <AlertDialogTitle>{renderTitle()}</AlertDialogTitle>
          <AlertDialogDescription className="pb-0">{renderDescription()}</AlertDialogDescription>
        </AlertDialogHeader>
        {renderContent()}
        {renderFooter()}
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ApplyForRoleButton;
