import { createFileRoute } from '@tanstack/react-router';

import ExpectationForm from '@/components/forms/candidate/expectations-form';

import useStepper from '@/hooks/useStepper';

export const Route = createFileRoute('/_authenticated/candidate/onboarding/_steps/step2')({
  component: Step2,
});

function Step2() {
  const { goToNextStep } = useStepper();

  return (
    <ExpectationForm
      onSuccess={goToNextStep}
      isOnboarding
    />
  );
}
