import { createFileRoute } from '@tanstack/react-router';

import EducationForm from '@/components/forms/candidate/education-form';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/settings/profile/education')({
  component: Education,
});

function Education() {
  return <EducationForm />;
}
