import { UilNotebooks } from '@iconscout/react-unicons';

import { Badge } from '@/components/ui/badge';
import { Card } from '@/components/ui/card';
import Separator from '@/components/ui/separator';

interface MatchingSkillsProps {
  candidateSkills: string[];
  roleSkills: string[];
}

const MatchingSkills = ({ candidateSkills, roleSkills }: MatchingSkillsProps) => {
  const matchingSkills = candidateSkills.filter((skill) => roleSkills.includes(skill));

  if (matchingSkills.length === 0) {
    return null;
  }

  return (
    <Card>
      <section className="flex gap-4">
        <Card className="flex items-center justify-center rounded-lg p-2.5 3xl:p-3">
          <UilNotebooks className="size-6 text-primary-dark-100 3xl:size-8" />
        </Card>
        <p className="font-semibold 2xl:text-xl">
          {matchingSkills.length} out of {roleSkills.length} skills match your profile
        </p>
      </section>
      <Separator size="md" />
      <section className="space-y-2">
        <h4 className="font-semibold">Matching Skills</h4>
        <div className="flex flex-wrap gap-2">
          {matchingSkills.map((skill) => (
            <Badge
              key={skill}
              variant="success"
              hideDot
            >
              {skill}
            </Badge>
          ))}
        </div>
      </section>
    </Card>
  );
};

export default MatchingSkills;
