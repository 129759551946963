import client, { TRequestOptions } from '@/lib/api';

import { PERMISSION_ENDPOINTS } from './permission.endpoints';
import {
  TGetBusinessPartnerPermissionsRequest,
  TGetBusinessUserPermissionsRequest,
  TPutBusinessPartnerPermissionsRequest,
  TPutBusinessUserPermissionsRequest,
} from './permission.types';

const { BUSINESS_PARTNER_PERMISSIONS, BUSINESS_USER_PERMISSIONS } = PERMISSION_ENDPOINTS;

export const getBusinessMemberPermissions = async ({
  signal,
  params,
}: TRequestOptions<TGetBusinessUserPermissionsRequest>) => {
  const { data } = await client.GET(BUSINESS_USER_PERMISSIONS, {
    signal,
    params,
  });

  return data?.data;
};

export const updateBusinessMemberPermissions = async ({
  signal,
  params,
  body,
}: TRequestOptions<TPutBusinessUserPermissionsRequest>) => {
  const { data } = await client.PUT(BUSINESS_USER_PERMISSIONS, {
    signal,
    params,
    body,
  });

  return data?.data;
};

export const getBusinessPartnerPermissions = async ({
  signal,
  params,
}: TRequestOptions<TGetBusinessPartnerPermissionsRequest>) => {
  const { data } = await client.GET(BUSINESS_PARTNER_PERMISSIONS, {
    signal,
    params,
  });

  return data?.data;
};

export const updateBusinessPartnerPermissions = async ({
  signal,
  params,
  body,
}: TRequestOptions<TPutBusinessPartnerPermissionsRequest>) => {
  const { data } = await client.PUT(BUSINESS_PARTNER_PERMISSIONS, {
    signal,
    params,
    body,
  });

  return data?.data;
};
