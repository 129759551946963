import React from 'react';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader } from '@/components/ui/card';

import { PersonalityArchetypeCode } from '@/services/candidate';

import { PERSONALITY_CODE_TO_EMOJI_MAP } from '@/constants/candidate';

interface IProps {
  onClick: () => void;
  code: PersonalityArchetypeCode;
  archetype: string;
  facets: string[];
  adjectives: string;
}

const PersonalityResultCard: React.FC<IProps> = ({ code, archetype, facets, adjectives, onClick }) => {
  return (
    <Card className="flex flex-col gap-14 px-8 py-12 shadow-2xl">
      <CardHeader className="flex flex-row items-start justify-between">
        <span className="text-7xl 3xl:text-8xl">{PERSONALITY_CODE_TO_EMOJI_MAP[code]}</span>
        <Button
          variant="tertiary"
          className="m-0 h-14"
          onClick={() => onClick()}
        >
          Score Breakdown
        </Button>
      </CardHeader>
      <CardContent className="flex flex-col gap-6">
        <div className="flex flex-col gap-1 text-xl font-semibold leading-[30px] text-primary-dark-100">
          <h3 className="text-sm font-semibold uppercase leading-tight text-primary-dark-40 after:content-[':']">
            ARCHETYPE
          </h3>
          {archetype}
        </div>
        <div className="flex flex-col gap-1 text-xl font-semibold leading-[30px] text-primary-dark-100">
          <h3 className="text-sm font-semibold uppercase leading-tight text-primary-dark-40 after:content-[':']">
            facets
          </h3>
          {facets.join(', ')}
        </div>
        <div className="flex flex-col gap-1 text-xl font-semibold leading-[30px] text-primary-dark-100">
          <h3 className="text-sm font-semibold uppercase leading-tight text-primary-dark-40 after:content-[':']">
            Adjectives
          </h3>
          {adjectives}
        </div>
      </CardContent>
    </Card>
  );
};

export default PersonalityResultCard;
