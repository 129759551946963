import { keepPreviousData, useQuery } from '@tanstack/react-query';

import {
  getAllCandidatesForPipeline,
  getCandidateDetailsForAPost,
  PIPELINE_ENDPOINTS,
  TGetAllCandidatesForPipelineRequest,
  TGetCandidateDetailsRequest,
} from '@/services/pipeline';

import { type UseQueryOptions } from '../types';

export function useGetAllCandidatesForPipeline({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetAllCandidatesForPipelineRequest & { items: { cardId: string }[] }>) {
  const { queryKey = [], ...rest } = reactQueryOptions;
  return useQuery({
    ...rest,
    queryKey: [PIPELINE_ENDPOINTS.GET_CANDIDATES_LIST_FOR_ROLES, params.path.businessId, ...queryKey],
    queryFn: ({ signal }) => getAllCandidatesForPipeline({ signal, params }),
    placeholderData: keepPreviousData,
  });
}

export function useGetAllCandidatesForPipelineBoard({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetAllCandidatesForPipelineRequest & { items: { cardId: string }[] }>) {
  const { queryKey = [], ...rest } = reactQueryOptions;
  return useQuery({
    ...rest,
    queryKey: [PIPELINE_ENDPOINTS.GET_CANDIDATES_LIST_FOR_ROLES, ...queryKey],
    queryFn: ({ signal }) => getAllCandidatesForPipeline({ signal, params }),
    placeholderData: keepPreviousData,
  });
}

export function useGetCandidateDetailsForAPost({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetCandidateDetailsRequest>) {
  const { ...rest } = reactQueryOptions;
  return useQuery({
    ...rest,
    queryKey: [
      PIPELINE_ENDPOINTS.GET_CANDIDATE_DETAILS,
      params.path.businessId,
      params.path.postId,
      params.path.userId,
    ],
    queryFn: ({ signal }) => getCandidateDetailsForAPost({ signal, params }),
  });
}
