import { z } from 'zod';

// ABN is an 11-digit number, and ACN is a 9-digit number.
const abnOrAcnRegex = /^(?:\d{11}|\d{9})$/;

export const businessProfileInfoSchema = z.object({
  logo_url: z.string().min(1, 'This is required'),
  name: z.string().min(1, 'Business name is required'),
  business_abn: z
    .string()
    .regex(abnOrAcnRegex, 'Your Business ABN/ACN must be either 11 digits (ABN) or 9 digits (ACN)')
    .min(1, 'Your Business ABN/ACN is required'),
  country: z.string().min(1, 'This is required'),
  city: z.string().min(1, 'This is required'),
});

export type TBusinessProfileInfoFormData = z.infer<typeof businessProfileInfoSchema>;
