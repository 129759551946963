export const GENDER_OPTIONS = [
  {
    value: 'He/Him/His',
    label: 'He/Him/His',
  },
  {
    value: 'She/Her/Hers',
    label: 'She/Her/Hers',
  },
  {
    value: 'Ze/Zir/Zirs',
    label: 'Ze/Zir/Zirs',
  },
  {
    value: 'Them/They/Theirs',
    label: 'Them/They/Theirs',
  },
  {
    value: 'I prefer not to say',
    label: 'I prefer not to say',
  },
];
