import * as React from 'react';
import { UilEye, UilEyeSlash } from '@iconscout/react-unicons';

import { Button } from '@/components/ui/button';
import { Input, type InputProps } from '@/components/ui/input';

import { cn } from '@/lib/utils';

const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>(({ className, ...props }, ref) => {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <div className="relative">
      <Input
        type={showPassword ? 'text' : 'password'}
        className={cn('pr-10', className)}
        ref={ref}
        {...props}
      />
      <Button
        type="button"
        variant="ghost"
        size="sm"
        className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
        onClick={() => setShowPassword((prev) => !prev)}
        // disabled={disabled}
      >
        {showPassword ? (
          <UilEye
            aria-hidden="true"
            className="default-icon-md"
          />
        ) : (
          <UilEyeSlash
            aria-hidden="true"
            className="default-icon-md"
          />
        )}
        <span className="sr-only">{showPassword ? 'Hide password' : 'Show password'}</span>
      </Button>
    </div>
  );
});
PasswordInput.displayName = 'PasswordInput';

export { PasswordInput };
