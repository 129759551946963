import { createFileRoute } from '@tanstack/react-router';

import BiosExtrasForm from '@/components/forms/candidate/bios-extras-from';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/settings/profile/bio')({
  component: Bio,
});

function Bio() {
  return <BiosExtrasForm />;
}
