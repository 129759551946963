import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { InputOTP, InputOTPGroup, InputOTPSlot } from '@/components/ui/input-otp';

export const twoFAVerificationSchema = z.object({
  otp: z.string().length(6).regex(/^\d+$/, 'Must contain only digits'),
});

const RESEND_COUNT = 120;

export type TTwoFAVerificationFormData = z.infer<typeof twoFAVerificationSchema>;

const TwoFAOtpVerificationForm = ({ onSuccess, phoneNumber }: { onSuccess: () => void; phoneNumber: string }) => {
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [resendCountdown, setResendCountdown] = useState(RESEND_COUNT);

  const resendOtp = () => {
    if (isResendDisabled) return;

    // Logic to resend the OTP
    setIsResendDisabled(true);
    setResendCountdown(RESEND_COUNT);
  };

  const form = useForm<TTwoFAVerificationFormData>({
    resolver: zodResolver(twoFAVerificationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (resendCountdown > 0) {
      timer = setTimeout(() => setResendCountdown(resendCountdown - 1), 1000);
    } else {
      setIsResendDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [resendCountdown]);

  const {
    handleSubmit,
    formState: { isValid },
    control,
  } = form;

  const onSubmit = (data: TTwoFAVerificationFormData) => {
    console.log(data);
    onSuccess();
  };

  return (
    <div className="space-y-4">
      <div>
        <h3 className="text-2xl font-semibold leading-loose text-primary-dark-100">Verify your number</h3>
        <p className="text-primary-dark-60">
          We’ve sent a 6 digit code to the mobile number ending in ****{phoneNumber.slice(-4)}.
        </p>
      </div>

      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormField
            control={control}
            name="otp"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Verification Code</FormLabel>
                <FormControl>
                  <InputOTP
                    maxLength={6}
                    {...field}
                    autoFocus
                  >
                    <InputOTPGroup>
                      <InputOTPSlot index={0} />
                      <InputOTPSlot index={1} />
                      <InputOTPSlot index={2} />
                      <InputOTPSlot index={3} />
                      <InputOTPSlot index={4} />
                      <InputOTPSlot index={5} />
                    </InputOTPGroup>
                  </InputOTP>
                </FormControl>
                <FormDescription>
                  {resendCountdown > 0 ? `Resend code in ${resendCountdown} seconds` : ''}
                </FormDescription>
              </FormItem>
            )}
          />
          <div className="flex flex-col gap-4">
            <Button
              type="submit"
              className="w-full"
              disabled={!isValid}
              size="md"
            >
              Continue
            </Button>
            <Button
              type="button"
              className="w-full"
              disabled={isResendDisabled}
              variant="tertiary"
              size="md"
              onClick={resendOtp}
            >
              Resend Code
            </Button>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};

export default TwoFAOtpVerificationForm;
