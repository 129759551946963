import { createFileRoute } from '@tanstack/react-router';

import { Skeleton } from '@/components/ui/skeleton';
import Heading from '@/components/ui/typography/heading';

import BackButton from '@/components/back-button';
import NoData from '@/components/no-data';
import RoleCard from '@/components/roles/role-card';
import RolesFilter from '@/components/roles/roles-filter';

import { useGetSavedPost } from '@/hooks/candidate';
import useFilters from '@/hooks/useFilters';

import { TCandidatePostsFilters, TCandidatePostsSort } from '@/services/candidate';

import { sortByToQuery } from '@/utils/table-sort-mapper';

import { PORTAL_IDS } from '@/constants/portal-ids';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/(roles-pages)/saved')({
  component: Saved,
});

const MatchesLoading = () => {
  return (
    <div className="grid grid-cols-1 gap-x-9 gap-y-8 xl:grid-cols-2 3xl:grid-cols-3">
      {new Array(6).fill(null).map((_, index) => (
        <Skeleton
          key={index}
          className="h-[300px]"
        />
      ))}
    </div>
  );
};

function Saved() {
  const { filters, setFilters } = useFilters<TCandidatePostsFilters>(Route.id);
  const sortQuery = sortByToQuery<TCandidatePostsSort>(filters.sortBy);

  const { data, isPending } = useGetSavedPost({
    params: {
      query: {
        limit: '20',
        offset: '0',
        q: filters.query,
        ...(sortQuery && {
          sort: sortQuery.id,
          order: sortQuery.order,
        }),
      },
    },
    reactQueryOptions: {
      queryKey: [filters],
    },
  });

  const renderData = () => {
    if (isPending) return <MatchesLoading />;

    if (data?.items.length === 0)
      return (
        <NoData
          title="No saved roles"
          description="You have not saved any roles yet"
        />
      );

    return (
      <div className="grid grid-cols-1 gap-x-9 gap-y-8 xl:grid-cols-2 3xl:grid-cols-3">
        {data?.items?.map((role) => (
          <RoleCard
            key={role.id}
            role={role}
            showSaveButton
            queryKey={[filters]}
            isFromSavedPage
          />
        ))}
      </div>
    );
  };

  return (
    <main className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-6">
          <BackButton backRoute="/candidate/roles" />
          <Heading
            title="Saved"
            size="page"
          />
        </div>
        <div className="flex items-center justify-between gap-4">
          <div id={PORTAL_IDS.rolesApplied}></div>
          <RolesFilter
            filters={filters}
            setFilters={setFilters}
          />
        </div>
      </div>
      {renderData()}
    </main>
  );
}
