import { create } from 'zustand';

interface INotificationPanelStore {
  showNotifications: boolean;
  unreadNotifications: number;
  setShowNotifications: (showNotifications: boolean) => void;
  setUnreadNotifications: (unreadNotifications: number) => void;
}

export const useNotificationPanelStore = create<INotificationPanelStore>()((set) => ({
  showNotifications: false,
  unreadNotifications: 0,
  setShowNotifications: (showNotifications) => set({ showNotifications }),
  setUnreadNotifications: (unreadNotifications) => set({ unreadNotifications }),
}));
