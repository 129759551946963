import GoogleMeetIcon from '@/assets/brands/google-meet.svg';
import MicrosoftTeamsIcon from '@/assets/brands/microsoft-teams.svg';
import ZoomIcon from '@/assets/brands/zoom.svg';

export const REMOTE_WORK_OPTIONS = {
  YES: 'Y',
  NO: 'N',
};

export enum INTERVIEW_PLATFORM_ID {
  GOOGLE_MEET = 'gmeet',
  MICROSOFT_TEAMS = 'teams',
  ZOOM = 'zoom',
}

//TODO: Move to constants
export const INTERVIEW_PLATFORMS_MAP: Record<
  INTERVIEW_PLATFORM_ID,
  {
    id: INTERVIEW_PLATFORM_ID;
    name: string;
    icon: string;
  }
> = {
  [INTERVIEW_PLATFORM_ID.GOOGLE_MEET]: {
    id: INTERVIEW_PLATFORM_ID.GOOGLE_MEET,
    name: 'Google Meet',
    icon: GoogleMeetIcon,
  },
  [INTERVIEW_PLATFORM_ID.MICROSOFT_TEAMS]: {
    id: INTERVIEW_PLATFORM_ID.MICROSOFT_TEAMS,
    name: 'Microsoft Teams',
    icon: MicrosoftTeamsIcon,
  },
  [INTERVIEW_PLATFORM_ID.ZOOM]: {
    id: INTERVIEW_PLATFORM_ID.ZOOM,
    name: 'Zoom',
    icon: ZoomIcon,
  },
};

export const INTERVIEW_PLATFORMS = Object.values(INTERVIEW_PLATFORMS_MAP);

export enum PAY_TYPE {
  DAY_RATE = 'day_rate',
  HOURLY_RATE = 'hourly_rate',
  ANNUAL_SALARY = 'annual_salary',
}

export const PAY_TYPES_MAP: Record<
  PAY_TYPE,
  {
    id: PAY_TYPE;
    name: string;
    shortName: string;
  }
> = {
  [PAY_TYPE.DAY_RATE]: {
    id: PAY_TYPE.DAY_RATE,
    name: 'Day Rate',
    shortName: '/day',
  },
  [PAY_TYPE.HOURLY_RATE]: {
    id: PAY_TYPE.HOURLY_RATE,
    name: 'Hourly Rate',
    shortName: '/hr',
  },
  [PAY_TYPE.ANNUAL_SALARY]: {
    id: PAY_TYPE.ANNUAL_SALARY,
    name: 'Salary',
    shortName: '/year',
  },
};

export const PAY_TYPES = Object.values(PAY_TYPES_MAP);

//TODO: remove if duplicated and use enum
export const JOB_STATUS_MAP: Record<number, string> = {
  1: 'Open',
  2: 'Deleted',
  3: 'Hold',
  4: 'Closed',
  5: 'Draft',
};

export enum JOB_POST_STATUS {
  OPEN = 1,
  DELETED = 2,
  HOLD = 3,
  CLOSED = 4,
  DRAFT = 5,
}
