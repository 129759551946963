import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';

import { AlertDialogCancel, AlertDialogFooter } from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { MultiSelect } from '@/components/ui/multi-select-new';
import { Select } from '@/components/ui/select-new';

import { useGetCurrentBusiness, useInviteBusinessPartner } from '@/hooks/business';

import { TAccountTypeSelectOption } from '@/constants/business-team';

import { invitePartnerSchema, TInvitePartnerFormData } from '@/validation-schemas/business/invite-partner-schema';

const accountTypeOptions = [
  // NOTE: Commented out options are not used in the current implementation
  // {
  //   label: 'Admin',
  //   value: 'admin',
  //   description: 'Can change member permissions. Can invite team members to the company page.',
  // },
  // {
  //   label: 'Member',
  //   value: 'member',
  //   description:
  //     'Cannot change company page settings or invite team members. Permissions granted by Workspace Owner and/or Admin user.',
  // },
  {
    label: 'View only',
    value: 'view_only',
    description:
      'Can view roles and pipeline only and no action(s) can be taken. Users will need to be promoted to Members by Workspace Owner and/or Admin users if further access is required.',
  },
];

const categoryOptions = ['Product', 'Design', 'HR', 'Admin'];

export const InvitePartnerForm: React.FC<{ onInviteSent: () => void }> = ({ onInviteSent }) => {
  const { data: business } = useGetCurrentBusiness({});

  const form = useForm<TInvitePartnerFormData>({
    resolver: zodResolver(invitePartnerSchema),
    defaultValues: {
      account_type: '',
      email: '',
      categories: [],
      name: '',
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = form;

  const { mutate: invitePartner, isPending: isInvitePartnerPending } = useInviteBusinessPartner({
    params: { path: { businessId: business?.id?.toString() ?? '' } },
    reactQueryOptions: {
      onSuccess: () => {
        onInviteSent();
      },
    },
  });

  const onSubmit: SubmitHandler<TInvitePartnerFormData> = (data) => {
    invitePartner({
      params: {
        path: {
          businessId: business?.id?.toString() ?? '',
        },
      },
      body: {
        email: data.email,
        categories: data?.categories?.join(';') || null,
        account_type: data.account_type as TAccountTypeSelectOption['value'],
        name: data.name,
      },
    });
  };

  return (
    <FormProvider {...form}>
      <Form
        className="space-y-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormField
          name="name"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Company name</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  error={!!errors?.name?.message}
                  placeholder="Company name"
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="account_type"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Account type</FormLabel>
              <FormControl>
                <Select
                  options={accountTypeOptions}
                  selected={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.account_type?.message}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="email"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  error={!!errors?.email?.message}
                  placeholder="Email address"
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="categories"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Tags (Optional)</FormLabel>
              <FormControl>
                <MultiSelect
                  options={categoryOptions.map((option) => ({ label: option, value: option }))}
                  selected={field.value || []}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.categories?.message}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <AlertDialogFooter>
          <AlertDialogCancel asChild>
            <Button
              type="button"
              variant="tertiary"
            >
              Cancel
            </Button>
          </AlertDialogCancel>
          <Button
            type="submit"
            disabled={isInvitePartnerPending}
          >
            {isInvitePartnerPending ? 'Sending...' : 'Send invite'}
          </Button>
        </AlertDialogFooter>
      </Form>
    </FormProvider>
  );
};
