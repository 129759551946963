import { useEffect } from 'react';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';

import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { MultiSelect } from '@/components/ui/multi-select-new';
import { toast } from '@/components/ui/toaster';

import OnboardingFooter from '@/components/onboarding/footer';

import { useGetCurrentCandidateSuspense, useUpdateCurrentCandidate } from '@/hooks/candidate';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { getArrayFromString, getFormattedDataForAPI } from '@/utils/format';
import { getOptionsForSelectFromValuesMap } from '@/utils/string';

import { VALUES_GROUP } from '@/constants/values-map';

import FormFooter from '../form-footer';
import FormNavigationBlocker from '../form-navigation-blocker';
import { IFormProps } from '../types';

type FormValues = {
  looking_for: string[];
  business_size: string[];
  roles_interested: string[];
};

const resolver: Resolver<FormValues> = async (values) => {
  const errors: Record<keyof FormValues, { type: string; message: string }> = {} as Record<
    keyof FormValues,
    { type: string; message: string }
  >;

  if (!values.looking_for || values.looking_for.length === 0) {
    errors.looking_for = {
      type: 'required',
      message: 'This is required.',
    };
  }
  if (!values.business_size || values.business_size.length === 0) {
    errors.business_size = {
      type: 'required',
      message: 'This is required.',
    };
  }
  if (!values.roles_interested || values.roles_interested.length === 0) {
    errors.roles_interested = {
      type: 'required',
      message: 'This is required.',
    };
  }

  return {
    values: Object.keys(errors).length === 0 ? values : {},
    errors,
  };
};
const WorkForm: React.FC<IFormProps> = ({ onSuccess, isOnboarding }) => {
  const { data } = useGetCurrentCandidateSuspense({});
  const form = useForm<FormValues>({
    defaultValues: {
      looking_for: [],
      business_size: [],
      roles_interested: [],
    },
    resolver,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = form;

  const { mutateAsync: updateCandidate, isPending: isUpdatingCandidate } = useUpdateCurrentCandidate({
    reactQueryOptions: {
      onSuccess: () => {
        if (!isOnboarding) {
          toast.success('Account updated successfully!');
        }
        onSuccess?.();
      },
    },
  });

  const { data: lookingForData, isLoading: isLookingForLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.WORK_TYPE,
      },
    },
  });

  const { data: rolesData, isLoading: isRolesLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.ROLES,
      },
    },
  });

  const { data: sizeOfBusinessData, isLoading: isSizeOfBusinessLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.BUSINESS_TYPES,
      },
    },
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    updateCandidate({ body: { ...getFormattedDataForAPI(data) } });
  };

  useEffect(() => {
    if (data) {
      const initialValues: FormValues = {
        looking_for: data?.looking_for ? getArrayFromString(data.looking_for) : [],
        business_size: data?.business_size ? getArrayFromString(data.business_size) : [],
        roles_interested: data?.roles_interested ? getArrayFromString(data.roles_interested) : [],
      };
      reset(initialValues);
    }
  }, [data, reset]);

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          name="looking_for"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Work types</FormLabel>
              <FormControl>
                <MultiSelect
                  selected={field.value}
                  options={getOptionsForSelectFromValuesMap(lookingForData)}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.looking_for}
                  isLoading={isLookingForLoading}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="business_size"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Business types</FormLabel>
              <FormControl>
                <MultiSelect
                  selected={field.value}
                  options={getOptionsForSelectFromValuesMap(sizeOfBusinessData)}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.business_size}
                  isLoading={isSizeOfBusinessLoading}
                  disableAlphabeticalSorting
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="roles_interested"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Role types</FormLabel>
              <FormControl>
                <MultiSelect
                  selected={field.value}
                  options={getOptionsForSelectFromValuesMap(rolesData)}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.roles_interested}
                  isLoading={isRolesLoading}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>
      {isOnboarding ? (
        <OnboardingFooter
          isLoading={isUpdatingCandidate}
          handleNext={handleSubmit(onSubmit)}
        />
      ) : (
        <>
          <FormNavigationBlocker />
          <FormFooter
            onSubmit={handleSubmit(onSubmit)}
            isSubmitting={isUpdatingCandidate}
          />
        </>
      )}
    </FormProvider>
  );
};

export default WorkForm;
