import { paths } from '@/lib/api/v1';

export const PIPELINE_ENDPOINTS = {
  GET_CANDIDATES_LIST_FOR_ROLES: '/v2/business/{businessId}/pipeline/candidate-list',
  GET_CANDIDATE_DETAILS: '/v2/business/{businessId}/pipeline/job-posts/{postId}/candidates/{userId}',
  POST_REINSTATE_CANDIDATE: '/v2/business/{businessId}/pipeline/job-posts/{postId}/candidates/{userId}/reinstate',
  POST_SHORTLIST_CANDIDATE: '/v2/business/{businessId}/pipeline/job-posts/{postId}/candidates/{userId}/shortlist',
  POST_MOVE_TO_INTERVIEW_STAGE:
    '/v2/business/{businessId}/pipeline/job-posts/{postId}/candidates/{userId}/schedule-interview',
  POST_OFFER_CANDIDATE: '/v2/business/{businessId}/pipeline/job-posts/{postId}/candidates/{userId}/make-offer',
  POST_REJECT_CANDIDATE: '/v2/business/{businessId}/pipeline/job-posts/{postId}/candidates/{userId}/reject',
  POST_HIRE_CANDIDATE: '/v2/business/{businessId}/pipeline/job-posts/{postId}/candidates/{userId}/hire',
  POST_FAVORITE_CANDIDATE: '/v2/business/{businessId}/pipeline/job-posts/{postId}/candidates/{userId}/favorite',
  POST_UNFAVORITE_CANDIDATE: '/v2/business/{businessId}/pipeline/job-posts/{postId}/candidates/{userId}/unfavorite',
} satisfies Record<string, keyof paths>;
