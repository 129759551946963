import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { UilArrowLeft } from '@iconscout/react-unicons';
import { useNavigate, useParams } from '@tanstack/react-router';
import { FormProvider, useForm } from 'react-hook-form';

import Avatar from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { PasswordInput } from '@/components/ui/password-input';

import FileUploader from '@/components/file-uploader';
import { Logo } from '@/components/logo';

import { useBusinessInviteSetup } from '@/hooks/business';

import { createPasswordSchema, TCreatePasswordFormData } from '@/validation-schemas/invitation-password-schema';
import { inviteProfileSetupSchema, TInviteProfileSetupFormData } from '@/validation-schemas/invitation-profile-schema';

import TwoFASetupForm from './2fa-setup-form';
import TwoFAOtpVerificationForm from './2fa-verification-form';

enum Steps {
  CreatePassword = 1,
  TwoFASetup = 2,
  TwoFAVerification = 3,
  ProfileSetup = 4,
}

export const BusinessInvitationForm = () => {
  const [step, setStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState('');

  const navigate = useNavigate({ from: '/invite/$token' });

  const { token } = useParams({ from: '/invite/$token/' });

  const { mutate: inviteBusinessSetup, isPending } = useBusinessInviteSetup({
    reactQueryOptions: {
      onSuccess: () => {
        navigate({ to: `/invite/$token/success` });
      },
    },
  });

  // Separate form instances for each step
  const passwordForm = useForm<TCreatePasswordFormData>({
    resolver: zodResolver(createPasswordSchema),
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
    mode: 'onChange',
  });

  const profileForm = useForm<TInviteProfileSetupFormData>({
    resolver: zodResolver(inviteProfileSetupSchema),
    defaultValues: {
      img_url: '',
      first_name: '',
      last_name: '',
    },
    mode: 'onChange',
  });

  // Keep track of password for final submission
  const [savedPassword, setSavedPassword] = useState('');

  const onSubmit = async (data: TCreatePasswordFormData | TInviteProfileSetupFormData) => {
    if (step === Steps.CreatePassword) {
      // Step 1: Create password
      const passwordData = data as TCreatePasswordFormData;
      setSavedPassword(passwordData.newPassword);
      // TODO: Navigate to 2FA setup
      // setStep(Steps.TwoFASetup);
      // NOW: Navigate to profile setup
      setStep(Steps.ProfileSetup);
    } else if (step === Steps.TwoFASetup) {
      // TODO: Step 2: Set up 2FA
    } else if (step === Steps.TwoFAVerification) {
      // TODO: Step 3: 2FA Verification
    } else if (step === Steps.ProfileSetup) {
      // TODO: Step 4: Profile setup
      const profileData = data as TInviteProfileSetupFormData;
      inviteBusinessSetup({
        body: {
          token: token,
          image_url: profileData.img_url || null,
          first_name: profileData.first_name,
          last_name: profileData.last_name,
          password: savedPassword,
        },
      });
    }
  };

  const handleFileUpload = (url: string) => {
    profileForm.setValue('img_url', url, { shouldDirty: true });
  };

  const renderStep1 = () => (
    <FormProvider {...passwordForm}>
      <Form
        className="space-y-4"
        onSubmit={passwordForm.handleSubmit(onSubmit)}
      >
        <div className="w-full pb-8">
          <Logo
            variant="full"
            className="mx-auto"
          />
        </div>
        <div className="flex w-full flex-col gap-4 py-4 text-center">
          <span className="text-base font-medium leading-normal text-primary-dark-60">
            Accept your invite by creating your password.
          </span>
        </div>
        <FormField
          control={passwordForm.control}
          name="newPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Create password</FormLabel>
              <FormControl>
                <PasswordInput
                  {...field}
                  placeholder="New password"
                  error={!!passwordForm.formState.errors?.newPassword}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          control={passwordForm.control}
          name="confirmPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Confirm password</FormLabel>
              <FormControl>
                <PasswordInput
                  {...field}
                  placeholder="Re-enter new password"
                  error={!!passwordForm.formState.errors?.confirmPassword}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <Button
          className="w-full"
          type="submit"
          size={'sm'}
        >
          Continue
        </Button>
      </Form>
    </FormProvider>
  );

  const renderStep2 = () => {
    return (
      <TwoFASetupForm
        onSuccess={(data) => {
          setStep(Steps.TwoFAVerification);
          setPhoneNumber(data.phoneNumber);
        }}
        defaultPhoneNumber={phoneNumber}
      />
    );
  };

  const renderStep3 = () => {
    return (
      <TwoFAOtpVerificationForm
        phoneNumber={phoneNumber}
        onSuccess={() => {
          setStep(Steps.ProfileSetup);
        }}
      />
    );
  };

  const renderStep4 = () => (
    <FormProvider {...profileForm}>
      <Form
        className="space-y-4"
        onSubmit={profileForm.handleSubmit(onSubmit)}
      >
        <div className="flex w-full flex-col gap-4 py-4">
          <span className="text-2xl font-semibold leading-loose text-primary-dark-100">Set up your profile</span>
          <span className="text-base font-medium leading-normal text-primary-dark-60">
            Complete your profile information.
          </span>
        </div>
        <div className="flex h-[70px] items-center gap-4">
          <Avatar
            src={profileForm.getValues('img_url')}
            size="xl"
          />
          <div className="flex flex-col gap-4">
            <FileUploader
              acceptedFileTypes={['image/jpeg', 'image/png']}
              keyPrefix={`business/`}
              onFileUpload={handleFileUpload}
            />
            <p className="text-xs font-medium leading-[18px] text-primary-dark-60">
              Upload a JPEG or PNG file no bigger than 6mb
            </p>
          </div>
        </div>
        <FormField
          control={profileForm.control}
          name="first_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>First name</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="First name"
                  error={!!profileForm.formState.errors?.first_name}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          control={profileForm.control}
          name="last_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Last name</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="Last name"
                  error={!!profileForm.formState.errors?.last_name}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <Button
          className="w-full"
          type="submit"
          size={'sm'}
          loading={isPending}
          loadingText="Setting up"
        >
          Continue
        </Button>
      </Form>
    </FormProvider>
  );

  const renderBackButton = () => {
    if (step === Steps.TwoFASetup || step === Steps.TwoFAVerification) {
      return (
        <div className="w-full pb-4">
          <Button
            variant={'ghost'}
            onClick={() => setStep(step - 1)}
            type="button"
            className="pl-0"
          >
            <UilArrowLeft className="size-8 text-primary-dark-60" />
          </Button>
        </div>
      );
    }
  };

  return (
    <>
      {renderBackButton()}
      {step === Steps.CreatePassword && renderStep1()}
      {step === Steps.TwoFASetup && renderStep2()}
      {step === Steps.TwoFAVerification && renderStep3()}
      {step === Steps.ProfileSetup && renderStep4()}
      {step === Steps.CreatePassword && (
        <div className="mt-6">
          <span className="text-sm font-medium leading-tight text-primary-dark-100">
            By selecting &apos;Continue&apos; you agree to Upplft&apos;s{' '}
            <span className="text-primary-blue-100">
              <a
                href="https://www.upplft.com/terms-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </a>
            </span>{' '}
            and{' '}
            <span className="text-primary-blue-100">
              <a
                href="https://www.upplft.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </span>
          </span>
        </div>
      )}
    </>
  );
};
