import { isToday } from 'date-fns';
import { formatDate } from 'date-fns/format';
import { DateHeaderProps } from 'react-big-calendar';

const DateHeader: React.FC<DateHeaderProps> = ({ date, onDrillDown }) => (
  <div
    className="inline-flex cursor-pointer items-center gap-[0.4375rem]"
    onClick={onDrillDown}
  >
    {isToday(date) && <span className="size-1.5 rounded-full bg-primary-blue-100" />}
    <span className={isToday(date) ? 'text-primary-blue-100' : ''}>{formatDate(date, 'd')}</span>
  </div>
);

export default DateHeader;
