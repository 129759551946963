import { useCreateRoleStore } from '@/store/create-role-store';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { createPortal } from 'react-dom';
import { useFormContext } from 'react-hook-form';

import { Button } from '@/components/ui/button';
import { toast } from '@/components/ui/toaster';

import { useGetCurrentBusiness } from '@/hooks/business';
import { useUpdateJobPost } from '@/hooks/business-roles';

import { mapCreateRoleFormDataToAPI } from '@/services/business-roles';

import { PORTAL_IDS } from '@/constants/portal-ids';

import {
  TFinalFormData,
  TStep1FormData,
  TStep2FormData,
  TStep3FormData,
  TStep4FormData,
  TStep5FormData,
  TStep6FormData,
  TStep7FormData,
} from '@/validation-schemas/business/create-role-schema';

const SaveChangesButton = ({ usePortal = true }: { usePortal?: boolean }) => {
  const { data } = useCreateRoleStore();
  const navigate = useNavigate();
  const { mode, id, backRoute } = useSearch({
    from: '/_authenticated/business/create-role',
  });

  const form = useFormContext<
    | TStep1FormData
    | TStep2FormData
    | TStep3FormData
    | TStep4FormData
    | TStep5FormData
    | TStep6FormData
    | TStep7FormData
    | TFinalFormData
  >();

  const { data: currentBusiness } = useGetCurrentBusiness({});
  const { mutate: updateJobPost, isPending: isUpdatingJobPost } = useUpdateJobPost({
    params: { path: { businessId: currentBusiness?.id.toString() ?? '', postId: id?.toString() || '' } },
  });

  const dashboardContainer = document.getElementById(PORTAL_IDS.saveAsDraft);

  if (!dashboardContainer) return null;
  if (mode !== 'edit') return null;

  const onSaveChanges = () => {
    const draftData = form
      ? {
          ...data,
          ...form.getValues(),
        }
      : data;

    updateJobPost(
      {
        body: mapCreateRoleFormDataToAPI(draftData, false),
        params: { path: { businessId: currentBusiness?.id.toString() ?? '', postId: id?.toString() || '' } },
      },
      {
        onSuccess: () => {
          toast.success('Role updated');
          if (backRoute) {
            navigate({ to: backRoute });
          } else {
            navigate({ to: '/business/roles' });
          }
        },
        onError: () => toast.error('Failed to update role'),
      }
    );
  };

  const isButtonDisabled = form ? !form?.formState?.isValid : false;

  const renderButton = () => (
    <Button
      onClick={onSaveChanges}
      loadingText="Saving"
      loading={isUpdatingJobPost}
      disabled={isButtonDisabled}
    >
      Save changes
    </Button>
  );

  if (usePortal) {
    return createPortal(renderButton(), dashboardContainer);
  }

  return renderButton();
};

export default SaveChangesButton;
