import { useQuery } from '@tanstack/react-query';

import { getGlobalSearchResults, GLOBAL_SEARCH_ENDPOINTS, TGlobalSearchRequest } from '@/services/global-search';

import { UseQueryOptions } from '../types';

export const useGlobalSearch = ({ reactQueryOptions, params }: UseQueryOptions<TGlobalSearchRequest>) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [GLOBAL_SEARCH_ENDPOINTS.SEARCH, params?.query?.q],
    queryFn: ({ signal }) => getGlobalSearchResults({ signal, params }),
  });
};
