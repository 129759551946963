import { useNavigate } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardTitle } from '@/components/ui/card';

import PersonalityCard from '@/components/personality-card';

import { useGetCurrentCandidate } from '@/hooks/candidate';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { PersonalityArchetypeCode } from '@/services/candidate';

import { VALUES_GROUP } from '@/constants/values-map';

import LoadingCard from './loading-card';

const CandidatePersonalityCard = () => {
  const { data: candidate, isLoading } = useGetCurrentCandidate({});
  const { data: personalityValuesMap, isLoading: isPersonalityValuesMapLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        value_group: VALUES_GROUP.PERSONALITY_FACTORS,
        offset: '0',
        limit: '6',
      },
    },
  });
  const navigate = useNavigate();

  if (isLoading || isPersonalityValuesMapLoading) return <LoadingCard />;

  const personality = personalityValuesMap?.items?.find((item) => item.code === candidate?.personality_archetype);

  if (!personality || !personality?.description) return null;

  const personalityCode = candidate?.personality_archetype as PersonalityArchetypeCode;

  return (
    <Card className="space-y-3">
      <CardTitle>Personality</CardTitle>
      <CardContent className="space-y-4">
        <PersonalityCard personalityCode={personalityCode} />
        <div className="flex w-full space-x-4">
          <Button
            className="flex-1"
            onClick={() => navigate({ to: '/candidate/quiz/personality/result' })}
          >
            View score
          </Button>
          <Button
            variant="tertiary"
            className="flex-1"
            onClick={() => navigate({ to: '/candidate/quiz/personality' })}
          >
            Re-take the quiz
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default CandidatePersonalityCard;
