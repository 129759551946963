import { useState } from 'react';
import { useCreateRoleStore } from '@/store/create-role-store';
import {
  closestCorners,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { createFileRoute } from '@tanstack/react-router';
import { createPortal } from 'react-dom';

import { Button } from '@/components/ui/button';
import Heading from '@/components/ui/typography/heading';

import DraggableCultureCard from '@/components/roles/business/create-role/quiz/draggable-culture-card';
import DroppableContainer from '@/components/roles/business/create-role/quiz/droppable-container';
import { getIndexFromId, updateTraits } from '@/components/roles/business/create-role/quiz/utils';

import useCreateRoleStepper from '@/hooks/useCreateRoleStepper';

import { CultureName } from '@/services/candidate';

export const Route = createFileRoute('/_authenticated/business/create-role/step5/culture-quiz')({
  component: CultureQuiz,
});

function CultureQuiz() {
  const { skipCultureQuiz, goToStep, currentStep } = useCreateRoleStepper();
  const { data, setStepData } = useCreateRoleStore();
  // Initial state setup
  const [initialTraits, setInitialTraits] = useState<(CultureName | null)[]>(
    data?.cultureFactors.length
      ? Array(4).fill(null)
      : [CultureName.CLAN, CultureName.ADHOCRACY, CultureName.MARKET, CultureName.HIERARCHY]
  );
  const [finalTraits, setFinalTraits] = useState<(CultureName | null)[]>(
    data?.cultureFactors.length ? data?.cultureFactors : Array(4).fill(null)
  );
  const [activeCode, setActiveCode] = useState<CultureName | null>(null);

  // Set up sensors for drag and drop
  const sensors = useSensors(useSensor(TouchSensor), useSensor(PointerSensor));

  // Handle the start of a drag operation
  const handleDragStart = (event: DragStartEvent) => {
    setActiveCode(event.active.id as CultureName);
  };

  // Handle drag over events
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!active.id || !over?.id) {
      setActiveCode(null);
      return;
    }

    const activeId = active.id as CultureName;
    const activeType = active.data.current?.type;
    const overType = over.data.current?.type;
    const targetIndex = getIndexFromId(over.id as string);

    if (activeType === overType) {
      // Moving within the same container
      const traits = activeType === 'initial' ? initialTraits : finalTraits;
      const setTraits = activeType === 'initial' ? setInitialTraits : setFinalTraits;
      const currentIndex = traits.findIndex((trait) => trait === activeId);

      // Swap positions within the same container
      setTraits((prev) => {
        const newTraits = [...prev];
        [newTraits[currentIndex], newTraits[targetIndex]] = [newTraits[targetIndex], newTraits[currentIndex]];
        return newTraits;
      });
    } else {
      // Moving between containers
      const sourceTraits = activeType === 'initial' ? initialTraits : finalTraits;
      const targetTraits = activeType === 'initial' ? finalTraits : initialTraits;
      const setSourceTraits = activeType === 'initial' ? setInitialTraits : setFinalTraits;
      const setTargetTraits = activeType === 'initial' ? setFinalTraits : setInitialTraits;

      if (!targetTraits[targetIndex]) {
        const currentIndex = sourceTraits.indexOf(activeId);
        setSourceTraits((prev) => updateTraits(prev, currentIndex, currentIndex, null));
        setTargetTraits((prev) => updateTraits(prev, targetIndex, targetIndex, activeId));
      }
    }

    setActiveCode(null);
  };

  // Create a portal for the drag overlay
  const renderDragOverlay = createPortal(
    <DragOverlay zIndex={30}>{activeCode ? <DraggableCultureCard cultureName={activeCode} /> : null}</DragOverlay>,
    document.body
  );

  const handleSubmit = () => {
    setStepData({
      ...data,
      cultureFactors: finalTraits,
    });

    goToStep(currentStep, 'results');
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCorners}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <main className="space-y-10 2xl:space-y-12 3xl:space-y-16">
        <header className="text-center">
          <Heading
            title="Culture quiz"
            subtitle="Please click, drag and drop each of the following traits in order 1 to 4 in terms of most to least suitable for this role."
            size="title"
          />
        </header>
        <section className="mx-auto flex max-w-[58.5rem] flex-col gap-10 2xl:gap-12 3xl:gap-16">
          {/* Initial traits container */}
          <div className="grid grid-cols-2 gap-5 2xl:gap-6 3xl:gap-8">
            {initialTraits.map((trait, index) => (
              <DroppableContainer
                key={index}
                id={`i${index}`}
                type="initial"
              >
                {trait && (
                  <DraggableCultureCard
                    cultureName={trait}
                    type="initial"
                  />
                )}
              </DroppableContainer>
            ))}
          </div>
          {/* Final traits container */}
          <div className="grid grid-cols-2 gap-6 3xl:gap-8">
            {finalTraits.map((trait, index) => (
              <DroppableContainer
                key={index}
                id={`f${index}`}
                title={String(index + 1)}
                subtitle={index === 0 ? 'Most suitable' : index === finalTraits.length - 1 ? 'Least suitable' : ''}
                type="final"
              >
                {trait && (
                  <DraggableCultureCard
                    cultureName={trait}
                    type="final"
                  />
                )}
              </DroppableContainer>
            ))}
          </div>
        </section>
        <footer className="flex justify-center gap-4">
          <Button
            variant="tertiary"
            className="w-[6rem]"
            onClick={skipCultureQuiz}
          >
            Skip
          </Button>
          <Button
            className="w-[6rem]"
            disabled={finalTraits.some((trait) => trait === null)}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </footer>
      </main>
      {renderDragOverlay}
    </DndContext>
  );
}

export default CultureQuiz;
