import { createFileRoute, useNavigate } from '@tanstack/react-router';

import BusinessBiosExtrasForm from '@/components/forms/business/business-bios-extras-form';

export const Route = createFileRoute('/_authenticated/business/onboarding/_steps/step4')({
  component: Step4,
});

function Step4() {
  const navigate = useNavigate();
  return (
    <BusinessBiosExtrasForm
      onSuccess={() => navigate({ to: '/business/onboarding/completed' })}
      isOnboarding
    />
  );
}
