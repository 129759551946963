import { cn } from '@/lib/utils';

import Spinner from './ui/spinner';

const FullPageLoader = ({ className }: { className?: string }) => {
  return (
    <div className={cn('flex h-[100dvh] w-screen items-center justify-center', className)}>
      <Spinner size="xl" />
    </div>
  );
};

export default FullPageLoader;
