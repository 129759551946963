import { useCreateRoleStore } from '@/store/create-role-store';
import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';

import { FormProvider } from '@/components/ui/form';

import CreateRoleFooter from '@/components/roles/business/create-role/create-role-footer';
import CreateRoleNavigation from '@/components/roles/business/create-role/create-role-navigation';
import Step3Fields from '@/components/roles/business/create-role/form-fields/step3-fields';
import SaveAsDraftButton from '@/components/roles/business/create-role/save-as-draft-button';
import SaveChangesButton from '@/components/roles/business/create-role/save-changes-button';

import { step3Schema, TStep3FormData } from '@/validation-schemas/business/create-role-schema';

export const Route = createFileRoute('/_authenticated/business/create-role/step3')({
  component: Step3,
});

function Step3() {
  const { data, setStepData } = useCreateRoleStore();

  const form = useForm<TStep3FormData>({
    resolver: zodResolver(step3Schema),
    values: {
      ...data,
    },
    mode: 'onChange',
  });

  const onSubmit = (data: TStep3FormData) => {
    setStepData(data);
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex h-full flex-col justify-between"
      >
        <Step3Fields />
        <CreateRoleFooter onSubmit={form.handleSubmit(onSubmit)} />
        <SaveChangesButton />
        <SaveAsDraftButton />
      </form>
      <CreateRoleNavigation saveFormData={form.handleSubmit(onSubmit)} />
    </FormProvider>
  );
}

export default Step3;
