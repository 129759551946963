import { UilPause, UilSuitcase, UilTrafficBarrier } from '@iconscout/react-unicons';
import { useNavigate } from '@tanstack/react-router';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownTriggerButton,
} from '@/components/ui/dropdown-menu';

interface RolesTableActionButtonProps {
  id: string;
}

export function RolesTableActionButton({ id }: RolesTableActionButtonProps) {
  const navigate = useNavigate();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <DropdownTriggerButton />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem onClick={() => navigate({ to: `/business/role/${id}` })}>
          <UilSuitcase className="text-primary-dark-60" /> View role
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate({ to: `/business/role/${id}` })}>
          <UilPause className="text-primary-dark-60" /> Pause
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate({ to: `/business/role/${id}` })}>
          <UilTrafficBarrier className="text-primary-dark-60" /> Close
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
