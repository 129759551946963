import { z } from 'zod';

export const experienceSchema = z
  .object({
    companyName: z.string().min(1, 'Company name is required'),
    role: z.string().min(1, 'Your title is required'),
    startDate: z
      .date({
        required_error: 'Start date is required',
        invalid_type_error: 'Invalid start date',
      })
      .min(new Date('1900-01-01'), 'Invalid start date')
      .max(new Date(), 'Start date cannot be in the future'),
    endDate: z.union([z.date().optional(), z.null()]).optional(),
    workingCurrently: z.boolean().default(false),
  })
  .superRefine((data, ctx) => {
    if (!data.workingCurrently && !data.endDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['endDate'],
        message: 'End date is required if not currently working',
      });
    } else if (data.endDate && data.endDate < data.startDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['endDate'],
        message: 'End date must be after start date',
      });
    }
  });

export type TExperienceFormData = z.infer<typeof experienceSchema>;
