import { UilSlidersVAlt } from '@iconscout/react-unicons';

import { Button } from '@/components/ui/button';

const FilterButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      variant="tertiary"
      onClick={onClick}
    >
      <span>Filter</span>
      <UilSlidersVAlt className="size-[1.125rem] text-primary-dark-100" />
    </Button>
  );
};

export default FilterButton;
