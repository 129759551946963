import { UilArrowUpRight, UilHeadphonesAlt } from '@iconscout/react-unicons';
import { Link } from '@tanstack/react-router';
import { format } from 'date-fns';

import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';

import NoData from '@/components/no-data';

import { useGetUpcomingMeetings } from '@/hooks/meetings';

import { TUpcomingMeeting } from '@/services/meetings';

const InterviewCard = ({ start_time, end_time, candidate, stage_name }: TUpcomingMeeting) => {
  if (!start_time || !end_time) return null;
  const startTime = new Date(start_time);
  const endTime = new Date(end_time);

  // Format strings
  const dayAndDateFormat = 'MMMM d'; // Format for day and date
  const timeFormat = 'h:mm a'; // Format for time in 12-hour format

  // Format the start date
  const formattedDayAndDate = format(startTime, dayAndDateFormat);
  const formattedStartTime = format(startTime, timeFormat);

  // Format the end time
  const formattedEndTime = format(endTime, timeFormat);

  // Combine the formatted strings
  const formattedString = `${formattedDayAndDate} • ${formattedStartTime} - ${formattedEndTime}`;
  return (
    <Card className="flex items-center justify-between gap-4 p-4">
      <div className="flex items-center gap-4">
        <div className="rounded-lg bg-primary-success-5 p-2">
          <UilHeadphonesAlt className="size-6 text-primary-success-100" />
        </div>
        <div className="space-y1">
          <p className="text-sm font-medium text-primary-dark-60">
            {/* {format(startTime, 'hh:mm a')} - {format(endTime, 'hh:mm a')} */}
            {formattedString}
          </p>
          <h3 className="font-semibold">
            {stage_name} with {candidate?.name}
          </h3>
        </div>
      </div>
    </Card>
  );
};

const UpcomingMeetings = () => {
  const { data: upcomingMeetings, isFetching: isLoading } = useGetUpcomingMeetings({});

  const renderContent = () => {
    if (isLoading)
      return Array.from({ length: 4 }).map((_, i) => (
        <Skeleton
          key={i}
          className="h-24 w-[full]"
        />
      ));
    if (upcomingMeetings?.length === 0)
      return (
        <NoData
          className="max-h-[28.125rem] min-h-[28.125rem]"
          title="No upcoming meetings"
          description="You have no upcoming meetings at the moment"
        />
      );
    return upcomingMeetings?.map((interview) => (
      <InterviewCard
        key={interview.id}
        {...interview}
      />
    ));
  };

  return (
    <Card className="space-y-4">
      <div className="flex items-center justify-between gap-4">
        <h3 className="text-xl font-semibold">My Calendar</h3>
        <Link to="/business/calendar">
          <Button
            variant="ghost"
            className="text-primary-blue-100"
          >
            <span>View calendar</span> <UilArrowUpRight className="size-[1.125rem]" />
          </Button>
        </Link>
      </div>
      <div className="space-y-4">{renderContent()}</div>
    </Card>
  );
};
export default UpcomingMeetings;
