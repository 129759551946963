import { memo } from 'react';
import { createFileRoute, Outlet } from '@tanstack/react-router';

import Heading from '@/components/ui/typography/heading';

import TabNav, { ITabNavItem } from '@/components/navigation/tab-nav';

import { PORTAL_IDS } from '@/constants/portal-ids';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/(roles-pages)/roles')({
  component: memo(RolesLayout),
});

const navItems: ITabNavItem[] = [
  {
    label: 'Matched',
    href: '/candidate/roles',
  },
  {
    label: 'Active',
    href: '/candidate/roles/applied',
  },
  {
    label: 'Offers',
    href: '/candidate/roles/offers',
  },
  {
    label: 'Hired',
    href: '/candidate/roles/hired',
  },
];

function RolesLayout() {
  return (
    <main>
      <Heading
        title="Roles"
        size="page"
      />
      <div className="flex items-center justify-between py-6">
        <TabNav navItems={navItems} />
        <div className="flex items-center justify-center gap-4">
          <div id={PORTAL_IDS.rolesApplied}></div>
          <div id={PORTAL_IDS.rolesButtons}></div>
        </div>
      </div>
      <Outlet />
    </main>
  );
}
