import { createFileRoute } from '@tanstack/react-router';

import WorkForm from '@/components/forms/candidate/work-form';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/settings/profile/')({
  component: Work,
});

function Work() {
  return <WorkForm />;
}
