import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';

import { useGetCurrentUser } from '@/hooks/user';

const PostHogUserIdentifier = () => {
  const { data: user } = useGetCurrentUser({});

  const posthog = usePostHog();

  useEffect(() => {
    if (user) {
      posthog.identify(user.email, {
        ...user,
      });
    }
  }, [user, posthog]);

  return null;
};

export default PostHogUserIdentifier;
