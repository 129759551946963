import { TBusiness } from '../business';

const isAllBusinessPartnerFieldsEmpty = (partner: TBusiness) => {
  // Check if all business fields are empty except for the name
  // The name is never empty, so we don't include it in our checks
  return !partner?.business_abn && !partner?.logo_url && !partner?.country && !partner?.city && !partner?.bio;
};

export const getBusinessPartnerOnbaordingStatus = (partner: TBusiness) => {
  if (isAllBusinessPartnerFieldsEmpty(partner)) {
    return 0;
  }
  if (!partner?.logo_url || !partner?.name || !partner?.business_abn || !partner?.country || !partner?.city) {
    return 1;
  }
  if (!partner?.bio) {
    return 2;
  }
  return 3;
};
