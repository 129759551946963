import { Tabs, TabsList, TabsTrigger } from '@/components//ui/tabs';

import {
  getNotificationTabFilterOptionEnumFromValue,
  NotificationTabFilterOptionEnum,
} from '@/constants/notifications';

export function NotificationNavBar({
  currentTab,
  handleTabChange,
}: {
  currentTab: NotificationTabFilterOptionEnum;
  handleTabChange: (tab: NotificationTabFilterOptionEnum) => void;
}) {
  return (
    <Tabs
      value={currentTab.toString()}
      onValueChange={(value: string) => {
        handleTabChange(getNotificationTabFilterOptionEnumFromValue(value));
      }}
      className="w-full"
    >
      <TabsList className="w-full">
        <TabsTrigger
          value={NotificationTabFilterOptionEnum.ALL}
          className="min-w-[6rem] flex-1 text-center 2xl:min-w-[7.68rem]"
        >
          All
        </TabsTrigger>
        <TabsTrigger
          value={NotificationTabFilterOptionEnum.ROLES}
          className="min-w-[6rem] flex-1 text-center 2xl:min-w-[7.68rem]"
        >
          Roles
        </TabsTrigger>
        <TabsTrigger
          value={NotificationTabFilterOptionEnum.CALENDAR}
          className="min-w-[6rem] flex-1 text-center 2xl:min-w-[7.68rem]"
        >
          Calendar
        </TabsTrigger>
      </TabsList>
    </Tabs>
  );
}
