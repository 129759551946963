import React, { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';

import { experienceSchema, TExperienceFormData } from '@/validation-schemas/experience-schema';

import { Button } from '../ui/button';
import { Checkbox } from '../ui/checkbox';
import DatePicker from '../ui/date-picker';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '../ui/form';
import { Input } from '../ui/input';

export interface IExperienceFormProps {
  roles: string[];
  data: TExperienceFormData;
  index: number;
  isLoading: boolean;
  save: (a: number, b: TExperienceFormData) => void;
  onCancel: () => void;
  className?: string;
}

export const ExperienceForm: React.FC<IExperienceFormProps> = ({
  isLoading,
  data,
  index,
  save,
  onCancel,
  className,
}) => {
  const [defaultValues, setDefaultValues] = useState<TExperienceFormData | null>(null);

  const form = useForm<TExperienceFormData>({
    resolver: zodResolver(experienceSchema),
    defaultValues: {
      companyName: '',
      role: '',
      startDate: undefined,
      endDate: undefined,
      workingCurrently: false,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = form;

  const onSubmit: SubmitHandler<TExperienceFormData> = async (data) => {
    save(index, data);
  };

  useEffect(() => {
    if (data) {
      const initialValues: TExperienceFormData = {
        companyName: data.companyName || '',
        role: data.role,
        startDate: data.startDate,
        endDate: data.endDate,
        workingCurrently: data.workingCurrently,
      };
      setDefaultValues(initialValues);
      reset(initialValues);
    }
  }, [data, reset]);

  if (isLoading || !defaultValues) {
    return <div>Loading...</div>;
  }

  const isWorkingCurrently = watch('workingCurrently');

  return (
    <FormProvider {...form}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className={className}
      >
        <FormField
          name="companyName"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Company name</FormLabel>
              <FormControl>
                <Input
                  className="w-full"
                  placeholder="Company name"
                  {...field}
                  error={!!errors.companyName?.message}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="role"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Title</FormLabel>
              <FormControl>
                <Input
                  className="w-full"
                  placeholder="Role"
                  {...field}
                  error={!!errors.role?.message}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <div className="flex w-full flex-col gap-4">
          <div className="flex w-full justify-between gap-4">
            <FormField
              name="startDate"
              control={control}
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>Start date</FormLabel>
                  <FormControl>
                    <DatePicker
                      selected={field.value}
                      onChange={field.onChange}
                      showMonthYearPicker
                      error={!!errors?.startDate?.message}
                      maxDate={new Date()}
                      dateFormat={'MMM, yyyy'}
                      placeholderText="Start date"
                    />
                  </FormControl>
                  <FormError />
                </FormItem>
              )}
            />
            <FormField
              name="endDate"
              control={control}
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel>End date</FormLabel>
                  <FormControl>
                    <DatePicker
                      disabled={isWorkingCurrently}
                      selected={isWorkingCurrently ? null : field.value}
                      onChange={field.onChange}
                      showMonthYearPicker
                      error={!!errors?.endDate?.message}
                      maxDate={new Date()}
                      dateFormat={'MMM, yyyy'}
                      placeholderText="End date"
                    />
                  </FormControl>
                  <FormError />
                </FormItem>
              )}
            />
          </div>
          <div className="flex items-center justify-end">
            <FormField
              name="workingCurrently"
              control={control}
              render={({ field }) => (
                <Checkbox
                  id="workingCurrently"
                  checked={field.value}
                  onCheckedChange={field.onChange}
                  label="I still work here!"
                />
              )}
            />
          </div>
        </div>
        <div className="flex justify-end gap-4">
          <Button
            variant="tertiary"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button onClick={handleSubmit(onSubmit)}>Save</Button>
        </div>
      </Form>
    </FormProvider>
  );
};
