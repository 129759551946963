import { useRef, useState } from 'react';
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { UilCheck, UilSearch } from '@iconscout/react-unicons';

import { cn } from '@/lib/utils';

import { inputVariants } from './input';
import Spinner from './spinner';

interface MultiSelectOption {
  label: string;
  value: number;
}

export type TRoleOption = {
  id: number;
  title: string;
};

interface IComboBoxProps {
  id?: string;
  options: MultiSelectOption[];
  selected: TRoleOption[];
  onSelect: (selected: TRoleOption[]) => void;
  placeholder?: string;
  error?: boolean;
  className?: string;
  isLoading?: boolean;
  onQueryChange: (query: string) => void;
}

export default function RolesSelectComboBox({
  id,
  selected,
  onSelect,
  options,
  className,
  error,
  onQueryChange,
  isLoading,
  placeholder,
}: IComboBoxProps) {
  const [value, setValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const selectedIds = selected.map((s) => s.id) || [];
  const isSelected = (value: number) => {
    if (!value) return false;
    return selectedIds.includes(value);
  };

  const removeFocusFromInput = () => {
    if (inputRef.current) {
      setTimeout(() => inputRef.current?.blur(), 0);
    }
  };

  const handleSelect = (value: number) => {
    if (!value) return; // Guard clause to avoid invalid value

    const isAlreadySelected = selectedIds.includes(value);

    if (isAlreadySelected) {
      const newValues = selected.filter((option) => option.id !== value);
      onSelect(newValues);
      setValue(''); // Clear the input or state

      return;
    }

    const newLabel = options.find((option) => option.value === value)?.label;

    // Ensure `newLabel` is defined before adding the new option
    if (newLabel) {
      const newValues = [...selected, { id: value, title: newLabel }];

      onSelect(newValues); // Trigger callback to update selected values
      setValue(''); // Clear the input or state
      removeFocusFromInput();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);
    onQueryChange(value);
  };

  return (
    <div>
      <Combobox
        as="div"
        onChange={handleSelect}
        onClose={() => {
          removeFocusFromInput();
          onQueryChange('');
        }}
        immediate
      >
        <div className="relative">
          <ComboboxInput
            id={id}
            aria-label="Assignee"
            onChange={handleChange}
            className={cn(inputVariants({ variant: error ? 'error' : 'default' }), className, 'pl-12')}
            placeholder={placeholder || 'Start typing here...'}
            value={value}
            ref={inputRef}
          />
          <UilSearch className="absolute left-[1.12375rem] top-1/4 size-6 text-primary-dark-60" />
        </div>

        <ComboboxOptions
          anchor={{
            to: 'bottom',
            gap: 4,
          }}
          transition
          className="z-50 w-[var(--input-width)] rounded-lg border border-primary-dark-10 bg-white shadow-lg outline-none scrollbar data-[open]:animate-in data-[close]:animate-out data-[close]:fade-out-0 data-[open]:fade-in-0 data-[close]:zoom-out-95 data-[open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:border-slate-800 dark:bg-slate-950 dark:text-slate-50"
        >
          {isLoading ? (
            <div className="flex min-h-20 w-full items-center justify-center text-center">
              <Spinner />
            </div>
          ) : options.length ? (
            options?.map((option) => (
              <ComboboxOption
                key={option.value}
                value={option.value}
                className={cn(
                  'group flex items-center justify-between px-4 py-3 font-medium text-primary-dark-60 outline-none data-[focus]:bg-primary-dark-10 data-[selected]:bg-primary-dark-5 data-[focus]:text-primary-dark-100 data-[selected]:text-primary-dark-100',
                  isSelected(option.value) && 'bg-primary-dark-5 text-primary-dark-100'
                )}
              >
                <span>{option.label}</span>
                <span>{isSelected(option.value) && <UilCheck className="size-5 text-primary-blue-100" />}</span>
              </ComboboxOption>
            ))
          ) : (
            <div className="flex min-h-20 w-full items-center justify-center text-center">
              <span>No results found</span>
            </div>
          )}
        </ComboboxOptions>
      </Combobox>
    </div>
  );
}
