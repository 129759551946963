import { useQuery } from '@tanstack/react-query';

import { getValuesMapByGroup, TAllValuesMapRequest, VALUES_MAP_ENDPOINTS } from '@/services/values-map';

import { type UseQueryOptions } from '../types';

const { GET_VALUES_MAP_BY_GROUP } = VALUES_MAP_ENDPOINTS;

export function useGetValuesMapByGroup({ reactQueryOptions, params }: UseQueryOptions<TAllValuesMapRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [GET_VALUES_MAP_BY_GROUP, params?.query?.value_group],
    queryFn: () =>
      getValuesMapByGroup({
        params: {
          query: {
            ...params?.query,
            limit: params?.query?.limit || '0',
            offset: params?.query?.offset || '0',
          },
        },
      }),
    staleTime: Infinity,
  });
}

export function useGetValuesMapByGroupAndCode({ reactQueryOptions, params }: UseQueryOptions<TAllValuesMapRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [GET_VALUES_MAP_BY_GROUP, params?.query?.value_group, params?.query?.code],
    queryFn: () =>
      getValuesMapByGroup({
        params: {
          query: {
            ...params?.query,
            limit: params?.query?.limit || '0',
            offset: params?.query?.offset || '0',
          },
        },
      }),
    staleTime: Infinity,
  });
}

export function useGetAllValuesMap({ reactQueryOptions, params }: UseQueryOptions<TAllValuesMapRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [GET_VALUES_MAP_BY_GROUP],
    queryFn: () =>
      getValuesMapByGroup({
        params: {
          query: {
            ...params?.query,
            limit: params?.query?.limit || '0',
            offset: params?.query?.offset || '0',
          },
        },
      }),
    staleTime: Infinity,
  });
}
