import { View, Views } from 'react-big-calendar';

import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';

const tabs = [
  {
    label: 'Month',
    value: Views.MONTH,
  },
  {
    label: 'Day',
    value: Views.DAY,
  },
  {
    label: 'Week',
    value: Views.WEEK,
  },
];

const CalendarTabNav = ({ view, onChangeView }: { view: View; onChangeView: (view: View) => void }) => {
  return (
    <Tabs
      value={view}
      onValueChange={(value) => {
        onChangeView(value as View);
      }}
    >
      <TabsList>
        {tabs.map((tab) => (
          <TabsTrigger
            key={tab.value}
            value={tab.value}
          >
            {tab.label}
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  );
};

export default CalendarTabNav;
