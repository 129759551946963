import { UilEnvelope } from '@iconscout/react-unicons';
import { createFileRoute } from '@tanstack/react-router';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import Heading from '@/components/ui/typography/heading';

import SettingContainer from '@/components/settings/setting-container';

import EXTERNAL_URLS from '@/constants/external-urls';

export const Route = createFileRoute('/_authenticated/partner/_dashboard/settings/faqs')({
  component: FAQs,
});

function FAQs() {
  const faqs = [
    {
      question: 'Is my personal/company information secure?',
      answer:
        'Absolutely. We take data security very seriously, using industry-standard encryption to protect your personal information. We also follow strict privacy policies to ensure your data is used solely for matching you with suitable job opportunities.',
    },
    {
      question: 'Do I need to complete my company profile?',
      answer:
        'It’s mandatory to provide your company’s basic information (Name, Business Number, Country, City). However, completing the rest of your profile is recommended to improve match accuracy with candidates.',
    },
    {
      question: 'Can I complete our company profile later?',
      answer: 'Yes, you can update your company profile at any time through the profile settings.',
    },
    {
      question: 'Can I add team members to our company profile?',
      answer: 'Yes, you can add as many team members as needed via the “Team” tab.',
    },
    {
      question: 'What are the different permissions?',
      answer: (
        <ol className="list-inside list-decimal">
          <li>
            <b>Owner/Super Admin, Admin</b> – Can manage permissions, invite team members, and update the company page.
          </li>
          <li>
            <b>Member</b> – Can access the company page but cannot change settings or invite members.
          </li>
          <li>
            <b>View-only</b> – Can view the company page but cannot make any changes or invite members.
          </li>
        </ol>
      ),
    },
    {
      question: 'How can I create roles?',
      answer: 'Roles can be created directly from the dashboard or under the "Roles" tab.',
    },
    {
      question: 'Who selects the availability for the interviews?',
      answer:
        'The team member assigned to each stage of the interview will need to select the availability for the interview stage. ',
    },
    {
      question: 'How do I track roles, and what is the pipeline?',
      answer:
        'After publishing a role, it will be live and visible from the "Roles" or "Pipeline" tab. The pipeline offers both board and table views, allowing candidates to be moved through various stages of the hiring process.',
    },
    {
      question: 'Can we post more than one role at a time?',
      answer: 'Yes, you can post multiple roles simultaneously.',
    },
    {
      question: 'What is pre-screening, and is it necessary?',
      answer: 'Pre-screening is optional and can be selected when creating a role.',
    },
    {
      question: 'How can we host interviews?',
      answer: 'You can host interviews via Google Meet, Microsoft Teams, or Zoom.',
    },
    {
      question: 'How can I track our interview schedule?',
      answer:
        'All meeting details, including date, time, and links, will be saved in your Upplft calendar and sent to your registered email.',
    },
    {
      question: 'Will I be reminded about upcoming interviews?',
      answer: "Yes, you'll receive a notification reminder through Upplft.",
    },
    {
      question: 'Can I invite partners to our company profile?',
      answer: 'Yes, partners can be added and managed via the "Partners" tab.',
    },
    {
      question: 'Why are some candidate profiles blurred out?',
      answer:
        'Some candidate profiles are blurred because they haven’t applied for the role yet. They’ve been matched from our candidate database. To view their full profile, you need to shortlist them, and they must accept the shortlist.',
    },
    {
      question: 'Can I advertise our role on external platforms?',
      answer:
        'Yes, you can share the job link to advertise on external platforms, directing applications back through Upplft for management.',
    },
    {
      question: "When can I see candidates' reference details?",
      answer: "Reference details become visible only after you've extended an offer to the candidate.",
    },
    {
      question: 'How do I send an offer?',
      answer:
        'You can send an offer by moving the candidate to the "Offer" stage and using our provided offer template.',
    },
    {
      question: 'How can I deactivate my account or company account?',
      answer:
        'Deactivating your account will remove your profile from our system. You will no longer be able to log into your business page. Deactivating the company account will remove your profile from our system. Only an admin can reactivate the account.',
    },
    {
      question: 'Can I update my notification preferences?',
      answer: 'Yes, notification preferences can be updated in the settings of your profile.',
    },
    {
      question: 'Can I change my password or email?',
      answer:
        "To change your password or email, go to your profile settings, where you'll find options to update your account details.",
    },
    {
      question: 'How can I transfer my account to another team member?',
      answer:
        'Transferring your account will remove you as the owner/admin and transfer full ownership to the selected team member. This can be done through "Settings" under "My Details."',
    },
  ];
  return (
    <SettingContainer>
      <div className="flex flex-col gap-4">
        <Heading
          title="FAQs"
          subtitle="Didn’t find what you were looking for?"
        />
        <a
          href={EXTERNAL_URLS.SUPPORT_PORTAL}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            variant="tertiary"
            className="flex gap-2"
          >
            <UilEnvelope className="h-[18px] w-[18px]" />
            <span>Contact us</span>
          </Button>
        </a>
      </div>
      <div className="space-y-8">
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            type="single"
            collapsible
            className="w-full"
          >
            <AccordionItem value={faq.question}>
              <AccordionTrigger>{faq.question}</AccordionTrigger>
              <AccordionContent>{faq.answer}</AccordionContent>
            </AccordionItem>
          </Accordion>
        ))}
      </div>
    </SettingContainer>
  );
}
