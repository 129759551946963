import { getCoreRowModel, PaginationOptions, PaginationState, useReactTable } from '@tanstack/react-table';

import { cn } from '@/lib/utils';

import { Button } from './button';

interface PaginationProps {
  state: PaginationState;
  onPaginationChange: PaginationOptions['onPaginationChange'];
  rowCount: PaginationOptions['rowCount'];
  className?: string;
}

const Pagination = ({ state, className, ...options }: PaginationProps) => {
  const table = useReactTable({
    state: {
      pagination: state,
    },
    manualPagination: false,
    getCoreRowModel: getCoreRowModel(),
    ...options,
    data: [],
    columns: [],
  });

  return (
    <div className={cn('flex items-center justify-evenly px-6 py-4', className)}>
      <Button
        variant="tertiary"
        onClick={table.previousPage}
        disabled={!table.getCanPreviousPage()}
      >
        Previous
      </Button>
      <span className="flex-1 text-center text-sm font-semibold text-primary-dark-60">
        Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount().toLocaleString()}
      </span>
      <Button
        variant="tertiary"
        onClick={table.nextPage}
        disabled={!table.getCanNextPage()}
      >
        Next
      </Button>
    </div>
  );
};

export default Pagination;
