import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import '@fontsource/montserrat';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';

import { configureSentry } from '@/lib/sentry/config';

import App from './App';

// Configure sentry
configureSentry();

// Render the app
const rootElement = document.getElementById('root')!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
}
