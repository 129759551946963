import { useQueryClient } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { format } from 'date-fns';

import { Card } from '@/components/ui/card';
import Separator from '@/components/ui/separator';
import Heading from '@/components/ui/typography/heading';

import ProfileDetailsForm from '@/components/forms/profile-details-form';
import DeactivateAccountCard from '@/components/settings/deactivate-account-card';
import SettingContainer from '@/components/settings/setting-container';
import SignOutCard from '@/components/settings/signout-card';

import { useGetCurrentCandidateSuspense } from '@/hooks/candidate';
import { useGetCurrentUser, useUpdateCurrentUser } from '@/hooks/user';

import { CANDIDATE_ENDPOINTS } from '@/services/candidate';

import { castToStringOrNull } from '@/utils/string';

import { TProfileDetailsFormData } from '@/validation-schemas/personal-details.schema';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/settings/')({
  component: Details,
});

function Details() {
  const { data: user, isStale } = useGetCurrentUser({
    reactQueryOptions: {
      notifyOnChangeProps: 'all',
    },
  });
  const { data: candidate } = useGetCurrentCandidateSuspense({});
  const queryClient = useQueryClient();
  const { mutateAsync, isPending } = useUpdateCurrentUser({
    reactQueryOptions: {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE] });
      },
    },
  });

  const onSubmit = async (data: TProfileDetailsFormData) => {
    await mutateAsync({
      body: {
        first_name: data.first_name,
        last_name: data.last_name,
        primary_phone: data.primary_phone,
        email: data?.email,
        dob: format(data.dob, 'yyyy-MM-dd'),
        username: user?.email as string,
        secondary_phone: castToStringOrNull(user?.secondary_phone),
        title: user?.title as string,
        image_url: user?.image_url as string,
        gender: data.pronouns,
      },
    });
  };

  if (!user || !candidate) {
    return null;
  }

  return (
    <>
      <SettingContainer>
        <Heading
          title="Personal details"
          subtitle="Update your personal details and profile photo."
        />
        <Card className="p-8">
          <ProfileDetailsForm
            onSubmit={onSubmit}
            defaultValues={{
              first_name: user?.first_name as string,
              last_name: user?.last_name as string,
              email: user?.email as string,
              primary_phone: user?.primary_phone as string,
              pronouns: user?.gender as string,
              dob: new Date(user?.dob as string),
            }}
            isSubmitting={isPending || isStale}
          />
        </Card>
      </SettingContainer>
      <Separator />
      <SignOutCard />
      <Separator />
      <DeactivateAccountCard />
    </>
  );
}
