import { useState } from 'react';
import { createFileRoute } from '@tanstack/react-router';

import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';

import PartnerBusinessRolesTable from '@/components/business/partner/roles-table';

import { useGetCurrentBusiness } from '@/hooks/business';
import { useGetAllRolesByBusinessIdV2 } from '@/hooks/business-roles';
import useFilters from '@/hooks/useFilters';

import { TGetAllBusinessRolesV2RequestSort, TGetAllBusinessRolesV2RequestStatus } from '@/services/business-roles';
import { Filters } from '@/services/types';

import { sortByToQuery } from '@/utils/table-sort-mapper';

import { DEFAULT_PAGE_INDEX } from '@/constants/table';

export const Route = createFileRoute('/_authenticated/business/_dashboard/partners/$partnerId/placements/')({
  component: PlacementsTable,
});

function PlacementsTable() {
  const { partnerId } = Route.useParams();

  const [activeStatus, setActiveStatus] = useState<TGetAllBusinessRolesV2RequestStatus>('active');
  const { data: business, isPending: isBusinessPending } = useGetCurrentBusiness({});
  const { filters, setFilters } = useFilters<Filters>(Route.id);

  const paginationState = {
    pageIndex: filters.pageIndex ?? DEFAULT_PAGE_INDEX,
    pageSize: 3,
  };

  const sortQuery = sortByToQuery<TGetAllBusinessRolesV2RequestSort>(filters.sortBy);

  const { data, isFetching } = useGetAllRolesByBusinessIdV2({
    params: {
      path: {
        businessId: business?.id.toString() as string,
      },
      query: {
        partnerId,
        limit: paginationState.pageSize.toString(),
        offset: (paginationState.pageIndex * paginationState.pageSize).toString(),
        status: activeStatus as TGetAllBusinessRolesV2RequestStatus,
        q: filters.query,
        ...(sortQuery && {
          sort: sortQuery.id,
          order: sortQuery.order,
        }),
      },
    },
    reactQueryOptions: {
      enabled: !!business,
      queryKey: [filters, activeStatus],
    },
  });

  const tabs: {
    label: string;
    count?: number | null;
    status: TGetAllBusinessRolesV2RequestStatus;
  }[] = [
    {
      label: 'Open',
      count: data?.metadata.total_open_posts || 0,
      status: 'active',
    },
    {
      label: 'Paused',
      count: data?.metadata.total_paused_posts || 0,
      status: 'hold',
    },
    {
      label: 'Closed',
      count: data?.metadata.total_closed_posts || 0,
      status: 'closed',
    },
    {
      label: 'Drafts',
      count: data?.metadata.total_draft_posts || 0,
      status: 'draft',
    },
  ];

  const handleActiveStatusChange = (newStatus: TGetAllBusinessRolesV2RequestStatus) => {
    setActiveStatus(newStatus);
    setFilters({ ...filters, pageIndex: DEFAULT_PAGE_INDEX });
  };

  const handleFiltersChange = async (newFilters: Filters) => {
    if (newFilters.sortBy || newFilters.sortBy !== filters.sortBy) {
      setFilters({ ...newFilters, pageIndex: DEFAULT_PAGE_INDEX });
    } else {
      setFilters(newFilters);
    }
  };
  return (
    <>
      <PartnerBusinessRolesTable
        tabNavs={
          <Tabs
            value={activeStatus}
            // @ts-expect-error status type as string but it has type of the status
            onValueChange={handleActiveStatusChange}
          >
            <TabsList>
              {tabs.map((tab) => (
                <TabsTrigger
                  key={tab.label}
                  value={tab.status as string}
                  className="inline-flex min-w-[7.5rem] items-center gap-x-2 2xl:min-w-[9rem] 3xl:min-w-[9.5rem]"
                >
                  {tab.label}
                  {(tab.count || tab.count === 0) && (
                    <span className="inline-flex h-5 w-5 flex-col items-center justify-center gap-2.5 rounded border border-[#e7e9ed] p-0.5">
                      <span className="text-center font-['Montserrat'] text-xs font-medium leading-[18px] text-[#0c2149]">
                        {tab.count}
                      </span>
                    </span>
                  )}
                </TabsTrigger>
              ))}
            </TabsList>
          </Tabs>
        }
        data={data}
        isPending={isFetching || isBusinessPending}
        filters={{ ...filters, status: activeStatus }}
        setFilters={handleFiltersChange}
        paginationState={paginationState}
      />
    </>
  );
}
