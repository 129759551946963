import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  BUSINESS_ROLES_ENDPOINTS,
  createJobPost,
  deleteRole,
  favouriteCandidate,
  generateRoleReferrerToken,
  TCreateJobPostRequest,
  TDeleteRoleRequest,
  TFavouriteCandidateRequest,
  TGenerateBusinessRoleReferralTokenRequest,
  TUnfavouriteCandidateRequest,
  TUpdateJobPostRequest,
  unfavouriteCandidate,
  updateJobPost,
} from '@/services/business-roles';

import { UseMutationOptions } from '../types';

// import { JOB_POST_STATUS } from "@/constants/post";

export const useCreateJobPost = ({ reactQueryOptions }: UseMutationOptions<TCreateJobPostRequest>) => {
  return useMutation({
    ...reactQueryOptions,
    mutationFn: createJobPost,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
    },
    onError: () => {
      reactQueryOptions?.onError?.();
    },
  });
};

export const useUpdateJobPost = ({ reactQueryOptions }: UseMutationOptions<TUpdateJobPostRequest>) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: updateJobPost,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [BUSINESS_ROLES_ENDPOINTS.GET_ALL_ROLES_BY_BUSINESS_ID_V2],
        exact: false,
        refetchType: 'all',
      });
    },
  });
};

export const useFavouriteCandidate = ({ reactQueryOptions }: UseMutationOptions<TFavouriteCandidateRequest>) => {
  return useMutation({
    ...reactQueryOptions,
    mutationFn: favouriteCandidate,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
    },
    onError: () => {
      reactQueryOptions?.onError?.();
    },
  });
};

export const useUnfavouriteCandidate = ({ reactQueryOptions }: UseMutationOptions<TUnfavouriteCandidateRequest>) => {
  return useMutation({
    ...reactQueryOptions,
    mutationFn: unfavouriteCandidate,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
    },
    onError: () => {
      reactQueryOptions?.onError?.();
    },
  });
};

export const useGenerateRoleReferrerToken = ({
  reactQueryOptions,
}: UseMutationOptions<TGenerateBusinessRoleReferralTokenRequest>) => {
  return useMutation({
    ...reactQueryOptions,
    mutationFn: generateRoleReferrerToken,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
    },
    onError: () => {
      reactQueryOptions?.onError?.();
    },
  });
};

export const useDeleteRole = ({ reactQueryOptions }: UseMutationOptions<TDeleteRoleRequest>) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...reactQueryOptions,
    mutationFn: deleteRole,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [BUSINESS_ROLES_ENDPOINTS.GET_ALL_ROLES_BY_BUSINESS_ID_V2],
        exact: false,
        refetchType: 'all',
      });
    },
    onError: () => {
      reactQueryOptions?.onError?.();
    },
  });
};
