import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { UilFastMail } from '@iconscout/react-unicons';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Input } from '@/components/ui/input';

import { useGetCurrentBusiness, useResendInviteBusinessTeamMember } from '@/hooks/business';

export function ResendInviteButton({
  id,
  email,
  hideTrigger = false,
  disableTrigger = false,
  triggerOpen = false,
  onOpenChange,
  onSubmit,
}: {
  id: string;
  email: string;
  hideTrigger?: boolean;
  disableTrigger?: boolean;
  triggerOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  onSubmit?: () => void;
}) {
  const { data: business } = useGetCurrentBusiness({});

  const { mutate: resendInvite, isPending } = useResendInviteBusinessTeamMember({
    params: {
      path: {
        businessId: business?.id?.toString() ?? '',
        memberId: id,
      },
    },
    reactQueryOptions: {
      onSuccess: () => {
        onSubmit?.();
        handleOpenChange(false);
      },
    },
  });
  const [open, setOpen] = useState(false);
  const form = useForm({
    values: { email },
    resolver: zodResolver(
      z.object({
        email: z
          .string()
          .email()
          .refine((value) => value === email, {
            message: "Email must match the user's email",
          }),
      })
    ),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = form;

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
    onOpenChange?.(newOpen);
  };

  const handleSubmitForm = () => {
    resendInvite({
      params: {
        path: {
          businessId: business?.id?.toString() ?? '',
          memberId: id,
        },
      },
    });
  };

  useEffect(() => {
    if (!open) {
      reset({ email: email });
    }
  }, [open, reset]);

  useEffect(() => {
    if (triggerOpen) {
      handleOpenChange(true);
    }
  }, [triggerOpen]);

  return (
    <AlertDialog
      open={open}
      onOpenChange={handleOpenChange}
    >
      {!hideTrigger && (
        <AlertDialogTrigger asChild>
          <Button
            disabled={disableTrigger}
            size="sm"
            variant="tertiary"
          >
            <UilFastMail />
            Resend invite
          </Button>
        </AlertDialogTrigger>
      )}
      <AlertDialogContent>
        <FormProvider {...form}>
          <AlertDialogHeader>
            <AlertDialogTitle className="flex items-center space-x-4">
              <Card className="flex h-8 w-8 items-center justify-center p-0">
                <UilFastMail />
              </Card>
              <span>Resend invite</span>
            </AlertDialogTitle>
            <AlertDialogDescription>
              Please enter the email address to confirm and resend the invitation:
            </AlertDialogDescription>
          </AlertDialogHeader>
          <Form className="space-y-4">
            <FormField
              name="email"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email address</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="Enter email address"
                      error={!!errors.email?.message}
                    />
                  </FormControl>
                  {errors.email && <FormError>{errors.email.message}</FormError>}
                </FormItem>
              )}
            />
          </Form>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleSubmit(handleSubmitForm)}
              disabled={isPending}
            >
              Send
            </AlertDialogAction>
          </AlertDialogFooter>
        </FormProvider>
      </AlertDialogContent>
    </AlertDialog>
  );
}
