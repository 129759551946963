import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/components/ui/toaster';

import { CoverImageUploader } from '@/components/cover-image-uploader';
import OnboardingFooter from '@/components/onboarding/footer';

import { useGetCurrentBusinessSuspense, useUpdateCurrentBusiness } from '@/hooks/business';

import { getFormattedDataForAPI } from '@/utils/format';

import {
  businessBiosExtrasSchema,
  TBusinessBiosExtrasFormData,
} from '@/validation-schemas/business/business-bios-extras';

import FormFooter from '../form-footer';
import FormNavigationBlocker from '../form-navigation-blocker';
import { IFormProps } from '../types';

const BusinessBiosExtrasForm: React.FC<IFormProps> = ({ onSuccess, isOnboarding, isDisabled }) => {
  const { data: currentBusiness } = useGetCurrentBusinessSuspense({});
  const { mutateAsync: updateBusiness, isPending: isUpdatingBusiness } = useUpdateCurrentBusiness({
    reactQueryOptions: {
      onSuccess: () => {
        if (!isOnboarding) {
          toast.success('Account updated successfully!');
        }
        onSuccess?.();
      },
    },
  });

  const form = useForm<TBusinessBiosExtrasFormData>({
    disabled: isDisabled,
    resolver: zodResolver(businessBiosExtrasSchema),
    defaultValues: {
      bio: currentBusiness?.bio || '',
      cover_url: currentBusiness?.cover_url || '',
    },
    values: {
      bio: currentBusiness?.bio || '',
      cover_url: currentBusiness?.cover_url || '',
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = form;

  const currentFile = watch('cover_url');

  const handleFileUpload = (url: string) => {
    setValue('cover_url', url, { shouldDirty: true });
  };

  const onSubmit: SubmitHandler<TBusinessBiosExtrasFormData> = (data) => {
    const formattedData = getFormattedDataForAPI(data);
    updateBusiness({ body: formattedData });
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CoverImageUploader
          disabled={isDisabled}
          keyPrefix={`business/${currentBusiness?.id}/`}
          onFileUpload={handleFileUpload}
          currentFile={currentFile}
          formError={errors?.cover_url?.message}
        />
        <FormField
          name="bio"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Company bio</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Enter description here"
                  {...field}
                  error={!!errors?.bio?.message}
                  rows={4}
                  maxLength={1000}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>

      {isOnboarding ? (
        <OnboardingFooter
          handleNext={handleSubmit(onSubmit)}
          domain="business"
          isLoading={isUpdatingBusiness}
        />
      ) : (
        <>
          <FormNavigationBlocker />
          <FormFooter
            isDisabled={isDisabled}
            onSubmit={handleSubmit(onSubmit)}
            isSubmitting={isUpdatingBusiness}
          />
        </>
      )}
    </FormProvider>
  );
};
export default BusinessBiosExtrasForm;
