import { PersonalityArchetypeCode } from '@/services/candidate';

import { cn } from '@/lib/utils';

import { PERSONALITY_ClASS_MAP, PERSONALITY_CODE_TO_EMOJI_MAP } from '@/constants/candidate';

// Todo move to constants

const PERSONALITY_CODE_TO_NAME_MAP: Record<PersonalityArchetypeCode, string> = {
  X: 'Extraversion',
  H: 'Honest-Humility',
  O: 'Openness to Experience',
  A: 'Agreeableness',
  E: 'Emotionality',
  C: 'Conscientiousness',
};

const PersonalityCard = ({
  personalityCode,
  className,
}: {
  personalityCode: PersonalityArchetypeCode;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        className,
        'flex items-center gap-x-4 rounded-lg border px-4 py-4 3xl:gap-x-8 3xl:px-8',
        PERSONALITY_ClASS_MAP[personalityCode]
      )}
    >
      <span className="text-7xl 3xl:text-8xl">{PERSONALITY_CODE_TO_EMOJI_MAP[personalityCode]}</span>
      <div className="space-y-1 font-semibold">
        <p className="text-sm text-primary-dark-40">ARCHETYPE:</p>
        <p className="text-xl">{PERSONALITY_CODE_TO_NAME_MAP[personalityCode]}</p>
      </div>
    </div>
  );
};

export default PersonalityCard;
