export type TAPIError = {
  success: false;
  error: {
    message: string;
    type: string;
    status: number;
    severity: 'error' | 'warning';
    logId: string;
    path: string;
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    resourceType: string;
  };
};

export const isAPIError = (error: unknown): error is TAPIError => {
  return (error as TAPIError)?.success === false;
};

export const getAPIErrorMessage = (error: TAPIError): string => {
  return error.error.message;
};

export const parseAPIErrorFromString = (errorString: string): TAPIError => {
  try {
    const error = JSON.parse(errorString);
    return error;
  } catch (error) {
    console.error('Error parsing API error:', error);
    return {
      success: false,
      error: {
        message: 'An unknown error occurred',
        type: 'unknown',
        status: 500,
        severity: 'error',
        logId: 'unknown',
        path: 'unknown',
        method: 'GET',
        resourceType: 'unknown',
      },
    };
  }
};
