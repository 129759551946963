import client, { TRequestOptions } from '@/lib/api';

import { MEETING_ENDPOINTS } from './meetings.endpoints';
import {
  TAddOwnerAvailability,
  TCancelMeetingRequest,
  TCandidateBookMeeting,
  TCreateMeetingDraft,
  TGetCandidateMeetingForPost,
  TGetMeetingDetails,
  TGetMeetings,
  TGetMeetingTimeSlots,
  TGetUpcomingMeetingsRequest,
  TRemoveMeetingFromUserCalendar,
  TRescheduleMeeting,
  TUpdateMeetingOwnerAvailability,
} from './meetings.types';

export const cancelMeetingById = async ({ signal, params, body }: TRequestOptions<TCancelMeetingRequest>) => {
  const { data } = await client.POST(MEETING_ENDPOINTS.CANCEL_MEETING, { signal, body, params });
  return data;
};

export const getUpcomingMeetings = async ({ signal }: TRequestOptions<TGetUpcomingMeetingsRequest>) => {
  const { data } = await client.GET(MEETING_ENDPOINTS.GET_UPCOMING_MEETINGS, { signal });
  return data?.data;
};

export const getMeetings = async ({ signal }: TRequestOptions<TGetMeetings>) => {
  const { data } = await client.GET(MEETING_ENDPOINTS.GET_MEETINGS, { signal });
  return data?.data;
};

export const createMeetingDraft = async ({ signal, body }: TRequestOptions<TCreateMeetingDraft>) => {
  const { data } = await client.POST(MEETING_ENDPOINTS.CREATE_MEETING_DRAFT, { signal, body });
  return data;
};

export const addOwnerAvailability = async ({ signal, body }: TRequestOptions<TAddOwnerAvailability>) => {
  const { data } = await client.POST(MEETING_ENDPOINTS.ADD_OWNER_AVAILABILITY, { signal, body });
  return data;
};

export const updateMeetingOwnerAvailability = async ({
  signal,
  body,
}: TRequestOptions<TUpdateMeetingOwnerAvailability>) => {
  const { data } = await client.POST(MEETING_ENDPOINTS.UPDATE_MEETING_OWNER_AVAILABILITY, { signal, body });
  return data;
};

export const getCandidateMeetingForPost = async ({ signal, params }: TRequestOptions<TGetCandidateMeetingForPost>) => {
  const { data } = await client.GET(MEETING_ENDPOINTS.GET_CANDIDATE_MEETING_FOR_POST, { signal, params });
  return data?.data;
};

export const candidateBookMeeting = async ({ signal, body }: TRequestOptions<TCandidateBookMeeting>) => {
  const { data } = await client.POST(MEETING_ENDPOINTS.CANDIDATE_BOOK_MEETING, { signal, body });
  return data;
};

export const getMeetingDetails = async ({ signal, params }: TRequestOptions<TGetMeetingDetails>) => {
  const { data } = await client.GET(MEETING_ENDPOINTS.GET_MEETING_DETAILS, { signal, params });
  return data?.data;
};

export const getMeetingTimeSlots = async ({ signal, params }: TRequestOptions<TGetMeetingTimeSlots>) => {
  const { data } = await client.GET(MEETING_ENDPOINTS.GET_MEETING_TIME_SLOTS, { signal, params });
  return data?.data;
};

export const rescheduleMeeting = async ({ signal, body }: TRequestOptions<TRescheduleMeeting>) => {
  const { data } = await client.POST(MEETING_ENDPOINTS.RESCHEDULE_MEETING, { signal, body });
  return data;
};

export const removeMeetingFromUserCalendar = async ({
  signal,
  params,
}: TRequestOptions<TRemoveMeetingFromUserCalendar>) => {
  const { data } = await client.DELETE(MEETING_ENDPOINTS.REMOVE_MEETING_FROM_USER_CALENDAR, { signal, params });
  return data;
};
