import { createFileRoute, useNavigate } from '@tanstack/react-router';

import PartnerSocialsBioForm from '@/components/forms/partner/partner-socials-bio-form';

export const Route = createFileRoute('/_authenticated/partner/onboarding/_steps/step2')({
  component: Step2,
});

function Step2() {
  const navigate = useNavigate();
  return (
    <PartnerSocialsBioForm
      onSuccess={() => navigate({ to: '/partner/onboarding/completed' })}
      isOnboarding
    />
  );
}
