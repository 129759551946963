import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import UnderLine from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';

export const extensions = [
  StarterKit.configure({
    bulletList: {
      HTMLAttributes: {
        class: 'list-disc pl-4',
      },
    },
    orderedList: {
      HTMLAttributes: {
        class: 'list-decimal',
      },
    },
  }),
  Link.configure({
    HTMLAttributes: {
      class: 'text-primary-blue-100',
      rel: 'noopener noreferrer',
      target: null,
    },
  }),
  UnderLine,
  Placeholder.configure({
    placeholder: 'Enter description here...',
    showOnlyWhenEditable: true,
  }),
];
