import { Logo } from './logo';

export default function MobileNotSupported() {
  return (
    <div className="flex h-[100dvh] w-full flex-col justify-center gap-8 p-6 sm:hidden">
      <span>
        <Logo variant="mini" />
      </span>
      <div className="space-y-2">
        <h1 className="text-2xl font-semibold">Uh oh, try your desktop!</h1>
        <p className="text-lg font-medium text-primary-dark-60">
          {
            "For the best experience, we recommend using the Upplft platform on your desktop or laptop as it's not configured for mobile phones yet."
          }
        </p>
      </div>
    </div>
  );
}
