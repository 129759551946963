import React, { useState } from 'react';
import { UilFileAlt, UilTrashAlt } from '@iconscout/react-unicons';
import { useDropzone } from 'react-dropzone';

import { useS3 } from '@/hooks/s3';

import { cn } from '@/lib/utils';

import FeaturedIcon from '../icons/featured-icon';
import { Button } from './button';

export interface FileUploaderProps extends React.InputHTMLAttributes<HTMLInputElement> {
  acceptedFileTypes: string[];
  keyPrefix: string;
  onFileUpload: (fileUrl: string, fileName: string) => void;
  className?: string;
  currentFile?: { name: string; url: string } | null;
  label?: string;
}

const convertArrayToObject = (arr: string[]): { [key: string]: [] } => {
  return arr.reduce(
    (acc, curr) => {
      acc[curr] = [];
      return acc;
    },
    {} as { [key: string]: [] }
  );
};

const FileUploader: React.FC<FileUploaderProps> = ({
  acceptedFileTypes,
  keyPrefix,
  onFileUpload,
  className,
  currentFile,
  ...props
}) => {
  const { upload } = useS3();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(currentFile?.name || null);
  const handleUpload = async (file: File) => {
    setLoading(true);
    setError(null);
    try {
      const key = `${keyPrefix}/${file.name}`;
      await upload(file, key);
      const url = `https://${import.meta.env.VITE_S3_BUCKET}.s3.${import.meta.env.VITE_S3_REGION}.amazonaws.com/${key}`;
      setUploadedFileName(file.name);
      onFileUpload(url, file.name);
    } catch (err) {
      setError('Upload failed. Please try again.');
      console.error('Upload failed:', err);
    } finally {
      setLoading(false);
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      handleUpload(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: convertArrayToObject(acceptedFileTypes),
    multiple: false,
  });

  const clearUpload = () => {
    setUploadedFileName(null);
  };

  return (
    <div className="w-full">
      {!uploadedFileName && (
        <div
          {...getRootProps()}
          className={cn(
            'inline-flex h-24 w-full items-center justify-center gap-4 rounded-lg border border-indigo-300 bg-white px-4 py-8',
            isDragActive ? 'border-dashed border-blue-500' : '',
            className
          )}
        >
          <input
            {...getInputProps()}
            {...props}
          />
          {loading && (
            <div className="relative h-8 w-8">
              <div className="spinner-border inline-block h-8 w-8 animate-spin rounded-full border-4 text-blue-600"></div>
            </div>
          )}
          <div>
            {isDragActive ? (
              <span className="text-lg font-semibold leading-7 text-sky-950">Drop the file here...</span>
            ) : (
              <div className="flex items-center gap-4">
                <UilFileAlt className="size-8 text-primary-dark-60" />
                <p className="text-lg font-semibold leading-7 text-sky-950">
                  Drag & drop {props?.label && `your ${props?.label} `}or{' '}
                  <span className="cursor-pointer text-lg font-semibold leading-7 text-blue-600">upload</span>
                </p>
              </div>
            )}
          </div>
        </div>
      )}
      {error && <div className="mt-2 text-red-500">{error}</div>}
      {uploadedFileName && !loading && (
        <div className="flex w-full items-center justify-between gap-4 rounded-lg border border-indigo-300 bg-white p-4">
          <div className="flex items-center gap-3.5">
            <FeaturedIcon variant="document" />
            <span className="text-lg font-semibold leading-7 text-primary-dark-100">{uploadedFileName}</span>
          </div>
          <Button
            type="button"
            variant="ghost"
            onClick={clearUpload}
          >
            <UilTrashAlt className="size-6 text-primary-dark-60" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default FileUploader;
