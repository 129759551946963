import client, { TRequestOptions } from '@/lib/api';

import { NOTIFICATION_ENDPOINTS, NOTIFICATION_SETTINGS_ENDPOINTS } from './notification.endpoints';
import {
  TGetAllUserNotificationSettingsRequest,
  TGetAllUserNotificationsRequest,
  TPutDisableUserNotificationSettingRequest,
  TPutEnableUserNotificationSettingRequest,
  TPutNotificationMarkAllAsReadRequest,
  TPutNotificationMarkAsReadRequest,
  TPutNotificationMarkAsUnreadRequest,
  TPutUserNotificationPlatformRequest,
} from './notification.types';

/**
 * User Notifications
 */

export const getAllUserNotifications = async ({ signal, params }: TRequestOptions<TGetAllUserNotificationsRequest>) => {
  const { data } = await client.GET(NOTIFICATION_ENDPOINTS.GET_USER_NOTIFICATIONS, {
    signal,
    params,
  });

  return data?.data;
};

export const updateAllNotificationsAsRead = async ({
  signal,
}: TRequestOptions<TPutNotificationMarkAllAsReadRequest>) => {
  const { data } = await client.PUT(NOTIFICATION_ENDPOINTS.MARK_ALL_NOTIFICATIONS_AS_READ, {
    signal,
  });

  return data;
};

export const updateNotificationAsRead = async ({
  signal,
  params,
}: TRequestOptions<TPutNotificationMarkAsReadRequest>) => {
  const { data } = await client.PUT(NOTIFICATION_ENDPOINTS.MARK_NOTIFICATION_AS_READ, {
    signal,
    params,
  });

  return data;
};

export const updateNotificationAsUnread = async ({
  signal,
  params,
}: TRequestOptions<TPutNotificationMarkAsUnreadRequest>) => {
  const { data } = await client.PUT(NOTIFICATION_ENDPOINTS.MARK_NOTIFICATION_AS_UNREAD, {
    signal,
    params,
  });

  return data;
};

/**
 * Notification Settings
 */

export const getAllUserNotificationSettings = async ({
  signal,
}: TRequestOptions<TGetAllUserNotificationSettingsRequest>) => {
  const { data } = await client.GET(NOTIFICATION_SETTINGS_ENDPOINTS.GET_USER_NOTIFICATION_SETTINGS, {
    signal,
  });

  return data?.data;
};

export const disableUserNotificationSetting = async ({
  signal,
  params,
  body,
}: TRequestOptions<TPutDisableUserNotificationSettingRequest>) => {
  const { data } = await client.PUT(NOTIFICATION_SETTINGS_ENDPOINTS.DISABLE_USER_NOTIFICATION_SETTING, {
    signal,
    params,
    body,
  });

  return data;
};

export const enableUserNotificationSetting = async ({
  signal,
  params,
  body,
}: TRequestOptions<TPutEnableUserNotificationSettingRequest>) => {
  const { data } = await client.PUT(NOTIFICATION_SETTINGS_ENDPOINTS.ENABLE_USER_NOTIFICATION_SETTING, {
    signal,
    params,
    body,
  });

  return data;
};

export const updateUserNotificationPlatform = async ({
  signal,
  params,
  body,
}: TRequestOptions<TPutUserNotificationPlatformRequest>) => {
  const { data } = await client.PUT(NOTIFICATION_SETTINGS_ENDPOINTS.UPDATE_USER_NOTIFICATION_PLATFORM, {
    signal,
    params,
    body,
  });

  return data;
};
