import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { UilLink } from '@iconscout/react-unicons';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Button } from '@/components/ui/button';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { toast } from '@/components/ui/toaster';

import OnboardingFooter from '@/components/onboarding/footer';

import { useGetCurrentBusinessSuspense, useUpdateCurrentBusiness } from '@/hooks/business';

import { getFormattedDataForAPI } from '@/utils/format';

import { businessSocialsSchema, TBusinessSocialsFormData } from '@/validation-schemas/business/business-socials';

import FacebookLogo from '@/assets/brands/facebook.svg';
import InstagramLogo from '@/assets/brands/instagram.svg';
import LinkedInLogo from '@/assets/brands/linkedin.svg';
import TikTokLogo from '@/assets/brands/tiktok.svg';
import TwitterLogo from '@/assets/brands/twitter.svg';

import FormFooter from '../form-footer';
import FormNavigationBlocker from '../form-navigation-blocker';
import { IFormProps } from '../types';

function getNullorValue(value: string | null | undefined): string {
  if (!value) {
    return '';
  }
  return value;
}

const BusinessSocialsForm: React.FC<IFormProps> = ({ onSuccess, isOnboarding, isDisabled }) => {
  const { data: currentBusiness } = useGetCurrentBusinessSuspense({});

  const { mutateAsync: updateBusiness, isPending: isUpdatingBusiness } = useUpdateCurrentBusiness({
    reactQueryOptions: {
      onSuccess: () => {
        if (!isOnboarding) {
          toast.success('Account updated successfully!');
        }
        onSuccess?.();
      },
    },
  });

  const form = useForm<TBusinessSocialsFormData>({
    disabled: isDisabled,
    resolver: zodResolver(businessSocialsSchema),
    defaultValues: {
      website: currentBusiness?.website || '',
      linkedin_url: currentBusiness?.linkedin_url || '',
      instagram_url: currentBusiness?.instagram_url || '',
      tiktok_url: currentBusiness?.tiktok_url || '',
      facebook_url: currentBusiness?.facebook_url || '',
      twitter_url: currentBusiness?.twitter_url || '',
    },
    values: {
      website: currentBusiness?.website || '',
      linkedin_url: currentBusiness?.linkedin_url || '',
      instagram_url: currentBusiness?.instagram_url || '',
      tiktok_url: currentBusiness?.tiktok_url || '',
      facebook_url: currentBusiness?.facebook_url || '',
      twitter_url: currentBusiness?.twitter_url || '',
    },
  });

  const [showInstagram, setShowInstagram] = useState(!!currentBusiness?.instagram_url);
  const [showTikTok, setShowTikTok] = useState(!!currentBusiness?.tiktok_url);
  const [showFacebook, setShowFacebook] = useState(!!currentBusiness?.facebook_url);
  const [showTwitter, setShowTwitter] = useState(!!currentBusiness?.twitter_url);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = form;

  const onSubmit: SubmitHandler<TBusinessSocialsFormData> = (data) => {
    const formattedData = getFormattedDataForAPI(data);
    updateBusiness({ body: formattedData });
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          name="website"
          control={control}
          render={({ field }) => (
            <FormItem>
              <div className="flex gap-x-2">
                <FormLabel>
                  <div className="flex h-full w-12 items-center justify-center rounded-lg border border-primary-dark-10 bg-primary-white-100 p-2">
                    <UilLink />
                  </div>
                </FormLabel>
                <FormControl>
                  <Input
                    className="w-full"
                    placeholder="www.upplft.com"
                    {...field}
                    value={getNullorValue(field.value)}
                    error={!!errors.website?.message}
                    prefix="https://"
                  />
                </FormControl>
              </div>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="linkedin_url"
          control={control}
          render={({ field }) => (
            <FormItem>
              <div className="flex gap-x-2">
                <FormLabel>
                  <div className="flex h-full w-12 items-center justify-center rounded-lg border border-primary-dark-10 bg-primary-white-100 p-2">
                    <img
                      src={LinkedInLogo}
                      alt="Instagram Icon"
                    />
                  </div>
                </FormLabel>
                <FormControl>
                  <Input
                    className="w-full"
                    placeholder="www.upplft.com"
                    {...field}
                    value={getNullorValue(field.value)}
                    error={!!errors.linkedin_url?.message}
                    prefix="https://"
                  />
                </FormControl>
              </div>
              <FormError />
            </FormItem>
          )}
        />
        {showInstagram && (
          <FormField
            name="instagram_url"
            control={control}
            render={({ field }) => (
              <FormItem>
                <div className="flex gap-x-2">
                  <FormLabel>
                    <div className="flex h-full w-12 items-center justify-center rounded-lg border border-primary-dark-10 bg-primary-white-100 p-2">
                      <img
                        src={InstagramLogo}
                        alt="Instagram Icon"
                      />
                    </div>
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder="www.upplft.com"
                      {...field}
                      value={getNullorValue(field.value)}
                      error={!!errors.instagram_url?.message}
                      prefix="https://"
                      clearable
                      onClear={() => {
                        field.onChange('');
                        setShowInstagram(false);
                      }}
                    />
                  </FormControl>
                </div>
                <FormError />
              </FormItem>
            )}
          />
        )}
        {showTikTok && (
          <FormField
            name="tiktok_url"
            control={control}
            render={({ field }) => (
              <FormItem>
                <div className="flex gap-x-2">
                  <FormLabel>
                    <div className="flex h-full w-12 items-center justify-center rounded-lg border border-primary-dark-10 bg-primary-white-100 p-2">
                      <img
                        src={TikTokLogo}
                        alt="Instagram Icon"
                      />
                    </div>
                  </FormLabel>
                  <FormControl>
                    <Input
                      className="w-full"
                      placeholder="www.upplft.com"
                      {...field}
                      value={getNullorValue(field.value)}
                      error={!!errors.tiktok_url?.message}
                      prefix="https://"
                      clearable
                      onClear={() => {
                        field.onChange('');
                        setShowTikTok(false);
                      }}
                    />
                  </FormControl>
                </div>
                <FormError />
              </FormItem>
            )}
          />
        )}
        {showFacebook && (
          <FormField
            name="facebook_url"
            control={control}
            render={({ field }) => (
              <FormItem>
                <div className="flex gap-x-2">
                  <FormLabel>
                    <div className="flex h-full w-12 items-center justify-center rounded-lg border border-primary-dark-10 bg-primary-white-100 p-2">
                      <img
                        src={FacebookLogo}
                        alt="Instagram Icon"
                      />
                    </div>
                  </FormLabel>
                  <FormControl>
                    <Input
                      className="w-full"
                      placeholder="www.upplft.com"
                      {...field}
                      value={getNullorValue(field.value)}
                      error={!!errors.facebook_url?.message}
                      prefix="https://"
                      clearable
                      onClear={() => {
                        field.onChange('');
                        setShowFacebook(false);
                      }}
                    />
                  </FormControl>
                </div>
                <FormError />
              </FormItem>
            )}
          />
        )}
        {showTwitter && (
          <FormField
            name="twitter_url"
            control={control}
            render={({ field }) => (
              <FormItem>
                <div className="flex gap-x-2">
                  <FormLabel>
                    <div className="flex h-full w-12 items-center justify-center rounded-lg border border-primary-dark-10 bg-primary-white-100 p-2">
                      <div className="flex h-6 w-6 items-center justify-center rounded-md bg-black">
                        <img
                          src={TwitterLogo}
                          alt="Twitter Icon"
                        />
                      </div>
                    </div>
                  </FormLabel>
                  <FormControl>
                    <Input
                      className="w-full"
                      placeholder="www.upplft.com"
                      {...field}
                      value={getNullorValue(field.value)}
                      error={!!errors.twitter_url?.message}
                      prefix="https://"
                      clearable
                      onClear={() => {
                        field.onChange('');
                        setShowTwitter(false);
                      }}
                    />
                  </FormControl>
                </div>
                <FormError />
              </FormItem>
            )}
          />
        )}
      </Form>
      {(!showFacebook || !showInstagram || !showTikTok || !showTwitter) && (
        <div className="mt-8 flex w-full flex-col gap-8">
          <div className="flex w-full items-center">
            <div className="flex-grow border-t border-primary-dark-20"></div>
            <span className="mx-8 whitespace-nowrap text-base font-medium leading-normal text-primary-dark-20">
              Add more
            </span>
            <div className="flex-grow border-t border-primary-dark-20"></div>
          </div>
          <div className="flex w-full justify-evenly gap-2">
            {!showInstagram && (
              <Button
                onClick={() => setShowInstagram(!showInstagram)}
                variant={'tertiary'}
                className="grow"
                size={'lg'}
              >
                <div className="h-6 w-6">
                  <img
                    src={InstagramLogo}
                    alt="Instagram Icon"
                  />
                </div>
              </Button>
            )}
            {!showTikTok && (
              <Button
                onClick={() => setShowTikTok(!showTikTok)}
                variant={'tertiary'}
                className="grow"
                size={'lg'}
              >
                <div className="h-6 w-6">
                  <img
                    src={TikTokLogo}
                    alt="Tiktok Icon"
                  />
                </div>
              </Button>
            )}
            {!showFacebook && (
              <Button
                onClick={() => setShowFacebook(!showFacebook)}
                variant={'tertiary'}
                className="grow"
                size={'lg'}
              >
                <div className="h-6 w-6">
                  <img
                    src={FacebookLogo}
                    alt="Facebook Icon"
                  />
                </div>
              </Button>
            )}
            {!showTwitter && (
              <Button
                onClick={() => setShowTwitter(!showTwitter)}
                variant={'tertiary'}
                className="grow"
                size={'lg'}
              >
                <div className="flex h-6 w-6 items-center justify-center rounded-md bg-black">
                  <img
                    src={TwitterLogo}
                    alt="Twitter Icon"
                  />
                </div>
              </Button>
            )}
          </div>
        </div>
      )}

      {isOnboarding ? (
        <OnboardingFooter
          handleNext={handleSubmit(onSubmit)}
          domain="business"
          isLoading={isUpdatingBusiness}
        />
      ) : (
        <>
          <FormNavigationBlocker />
          <FormFooter
            isDisabled={isDisabled}
            onSubmit={handleSubmit(onSubmit)}
            isSubmitting={isUpdatingBusiness}
          />
        </>
      )}
    </FormProvider>
  );
};
export default BusinessSocialsForm;
