import React from 'react';

import { Card, CardContent, CardDescription, CardHeader } from '@/components/ui/card';

import { cn } from '@/lib/utils';

import { Button } from '../ui/button';

export interface IExperienceCardProps {
  companyName: string;
  role: string;
  startDate: Date;
  endDate?: Date | null | undefined;
  className?: string;
  workingCurrently: boolean;
  onEdit?: () => void;
}

export const ExperienceCard: React.FC<IExperienceCardProps> = ({
  className,
  companyName,
  role,
  startDate,
  endDate,
  workingCurrently,
  onEdit,
}) => {
  const startDateString = startDate.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
  const endDateString = !workingCurrently
    ? endDate?.toLocaleDateString('en-US', { month: 'short', year: 'numeric' })
    : 'Present';
  return (
    <Card
      className={cn(
        'inline-flex w-full items-start justify-between rounded-lg border border-primary-dark-10 bg-primary-white-100 px-6 py-4 shadow-xs',
        className
      )}
    >
      <div className="flex flex-col space-y-2">
        <CardHeader>
          <div className="flex flex-col space-y-2">
            <h2 className="text-xl font-semibold leading-normal text-primary-dark-100">{companyName}</h2>
            <CardDescription className="flex text-sm font-medium leading-tight text-primary-dark-60">
              <span>{role}</span>
            </CardDescription>
          </div>
        </CardHeader>
        <CardContent className="">
          <p className="text-xs font-medium leading-[18px] text-primary-dark-40">
            {startDateString} to {endDateString}
          </p>
        </CardContent>
      </div>
      {onEdit && (
        <Button
          variant="link"
          onClick={onEdit}
        >
          Edit
        </Button>
      )}
    </Card>
  );
};
