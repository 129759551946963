import { UilExclamationTriangle } from '@iconscout/react-unicons';
import { createFileRoute } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';

import { Logo } from '@/components/logo';

export const Route = createFileRoute('/invite/invalid-token')({
  component: InvalidTokenPage,
});

function InvalidTokenPage() {
  return (
    <div className="flex justify-center py-8">
      <Card className="flex w-[28.75rem] flex-col items-center justify-center p-8 shadow-lg">
        <Logo
          variant="full"
          className="mx-auto mb-8"
        />
        <UilExclamationTriangle className="mb-4 h-16 w-16 text-red-500" />
        <h1 className="mb-4 text-2xl font-semibold text-primary-dark-100">Invalid Invitation</h1>
        <p className="mb-6 text-center text-base text-primary-dark-60">
          The invitation link you&apos;ve used is invalid or has expired. Please contact your administrator for a new
          invitation.
        </p>
        <Button
          onClick={() => (window.location.href = '/login')}
          className="w-full"
          size="sm"
        >
          Return to Login
        </Button>
      </Card>
    </div>
  );
}
