import { useState } from 'react';
import { UilTimes } from '@iconscout/react-unicons';
import { useFormContext } from 'react-hook-form';

import { Badge } from '@/components/ui/badge';
import ComboBoxMultipleSelect from '@/components/ui/combo-box-multiple';
import { FormControl, FormError, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { MultiSelect } from '@/components/ui/multi-select-new';
import Separator from '@/components/ui/separator';
import Heading from '@/components/ui/typography/heading';

import { DomainExperienceForm } from '@/components/forms/coupled-select-option';
import SettingContainer from '@/components/settings/setting-container';

import { useGetAllSkillsets } from '@/hooks/skillsets';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { getOptionsForSelectFromValuesMap } from '@/utils/string';

import { VALUES_GROUP } from '@/constants/values-map';

import { TStep3FormData } from '@/validation-schemas/business/create-role-schema';
import { TDomainExperienceFormData } from '@/validation-schemas/coupled-fields.schema';

const Step3Fields = () => {
  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext<TStep3FormData>();
  const [skillsetQuery, setSkillsetQuery] = useState('');

  const { data: technicalSkillsList, isLoading: isTechnicalSkillsLoading } = useGetAllSkillsets({
    params: {
      query: {
        q: skillsetQuery,
      },
    },
  });

  const { data: softSkillsData, isLoading: isSoftSkillsLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        value_group: VALUES_GROUP.SOFT_SKILLS,
      },
    },
  });

  const handleDomainExperienceSubmit = (data: TDomainExperienceFormData) => {
    const { domainExperience } = getValues();
    const existingDomainIndex = domainExperience.findIndex((item) => item.domain === data.domain);

    if (existingDomainIndex !== -1) {
      // If domain already exists, update its experience
      const updatedDomainExperience = [...domainExperience];
      updatedDomainExperience[existingDomainIndex] = data;
      setValue('domainExperience', updatedDomainExperience, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    } else {
      // If domain doesn't exist, add it to the array
      const newDomainExperience = [...domainExperience, data];
      setValue('domainExperience', newDomainExperience, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  const removeDomainExperience = (domain: string) => {
    const { domainExperience } = getValues();
    const updatedDomainExperience = domainExperience.filter((item) => item.domain !== domain);
    setValue('domainExperience', updatedDomainExperience, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  return (
    <>
      <SettingContainer>
        <Heading
          title="Domain experience"
          subtitle="Select one or more required for this role."
        />
        <FormField
          control={control}
          name="domainExperience"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div className="space-y-2">
                  <DomainExperienceForm
                    onSubmit={handleDomainExperienceSubmit}
                    error={!!errors.domainExperience}
                  />
                  {field.value?.length > 0 && (
                    <div className="flex flex-row flex-wrap gap-2">
                      {field.value.map((s) => (
                        <Badge
                          key={s.domain}
                          variant="success"
                          hideDot
                          onClick={() => removeDomainExperience(s.domain)}
                          className="cursor-pointer"
                        >
                          <span>
                            {s.domain} - {s.experience} years
                          </span>
                          <UilTimes className="mt-0.5 size-3.5 text-primary-success-80" />
                        </Badge>
                      ))}
                    </div>
                  )}
                </div>
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </SettingContainer>
      <Separator />
      <SettingContainer>
        <Heading
          title="Technical skills"
          subtitle="Select up to 10 technical skills required for this role."
        />
        <FormField
          name="technicalSkills"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Technical skills</FormLabel>
              <FormControl>
                <ComboBoxMultipleSelect
                  placeholder="Start typing or select"
                  options={
                    technicalSkillsList?.items
                      .map((s) => s.category)
                      .filter((s): s is string => s !== undefined && s !== null) || []
                  }
                  selected={field.value}
                  onSelect={(value) => field.onChange(value)}
                  isLoading={isTechnicalSkillsLoading}
                  onQueryChange={(query) => setSkillsetQuery(query)}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </SettingContainer>
      <Separator />
      <SettingContainer>
        <Heading
          title="Soft skills"
          subtitle="Select up to 10 soft skills required for this role."
        />
        <FormField
          name="softSkills"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Soft skills</FormLabel>
              <FormControl>
                <MultiSelect
                  selected={field.value || []}
                  options={getOptionsForSelectFromValuesMap(softSkillsData)}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.softSkills}
                  isLoading={isSoftSkillsLoading}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </SettingContainer>
      <Separator />
    </>
  );
};

export default Step3Fields;
