import { z } from 'zod';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const selectedRolesSchema = z.array(
  z.object({
    id: z.number().min(1, 'Role ID is required'),
    title: z.string().min(1, 'Role Title is required'),
  })
);

export type TSelectedRolesFormData = z.infer<typeof selectedRolesSchema>;

type TPipelineStore = {
  data: TSelectedRolesFormData;
  setData: (data: TSelectedRolesFormData) => void;
  resetData: () => void;
};

const initialData: TSelectedRolesFormData = [];

export const usePipelineStore = create<TPipelineStore>()(
  persist(
    (set) => ({
      data: initialData,
      setData: (data: TSelectedRolesFormData) => set({ data }),
      resetData: () => set({ data: initialData }),
    }),
    {
      name: 'pipeline-store',
    }
  )
);
