import { useEffect } from 'react';
import { useCreateRoleStore } from '@/store/create-role-store';
import { createFileRoute } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import Heading from '@/components/ui/typography/heading';

import useCreateRoleStepper from '@/hooks/useCreateRoleStepper';

import CultureQuizImg from '@/assets/culture-quiz.png';

export const Route = createFileRoute('/_authenticated/business/create-role/step5/')({
  component: CultureQuizMainPage,
});

function CultureQuizMainPage() {
  const { skipCultureQuiz, goToStep, currentStep } = useCreateRoleStepper();
  const { data } = useCreateRoleStore();

  useEffect(() => {
    if (data.cultureFactors.length > 0) {
      goToStep(currentStep, 'results');
    }
  }, [data, currentStep, goToStep]);

  return (
    <main>
      <section className="flex flex-col justify-center gap-8 p-4 pb-6 2xl:p-8 2xl:pb-8">
        <div className="mx-auto max-w-[30rem] space-y-2 text-center">
          <Heading
            title="Culture quiz"
            size="title"
          />
          <p className="text-primary-dark-60">
            This culture quiz is designed to highlight your team values and environment.
          </p>
        </div>
        <div className="flex justify-center gap-4">
          <Button
            variant="tertiary"
            onClick={skipCultureQuiz}
          >
            Skip for now
          </Button>
          <Button onClick={() => goToStep(currentStep, 'culture-quiz')}>Start quiz</Button>
        </div>
      </section>
      <section className="mx-auto max-w-[78.125rem]">
        <img
          src={CultureQuizImg}
          alt="Culture quiz"
          className="h-full w-full object-contain"
        />
      </section>
    </main>
  );
}
