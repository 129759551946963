import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { OptionType } from '@/components/ui/select-new';

import { TBusiness } from '@/services/business';

export type TPartnerStoreData = {
  selectedBusiness?: string;
  businessOptions?: OptionType<TBusiness>[];
};

type TPartnerStore = {
  data: TPartnerStoreData;
  setData: (data: TPartnerStoreData) => void;
  resetData: () => void;
};

const initialData: TPartnerStoreData = {
  selectedBusiness: undefined,
  businessOptions: [],
};

export const usePartnerStore = create<TPartnerStore>()(
  persist(
    (set) => ({
      data: initialData,
      setData: (data: TPartnerStoreData) => set({ data }),
      resetData: () => set({ data: initialData }),
    }),
    {
      name: 'partner-store',
    }
  )
);
