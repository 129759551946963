import { Tag } from '@/components/ui/tag';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

import { AU } from '@/components/icons/flags';

import { cn } from '@/lib/utils';

// Todo: Make country and flag dynamic
const LocationTag = ({
  location = 'Sydney',
  className,
  useCountryCode,
}: {
  location: string | null;
  className?: string;
  useCountryCode?: boolean;
}) => {
  if (!location) return null;

  if (useCountryCode)
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <Tag
              size={'sm'}
              hideDot
              className={cn('w-[7.5rem] font-medium', className)}
            >
              <AU />
              <span className={cn('w-[5rem] truncate')}>{location}, AU</span>
            </Tag>
            <TooltipContent
              className="bg-primary-dark-120 text-xs text-primary-white-100"
              arrowPadding={0}
            >
              <span>{location}, Australia</span>
            </TooltipContent>
          </TooltipTrigger>
        </Tooltip>
      </TooltipProvider>
    );

  return (
    <Tag
      size={'sm'}
      hideDot
      className={cn('font-medium', className)}
    >
      <AU />
      <span>{location}, Australia</span>
    </Tag>
  );
};

export default LocationTag;
