import { paths } from '@/lib/api/v1';

import { Filters } from '../types';
import { CANDIDATE_ENDPOINTS } from './candidate.endpoints';
import { getCurrentCandidate } from './candidate.services';

// enums
export enum PersonalityArchetypeCode {
  X = 'X',
  H = 'H',
  O = 'O',
  A = 'A',
  E = 'E',
  C = 'C',
}

export enum PersonalityArchetypeName {
  X = 'Extraversion',
  H = 'Honest-Humility',
  O = 'Openness to Experience',
  A = 'Agreeableness',
  E = 'Emotionality',
  C = 'Conscientiousness',
}

export enum CultureName {
  CLAN = 'Clan',
  ADHOCRACY = 'Adhocracy',
  MARKET = 'Market',
  HIERARCHY = 'Hierarchy',
}

//types
export type TCandidate = Awaited<ReturnType<typeof getCurrentCandidate>>;
export type TGetCurrentCandidateRequest = paths[typeof CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE]['get'];

export type TUpdateCurrentCandidateRequest = paths[typeof CANDIDATE_ENDPOINTS.UPDATE_CURRENT_CANDIDATE]['put'];

export type TCandidateCulture = {
  measures: string;
  rank: number;
  score: number;
  name: CultureName;
};

export type TGetBookedTimeslotsRequest = paths[typeof CANDIDATE_ENDPOINTS.GET_BOOKED_TIMESLOTS]['get'];
export type TBookedTimeSlot =
  TGetBookedTimeslotsRequest['responses']['200']['content']['application/json']['data']['items'][0];

export type TGetCurrentCandidateMatchesRequest = paths[typeof CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_MATCHES]['get'];
export type TCurrentCandidateMatches =
  TGetCurrentCandidateMatchesRequest['responses']['200']['content']['application/json']['data'];
export type TCurrentCandidateMatch =
  TGetCurrentCandidateMatchesRequest['responses']['200']['content']['application/json']['data']['items'][0];
export type TCandidatePostsSort = NonNullable<TGetCurrentCandidateMatchesRequest['parameters']['query']>['sort'];

export type TGetCurrentCandidateAppliedMatchesRequest =
  paths[typeof CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_APPLIED_MATCHES]['get'];
export type TCurrentCandidateAppliedMatches =
  TGetCurrentCandidateAppliedMatchesRequest['responses']['200']['content']['application/json']['data'];
export type TCurrentCandidateAppliedMatch = TCurrentCandidateAppliedMatches['items'][0];
export type TGetCurrentCandidateAppliedSort = NonNullable<
  TGetCurrentCandidateAppliedMatchesRequest['parameters']['query']
>['sort'];
export type TCandidatePostsFilters = Filters;

export type TCandidateMatchStatus = NonNullable<TCurrentCandidateAppliedMatch['metadata']>['candidate_status'];

export type TCandidateJobStatus = NonNullable<TCurrentCandidateAppliedMatch['status']>;

export type TGetCurrentCandidateHiredMatchesRequest =
  paths[typeof CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_HIRED_MATCHES]['get'];

export type TGetCurrentCandidateSingleJobDetailsRequest =
  paths[typeof CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_SINGLE_JOB_POST]['get'];
export type TCandidateJobPost =
  TGetCurrentCandidateSingleJobDetailsRequest['responses']['200']['content']['application/json']['data'];

export type TSavePostRequest = paths[typeof CANDIDATE_ENDPOINTS.SAVE_POST]['post'];
export type TUnsavePostRequest = paths[typeof CANDIDATE_ENDPOINTS.UNSAVE_POST]['delete'];

export type TGetCurrentCandidateArchivedJobPosts =
  paths[typeof CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_ARCHIVED_JOB_POST]['get'];
export type TArchivedCandidateMatches =
  TGetCurrentCandidateArchivedJobPosts['responses']['200']['content']['application/json']['data'];
export type TArchivedCandidateMatch = TArchivedCandidateMatches['items'][0];

export type TGetSavedPostRequest = paths[typeof CANDIDATE_ENDPOINTS.GET_SAVED_POST]['get'];
export type TGetSavedPostResponse = TGetSavedPostRequest['responses']['200']['content']['application/json']['data'];
export type TGetSavedPostData = TGetSavedPostResponse['items'][0];
export type TGetSavedPostSort = NonNullable<TGetSavedPostRequest['parameters']['query']>['sort'];
export type TGetSavedPostFilters = Filters;

export type TGetCandidateByIdRequest = paths[typeof CANDIDATE_ENDPOINTS.GET_CANDIDATE_BY_ID]['get'];
export type TCandidateById = TGetCandidateByIdRequest['responses']['200']['content']['application/json']['data'];

export type TGetCurrentCandidateOffersRequest = paths[typeof CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE_OFFERS]['get'];
export type TCurrentCandidateOffers =
  TGetCurrentCandidateOffersRequest['responses']['200']['content']['application/json']['data'];
export type TCurrentCandidateOffer = TCurrentCandidateOffers['items'][0];

export type TOfferStatus = TCurrentCandidateOffer['status'];
