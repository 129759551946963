import { useQuery } from '@tanstack/react-query';

import {
  getCandidateMeetingForPost,
  getMeetingDetails,
  getMeetings,
  getMeetingTimeSlots,
  getUpcomingMeetings,
  MEETING_ENDPOINTS,
  TGetCandidateMeetingForPost,
  TGetMeetingDetails,
  TGetMeetingTimeSlots,
  TGetUpcomingMeetingsRequest,
} from '@/services/meetings';

import { type UseQueryOptions } from '../types';

export function useGetUpcomingMeetings({ reactQueryOptions }: UseQueryOptions<TGetUpcomingMeetingsRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [MEETING_ENDPOINTS.GET_UPCOMING_MEETINGS],
    queryFn: ({ signal }) => getUpcomingMeetings({ signal }),
    staleTime: Infinity,
  });
}

export function useGetMeetings({ reactQueryOptions }: UseQueryOptions<TGetUpcomingMeetingsRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [MEETING_ENDPOINTS.GET_MEETINGS],
    queryFn: ({ signal }) => getMeetings({ signal }),
    staleTime: Infinity,
  });
}

export function useGetCandidateMeetingForPost({
  reactQueryOptions,
  params,
}: UseQueryOptions<TGetCandidateMeetingForPost>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [MEETING_ENDPOINTS.GET_CANDIDATE_MEETING_FOR_POST, params.path.post_id],
    queryFn: ({ signal }) => getCandidateMeetingForPost({ signal, params }),
    staleTime: Infinity,
  });
}

export function useGetMeetingDetails({ reactQueryOptions, params }: UseQueryOptions<TGetMeetingDetails>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [MEETING_ENDPOINTS.GET_MEETING_DETAILS, params.path.meeting_id],
    queryFn: ({ signal }) => getMeetingDetails({ signal, params }),
    staleTime: Infinity,
  });
}

export function useGetMeetingTimeSlots({ reactQueryOptions, params }: UseQueryOptions<TGetMeetingTimeSlots>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [MEETING_ENDPOINTS.GET_MEETING_TIME_SLOTS, params.path.meeting_id],
    queryFn: ({ signal }) => getMeetingTimeSlots({ signal, params }),
    staleTime: Infinity,
  });
}
