import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { createFileRoute, redirect } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';

import FeaturedIcon from '@/components/icons/featured-icon';

import { useSendSelfEmailVerification } from '@/hooks/auth';
import useAuth from '@/hooks/useAuth';

export const Route = createFileRoute('/unverified')({
  component: Unverified,
  beforeLoad: async ({ context }) => {
    const { isAuthenticated, user } = context.auth;

    if (!isAuthenticated) {
      throw redirect({
        to: '/login',
      });
    }
    if (user?.email_verified) {
      throw redirect({
        to: '/',
      });
    }
  },
});

function Unverified() {
  const { logout } = useAuth();
  const [isSent, setIsSent] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  getAccessTokenSilently({ cacheMode: 'off' });
  const { mutateAsync, isPending } = useSendSelfEmailVerification({
    reactQueryOptions: {
      onSuccess: () => {
        setIsSent(true);
      },
    },
  });

  return (
    <div className="w-svh relative h-svh bg-slate-100">
      <div className="mx-auto flex h-full flex-col">
        <div className="absolute flex h-full w-full">
          <div className="m-auto flex">
            <Card className="border-none p-8 shadow-md">
              <CardHeader className="pb-4">
                <CardTitle className="flex flex-row items-center gap-x-4">
                  <FeaturedIcon variant="warning" />
                  <div className="text-2xl font-semibold">Please verify your email address</div>
                </CardTitle>
              </CardHeader>
              <CardContent className="max-w-[660px] py-4">
                <p className="font-medium text-primary-dark-60">
                  If you have verified your email address, please re-login.
                </p>
                <br />
                <p className="font-medium text-primary-dark-60">
                  If you have not received the email, or the link has expired, please click the button below to resend
                  the verification link.
                </p>
              </CardContent>
              <CardFooter className="flex justify-end gap-x-4">
                {!isSent ? (
                  <Button
                    variant="tertiary"
                    onClick={() => mutateAsync()}
                    loading={isPending}
                  >
                    Resend verification
                  </Button>
                ) : (
                  <Button
                    className="cursor-default hover:enabled:bg-white"
                    variant="tertiary"
                  >
                    Sent{' '}
                    <FeaturedIcon
                      size="sm"
                      variant="success"
                    />
                  </Button>
                )}
                <Button onClick={logout}>Re-login</Button>
              </CardFooter>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
