import { Area, AreaChart } from 'recharts';

import { ChartConfig, ChartContainer } from '@/components/ui/chart';

export const description = 'An area chart with gradient fill';

export function AreaChartWithGradient({
  chartData,
  isTrendingUp,
}: {
  chartData: { number: number }[];
  isTrendingUp?: boolean;
}) {
  const color = isTrendingUp ? '#71D4A6' : '#FF8279';
  const chartConfig = {
    number: {
      label: 'Number',
      color,
    },
  } satisfies ChartConfig;
  return (
    <ChartContainer config={chartConfig}>
      <AreaChart
        accessibilityLayer
        data={chartData}
      >
        <defs>
          <linearGradient
            id={`${color}`}
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop
              offset="10%"
              stopColor={color}
              stopOpacity={0.8}
            />

            <stop
              offset="90%"
              stopColor={isTrendingUp ? '#71D4A600' : '#F0443800'}
              // stopColor={color}
              stopOpacity={0.1}
            />
          </linearGradient>
        </defs>
        <Area
          dataKey="number"
          type="natural"
          fill={`url(#${color})`}
          fillOpacity={0.4}
          stroke={color}
          stackId="a"
        />
      </AreaChart>
    </ChartContainer>
  );
}
