import { useState } from 'react';
import { useCreateRoleStore } from '@/store/create-role-store';
import { DevTool } from '@hookform/devtools';
import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';

import { FormProvider } from '@/components/ui/form';
import Separator from '@/components/ui/separator';
import { toast } from '@/components/ui/toaster';

import CreateRoleNavigation from '@/components/roles/business/create-role/create-role-navigation';
import Step1Fields from '@/components/roles/business/create-role/form-fields/step1-fields';
import Step2Fields from '@/components/roles/business/create-role/form-fields/step2-fields';
import Step3Fields from '@/components/roles/business/create-role/form-fields/step3-fields';
import Step6Fields from '@/components/roles/business/create-role/form-fields/step6-fields';
import Step7Fields from '@/components/roles/business/create-role/form-fields/step7-fields';
import PublishRoleButton from '@/components/roles/business/create-role/publish-role-button';
import CultureQuizResults from '@/components/roles/business/create-role/quiz/culture-quiz-results';
import PersonalityQuizResults from '@/components/roles/business/create-role/quiz/personality-quiz-results';
import RoleCreatedModal from '@/components/roles/business/create-role/role-created-modal';
import SaveAsDraftButton from '@/components/roles/business/create-role/save-as-draft-button';

import { useGetCurrentBusiness } from '@/hooks/business';
import { useCreateJobPost, useUpdateJobPost } from '@/hooks/business-roles';

import { mapCreateRoleFormDataToAPI, mapUpdateRoleFormDataToAPI } from '@/services/business-roles';

import { JOB_POST_STATUS } from '@/constants/post';

import { finalSchema, TFinalFormData } from '@/validation-schemas/business/create-role-schema';

export const Route = createFileRoute('/_authenticated/business/create-role/step8')({
  component: Step8,
});

function Step8() {
  const [open, setOpen] = useState(false);
  const { mode, id } = Route.useSearch();
  const navigate = useNavigate();

  const { data } = useCreateRoleStore();

  const { data: currentBusiness } = useGetCurrentBusiness({});

  const form = useForm<TFinalFormData>({
    resolver: zodResolver(finalSchema),
    values: {
      ...data,
      startDate: data.startDate ? new Date(data.startDate) : null,
      endDate: data.endDate ? new Date(data.endDate) : null,
    },
    mode: 'onChange',
    shouldFocusError: true,
  });

  const { mutate: createJobPost, isPending: isCreatingJobPost } = useCreateJobPost({
    params: { path: { businessId: currentBusiness?.id.toString() ?? '' } },
  });

  const { mutate: updateJobPost, isPending: isUpdatingJobPost } = useUpdateJobPost({
    params: { path: { businessId: currentBusiness?.id.toString() ?? '', postId: id?.toString() || '' } },
  });

  const onSubmit = () => {
    if (mode === 'create') {
      createJobPost(
        {
          body: mapCreateRoleFormDataToAPI(form.getValues(), true),
          params: { path: { businessId: currentBusiness?.id.toString() ?? '' } },
        },
        {
          onSuccess: () => setOpen(true),
          onError: () => toast.error('Failed to create job post'),
        }
      );
    } else {
      updateJobPost(
        {
          body: {
            ...mapUpdateRoleFormDataToAPI(form.getValues()),
            ...(mode === 'draft' && { status: JOB_POST_STATUS.OPEN }),
          },
          params: { path: { businessId: currentBusiness?.id.toString() ?? '', postId: id?.toString() || '' } },
        },
        {
          onSuccess: () => {
            if (mode === 'draft') {
              setOpen(true);
            } else {
              toast.success('Job post updated');
              navigate({ to: '/business/roles' });
            }
          },
          onError: () => toast.error('Failed to update job post'),
        }
      );
    }
  };

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Step1Fields />
          <Step2Fields />
          <Step3Fields />
          <div className="flex flex-col gap-8">
            <PersonalityQuizResults />
            <CultureQuizResults />
          </div>
          <Separator />
          <Step6Fields />
          <Step7Fields />
        </form>
        <PublishRoleButton
          onPublish={form.handleSubmit(onSubmit)}
          isLoading={isCreatingJobPost || isUpdatingJobPost}
          label={mode === 'edit' ? 'Save Changes' : 'Publish Role'}
        />
        <SaveAsDraftButton />
        <CreateRoleNavigation saveFormData={form.handleSubmit(onSubmit)} />
      </FormProvider>
      <RoleCreatedModal
        open={open}
        onOpenChange={setOpen}
      />
      <DevTool
        control={form.control}
        placement="top-left"
      />
    </>
  );
}

export default Step8;
