import { useState } from 'react';
import { UilFastMail, UilPlus } from '@iconscout/react-unicons';
import Fireworks from 'react-canvas-confetti/dist/presets/fireworks';
import { createPortal } from 'react-dom';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';

import { InviteTeamMemberForm } from '@/components/forms/business/team/invite-team-member-form';

import { useGetCurrentBusinessPermissions } from '@/hooks/permission';

const InviteTeamMemberButton: React.FC<{ disabled?: boolean }> = ({ disabled }) => {
  const { data: permissions } = useGetCurrentBusinessPermissions();
  const isInviteButtonDisabled = disabled || !permissions?.manage_team_members;

  const [isSuccess, setIsSuccess] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSubmit = () => {
    setIsSuccess(true);
  };

  const renderContent = () => {
    if (isSuccess) {
      return (
        <>
          <AlertDialogDescription>
            Your invite has been sent. You will receive a notification when it has been accepted.
            <br />
            <br />
            Manage user permissions in &apos;Team&apos;.
          </AlertDialogDescription>
          <AlertDialogFooter>
            <AlertDialogAction onClick={() => setOpen(false)}>Continue</AlertDialogAction>
          </AlertDialogFooter>
        </>
      );
    }

    return (
      <>
        <AlertDialogDescription>
          Invite your team members to your company page and share the load.
        </AlertDialogDescription>
        <InviteTeamMemberForm onInviteSent={handleSubmit} />
      </>
    );
  };

  return (
    <AlertDialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        if (!open) {
          setIsSuccess(false);
        }
      }}
    >
      <AlertDialogTrigger asChild>
        <Button
          disabled={isInviteButtonDisabled}
          className="w-full"
          size={'sm'}
        >
          <UilPlus size={'1.125rem'} />
          <span>Invite member</span>
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="gap-6">
        {isSuccess &&
          createPortal(
            <Fireworks
              autorun={{ speed: 3, duration: 900 }}
              className="fixed left-0 top-0 z-[99] h-full w-full"
            />,
            document.body
          )}
        <AlertDialogHeader>
          <AlertDialogTitle iconVariant={isSuccess ? 'success' : null}>
            {isSuccess ? (
              'Invite sent!'
            ) : (
              <div className="flex items-center gap-2">
                <UilFastMail /> <span>Invite team members</span>
              </div>
            )}
          </AlertDialogTitle>
        </AlertDialogHeader>
        {renderContent()}
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default InviteTeamMemberButton;
