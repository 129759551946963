import { useCallback } from 'react';
import { useCreateRoleStore } from '@/store/create-role-store';
import { useLocation, useNavigate, useSearch } from '@tanstack/react-router';

const getStepFromRoute = (stepString: string) => {
  const match = stepString.match(/step(\d+)/);

  // If a match is found, parse it as a number
  if (match && match[1]) {
    return parseInt(match[1], 10);
  }

  // If no match is found, return a default value (e.g., 0 or -1)
  return 0;
};

const TOTAL_STEPS = 8;

function useCreateRoleStepper() {
  const currentRoute = useLocation({ select: (location) => location.pathname });
  const { resetData } = useCreateRoleStore();
  const navigate = useNavigate();
  const search = useSearch({ from: '/_authenticated/business/create-role' });

  const getCurrentStep = useCallback(() => getStepFromRoute(currentRoute), [currentRoute]);

  const currentStep = getCurrentStep();

  const domain = '/business/create-role';

  const goToStep = (step: number, url?: string) => {
    navigate({
      to: url ? `/${domain}/step${step}/${url}` : `/${domain}/step${step}`,
      mask: {
        to: domain,
        search,
        unmaskOnReload: false,
      },
      search,
    });
  };

  const goToNextStep = () => {
    const nextStep = currentStep + 1;
    goToStep(nextStep);
  };

  const goToPreviousStep = () => {
    const previousStep = currentStep - 1;
    goToStep(previousStep);
  };

  const skipPersonalityQuiz = () => {
    goToStep(5);
  };

  const skipCultureQuiz = () => {
    goToStep(6);
  };

  const isLastStep = currentStep === TOTAL_STEPS - 1;
  const isFirstStep = currentStep === 1;

  const exitForm = () => {
    if (search.backRoute) {
      navigate({ to: search.backRoute });
      return resetData();
    }

    navigate({ to: '/business/roles' });
    resetData();
  };

  return {
    currentStep,
    goToNextStep,
    goToPreviousStep,
    goToStep,
    isLastStep,
    isFirstStep,
    skipPersonalityQuiz,
    skipCultureQuiz,
    exitForm,
  };
}

export default useCreateRoleStepper;
