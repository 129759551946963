import { paths } from '@/lib/api/v1';

import { USER_ENDPOINTS } from './user.endpoints';
import { getCurrentUser } from './user.services';

export enum USER_TYPE {
  CANDIDATE = 1,
  BUSINESS = 2,
  ADMIN = 3,
  PARTNER = 4,
}
export enum APP_DASHBOARD {
  BUSINESS = 'business',
  PARTNER = 'partner',
}

export type TUser = Awaited<ReturnType<typeof getCurrentUser>>;
export type TGetCurrentUserRequest = paths[typeof USER_ENDPOINTS.GET_CURRENT_USER]['get'];
export type TUPdateCurrentUserPasswordRequest = paths[typeof USER_ENDPOINTS.UPDATE_PASSWORD]['put'];
export type TUpdateCurrentUserRequest = paths[typeof USER_ENDPOINTS.UPDATE_CURRENT_USER]['put'];
export type TReactivateAccountRequest = paths[typeof USER_ENDPOINTS.REACTIVATE_ACCOUNT]['post'];
