import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { toast } from '@/components/ui/toaster';

import { useGetCurrentBusiness } from '@/hooks/business';
import { useDeleteRole } from '@/hooks/business-roles';

interface IProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  roleId: number;
}

const DeleteDraftModal: React.FC<IProps> = ({ open, onOpenChange, roleId }) => {
  const { data: currentBusiness } = useGetCurrentBusiness({});

  const { mutate: deleteRole, isPending } = useDeleteRole({
    params: { path: { businessId: currentBusiness?.id.toString() ?? '', postId: roleId.toString() } },
  });

  const handleDeleteDraft = () => {
    deleteRole(
      {
        params: { path: { businessId: currentBusiness?.id.toString() ?? '', postId: roleId.toString() } },
      },
      {
        onSuccess: () => {
          toast.success('Job post deleted');
          onOpenChange(false);
        },
        onError: () => {
          toast.error('Failed to delete job post');
        },
      }
    );
  };

  return (
    <>
      <AlertDialog
        open={open}
        onOpenChange={onOpenChange}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle iconVariant="alert">Delete draft</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to delete your draft?
              <br />
              <br />
              All of your hard work will not be saved.
            </AlertDialogDescription>
          </AlertDialogHeader>

          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              variant="destructive"
              disabled={isPending}
              onClick={handleDeleteDraft}
            >
              {isPending ? 'Deleting...' : 'Yes, delete draft'}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default DeleteDraftModal;
