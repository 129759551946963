import { UilAngleUp } from '@iconscout/react-unicons';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  OnChangeFn,
  PaginationOptions,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';

import { cn } from '@/lib/utils';

import NoData from '../no-data';
import { Button } from './button';
import { Card } from './card';
import Search from './search';
import { Skeleton } from './skeleton';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  title?: string;
  tabNavs?: React.ReactNode;
  isLoading?: boolean;
  enableSelect?: boolean;
  pagination?: {
    state: PaginationState;
    options: {
      onPaginationChange: PaginationOptions['onPaginationChange'];
      rowCount: PaginationOptions['rowCount'];
    };
  };
  sorting?: {
    state: SortingState;
    onSortingChange: OnChangeFn<SortingState>;
  };
  search?: {
    value: string;
    onChange: (value: string) => void;
  };
  noData?: {
    title: string;
    description: string;
  };
  headerButtons?: React.ReactNode;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  title,
  tabNavs,
  isLoading,
  pagination,
  search,
  noData,
  sorting,
  enableSelect,
  headerButtons,
}: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    data,
    columns,
    state: {
      ...(pagination && { pagination: pagination.state }),
      ...(sorting && { sorting: sorting.state }),
    },
    manualPagination: !!pagination,
    manualSorting: !!sorting,
    getCoreRowModel: getCoreRowModel(),
    ...(pagination && {
      ...pagination.options,
    }),
    ...(sorting && {
      onSortingChange: sorting.onSortingChange,
    }),
    enableMultiRowSelection: enableSelect,
  });

  const renderLoading = () => {
    return new Array(pagination?.state.pageSize || 10).fill(null).map((_, index) => (
      <TableRow key={index}>
        {columns.map((_, index) => (
          <TableCell
            className="h-10 text-center"
            key={index}
          >
            <Skeleton className="h-8 w-full rounded-lg" />
          </TableCell>
        ))}
      </TableRow>
    ));
  };

  const renderNoResults = () => {
    return (
      <TableRow>
        <TableCell
          colSpan={columns.length}
          className="text-center"
        >
          <NoData
            title={noData?.title || 'No data found'}
            description={noData?.description || ''}
            isSearchResults={!!search?.value}
            className="min-h-[50vh]"
          />
        </TableCell>
      </TableRow>
    );
  };

  const renderRows = () => {
    return table.getRowModel().rows.map((row) => (
      <TableRow
        key={row.id}
        data-state={row.getIsSelected() && 'selected'}
      >
        {row.getVisibleCells().map((cell) => (
          <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
        ))}
      </TableRow>
    ));
  };

  const renderTableBody = () => {
    if (isLoading) {
      return renderLoading();
    }

    if (table.getRowModel().rows?.length) {
      return renderRows();
    }

    return renderNoResults();
  };

  const renderPagination = () => {
    if (!pagination) {
      return null;
    }

    return (
      <div className="flex items-center justify-evenly border-t border-primary-dark-10 px-6 py-4">
        <Button
          variant="tertiary"
          onClick={table.previousPage}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </Button>
        <span className="flex-1 text-center text-sm font-semibold text-primary-dark-60">
          Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount().toLocaleString()}
        </span>
        <Button
          variant="tertiary"
          onClick={table.nextPage}
          disabled={!table.getCanNextPage()}
        >
          Next
        </Button>
      </div>
    );
  };

  return (
    <Card className="overflow-hidden rounded-2xl border p-0 shadow-md">
      {(title || search || headerButtons || tabNavs) && (
        <div className="flex flex-col px-6 py-4">
          <div className="flex items-center justify-between">
            {title && <h1 className="text-xl font-semibold">{title}</h1>}
            {tabNavs && <div className="mt-4">{tabNavs}</div>}
            <div className="flex items-center gap-4">
              {search && (
                <Search
                  value={search.value}
                  onChange={search.onChange}
                  className="w-[20rem]"
                />
              )}
              {headerButtons && headerButtons}
            </div>
          </div>
        </div>
      )}
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    key={header.id}
                    className="space-x-2"
                    style={{ width: `${header.getSize()}px` }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        onClick={header.column.getToggleSortingHandler()}
                        className={cn(
                          'inline-flex items-center gap-2',
                          header.column.getCanSort() && 'cursor-pointer',
                          header.column.getIsSorted() === 'desc' && '[&>svg]:rotate-180'
                        )}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        <UilAngleUp
                          className={cn(
                            'pointer-events-none size-4 text-primary-dark-60 transition-all duration-200',
                            header.column.getIsSorted() === 'asc' || header.column.getIsSorted() === 'desc'
                              ? 'opacity-100'
                              : 'opacity-0'
                          )}
                        />
                      </div>
                    )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>{renderTableBody()}</TableBody>
      </Table>
      {renderPagination()}
    </Card>
  );
}
