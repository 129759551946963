import { useNavigate } from '@tanstack/react-router';
import { ColumnDef, PaginationState } from '@tanstack/react-table';

import Avatar from '@/components/ui/avatar';
import { DataTable } from '@/components/ui/data-table';
import { Tag } from '@/components/ui/tag';

import {
  TBusinessTeamMember,
  TGetBusinessTeamMembersResponse,
  TUpdateBusinessTeamMembersDetailsRequest,
} from '@/services/business';
import { Filters } from '@/services/types';

import { getAccountTypeLabel } from '@/constants/business-team';

import { PartnerTeamMembersActionButton } from './partner-members-action-button';

function getAccountType(
  isOwner: boolean,
  accountType?: NonNullable<
    TUpdateBusinessTeamMembersDetailsRequest['requestBody']
  >['content']['application/json']['account_type']
) {
  if (!accountType) return 'Unknown';
  if (isOwner) return 'Owner-Admin';
  return getAccountTypeLabel(accountType);
}

interface ITeamTableProps {
  partnerId: string;
  data?: TGetBusinessTeamMembersResponse;
  isPending?: boolean;
  filters: Filters;
  setFilters: (filters: Filters) => void;
  paginationState: PaginationState;
}

export default function BusinessTeamTableForPartner({
  partnerId,
  data,
  isPending,
  paginationState,
  filters,
  setFilters,
}: ITeamTableProps) {
  const navigate = useNavigate();
  const columnDefs: ColumnDef<TBusinessTeamMember>[] = [
    {
      accessorKey: 'business.name',
      id: 'business.name',
      header: 'Name',
      cell: ({ row }) => {
        return (
          <div
            className="flex items-center gap-4"
            onClick={() =>
              navigate({
                to: `/business/team/${row.original.id}/profile`,
              })
            }
          >
            <Avatar
              fallbackType="user"
              src={row.original?.image_url}
              size="md"
            />
            <span className="text-sm font-semibold">
              {row.original?.first_name} {row.original?.last_name}
            </span>
          </div>
        );
      },
    },
    {
      header: 'Account type',
      accessorKey: 'metadata.match_percentage',
      id: 'metadata.match_percentage',
      cell: ({ row }) => {
        return (
          <Tag hideDot>
            {getAccountType(Boolean(row.original?.metadata?.is_owner), row.original?.metadata?.account_type)}
          </Tag>
        );
      },
    },

    // {
    //   accessorKey: 'last_active',
    //   header: 'Last active',
    //   cell: ({ row }) => {
    //     return formatDistanceToNow(row.original.last_active, { addSuffix: true });
    //   },
    // },
    {
      accessorKey: 'more',
      header: '',
      size: 24,
      cell: ({ row }) => {
        return (
          <div className="flex justify-end">
            <PartnerTeamMembersActionButton
              partnerId={partnerId}
              memberId={row.original.id.toString()}
            />
          </div>
        );
      },
    },
  ];

  return (
    <DataTable
      columns={columnDefs}
      data={data?.items || []}
      title="Members"
      isLoading={isPending}
      noData={{
        title: 'No team members available',
        description: 'You currently have no team members available.',
      }}
      search={{
        value: filters.query || '',
        onChange: (value) => {
          setFilters({ ...filters, query: value });
        },
      }}
      pagination={{
        state: paginationState,
        options: {
          rowCount: data?.total || 0,
          onPaginationChange: (pagination) => {
            setFilters(typeof pagination === 'function' ? pagination(paginationState) : pagination);
          },
        },
      }}
    />
  );
}
