import * as React from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const Tabs = TabsPrimitive.Root;

const tabListVariants = cva('inline-flex items-center justify-center rounded-[10px]', {
  variants: {
    variant: {
      primary: 'bg-primary-dark-5 p-1 text-primary-dark-100',
      underlined: 'bg-transparent text-primary-dark-60',
      secondary: '', //todo
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> & VariantProps<typeof tabListVariants>
>(({ className, variant, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(tabListVariants({ className, variant }))}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const tabTriggerVariants = cva(
  'inline-flex min-w-[9.75rem] items-center justify-center whitespace-nowrap px-3 py-2 text-sm font-medium ring-offset-white transition-all focus-visible:outline-none focus-visible:ring-2',
  {
    variants: {
      variant: {
        primary:
          'rounded-md data-[state=active]:bg-white data-[state=active]:text-primary-dark-100 data-[state=active]:shadow-sm focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
        underlined:
          'border-b-[2px] border-transparent pb-[18px] data-[state=active]:border-primary-blue-100 data-[state=active]:text-primary-blue-100',
        secondary: '',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  }
);

export type TTabTriggerVariantProps = VariantProps<typeof tabTriggerVariants>;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> & TTabTriggerVariantProps
>(({ className, variant, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(tabTriggerVariants({ className, variant }))}
    {...props}
  />
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'mt-2 ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300',
      className
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };
