import { createFileRoute, useNavigate } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';

import FeaturedIcon from '@/components/icons/featured-icon';
import { Logo } from '@/components/logo';

import BusinessOnboardingImage from '@/assets/business/onboarding/business_onboarding.png';

export const Route = createFileRoute('/_authenticated/business/onboarding/')({
  component: OnboardingComponent,
});

function OnboardingComponent() {
  const navigate = useNavigate();
  const handleNavigate = () => navigate({ to: '/business/onboarding/step1' });

  return (
    <main className="flex h-[100dvh] max-h-[100dvh] w-full flex-col items-center justify-center overflow-hidden pt-2 2xl:pt-8">
      <section className="inline-flex w-full max-w-[880px] shrink grow basis-0 flex-col items-center justify-start">
        <div className="inline-flex items-center justify-center gap-2.5 self-stretch px-8 py-2 2xl:gap-2.5">
          <Logo variant="mini" />
        </div>
        <div className="my-2 inline-flex h-64 w-full flex-col items-center justify-center space-y-4 px-8 py-2 2xl:my-10 2xl:space-y-6 3xl:my-20 3xl:space-y-8">
          <div className="flex flex-col items-center justify-center space-y-2 self-stretch 2xl:space-y-4">
            <div className="text-center text-[32px] font-semibold leading-10 text-primary-dark-100">
              Welcome to Upplft!
            </div>
            <div className="self-stretch text-center text-base font-medium leading-normal text-primary-dark-60">
              Before you land your next hire, we require you to build out your business profile!
            </div>
          </div>
          <div className="flex items-center gap-3.5 py-2 text-base font-medium leading-normal text-primary-dark-60">
            <FeaturedIcon
              variant="success"
              size="sm"
            />
            <span>This will only take you 3 - 5 minutes</span>
          </div>
          <Button
            onClick={handleNavigate}
            size={'lg'}
          >
            Get Started
          </Button>
        </div>
      </section>
      <section className="w-auto max-w-[84.25rem] items-center justify-center overflow-hidden">
        <img
          src={BusinessOnboardingImage}
          alt="Business Dashboard Image"
        />
      </section>
    </main>
  );
}
