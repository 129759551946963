import { UilFastMail, UilLockAlt, UilTrashAlt, UilUserCircle } from '@iconscout/react-unicons';
import { useNavigate } from '@tanstack/react-router';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownTriggerButton,
} from '@/components/ui/dropdown-menu';

interface PartnersTableActionButtonProps {
  id: string;
  email: string;
  // onResetPassword: (id: string, email: string) => void;
  onResendInvite: (id: string, email: string) => void;
  onRemove: (id: string, email: string) => void;
}

export function PartnersTableActionButton({
  id,
  email,
  //   onResetPassword,
  onResendInvite,
  onRemove,
}: PartnersTableActionButtonProps) {
  const navigate = useNavigate();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <DropdownTriggerButton />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem onClick={() => navigate({ to: `/business/partners/${id}/profile` })}>
          <UilUserCircle className="default-icon-sm" /> View Profile
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate({ to: `/business/partners/${id}/permissions` })}>
          <UilLockAlt className="default-icon-sm" /> Permissions
        </DropdownMenuItem>
        {/* <DropdownMenuItem >
                  <UilEyeSlash className="default-icon-sm" /> Disable
                </DropdownMenuItem> */}
        <DropdownMenuItem onClick={() => onResendInvite(id, email)}>
          <UilFastMail className="default-icon-sm" /> Resend invite
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => onRemove(id, email)}>
          <UilTrashAlt className="default-icon-sm" /> Remove
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
