import { ToOptions, useMatchRoute, useNavigate } from '@tanstack/react-router';

import { Tabs, TabsList, TabsTrigger, TTabTriggerVariantProps } from '@/components/ui/tabs';

import { cn } from '@/lib/utils';

export interface ITabNavItem {
  label: string;
  href: ToOptions['to'];
  fuzzyMatch?: boolean;
  icon?: React.ReactNode;
  count?: number;
  search?: ToOptions['search'];
}

interface IProps extends TTabTriggerVariantProps {
  navItems: ITabNavItem[];
  className?: string;
  triggerClassName?: string;
}

const TabNav: React.FC<IProps> = ({ navItems, variant, triggerClassName, className }) => {
  const matchRoute = useMatchRoute();
  const navigate = useNavigate();

  const activeTab = navItems.find((item) => matchRoute({ to: item.href, fuzzy: item.fuzzyMatch }));

  return (
    <Tabs
      value={activeTab?.href}
      onValueChange={(value: string) => {
        navigate({
          to: value as ToOptions['to'],
          resetScroll: false,
          search: activeTab?.search,
        });
      }}
      className={className}
    >
      <TabsList variant={variant}>
        {navItems.map((item) => (
          <TabsTrigger
            value={item.href!}
            key={item.label}
            variant={variant}
            className={cn('inline-flex items-center gap-x-2', triggerClassName)}
          >
            {item.icon}
            {item.label}
            {(item.count || item.count === 0) && (
              <div className="inline-flex h-5 w-5 flex-col items-center justify-center gap-2.5 rounded border border-[#e7e9ed] p-0.5">
                <div className="text-center font-['Montserrat'] text-xs font-medium leading-[18px] text-[#0c2149]">
                  {item.count}
                </div>
              </div>
            )}
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  );
};

export default TabNav;
