import { cva, VariantProps } from 'class-variance-authority';
import { LoaderCircle } from 'lucide-react';

import { cn } from '@/lib/utils';

const spinnerVariants = cva('animate-spin', {
  variants: {
    variant: {
      default: 'text-primary-blue-100',
      secondary: 'text-primary-white-100',
    },
    size: {
      xs: 'w-4.5 h-4.5',
      sm: 'h-6 w-6',
      default: 'h-8 w-8',
      lg: 'h-12 w-12',
      xl: 'h-14 w-14',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
  },
});
const Spinner: React.FC<React.SVGProps<SVGSVGElement> & VariantProps<typeof spinnerVariants>> = ({
  variant,
  size,
  className,
}) => {
  return (
    <LoaderCircle
      className={cn(spinnerVariants({ variant, size, className }))}
      strokeWidth={1}
    />
  );
};

export default Spinner;
