import { useEffect, useState } from 'react';
import { UilTimes } from '@iconscout/react-unicons';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';

import { Badge } from '@/components/ui/badge';
import { CreatableInput } from '@/components/ui/creatable-tag';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Select } from '@/components/ui/select-new';
import { toast } from '@/components/ui/toaster';

import OnboardingFooter from '@/components/onboarding/footer';

import { useGetCurrentCandidateSuspense, useUpdateCurrentCandidate } from '@/hooks/candidate';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import {
  getArrayFromString,
  getFormattedAdditionalLanguagesForAPI,
  getFormattedDataForAPI,
  parseFormattedAdditionalLanguages,
} from '@/utils/format';
import { getOptionsForSelectFromValuesMap } from '@/utils/string';

import { VALUES_GROUP } from '@/constants/values-map';

import { TAdditionalLanguageFormData } from '@/validation-schemas/coupled-fields.schema';

import { AdditionalLanguageForm } from '../coupled-select-option';
import FormFooter from '../form-footer';
import FormNavigationBlocker from '../form-navigation-blocker';
import { IFormProps } from '../types';

type FormValues = {
  education: string;
  qualification: string[];
  english_proficiency: string;
  languages: TAdditionalLanguageFormData[];
};

const resolver: Resolver<FormValues> = async (values) => {
  const errors: Record<keyof FormValues, { type: string; message: string }> = {} as Record<
    keyof FormValues,
    { type: string; message: string }
  >;

  if (!values.education || values.education.length === 0) {
    errors.education = {
      type: 'required',
      message: 'This is required.',
    };
  }
  if (!values.english_proficiency || values.english_proficiency === '') {
    errors.english_proficiency = {
      type: 'required',
      message: 'This is required.',
    };
  }

  return {
    values: Object.keys(errors).length === 0 ? values : {},
    errors,
  };
};

const EducationForm: React.FC<IFormProps> = ({ onSuccess, isOnboarding }) => {
  const { data } = useGetCurrentCandidateSuspense({});
  const [additionalLanguages, setAdditionalLanguages] = useState<TAdditionalLanguageFormData[]>([]);

  const form = useForm<FormValues>({
    defaultValues: {
      education: '',
      qualification: [],
      english_proficiency: '',
    },
    resolver,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = form;

  const { mutateAsync: updateCandidate, isPending: isUpdatingCandidate } = useUpdateCurrentCandidate({
    reactQueryOptions: {
      onSuccess: () => {
        if (!isOnboarding) {
          toast.success('Account updated successfully!');
        }
        onSuccess?.();
      },
    },
  });

  useEffect(() => {
    if (data) {
      const initialValues: FormValues = {
        education: getArrayFromString(data.education)[0] || '',
        qualification: getArrayFromString(data.qualification) || [],
        english_proficiency: data.english_proficiency || '',
        languages: parseFormattedAdditionalLanguages(data?.languages || '') || [],
      };
      setAdditionalLanguages(initialValues.languages);
      reset(initialValues);
    }
  }, [data, reset]);

  const { data: educationList, isLoading: isEducationLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.EDUCATION,
      },
    },
  });

  const { data: englishProfeciencyList, isLoading: isEnglishProfeciencyLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.ENGLISH_PROFECIENCY,
      },
    },
  });

  const handleAdditionalLanguageSubmit = (data: TAdditionalLanguageFormData) => {
    setAdditionalLanguages((prev) => {
      let updatedLanguages;

      const languageExists = prev.some((lang) => lang.language === data.language);

      if (languageExists) {
        // Remove the existing language
        updatedLanguages = prev.filter((lang) => lang.language !== data.language);
      } else {
        // Add the new language
        updatedLanguages = [...prev, data];
      }

      // Update the form values with the new languages list
      setValue('languages', updatedLanguages, { shouldValidate: true, shouldDirty: true });

      return updatedLanguages;
    });
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const { languages, ...rest } = data;
    const formattedlanguagues = getFormattedAdditionalLanguagesForAPI(languages);
    updateCandidate({ body: { ...getFormattedDataForAPI(rest), ...formattedlanguagues } });
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          name="education"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Highest Education level achieved</FormLabel>
              <FormControl>
                <Select
                  selected={field.value}
                  options={getOptionsForSelectFromValuesMap(educationList)}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.education?.message}
                  isLoading={isEducationLoading}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="qualification"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Qualifications / Certifications (Optional)</FormLabel>
              <FormControl>
                <CreatableInput
                  values={getArrayFromString(data?.qualification as string) || []}
                  onUpdate={(tags: string[]) => field.onChange(tags)}
                  placeholder="e.g. PMBOK, AWS Certified Architect"
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="english_proficiency"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>English level</FormLabel>
              <FormControl>
                <Select
                  className=""
                  options={getOptionsForSelectFromValuesMap(englishProfeciencyList)}
                  selected={field.value}
                  placeholder="English Profeciency"
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.english_proficiency?.message}
                  isLoading={isEnglishProfeciencyLoading}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>
      <div className="flex-col space-y-2">
        <AdditionalLanguageForm
          selectedLanguages={additionalLanguages?.map((s) => s.language)}
          onSubmit={handleAdditionalLanguageSubmit}
        />
        {additionalLanguages?.length > 0 && (
          <div className="flex flex-row flex-wrap gap-2">
            {additionalLanguages.map((s) => (
              <Badge
                key={s.language}
                variant="success"
                hideDot
                onClick={() => handleAdditionalLanguageSubmit(s)}
                className="cursor-pointer"
              >
                <span>
                  {s.language} - {s.proficiency}
                </span>
                <UilTimes className="mt-0.5 size-3.5 text-primary-success-80" />
              </Badge>
            ))}
          </div>
        )}
      </div>

      {isOnboarding ? (
        <OnboardingFooter
          isLoading={isUpdatingCandidate}
          handleNext={handleSubmit(onSubmit)}
        />
      ) : (
        <>
          <FormNavigationBlocker />
          <FormFooter
            onSubmit={handleSubmit(onSubmit)}
            isSubmitting={isUpdatingCandidate}
          />
        </>
      )}
    </FormProvider>
  );
};

export default EducationForm;
