import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const headingVariants = cva('font-semibold text-primary-dark-100', {
  variants: {
    size: {
      page: 'text-3xl',
      section: 'text-xl',
      title: 'text-2xl',
      subtitle: 'text-base',
    },
  },
  defaultVariants: {
    size: 'section',
  },
});

interface IProps extends VariantProps<typeof headingVariants> {
  title: string;
  subtitle?: string;
  className?: string;
}

const Heading: React.FC<IProps> = ({ title, subtitle, className, size }) => {
  return (
    <div className={cn('space-y-2', className)}>
      <h2 className={cn(headingVariants({ size }))}>{title}</h2>
      {subtitle && <p className="text-base text-primary-dark-60">{subtitle}</p>}
    </div>
  );
};

export default Heading;
