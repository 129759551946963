import { memo, Suspense } from 'react';
import { createFileRoute, Outlet } from '@tanstack/react-router';

import Heading from '@/components/ui/typography/heading';

export const Route = createFileRoute('/_authenticated/business/_dashboard/roles')({
  component: memo(Roles),
});

function Roles() {
  return (
    <main className="space-y-6">
      <Heading
        title="Roles"
        size="page"
      />
      <Suspense>
        <Outlet />
      </Suspense>
    </main>
  );
}
