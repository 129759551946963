import TeamMemberTag from '../tags/team-member-tag';
import Avatar from './avatar';
import { MultiSelect } from './multi-select-new';
import { Select } from './select-new';

export type TTeamMember = {
  imageUrl?: string | null;
  fullName: string;
  id: string;
};
interface TeamMemberSelectProps {
  options: TTeamMember[];
  selected: TTeamMember[] | TTeamMember | undefined;
  onChange: (value: TTeamMember[] | TTeamMember | undefined) => void;
  isMulti?: boolean;
  isLoading?: boolean;
  error?: boolean;
}

export const PartnerLabel = ({ name, imageUrl }: { name: string; imageUrl?: string }) => {
  return (
    <div className="flex items-center gap-4">
      <Avatar
        src={imageUrl}
        size="sm"
        fallbackType="company"
      />
      <span className="text-sm font-medium leading-none">{name}</span>
    </div>
  );
};

export const TeamMemberLabel = ({ name, imageUrl }: { name: string; imageUrl?: string }) => {
  return (
    <div className="flex items-center gap-4">
      <Avatar
        src={imageUrl}
        size="sm"
      />
      <span className="text-sm font-medium leading-none">{name}</span>
    </div>
  );
};

const TeamMemberSelect = ({ options, selected, onChange, isMulti, error }: TeamMemberSelectProps) => {
  if (isMulti) {
    const selectedMembers = selected as TTeamMember[];
    const selectedIds = selectedMembers?.map((member) => member.id) || [];
    const onChangeMulti = (selectedIds: string[]) => {
      const selectedMembers = options.filter((option) => selectedIds.includes(option.id));
      onChange(selectedMembers);
    };

    return (
      <div className="w-full space-y-2">
        <MultiSelect
          options={options.map((option) => ({
            label: (
              <TeamMemberLabel
                name={option.fullName}
                imageUrl={option.imageUrl ?? undefined}
              />
            ),
            value: option.id,
          }))}
          selected={selectedIds}
          onChange={onChangeMulti}
          error={error}
          hideSelected={true}
        />
        <div className="flex flex-wrap gap-2">
          {selectedMembers?.map((member) => (
            <TeamMemberTag
              key={member.id}
              name={member.fullName}
              imageUrl={member.imageUrl ?? undefined}
              onCrossClick={() => {
                onChange(selectedMembers.filter((m) => m.id !== member.id));
              }}
            />
          ))}
        </div>
      </div>
    );
  }

  const selectedMember = selected as TTeamMember;
  const selectedId = selectedMember?.id;
  const onChangeSingle = (selectedId: string) => {
    const selectedMember = options.find((option) => option.id === selectedId);
    onChange(selectedMember);
  };

  return (
    <Select
      options={options.map((option) => ({
        label: (
          <TeamMemberLabel
            name={option.fullName}
            imageUrl={option.imageUrl ?? undefined}
          />
        ),
        value: option.id,
      }))}
      selected={selectedId}
      onChange={onChangeSingle}
    />
  );
};

export default TeamMemberSelect;
