import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Card, CardFooter } from '@/components/ui/card';
import Heading from '@/components/ui/typography/heading';

import useAuth from '@/hooks/useAuth';

import SettingContainer from './setting-container';

const SignOutButton = () => {
  const { logout } = useAuth();

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="destructive">Sign out</Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle iconVariant="alert">Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to sign out?
            <br />
            <br />
            All of your data will be saved for when you sign back in.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            variant="destructive"
            onClick={logout}
          >
            Sign out
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const SignOutCard = () => {
  return (
    <SettingContainer>
      <Heading
        title="Sign out"
        subtitle="Sign out of your account."
      />
      <Card>
        <Heading
          title="Sign out"
          subtitle="Sign out of your account, all of your data will be saved."
        />
        <CardFooter>
          <SignOutButton />
        </CardFooter>
      </Card>
    </SettingContainer>
  );
};

export default SignOutCard;
