import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { createFileRoute } from '@tanstack/react-router';

import '@cyntler/react-doc-viewer/dist/index.css';

import { UilFileDownload } from '@iconscout/react-unicons';

import { Button } from '@/components/ui/button';
import Spinner from '@/components/ui/spinner';
import Heading from '@/components/ui/typography/heading';

import { useGetCandidateForAPost } from '@/hooks/post';

export const Route = createFileRoute(
  '/_authenticated/partner/_dashboard/pipeline-candidate-profile/$postId/$candidateId/resume'
)({
  component: Resume,
});

type TResume = {
  url: string;
  name: string;
};

function Resume() {
  const { candidateId, postId } = Route.useParams();

  const { data, isLoading } = useGetCandidateForAPost({
    params: {
      path: { id: postId, userId: candidateId },
    },
  });

  if (isLoading) return null;

  if (!data?.cv_url) return <div>No resume found</div>;

  const resume = JSON.parse(data.cv_url) as TResume;

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = resume.url;
    link.setAttribute('download', resume.name); // Optional: specify a file name
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <main className="flex h-full flex-col gap-6">
      <Heading
        title="Resume"
        size="title"
      />
      <div>
        <Button
          variant="tertiary"
          onClick={handleDownload}
        >
          <UilFileDownload className="!text-primary-dark-100 default-icon-sm" />
          Download
        </Button>
      </div>
      <DocViewer
        documents={[
          {
            uri: resume.url,
            fileType: resume.name.split('.').pop(),
          },
        ]}
        pluginRenderers={DocViewerRenderers}
        className="flex h-full w-full max-w-full flex-1 overflow-x-hidden !bg-transparent scrollbar scrollbar-track-transparent scrollbar-thumb-primary-dark-10"
        config={{
          header: {
            disableHeader: true,
          },
          loadingRenderer: {
            overrideComponent: () => (
              <div className="flex h-full w-full items-center justify-center">
                <Spinner />
              </div>
            ),
          },
          pdfZoom: {
            defaultZoom: 1.109,
            zoomJump: 0.5,
          },
          pdfVerticalScrollByDefault: true,
        }}
      />
    </main>
  );
}
