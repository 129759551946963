import { UilArrowLeft } from '@iconscout/react-unicons';
import { ToOptions, useLocation, useNavigate, useRouter } from '@tanstack/react-router';

import { Button } from './ui/button';

interface IBackButtonProps {
  backRoute: ToOptions['to']; // fallback route to get back to if no  router state is available
  onlyUseBackRoute?: boolean; // if true, will only use the back route
}

const BackButton: React.FC<IBackButtonProps> = ({ backRoute, onlyUseBackRoute }) => {
  const navigate = useNavigate();
  const router = useRouter();
  const location = useLocation();

  const handleBackClick = () => {
    if (onlyUseBackRoute) {
      return navigate({ to: backRoute });
    }

    if (location.state.key) {
      return router.history.back();
    }

    return navigate({ to: backRoute });
  };

  return (
    <Button
      variant="tertiary"
      onClick={handleBackClick}
    >
      <UilArrowLeft className="size-[1.125rem] text-primary-dark-100" />
      <span>Back</span>
    </Button>
  );
};

export default BackButton;
