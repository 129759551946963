import { ScrollArea } from '@/components/ui/scroll-area';
import { Skeleton } from '@/components/ui/skeleton';

import { TUserNotification } from '@/services/notification';

import { NotificationItem } from './notification-item';

export interface INotificationList {
  isLoading: boolean;
  notifications: TUserNotification[];
}

export function NotificationList({ isLoading, notifications }: INotificationList) {
  const renderLoadingSkeleton = () =>
    new Array(6).fill(null).map((_, index) => (
      <Skeleton
        key={index}
        className="mx-4 h-20 rounded-md"
      />
    ));

  return (
    <ScrollArea
      className="h-full flex-grow"
      type="scroll"
    >
      <div className="flex flex-col space-y-2 pt-8">
        {isLoading
          ? renderLoadingSkeleton()
          : notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))}
      </div>
    </ScrollArea>
  );
}
