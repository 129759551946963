import { Button } from '@/components/ui/button';

import { cn } from '@/lib/utils';

interface IProps {
  title: string;
  subTitle: string;
  buttonText: string;
  redirectUrl: string;
  className?: string;
}

const Highlight: React.FC<IProps> = ({ title, subTitle, buttonText, redirectUrl, className }) => {
  return (
    <article className={cn('relative w-full overflow-clip rounded-2xl bg-secondary-yellow-80 p-6', className)}>
      <div className="flex flex-col gap-[2.375rem]">
        <div className="z-10 max-w-[26.25rem]">
          <h3 className="text-xl font-semibold">{title}</h3>
          <p className="text-sm">{subTitle}</p>
        </div>
        <a
          href={redirectUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button variant={'tertiary'}>{buttonText}</Button>
        </a>

        <div className="absolute -top-[205px] right-0 h-[363px] w-[32%] skew-y-[15deg] rounded-lg bg-secondary-yellow-120 3xl:w-[45%]"></div>
        <div className="absolute -top-[180px] right-[35px] h-[363px] w-[32%] skew-y-[15deg] rounded-lg border border-secondary-yellow-120 bg-transparent 3xl:w-[45%]"></div>
      </div>
    </article>
  );
};

export default Highlight;
