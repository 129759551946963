import React, { useState } from 'react';

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { toast } from '@/components/ui/toaster';

import TimeSlotBooker from '@/components/time-slot-booker';

import { useCandidateBookMeeting } from '@/hooks/meetings';

type TTimeSlot = {
  start_time: string;
  end_time: string;
};

interface BookASlotButtonProps {
  meeting_id: number;
  timeSlots: TTimeSlot[];
  interval: number;
}

export const BookASlotButton: React.FC<BookASlotButtonProps> = ({ meeting_id, timeSlots, interval }) => {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<Date | null>(null);
  const [open, setOpen] = useState(false);

  const { mutate: bookMeeting, isPending } = useCandidateBookMeeting({
    reactQueryOptions: {
      onSuccess: () => {
        toast.success('Meeting booked successfully');
        setOpen(false);
      },
      onError: () => {
        toast.error('Failed to book meeting, please try again.');
      },
    },
  });

  const handleSelectSlot = (slot: Date | null) => {
    setSelectedTimeSlot(slot);
  };

  const onBookMeeting = () => {
    if (!selectedTimeSlot) return;
    bookMeeting({
      body: {
        meeting_id,
        start_time: selectedTimeSlot.toISOString(),
      },
    });
  };

  return (
    <AlertDialog
      open={open}
      onOpenChange={setOpen}
    >
      <AlertDialogTrigger asChild>
        <Button
          variant="default"
          className="w-full"
        >
          Book a slot
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="gap-6">
        <AlertDialogHeader>
          <AlertDialogTitle> Book a slot </AlertDialogTitle>
          <AlertDialogDescription className="pb-0">
            Please select a time slot to book the meeting.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <TimeSlotBooker
          events={timeSlots}
          selectedSlot={selectedTimeSlot}
          onSlotSelect={handleSelectSlot}
          interval={interval}
        />
        <AlertDialogFooter>
          {!isPending && <AlertDialogCancel onClick={() => setSelectedTimeSlot(null)}>Cancel</AlertDialogCancel>}
          <Button
            onClick={onBookMeeting}
            disabled={!selectedTimeSlot || isPending}
            loading={isPending}
          >
            Book now
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
