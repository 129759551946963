import { useEffect, useState } from 'react';
import { UilTimes } from '@iconscout/react-unicons';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';

import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import ComboBoxMultipleSelect from '@/components/ui/combo-box-multiple';
import { FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { MultiSelect } from '@/components/ui/multi-select-new';
import { toast } from '@/components/ui/toaster';

import { ExperienceCard } from '@/components/onboarding/experience-card';
import { ExperienceForm } from '@/components/onboarding/experience-form';

import { useGetCurrentCandidateSuspense, useUpdateCurrentCandidate } from '@/hooks/candidate';
import { useGetAllSkillsets } from '@/hooks/skillsets';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import {
  getArrayFromString,
  getExperiencesFromAPI,
  getFormattedDataForAPI,
  getFormattedDomainExperienceForAPI,
  getFormattedExperiencesForAPI,
  parseFormattedDomainExperience,
} from '@/utils/format';
import { getOptionsForSelectFromValuesMap } from '@/utils/string';

import { VALUES_GROUP } from '@/constants/values-map';

import { TDomainExperienceFormData } from '@/validation-schemas/coupled-fields.schema';
import { TExperienceFormData } from '@/validation-schemas/experience-schema';

import { DomainExperienceForm } from '../coupled-select-option';
import FormFooter from '../form-footer';
import FormNavigationBlocker from '../form-navigation-blocker';
import { IFormProps } from '../types';

type FormValues = {
  technical_skills: string[];
  industry_experience: string[];
  business_experience: string[];
  experiences: TExperienceFormData[];
  domain_exp: TDomainExperienceFormData[];
};

const resolver: Resolver<FormValues> = async (values) => {
  const errors: Record<keyof FormValues, { type: string; message: string }> = {} as Record<
    keyof FormValues,
    { type: string; message: string }
  >;
  if (!values.technical_skills || values.technical_skills.length === 0) {
    errors.technical_skills = {
      type: 'required',
      message: 'This is required.',
    };
  }
  if (!values.industry_experience || values.industry_experience.length === 0) {
    errors.industry_experience = {
      type: 'required',
      message: 'This is required.',
    };
  }
  if (!values.business_experience || values.business_experience.length === 0) {
    errors.business_experience = {
      type: 'required',
      message: 'This is required.',
    };
  }
  if (!values.domain_exp || values.domain_exp.length === 0) {
    errors.domain_exp = {
      type: 'required',
      message: 'This is required.',
    };
  }
  return {
    values: Object.keys(errors).length === 0 ? values : {},
    errors,
  };
};

const ProfileExperienceForm: React.FC<IFormProps> = ({ onSuccess, isOnboarding }) => {
  const { data } = useGetCurrentCandidateSuspense({});
  const [skillsetQuery, setSkillsetQuery] = useState('');
  const [domains, setDomains] = useState<TDomainExperienceFormData[]>([]);

  const { data: rolesList } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.ROLES,
      },
    },
  });

  const { data: businessTypesList, isLoading: isBusinessTypesLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.BUSINESS_TYPE,
      },
    },
  });

  const { data: industryTypesList, isLoading: isIndustryTypesLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.INDUSTRY_TYPE,
      },
    },
  });

  const { data: technicalSkillsList, isLoading: isTechnicalSkillsLoading } = useGetAllSkillsets({
    params: {
      query: {
        q: skillsetQuery,
      },
    },
  });

  const form = useForm<FormValues>({
    defaultValues: {
      technical_skills: [],
      industry_experience: [],
      business_experience: [],
      domain_exp: [],
    },
    resolver,
  });

  const { mutateAsync: updateCandidate, isPending: isUpdatingCandidate } = useUpdateCurrentCandidate({
    reactQueryOptions: {
      onSuccess: () => {
        if (!isOnboarding) {
          toast.success('Account updated successfully!');
        }
        onSuccess?.();
      },
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = form;

  const [experiences, setExperiences] = useState<TExperienceFormData[]>([]);
  const [currentFormIndex, setCurrentFormIndex] = useState<number | null>(null);

  useEffect(() => {
    if (data) {
      const {
        experience_1_compname,
        experience_1_start_date,
        experience_1_role,
        experience_1_end_date,
        experience_1_is_current,
        experience_2_compname,
        experience_2_start_date,
        experience_2_role,
        experience_2_end_date,
        experience_2_is_current,
        experience_3_compname,
        experience_3_start_date,
        experience_3_role,
        experience_3_end_date,
        experience_3_is_current,
      } = data;

      const experiencesFromAPI = getExperiencesFromAPI({
        experience_1_compname,
        experience_1_start_date,
        experience_1_role,
        experience_1_end_date,
        experience_1_is_current,
        experience_2_compname,
        experience_2_start_date,
        experience_2_role,
        experience_2_end_date,
        experience_2_is_current,
        experience_3_compname,
        experience_3_start_date,
        experience_3_role,
        experience_3_end_date,
        experience_3_is_current,
      });
      setExperiences(experiencesFromAPI);

      const initialValues: FormValues = {
        technical_skills: getArrayFromString(data.technical_skills) || [],
        industry_experience: getArrayFromString(data.industry_experience) || [],
        business_experience: getArrayFromString(data.business_experience) || [],
        experiences: experiencesFromAPI,
        domain_exp: parseFormattedDomainExperience(data?.domain_exp || '') || [],
      };
      reset(initialValues);

      setExperiences(experiencesFromAPI);
      setDomains(initialValues.domain_exp);

      setCurrentFormIndex(experiencesFromAPI.length === 0 ? 0 : null);
    }
  }, [data, reset]);

  const saveExperience = (index: number, data: TExperienceFormData) => {
    setExperiences((prevExperiences) => {
      const updatedExperiences = [...prevExperiences];
      if (index >= updatedExperiences.length) {
        updatedExperiences.push(data);
      } else {
        updatedExperiences[index] = data;
      }
      setValue('experiences', updatedExperiences, { shouldValidate: true, shouldDirty: true });
      return updatedExperiences;
    });
    setCurrentFormIndex(null);
  };

  const handleDomainSubmit = (data: TDomainExperienceFormData) => {
    setDomains((prev) => {
      let updatedDomains;

      const domainExists = prev.some((lang) => lang.domain === data.domain);

      if (domainExists) {
        // Remove the existing language
        updatedDomains = prev.filter((lang) => lang.domain !== data.domain);
      } else {
        // Add the new language
        updatedDomains = [...prev, data];
      }

      // Update the form values with the new languages list
      setValue('domain_exp', updatedDomains, { shouldValidate: true, shouldDirty: true });

      return updatedDomains;
    });
  };

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const { domain_exp, experiences, ...rest } = data;
    const formattedDomains = getFormattedDomainExperienceForAPI(domain_exp);
    const formattedExperiences = getFormattedExperiencesForAPI(experiences);
    updateCandidate({ body: { ...getFormattedDataForAPI(rest), ...formattedExperiences, ...formattedDomains } });
  };
  const handleCancel = () => {
    setCurrentFormIndex(null);
  };

  return (
    <FormProvider {...form}>
      <div className="flex flex-col gap-2">
        {experiences.map((experience, index) =>
          currentFormIndex === index ? (
            <ExperienceForm
              key={index}
              isLoading={false}
              index={index}
              data={experience}
              roles={
                rolesList?.items.map((s) => s.description).filter((s): s is string => s !== undefined && s !== null) ||
                []
              }
              save={saveExperience}
              onCancel={handleCancel}
              className={currentFormIndex === 0 ? 'mb-8' : 'my-8'}
            />
          ) : (
            <ExperienceCard
              key={index}
              {...experience}
              onEdit={() => setCurrentFormIndex(index)}
            />
          )
        )}
        {currentFormIndex === experiences.length && (
          <ExperienceForm
            key={currentFormIndex}
            isLoading={false}
            index={currentFormIndex}
            data={{} as TExperienceFormData}
            onCancel={handleCancel}
            roles={
              rolesList?.items.map((s) => s.description).filter((s): s is string => s !== undefined && s !== null) || []
            }
            save={saveExperience}
            className={currentFormIndex === 0 ? 'mb-8' : 'my-8'}
          />
        )}
        {experiences.length < 3 && (
          <div className="flex w-full justify-end">
            <Button
              onClick={() => setCurrentFormIndex(experiences.length)}
              variant="link"
              className="flex w-auto font-semibold text-primary-blue-100"
            >
              + Add work experience
            </Button>
          </div>
        )}
      </div>
      <div className="mt-8 space-y-8">
        <FormField
          name="business_experience"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Business types</FormLabel>
              <FormControl>
                <MultiSelect
                  selected={field.value}
                  options={getOptionsForSelectFromValuesMap(businessTypesList)}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.business_experience?.message}
                  isLoading={isBusinessTypesLoading}
                  disableAlphabeticalSorting
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="industry_experience"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Industry types</FormLabel>
              <FormControl>
                <MultiSelect
                  selected={field.value}
                  placeholder="Start typing or select"
                  options={getOptionsForSelectFromValuesMap(industryTypesList)}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.industry_experience?.message}
                  enableSearch
                  isLoading={isIndustryTypesLoading}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <div className="flex-col space-y-2">
          <DomainExperienceForm
            selectedDomains={domains?.map((s) => s.domain)}
            onSubmit={handleDomainSubmit}
            error={!!errors.domain_exp}
          />
          {domains?.length > 0 && (
            <div className="flex flex-row flex-wrap gap-2">
              {domains.map((s) => (
                <Badge
                  key={s.domain}
                  variant="success"
                  hideDot
                  onClick={() => handleDomainSubmit(s)}
                  className="cursor-pointer"
                >
                  <span>
                    {s.domain} - {s.experience} years
                  </span>
                  <UilTimes className="mt-0.5 size-3.5 text-primary-success-80" />
                </Badge>
              ))}
            </div>
          )}
        </div>
        <FormField
          name="technical_skills"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Technical skills</FormLabel>
              <FormControl>
                <ComboBoxMultipleSelect
                  placeholder="Start typing or select"
                  options={
                    technicalSkillsList?.items
                      .map((s) => s.category)
                      .filter((s): s is string => s !== undefined && s !== null) || []
                  }
                  selected={field.value}
                  onSelect={(value) => field.onChange(value)}
                  isLoading={isTechnicalSkillsLoading}
                  onQueryChange={(query) => setSkillsetQuery(query)}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </div>

      <FormNavigationBlocker />
      <FormFooter
        onSubmit={handleSubmit(onSubmit)}
        isSubmitting={isUpdatingCandidate}
      />
    </FormProvider>
  );
};

export default ProfileExperienceForm;
