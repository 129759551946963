import { useQuery } from '@tanstack/react-query';

import {
  getAllUserNotifications,
  getAllUserNotificationSettings,
  NOTIFICATION_ENDPOINTS,
  NOTIFICATION_SETTINGS_ENDPOINTS,
  TGetAllUserNotificationSettingsRequest,
  TGetAllUserNotificationsRequest,
} from '@/services/notification';

import { type UseQueryOptions } from '../types';

export function useGetAllUserNotifications({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetAllUserNotificationsRequest & { items: { cardId: string }[] }>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [NOTIFICATION_ENDPOINTS.GET_USER_NOTIFICATIONS],
    queryFn: ({ signal }) => getAllUserNotifications({ signal, params }),
  });
}

export function useGetAllUserNotificationSettings({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetAllUserNotificationSettingsRequest & { items: { cardId: string }[] }>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [NOTIFICATION_SETTINGS_ENDPOINTS.GET_USER_NOTIFICATION_SETTINGS],
    queryFn: ({ signal }) => getAllUserNotificationSettings({ signal, params }),
  });
}
