import { useDraggable } from '@dnd-kit/core';

import { PersonalityArchetypeCode } from '@/services/candidate';

import { cn } from '@/lib/utils';

import { PERSONALITY_ClASS_MAP, PERSONALITY_CODE_TO_EMOJI_MAP } from '@/constants/candidate';
import { PERSONALITY_ARCHETYPES_TO_DESCRIPTIONS } from '@/constants/quiz';

import BaseQuizCardWrapper from './base-quiz-card';

interface DraggablePersonalityCardProps {
  personalityCode: PersonalityArchetypeCode;
  type?: 'initial' | 'final';
}
const DraggablePersonalityCard = ({ personalityCode, type }: DraggablePersonalityCardProps) => {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: personalityCode,
    data: {
      type,
    },
  });

  return (
    <BaseQuizCardWrapper
      className={cn(
        'flex items-center gap-6 outline outline-[1.5px]',
        isDragging ? 'opacity-0' : PERSONALITY_ClASS_MAP[personalityCode]
      )}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      {!isDragging && (
        <>
          <span className="text-[3.6rem] 2xl:text-[4.25rem] 3xl:text-[6.375rem]">
            {PERSONALITY_CODE_TO_EMOJI_MAP[personalityCode]}
          </span>
          <span className="font-semibold 2xl:text-lg 3xl:text-xl">
            {PERSONALITY_ARCHETYPES_TO_DESCRIPTIONS[personalityCode]}
          </span>
        </>
      )}
    </BaseQuizCardWrapper>
  );
};

export default DraggablePersonalityCard;
