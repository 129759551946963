import { useState } from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { addMinutes } from 'date-fns/addMinutes';
import { View } from 'react-big-calendar';

import Heading from '@/components/ui/typography/heading';

import BusinessCalendar from '@/components/big-calendar/business/business-calendar';
import { IBusinessEvent } from '@/components/big-calendar/business/busniess-event';
import CalendarTabNav from '@/components/big-calendar/components/calendar-tab-nav';

import { useGetAllCalendarEvents } from '@/hooks/business';

export const Route = createFileRoute('/_authenticated/partner/_dashboard/calendar')({
  component: BusinessCalendarPage,
});

function BusinessCalendarPage() {
  const [view, setView] = useState<View>('month');
  const { data: calendarEvents, isLoading } = useGetAllCalendarEvents({
    reactQueryOptions: {
      enabled: true,
    },
  });

  const events: IBusinessEvent[] =
    calendarEvents?.items
      ?.filter((timeslot) => !!timeslot)
      ?.map((timeslot) => ({
        start: new Date(timeslot?.slot_date || 0),
        end: new Date(addMinutes(new Date(timeslot?.slot_date || 0), 45) || 0), // TODO: Add end time
        title: timeslot?.post?.title || '',
        data: timeslot,
      })) || [];

  return (
    <div className="space-y-6">
      <Heading
        title="Calendar"
        size="page"
      />
      <CalendarTabNav
        view={view}
        onChangeView={setView}
      />
      <BusinessCalendar
        view={view}
        onChangeView={setView}
        events={events}
        isLoading={isLoading}
      />
    </div>
  );
}
