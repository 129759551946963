import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Card, CardFooter } from '@/components/ui/card';
import Heading from '@/components/ui/typography/heading';

import useAuth from '@/hooks/useAuth';
import { useDeactivateAccount } from '@/hooks/user';

import SettingContainer from './setting-container';

const DeactivateAccountCard = () => {
  return (
    <SettingContainer>
      <Heading
        title="Danger zone"
        subtitle="Deactivating your account."
      />
      <Card>
        <Heading
          title="Deactivate account"
          subtitle="Deactivating your account will remove your profile from our system. Businesses will no longer be able view your profile."
        />
        <CardFooter>
          <DeactivateButton />
        </CardFooter>
      </Card>
    </SettingContainer>
  );
};

const DeactivateButton = () => {
  const { logout } = useAuth();
  const { mutateAsync: updateCandidate, isPending: isUpdatingCandidate } = useDeactivateAccount({
    reactQueryOptions: { onSuccess: logout },
  });

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="destructive">Deactivate account</Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle iconVariant="alert">Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            You are about to deactivate your account.
            <br />
            <br />
            This cannot be undone.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isUpdatingCandidate}>Cancel</AlertDialogCancel>
          <AlertDialogAction
            asChild
            variant="destructive"
            onClick={() => updateCandidate()}
          >
            <Button
              variant="destructive"
              loading={isUpdatingCandidate}
            >
              Deactivate account
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeactivateAccountCard;
