import React, { forwardRef } from 'react';

import { cn } from '@/lib/utils';

interface BaseQuizCardProps {
  className?: string;
  children: React.ReactNode;
}

const BaseQuizCardWrapper = forwardRef<HTMLDivElement, BaseQuizCardProps>(({ className, children, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        className,
        'h-[6.25rem] w-full rounded-lg px-6 py-3.5 2xl:h-[7.25rem] 3xl:h-[8.375rem] 3xl:px-8 3xl:py-4'
      )}
      {...props}
    >
      {children}
    </div>
  );
});

BaseQuizCardWrapper.displayName = 'BaseQuizCardWrapper';

export default BaseQuizCardWrapper;
