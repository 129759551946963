import { useQuery } from '@tanstack/react-query';

import { APP_DASHBOARD, getCurrentUser, TGetCurrentUserRequest, USER_ENDPOINTS, USER_TYPE } from '@/services/user';

import { type UseQueryOptions } from '../types';

const { GET_CURRENT_USER } = USER_ENDPOINTS;
export function useGetCurrentUser({ reactQueryOptions }: UseQueryOptions<TGetCurrentUserRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [GET_CURRENT_USER],
    queryFn: getCurrentUser,
    staleTime: Infinity,
  });
}

export const useGetCurrentDashboard = (): APP_DASHBOARD => {
  const { data: user } = useGetCurrentUser({});
  return user?.user_type === USER_TYPE.PARTNER ? APP_DASHBOARD.PARTNER : APP_DASHBOARD.BUSINESS;
};
