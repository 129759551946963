import { createFileRoute } from '@tanstack/react-router';

import WorkForm from '@/components/forms/candidate/work-form';

import useStepper from '@/hooks/useStepper';

export const Route = createFileRoute('/_authenticated/candidate/onboarding/_steps/step1')({
  component: Step1,
});

function Step1() {
  const { goToNextStep } = useStepper();

  return (
    <WorkForm
      onSuccess={goToNextStep}
      isOnboarding
    />
  );
}
