import { Button } from '@/components/ui/button';
import { Card, CardTitle } from '@/components/ui/card';
import Heading from '@/components/ui/typography/heading';

import CultureCard from '@/components/culture-card';
import PersonalityCard from '@/components/personality-card';

import { parseJobPostCultureProfile, parseJobPostPersonalityProfile, TJobPost } from '@/services/business-roles';

import Partners from './partners';
import TeamMembers from './team-members';

interface IRoleDetailsSidePanelProps {
  role: TJobPost;
  showPersonalityBreakdown: boolean;
  showCultureBreakdown: boolean;
  togglePersonalityBreakdown: () => void;
  toggleCultureBreakdown: () => void;
}

// pass role as prop or fetch from url
const RoleDetailsSidePanel: React.FC<IRoleDetailsSidePanelProps> = ({
  role,
  showCultureBreakdown,
  showPersonalityBreakdown,
  toggleCultureBreakdown,
  togglePersonalityBreakdown,
}) => {
  const highestRankingPersonality =
    role.personality_profile &&
    parseJobPostPersonalityProfile(role.personality_profile).find((profile) => profile.rank === 1);

  const highestRankingCulture =
    role.culture_profile && parseJobPostCultureProfile(role.culture_profile).find((profile) => profile.rank === 1);

  const renderMatching = () => {
    if (!highestRankingCulture && !highestRankingPersonality) return null;

    return (
      <Card className="space-y-6 p-8">
        <CardTitle>Matching</CardTitle>
        <div className="space-y-8">
          {highestRankingPersonality && (
            <div className="space-y-2">
              <Heading
                title="Desired Personality"
                size="subtitle"
              />
              <PersonalityCard personalityCode={highestRankingPersonality.code} />
              <Button
                className="w-full"
                variant="tertiary"
                onClick={togglePersonalityBreakdown}
              >
                {showPersonalityBreakdown ? 'Hide results' : 'View results'}
              </Button>
            </div>
          )}
        </div>
        <div className="space-y-8">
          {highestRankingCulture && (
            <div className="space-y-2">
              <Heading
                title="Team Culture"
                size="subtitle"
              />
              <CultureCard cultureName={highestRankingCulture.name} />
              <Button
                className="w-full"
                variant="tertiary"
                onClick={toggleCultureBreakdown}
              >
                {showCultureBreakdown ? 'Hide results' : 'View results'}
              </Button>
            </div>
          )}
        </div>
      </Card>
    );
  };

  return (
    <aside className="space-y-8">
      {renderMatching()}
      <TeamMembers
        teamMembers={role.members}
        roleId={role.id}
      />
      <Partners
        partners={role.partners}
        roleId={role.id}
      />
    </aside>
  );
};

export default RoleDetailsSidePanel;
