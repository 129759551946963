import { createFileRoute } from '@tanstack/react-router';

import { Badge } from '@/components/ui/badge';
import { Card } from '@/components/ui/card';
import Separator from '@/components/ui/separator';
import { Skeleton } from '@/components/ui/skeleton';
import Heading from '@/components/ui/typography/heading';

import { ExperienceCard } from '@/components/onboarding/experience-card';
import { CultureChart } from '@/components/quiz/charts/culture-chart';
import { PersonalityChart } from '@/components/quiz/charts/personality-chart';
import { CultureBreakdownCard } from '@/components/roles/details/culture-breakdown';
import { PersonalityBreakdownCard } from '@/components/roles/details/personality-breakdown';

import { useGetCandidateForAPost } from '@/hooks/post';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { CultureName, PersonalityArchetypeCode } from '@/services/candidate';
import { TGetCandidateForAPost } from '@/services/post';

import { getArrayFromString } from '@/utils/format';
import { parseCandidateCulture, parseCultureDescription, parsePersonalityDescription } from '@/utils/string';

import { getRandomPersonalityTestInfo } from '@/constants/candidate';
import { VALUES_GROUP } from '@/constants/values-map';

export const Route = createFileRoute(
  '/_authenticated/partner/_dashboard/pipeline-candidate-profile/$postId/$candidateId/profile'
)({
  component: Profile,
});

function Profile() {
  const { candidateId, postId } = Route.useParams();
  const { data, isLoading } = useGetCandidateForAPost({
    params: {
      path: { id: postId, userId: candidateId },
    },
  });

  if (isLoading) return null;

  return (
    <main className="space-y-6">
      <Heading
        title="Profile"
        size="title"
      />
      <section className="space-y-2">
        <Heading
          title="Candidate bio"
          size="subtitle"
        />
        {data?.bio
          ?.split('\n')
          .filter((line) => line.trim() !== '')
          .map((line, index) => (
            <p
              key={index}
              className="text-sm font-medium text-primary-dark-60"
            >
              {index > 0 && <br />}
              {line}
            </p>
          ))}
      </section>
      <Separator />
      <section className="space-y-2">
        <Heading
          title="Experience"
          size="subtitle"
        />
        <div className="space-y-4">
          {data?.experience_1_role && (
            <ExperienceCard
              companyName={data?.experience_1_compname || ''}
              role={data?.experience_1_role}
              startDate={new Date(data?.experience_1_start_date || '')}
              workingCurrently={data?.experience_1_is_current}
              endDate={new Date(data?.experience_1_end_date || '')}
            />
          )}
          {data?.experience_2_role && (
            <ExperienceCard
              companyName={data?.experience_2_compname || ''}
              role={data?.experience_2_role || ''}
              startDate={new Date(data?.experience_2_start_date || '')}
              workingCurrently={data?.experience_2_is_current}
              endDate={new Date(data?.experience_2_end_date || '')}
            />
          )}
          {data?.experience_3_role && (
            <ExperienceCard
              companyName={data?.experience_3_compname || ''}
              role={data?.experience_3_role || ''}
              startDate={new Date(data?.experience_3_start_date || '')}
              workingCurrently={data?.experience_3_is_current}
              endDate={new Date(data?.experience_3_end_date || '')}
            />
          )}
        </div>
      </section>
      <Separator />
      <section className="space-y-6">
        <div className="space-y-2">
          <Heading
            title="Education"
            size="subtitle"
          />
          {data?.education && (
            <Badge
              variant="success"
              hideDot
            >
              <span>{data?.education}</span>
            </Badge>
          )}
        </div>
        <div className="space-y-2">
          <Heading
            title="Education"
            size="subtitle"
          />
          <div className="flex gap-2">
            {data?.education && (
              <Badge
                variant="success"
                hideDot
              >
                <span>{data?.education}</span>
              </Badge>
            )}
          </div>
        </div>
        <div className="space-y-2">
          <Heading
            title="Qualifications"
            size="subtitle"
          />
          <div className="flex flex-wrap gap-2">
            {getArrayFromString(data?.qualification || '').map((qualification, index) => (
              <Badge
                key={index}
                variant="success"
                hideDot
              >
                <span>{qualification}</span>
              </Badge>
            ))}
          </div>
        </div>
        <div className="space-y-2">
          <Heading
            title="Languages"
            size="subtitle"
          />
          <div className="flex flex-wrap gap-2">
            <Badge
              variant="success"
              hideDot
            >
              <span>English - {data?.english_proficiency}</span>
            </Badge>
            {getArrayFromString(data?.languages || '').map((language, index) => (
              <Badge
                key={index}
                variant="success"
                hideDot
              >
                <span>{language}</span>
              </Badge>
            ))}
          </div>
        </div>
      </section>
      <Separator />
      <section className="space-y-6">
        <div className="space-y-2">
          <Heading
            title="Technical skills"
            size="subtitle"
          />
          <div className="flex flex-wrap gap-2">
            {getArrayFromString(data?.technical_skills || '').map((skill, index) => (
              <Badge
                key={index}
                variant="success"
                hideDot
              >
                <span>{skill}</span>
              </Badge>
            ))}
          </div>
        </div>
        <div className="space-y-2">
          <Heading
            title="Domain"
            size="subtitle"
          />
          <div className="flex flex-wrap gap-2">
            {getArrayFromString(data?.domain_exp || '').map((domain, index) => (
              <Badge
                key={index}
                variant="success"
                hideDot
              >
                <span>{domain}</span>
              </Badge>
            ))}
          </div>
        </div>
      </section>
      <Separator />
      <section className="space-y-6">
        <div className="space-y-2">
          <Heading
            title="Soft Skills"
            size="subtitle"
          />
          <div className="flex flex-wrap gap-2">
            {getArrayFromString(data?.skills || '').map((skill, index) => (
              <Badge
                key={index}
                variant="success"
                hideDot
              >
                <span>{skill}</span>
              </Badge>
            ))}
          </div>
        </div>
        <div className="space-y-2">
          <Heading
            title="Values"
            size="subtitle"
          />
          <div className="flex flex-wrap gap-2">
            {getArrayFromString(data?.candidate_values || '').map((value, index) => (
              <Badge
                key={index}
                variant="success"
                hideDot
              >
                <span>{value}</span>
              </Badge>
            ))}
          </div>
        </div>
      </section>
      <Separator />
      <section className="space-y-2">
        <Heading
          title="Personality"
          size="subtitle"
        />
        <Personality candidate={data} />
      </section>
      <Separator />
      <section className="space-y-2">
        <Heading
          title="Culture"
          size="subtitle"
        />
        <Culture candidate={data} />
      </section>
    </main>
  );
}

const Personality = ({ candidate }: { candidate: TGetCandidateForAPost | undefined }) => {
  const { data: personalityValuesMap, isLoading: isPersonalityFactorLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        value_group: VALUES_GROUP.PERSONALITY_FACTORS,
      },
    },
  });

  if (isPersonalityFactorLoading) return <Skeleton className="h-[400px] w-full" />;
  const personalityCode = candidate?.personality_archetype as PersonalityArchetypeCode;

  const personalityData = JSON.parse(candidate?.personality_test_info || '{}');
  const dummyPersonalityData = getRandomPersonalityTestInfo();
  const personalityFactors = personalityValuesMap?.items.map((item) => {
    if (!item.description || !item.code)
      return {
        code: '',
        Name: '',
        Adjectives: '',
        Facets: '',
      };
    const desc = JSON.parse(item.description?.replace(' "Adjectives"', ',  "Adjectives"')) as {
      Name: string;
      Adjectives: string;
      Facets: string;
    };
    return {
      code: item.code,
      ...desc,
    };
  });

  const personality = personalityValuesMap?.items?.find((item) => item.code === candidate?.personality_archetype);

  if (!personality || !personality?.description) return <span>Cannot render graph. Personality data not found</span>;

  const personalityDescription = parsePersonalityDescription(personality.description);

  if (!personalityFactors) return <span>Cannot render graph. Personality factors not found</span>;

  return (
    <Card className="flex flex-col gap-6 rounded-lg">
      <PersonalityBreakdownCard
        code={personalityCode}
        archetype={personalityDescription.name}
        facets={personalityDescription.facets}
        adjectives={personalityDescription.defaultAdjectives}
      />
      <PersonalityChart
        data={personalityData}
        data2={dummyPersonalityData}
        factors={personalityFactors}
      />
    </Card>
  );
};

const Culture = ({ candidate }: { candidate: TGetCandidateForAPost | undefined }) => {
  const { data: cultureValueMap, isLoading: isCultureFactorLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        value_group: VALUES_GROUP.CULTURE_FACTORS,
      },
    },
  });

  if (isCultureFactorLoading) return <Skeleton className="h-[400px] w-full" />;

  const culture = parseCandidateCulture(candidate?.culture as string);
  if (!culture || !culture.length) return <span>Cannot render graph. Culture data not found</span>;

  const highestRankCulture = culture?.reduce((highest, current) => (current.rank < highest.rank ? current : highest));

  const candidateCultureFactor = cultureValueMap?.items?.find(
    (item) => parseCultureDescription(item.description as string).name === (highestRankCulture.name as CultureName)
  )?.description as string;

  const candidateCulture = parseCultureDescription(candidateCultureFactor);

  const cultureFactors = cultureValueMap?.items.map((item) => {
    if (item.description && item.code) {
      const desc = { ...parseCultureDescription(item.description), code: item.code };

      return desc;
    } else
      return {
        code: '',
        name: 'string',
        measures: 'string',
        means: 'string',
        ends: 'string',
        thrust: 'string',
        extras: [''],
      };
  });

  // const candidateCulture = parseCandidateCulture(candidate?.culture as string);

  if (!cultureFactors) return <span>Cannot render graph. Culture factors not found</span>;
  return (
    <Card className="flex flex-col gap-6 rounded-lg">
      <CultureBreakdownCard
        cultureName={candidateCulture.name as CultureName}
        thrust={candidateCulture.thrust}
        means={candidateCulture.means}
        ends={candidateCulture.ends}
        // showSecondLabel={!!roleCulture.length}
      />
      <CultureChart
        data={culture}
        // data2={roleCulture}
        factors={cultureFactors}
      />
    </Card>
  );
};

export default Profile;
