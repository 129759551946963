import { Skeleton } from '../ui/skeleton';

const FormLoader = () => {
  return (
    <div className="flex flex-col gap-8">
      {new Array(3).fill(null).map((_, index) => (
        <Skeleton
          key={index}
          className="h-20 w-full"
        ></Skeleton>
      ))}
    </div>
  );
};

export default FormLoader;
