import React, { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { UilTimes } from '@iconscout/react-unicons';
import { createPortal } from 'react-dom';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import Search from '@/components/ui/search';
import { Select } from '@/components/ui/select-new';
import { Sheet, SheetContent, SheetFooter, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import { PartnerLabel, TeamMemberLabel } from '@/components/ui/team-member-select';

import FilterButton from '@/components/filter/filter-button';

import { useGetBusinessPartners, useGetBusinessTeamMembers, useGetCurrentBusiness } from '@/hooks/business';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { TBusinessTeamMembersList } from '@/services/business';
import { BusinessRolesFilters, TGetAllBusinessRolesV2RequestStatus } from '@/services/business-roles';
import { TGetCurrentCandidateAppliedSort } from '@/services/candidate';
import { SortParams } from '@/services/types';

import { getOptionsForSelectFromValuesMap, truncateString } from '@/utils/string';
import { ORDER, sortByToQuery } from '@/utils/table-sort-mapper';

import { PORTAL_IDS } from '@/constants/portal-ids';
import { VALUES_GROUP } from '@/constants/values-map';

const formSchema = z.object({
  search: z.string().optional(),
  role: z.enum(['asc', 'desc']).nullable().optional(),
  roleType: z.string().nullable().optional(),
  status: z.string().optional(),
  teamMember: z.string().optional(),
  partner: z.string().optional(),
});

type TFormData = z.infer<typeof formSchema>;

interface IRolesFilterFormProps {
  values: TFormData;
  onSubmit: (values: TFormData) => void;
  onClose: () => void;
  hideSearch?: boolean;
}
const RolesFilterForm: React.FC<IRolesFilterFormProps> = ({ values, onSubmit, onClose, hideSearch }) => {
  const { data: business, isPending: isBusinessPending } = useGetCurrentBusiness({});

  const { data: businessMembers, isPending: isBusinessMembersPending } = useGetBusinessTeamMembers({
    params: {
      path: {
        businessId: business?.id.toString() as string,
      },
      query: {
        limit: '100',
        offset: '0',
        sort: 'first_name',
      },
    },
  });

  const { data: businessPartners, isPending: isBusinessPartnersPending } = useGetBusinessPartners({
    params: {
      path: {
        businessId: business?.id.toString() as string,
      },
      query: {
        limit: '100',
        offset: '0',
        sort: 'account_type',
      },
    },
  });

  const { data: rolesData, isLoading: isRolesLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.ROLES,
      },
    },
  });

  const statusOptions: {
    label: string;
    value: TGetAllBusinessRolesV2RequestStatus;
  }[] = [
    {
      label: 'All',

      value: 'all',
    },
    {
      label: 'Open',

      value: 'active',
    },
    {
      label: 'Paused',

      value: 'hold',
    },
    {
      label: 'Closed',

      value: 'closed',
    },
    // {
    //   label: 'Drafts',
    //   value: 'drafts',
    // },
  ];

  const form = useForm<TFormData>({
    resolver: zodResolver(formSchema),
    values,
  });

  const { control, setValue, handleSubmit } = form;
  return (
    <FormProvider {...form}>
      <div className="relative flex flex-grow flex-col justify-between">
        <Form
          className="flex-grow px-6 py-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          {!hideSearch && (
            <FormField
              control={control}
              name="search"
              render={({ field }) => (
                <Search
                  {...field}
                  debounce={0}
                />
              )}
            />
          )}
          <FormField
            control={form.control}
            name="role"
            render={({ field }) => (
              <FormItem className="space-y-3">
                <FormLabel className="text-lg font-semibold">Role</FormLabel>
                <FormControl>
                  <RadioGroup
                    defaultValue={field.value as string | undefined}
                    value={field.value as string | undefined}
                    className="flex gap-x-1"
                    onValueChange={(value: ORDER) => {
                      field.onChange(value);
                      setValue('roleType', null);
                    }}
                  >
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="asc" />
                      </FormControl>
                      <FormLabel className="text-lg">A to Z</FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="desc" />
                      </FormControl>
                      <FormLabel className="text-lg">Z to A</FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="status"
            render={({ field }) => (
              <FormItem className="space-y-3">
                <FormLabel className="text-lg font-semibold">Status</FormLabel>
                <FormControl>
                  <Select
                    // @ts-expect-error status is a string but it has type of the status
                    options={statusOptions}
                    selected={field.value || null}
                    onChange={(value) => field.onChange(value)}
                    disableAlphabeticalSorting
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="roleType"
            render={({ field }) => (
              <FormItem className="space-y-3">
                <FormLabel className="text-lg font-semibold">Role type</FormLabel>
                <FormControl>
                  <Select
                    selected={field.value || null}
                    options={getOptionsForSelectFromValuesMap(rolesData)}
                    onChange={(value) => field.onChange(value)}
                    isLoading={isRolesLoading}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="teamMember"
            render={({ field }) => (
              <FormItem className="space-y-3">
                <FormLabel className="text-lg font-semibold">Team members</FormLabel>
                <FormControl>
                  <Select
                    options={
                      businessMembers?.items.map((member) => ({
                        label: (
                          <TeamMemberLabel
                            name={`${member?.first_name} ${member?.last_name}`}
                            imageUrl={member?.image_url || undefined}
                          />
                        ),
                        value: member.id.toString(),
                      })) || []
                    }
                    selected={field.value || null}
                    onChange={(value) => field.onChange(value)}
                    isLoading={isBusinessPending || isBusinessMembersPending}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="partner"
            render={({ field }) => (
              <FormItem className="space-y-3">
                <FormLabel className="text-lg font-semibold">Partners</FormLabel>
                <FormControl>
                  <Select
                    options={
                      businessPartners?.items.map((partner) => ({
                        label: (
                          <PartnerLabel
                            name={partner?.name}
                            imageUrl={partner?.logo_url || undefined}
                          />
                        ),
                        value: partner.id.toString(),
                      })) || []
                    }
                    selected={field.value || null}
                    onChange={(value) => field.onChange(value)}
                    isLoading={isBusinessPending || isBusinessPartnersPending}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </Form>
        <SheetFooter className="flex justify-end border-t border-primary-dark-10">
          <Button
            variant="tertiary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Apply
          </Button>
        </SheetFooter>
      </div>
    </FormProvider>
  );
};

const getLabelFromFilter = (key: keyof BusinessRolesFilters, value: string) => {
  if (key === 'query') return truncateString(value);

  if (key === 'sortBy') {
    const sortQuery = sortByToQuery<TGetCurrentCandidateAppliedSort>(value as SortParams['sortBy']);
    if (sortQuery?.id === 'title') {
      return sortQuery.order === 'asc' ? 'A to Z' : 'Z to A';
    }

    if (sortQuery?.id === 'remote_work') {
      return sortQuery.order === 'asc' ? 'Low to High' : 'High to Low';
    }
  }

  return null;
};
const AppliedFilters: React.FC<{
  filters: BusinessRolesFilters;
  setFilters: (filters: BusinessRolesFilters) => void;
}> = ({ filters, setFilters }) => {
  const [portalNode, setPortalNode] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setPortalNode(document.getElementById(PORTAL_IDS.rolesApplied));
  }, []);

  if (!portalNode) return null;

  const renderFilters = () => {
    return Object.entries(filters).map(([key, value]) => {
      if (!value) return null;

      return (
        <Button
          key={key}
          variant="tertiary"
          onClick={() => {
            setFilters({ ...filters, [key]: undefined });
          }}
        >
          <span>{getLabelFromFilter(key as keyof BusinessRolesFilters, value.toString())}</span>
          <UilTimes className="size-[1.125rem] text-primary-dark-100" />
        </Button>
      );
    });
  };

  return createPortal(
    <div className="flex items-center gap-4 border-r border-primary-dark-10 pr-4">{renderFilters()}</div>,
    portalNode
  );
};

interface RolesFilterProps {
  usePortalForAppliedFilters?: boolean;
  hideSearch?: boolean;
  filters: BusinessRolesFilters;
  setFilters: (filters: BusinessRolesFilters) => void;
  teamMembers?: TBusinessTeamMembersList;
}

const BusinessRolesFilter: React.FC<RolesFilterProps> = ({ filters, setFilters, hideSearch }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Sheet
        open={open}
        onOpenChange={setOpen}
      >
        <FilterButton onClick={() => setOpen(true)} />
        <SheetContent className="flex flex-col gap-0">
          <SheetHeader>
            <SheetTitle>Filter</SheetTitle>
          </SheetHeader>
          <RolesFilterForm
            values={{
              search: filters.query,
              status: filters.status,
              role: filters.sortBy?.startsWith('title') ? sortByToQuery(filters.sortBy)!.order : null,
              roleType: filters.sortBy?.startsWith('remote_work') ? sortByToQuery(filters.sortBy)!.order : null,
            }}
            onSubmit={(values) => {
              setFilters({
                query: values.search,
                status: values.status as TGetAllBusinessRolesV2RequestStatus,
                sortBy: values.role ? `title.${values.role}` : undefined,
              });
              setOpen(false);
            }}
            onClose={() => setOpen(false)}
            hideSearch={hideSearch}
          />
        </SheetContent>
      </Sheet>
      <AppliedFilters
        filters={filters}
        setFilters={setFilters}
      />
    </>
  );
};

export default BusinessRolesFilter;
