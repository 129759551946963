import { useEffect, useState } from 'react';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import { createPortal } from 'react-dom';

import Avatar from '@/components/ui/avatar';

import TabNav, { ITabNavItem } from '@/components/navigation/tab-nav';

import { useGetCurrentUser } from '@/hooks/user';

import { getFullName } from '@/utils/string';

export const Route = createFileRoute('/_authenticated/partner/_dashboard/settings')({
  component: SettingsLayout,
});

const Banner = () => {
  const [domNode, setDomNode] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const dashboardContainer = document.getElementById('dashboard-container');
    setDomNode(dashboardContainer);
  }, []);

  if (!domNode) return null;

  return createPortal(
    <div className="absolute left-0 top-0 z-0 h-[200px] w-full bg-[url('/business-profile-settings-banner.svg')]"></div>,
    domNode
  );
};

function SettingsLayout() {
  const { data: user } = useGetCurrentUser({});
  const navItems: ITabNavItem[] = [
    {
      label: 'My Details',
      href: `/partner/settings`,
    },
    {
      label: 'Company',
      href: `/partner/settings/company`,
      fuzzyMatch: true,
    },
    {
      label: 'Password',
      href: `/partner/settings/password`,
    },
    {
      label: 'FAQs',
      href: `/partner/settings/faqs`,
    },
  ];
  return (
    <>
      <Banner />
      <main className="relative z-10">
        <div className="mt-32 flex items-center gap-x-10">
          <Avatar
            src={user?.image_url}
            size="4xl"
            variant={'bordered'}
          />
          <h1 className="text-3xl font-semibold">{getFullName(user)}</h1>
        </div>
        <TabNav
          navItems={navItems}
          className="my-8"
        />

        <Outlet />
      </main>
    </>
  );
}
