import client, { publicClient, TRequestOptions } from '@/lib/api';

import { AUTH_ENDPOINTS } from './auth.endpoints';
import { TResetPasswordRequest, TVerifyResetPasswordTokenRequest } from './auth.types';

const { SELF_SEND_VERIFICATION_EMAIL, RESET_PASSWORD_WITH_TOKEN } = AUTH_ENDPOINTS;

export const sendSelfVerificatonEmail = async () => {
  return await client.POST(SELF_SEND_VERIFICATION_EMAIL);
};

export const verifyResetPasswordToken = async ({
  signal,
  params,
}: TRequestOptions<TVerifyResetPasswordTokenRequest>) => {
  const { data } = await publicClient.GET(AUTH_ENDPOINTS.VERIFY_RESET_PASSWORD_TOKEN, { signal, params });

  return data?.success;
};

export const resetPassword = async ({ signal, body, params }: TRequestOptions<TResetPasswordRequest>) => {
  return await client.POST(RESET_PASSWORD_WITH_TOKEN, { signal, body, params });
};
