import React from 'react';
import { UilCell, UilSmile } from '@iconscout/react-unicons';
import { useNavigate } from '@tanstack/react-router';

import { Card, CardContent, CardFooter, CardHeader } from '@/components/ui/card';

import { useGetCurrentCandidate } from '@/hooks/candidate';

import FeaturedIcon from '../icons/featured-icon';
import { Button } from '../ui/button';

type QuizName = 'personality' | 'culture';

export interface QuizInfoCardProps {
  name: QuizName;
  title: string;
  description: string;
  timeTaken: number;
}

export const QuizInfoCard: React.FC<QuizInfoCardProps> = ({ name, title, description, timeTaken }) => {
  const { data: candidate, isLoading } = useGetCurrentCandidate({});
  const navigate = useNavigate();
  const getIcon = () => {
    if (name === 'personality') return UilSmile;
    return UilCell;
  };
  const isQuizTaken = () => {
    if (name === 'culture') {
      return !!candidate?.culture;
    }
    if (name === 'personality') {
      return !!candidate?.personality_archetype;
    }
    return false;
  };
  if (isLoading) return <div>Loading ....</div>;

  return (
    <Card className="w-[526px] rounded-2xl border bg-primary-white-100 p-8 shadow-2xl">
      <CardHeader className="flex flex-row items-center gap-3 pb-4">
        <FeaturedIcon
          variant={isQuizTaken() ? 'success' : 'document'}
          Icon={isQuizTaken() ? null : getIcon()}
        />
        <h1 className="m-0 text-2xl font-semibold leading-loose text-primary-dark-100">{title}</h1>
      </CardHeader>
      <CardContent className="text-base font-medium leading-normal text-primary-dark-60">
        <p className="py-4">{description}</p>
        <p className="py-4">Average time: {timeTaken} minutes</p>
      </CardContent>
      <CardFooter className="m-0 flex justify-end pt-8">
        {isQuizTaken() ? (
          <div className="space-x-4">
            <Button
              onClick={() => navigate({ to: `/candidate/quiz/${name}` })}
              size="lg"
              variant="tertiary"
            >
              Re-take quiz
            </Button>
            <Button
              onClick={() => navigate({ to: `/candidate/quiz/${name}/result` })}
              size="lg"
            >
              View score
            </Button>
          </div>
        ) : (
          <Button
            onClick={() => navigate({ to: `/candidate/quiz/${name}` })}
            size="lg"
          >
            Start quiz
          </Button>
        )}
      </CardFooter>
    </Card>
  );
};
