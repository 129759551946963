import React from 'react';

import { Card, CardContent, CardHeader } from '@/components/ui/card';
import Spinner from '@/components/ui/spinner';

import { PersonalityChart } from '@/components/quiz/charts/personality-chart';

import { useGetCurrentCandidate } from '@/hooks/candidate';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { TPersonalityProfile } from '@/services/business-roles';
import { PersonalityArchetypeCode } from '@/services/candidate';

import { getPersonalityChartData } from '@/utils/role';

import { PERSONALITY_CODE_TO_EMOJI_MAP } from '@/constants/candidate';
import { VALUES_GROUP } from '@/constants/values-map';

import { ChartLabels } from './labels';

interface IProps {
  code: PersonalityArchetypeCode;
  archetype: string;
  facets: string[];
  adjectives: string;
  showSecondLabel?: boolean;
}

export const PersonalityBreakdownCard: React.FC<IProps> = ({
  code,
  archetype,
  facets,
  adjectives,
  showSecondLabel,
}) => {
  return (
    <Card className="mb-8 flex w-full space-x-8 border-none p-0">
      <CardHeader className="flex flex-row items-center">
        <span className="text-7xl 3xl:text-8xl">{PERSONALITY_CODE_TO_EMOJI_MAP[code]}</span>
      </CardHeader>
      <CardContent className="flex space-x-8">
        <div className="grid max-w-[37rem] grid-cols-1 space-y-2">
          <div className="grid grid-cols-[7rem_auto] text-sm font-semibold text-primary-dark-100">
            <h3 className="text-primary-dark-40">Archetype:</h3>
            <span>{archetype}</span>
          </div>
          <div className="grid grid-cols-[7rem_auto] text-sm font-semibold text-primary-dark-100">
            <h3 className="text-primary-dark-40">Facets:</h3>
            <span>{facets.join(', ')}</span>
          </div>
          <div className="grid grid-cols-[7rem_auto] text-sm font-semibold text-primary-dark-100">
            <h3 className="text-primary-dark-40">Adjectives:</h3>
            <span>{adjectives}</span>
          </div>
        </div>

        <ChartLabels showSecondLabel={showSecondLabel} />
      </CardContent>
    </Card>
  );
};

interface IPersonalityMatchProps {
  rolePersonalityProfile: TPersonalityProfile[];
  showCandidateData?: boolean;
}

export const PersonalityMatch: React.FC<IPersonalityMatchProps> = ({ rolePersonalityProfile, showCandidateData }) => {
  const { data: candidate, isLoading: isCandidateLoading } = useGetCurrentCandidate({
    reactQueryOptions: {
      enabled: !!showCandidateData,
    },
  });

  const { data: personalityValuesMap, isLoading: isPersonalityFactorLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        value_group: VALUES_GROUP.PERSONALITY_FACTORS,
      },
    },
  });

  const rolePersonalityCode = rolePersonalityProfile[0].code;

  if (isCandidateLoading || isPersonalityFactorLoading)
    return (
      <Card className="flex min-h-[30rem] items-center justify-center">
        <Spinner />
      </Card>
    );

  const personalityFactors = personalityValuesMap?.items.map((item) => {
    if (!item.description || !item.code)
      return {
        code: '',
        Name: '',
        Adjectives: '',
        Facets: '',
      };
    const desc = JSON.parse(item.description?.replace(' "Adjectives"', ',  "Adjectives"')) as {
      Name: string;
      Adjectives: string;
      Facets: string;
    };
    return {
      code: item.code,
      ...desc,
    };
  });

  const candidatePersonalityData = showCandidateData ? JSON.parse(candidate?.personality_test_info || '{}') : {};
  const rolePersonalityData = getPersonalityChartData(rolePersonalityProfile);

  const rolePersonalityFactor = personalityFactors?.find((item) => item.code === rolePersonalityCode);

  if (!rolePersonalityFactor || !personalityFactors) return null;

  return (
    <Card className="flex flex-col gap-6">
      <PersonalityBreakdownCard
        code={rolePersonalityCode}
        archetype={rolePersonalityFactor.Name}
        facets={rolePersonalityFactor.Facets.split(',')}
        adjectives={rolePersonalityFactor.Adjectives}
        showSecondLabel={!!showCandidateData}
      />
      <PersonalityChart
        data={showCandidateData ? candidatePersonalityData : rolePersonalityData}
        data2={showCandidateData ? rolePersonalityData : undefined}
        factors={personalityFactors}
      />
    </Card>
  );
};
