import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const badgeVariants = cva(
  '3xl:text-md inline-flex cursor-pointer items-center justify-center gap-1 px-3 py-0.5 text-sm font-medium leading-5 focus:outline-none focus:ring-2 focus:ring-slate-950 focus:ring-offset-2 3xl:py-1',
  {
    variants: {
      variant: {
        default: 'bg-primary-blue-10 text-primary-blue-120',
        warning: 'bg-primary-warning-10 text-primary-warning-120',
        destructive: 'bg-primary-alert-10 text-primary-alert-120',
        success: 'bg-primary-success-10 text-primary-success-120',
        grey: 'bg-primary-dark-10 text-primary-dark-120',
      },
      shape: {
        default: 'rounded-2xl',
        rounded: 'rounded-full',
      },
    },
    defaultVariants: {
      variant: 'default',
      shape: 'default',
    },
  }
);

const dotVariants = cva('h-1.5 w-1.5 rounded-full', {
  variants: {
    dotVariant: {
      default: 'bg-primary-blue-100 text-primary-blue-80',
      warning: 'bg-primary-warning-100 text-primary-warning-80',
      destructive: 'bg-primary-alert-100 text-primary-alert-80',
      success: 'bg-primary-success-100 text-primary-success-80',
      grey: 'bg-primary-dark-100 text-primary-dark-80',
    },
  },
  defaultVariants: {
    dotVariant: 'default',
  },
});

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants>,
    VariantProps<typeof dotVariants> {
  hideDot?: boolean;
}

function Badge({ shape, className, variant, hideDot, children, ...props }: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ variant, shape }), className, 'justify-items-stretch')}
      {...props}
    >
      {!hideDot && <div className={cn(dotVariants({ dotVariant: variant }))}></div>}
      <div className="inline-flex items-center justify-center gap-1">{children}</div>
    </div>
  );
}

export { Badge };
