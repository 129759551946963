import { CultureName, PersonalityArchetypeCode } from '@/services/candidate';

export const getIndexFromId = (id: string) => parseInt(id.slice(1));

export const updateTraits = <T extends PersonalityArchetypeCode | CultureName>(
  traits: (T | null)[],
  currentIndex: number,
  targetIndex: number,
  activeId: T | null
) => {
  return traits.map((trait, index) => {
    if (index === targetIndex) return activeId;
    if (index === currentIndex) return null;
    return trait;
  });
};
