import { useState } from 'react';
import { createFileRoute } from '@tanstack/react-router';

import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';

import BusinessRolesTable from '@/components/roles/business/business-roles-table';

import { useGetCurrentPartnerBusiness } from '@/hooks/business';
import { useGetAllRolesByBusinessIdV2 } from '@/hooks/business-roles';
import useFilters from '@/hooks/useFilters';

import {
  BusinessRolesFilters,
  TGetAllBusinessRolesV2RequestSort,
  TGetAllBusinessRolesV2RequestStatus,
} from '@/services/business-roles';

import { sortByToQuery } from '@/utils/table-sort-mapper';

import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '@/constants/table';

export const Route = createFileRoute('/_authenticated/partner/_dashboard/roles/')({
  component: BusinessRoles,
});

function BusinessRoles() {
  const [activeStatus, setActiveStatus] = useState<TGetAllBusinessRolesV2RequestStatus>('all');
  const { data: business, isPending: isBusinessPending } = useGetCurrentPartnerBusiness();

  const { filters, setFilters } = useFilters<BusinessRolesFilters>(Route.id);

  const paginationState = {
    pageIndex: filters.pageIndex ?? DEFAULT_PAGE_INDEX,
    pageSize: filters.pageSize ?? DEFAULT_PAGE_SIZE,
  };

  const sortQuery = sortByToQuery<TGetAllBusinessRolesV2RequestSort>(filters.sortBy);

  const { data, isFetching } = useGetAllRolesByBusinessIdV2({
    params: {
      path: {
        businessId: business?.id.toString() as string,
      },
      query: {
        limit: paginationState.pageSize.toString(),
        offset: (paginationState.pageIndex * paginationState.pageSize).toString(),
        status: activeStatus as TGetAllBusinessRolesV2RequestStatus,
        q: filters.query,
        ...(sortQuery && {
          sort: sortQuery.id,
          order: sortQuery.order,
        }),
      },
    },
    reactQueryOptions: {
      enabled: !!business,
      queryKey: [filters, activeStatus],
    },
  });
  const tabs: {
    label: string;
    count?: number | null;
    status: TGetAllBusinessRolesV2RequestStatus;
  }[] = [
    {
      label: 'All',
      count: data?.metadata.total_posts || 0,
      status: 'all',
    },
    {
      label: 'Open',
      count: data?.metadata.total_open_posts || 0,
      status: 'active',
    },
    {
      label: 'Paused',
      count: data?.metadata.total_paused_posts || 0,
      status: 'hold',
    },
    {
      label: 'Closed',
      count: data?.metadata.total_closed_posts || 0,
      status: 'closed',
    },
    {
      label: 'Drafts',
      count: data?.metadata.total_draft_posts || 0,
      status: 'draft',
    },
  ];
  const handleActiveStatusChange = (newStatus: TGetAllBusinessRolesV2RequestStatus) => {
    setActiveStatus(newStatus);
    setFilters({ ...filters, pageIndex: DEFAULT_PAGE_INDEX, status: newStatus });
  };

  const handleFiltersChange = async (newFilters: BusinessRolesFilters) => {
    const pageIndex = newFilters.pageIndex || DEFAULT_PAGE_INDEX;
    if (newFilters.status && newFilters.status !== filters.status) {
      setActiveStatus(newFilters.status);
    }
    if (newFilters.sortBy || newFilters.sortBy !== filters.sortBy || newFilters.status !== filters.status) {
      setFilters({ ...newFilters, pageIndex: pageIndex });
    } else {
      setFilters(newFilters);
    }
  };

  return (
    <>
      <div className="flex justify-between gap-4">
        <Tabs
          value={activeStatus}
          // @ts-expect-error returns string but it has type of the status
          onValueChange={handleActiveStatusChange}
        >
          <TabsList>
            {tabs.map((tab) => (
              <TabsTrigger
                key={tab.label}
                value={tab.status as string}
                className="inline-flex min-w-[7.5rem] items-center gap-x-2 2xl:min-w-[9rem] 3xl:min-w-[9.5rem]"
              >
                {tab.label}
                {(tab.count || tab.count === 0) && (
                  <span className="inline-flex h-5 w-5 flex-col items-center justify-center gap-2.5 rounded border border-[#e7e9ed] p-0.5">
                    <span className="text-center font-['Montserrat'] text-xs font-medium leading-[18px] text-[#0c2149]">
                      {tab.count}
                    </span>
                  </span>
                )}
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>
        {/* NOTE: Partners cannot create roles */}
        {/* <div className="flex gap-4">
          <CreateRoleButton />
        </div> */}
      </div>
      <BusinessRolesTable
        data={data}
        isPending={isFetching || isBusinessPending}
        filters={{ ...filters, status: activeStatus }}
        setFilters={handleFiltersChange}
        paginationState={paginationState}
      />
    </>
  );
}
