import { cn } from '@/lib/utils';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const SettingContainer: React.FC<IProps> = ({ children, className }) => {
  return <div className={cn('grid grid-cols-[1.5fr_3fr] gap-8', className)}>{children}</div>;
};

export default SettingContainer;
