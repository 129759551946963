import { useEffect, useState } from 'react';
import { useCreateRoleStore } from '@/store/create-role-store';
import { UilClipboardNotes } from '@iconscout/react-unicons';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { createPortal } from 'react-dom';
import { useFormContext } from 'react-hook-form';

import { Button } from '@/components/ui/button';
import { toast } from '@/components/ui/toaster';

import { useGetCurrentBusiness } from '@/hooks/business';
import { useCreateJobPost } from '@/hooks/business-roles';

import { mapCreateRoleFormDataToAPI } from '@/services/business-roles';

import { PORTAL_IDS } from '@/constants/portal-ids';

import {
  TFinalFormData,
  TStep1FormData,
  TStep2FormData,
  TStep3FormData,
  TStep4FormData,
  TStep5FormData,
  TStep6FormData,
  TStep7FormData,
} from '@/validation-schemas/business/create-role-schema';

const SaveAsDraftButton = ({ usePortal = true }: { usePortal?: boolean }) => {
  const [domNode, setDomNode] = useState<HTMLElement | null>(null);
  const { data } = useCreateRoleStore();
  const navigate = useNavigate();
  const { mode } = useSearch({
    from: '/_authenticated/business/create-role',
  });

  const form = useFormContext<
    | TStep1FormData
    | TStep2FormData
    | TStep3FormData
    | TStep4FormData
    | TStep5FormData
    | TStep6FormData
    | TStep7FormData
    | TFinalFormData
  >();

  const { data: currentBusiness } = useGetCurrentBusiness({});
  const { mutate: createJobPost, isPending: isCreatingJobPost } = useCreateJobPost({
    reactQueryOptions: {
      onSuccess: () => {
        toast.success('Role saved as draft');
        navigate({ to: '/business/roles' });
      },
      onError: () => toast.error('Failed to save role as draft'),
    },
    params: { path: { businessId: currentBusiness?.id.toString() ?? '' } },
  });

  useEffect(() => {
    const dashboardContainer = document.getElementById(PORTAL_IDS.saveAsDraft);
    setDomNode(dashboardContainer);
  }, []);

  if (!domNode) return null;
  if (mode === 'edit') return null;

  const onSaveAsDraft = () => {
    const draftData = form
      ? {
          ...data,
          ...form.getValues(),
        }
      : data;

    if (!draftData.location) {
      return toast.error('Please enter a location first');
    }

    createJobPost({
      body: mapCreateRoleFormDataToAPI(draftData, false),
      params: { path: { businessId: currentBusiness?.id.toString() ?? '' } },
    });
  };

  const renderButton = () => (
    <Button
      variant="tertiary"
      onClick={onSaveAsDraft}
      loadingText="Saving"
      loading={isCreatingJobPost}
    >
      Save as Draft <UilClipboardNotes className="size-[1.125rem]" />
    </Button>
  );

  if (usePortal) {
    return createPortal(renderButton(), domNode);
  }

  return renderButton();
};

export default SaveAsDraftButton;
