import { UilAngleLeft, UilAngleRight, UilCalendarAlt } from '@iconscout/react-unicons';
import { Navigate, type ToolbarProps } from 'react-big-calendar';

import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';

const ToolBar: React.FC<ToolbarProps> = ({ label, onNavigate }) => {
  return (
    <Card className="flex items-center justify-between rounded-b-none rounded-t-2xl border-b-0 px-6 py-4">
      <div className="flex items-center gap-8">
        <Button
          variant="tertiary"
          onClick={() => onNavigate(Navigate.TODAY)}
        >
          <UilCalendarAlt className="mr-2 size-[1.125rem] text-primary-dark-100" /> Today
        </Button>
        <h1 className="text-xl font-semibold">{label}</h1>
      </div>
      <div className="flex gap-2">
        <Button
          variant="tertiary"
          className="p-3"
          onClick={() => onNavigate(Navigate.PREVIOUS)}
        >
          <UilAngleLeft className="size-[1.125rem] text-primary-dark-100" />
        </Button>
        <Button
          variant="tertiary"
          className="p-3"
          onClick={() => onNavigate(Navigate.NEXT)}
        >
          <UilAngleRight className="size-[1.125rem] text-primary-dark-100" />
        </Button>
      </div>
    </Card>
  );
};

export default ToolBar;
