import React from 'react';

import { Card, CardContent, CardHeader } from '@/components/ui/card';

import { PersonalityChart } from '@/components/quiz/charts/personality-chart';

import { useGetCurrentCandidate } from '@/hooks/candidate';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { PersonalityArchetypeCode } from '@/services/candidate';

import { parsePersonalityDescription } from '@/utils/string';

import { getRandomPersonalityTestInfo, PERSONALITY_CODE_TO_EMOJI_MAP } from '@/constants/candidate';
import { VALUES_GROUP } from '@/constants/values-map';

import { ChartLabels } from './labels';

interface IProps {
  code: PersonalityArchetypeCode;
  archetype: string;
  facets: string[];
  adjectives: string;
}

export const PersonalityBreakdownCard: React.FC<IProps> = ({ code, archetype, facets, adjectives }) => {
  return (
    <Card className="mb-8 flex w-full space-x-8 border-none p-0">
      <CardHeader className="flex flex-row items-center">
        <span className="text-7xl 3xl:text-8xl">{PERSONALITY_CODE_TO_EMOJI_MAP[code]}</span>
      </CardHeader>
      <CardContent className="flex space-x-8">
        <div className="grid max-w-[37rem] grid-cols-1 space-y-2">
          <div className="grid grid-cols-[7rem_auto] text-sm font-semibold text-primary-dark-100">
            <h3 className="text-primary-dark-40">Archetype:</h3>
            <span>{archetype}</span>
          </div>
          <div className="grid grid-cols-[7rem_auto] text-sm font-semibold text-primary-dark-100">
            <h3 className="text-primary-dark-40">Facets:</h3>
            <span>{facets.join(', ')}</span>
          </div>
          <div className="grid grid-cols-[7rem_auto] text-sm font-semibold text-primary-dark-100">
            <h3 className="text-primary-dark-40">Adjectives:</h3>
            <span>{adjectives}</span>
          </div>
        </div>

        <ChartLabels />
      </CardContent>
    </Card>
  );
};

export const PersonalityMatch: React.FC = () => {
  const { data: candidate, isLoading: isCandidateLoading } = useGetCurrentCandidate({});

  const { data: personalityValuesMap, isLoading: isPersonalityFactorLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        value_group: VALUES_GROUP.PERSONALITY_FACTORS,
      },
    },
  });

  if (isCandidateLoading || isPersonalityFactorLoading) return <div>Loading...</div>;
  const personalityCode = candidate?.personality_archetype as PersonalityArchetypeCode;

  const personalityData = JSON.parse(candidate?.personality_test_info || '{}');
  const dummyPersonalityData = getRandomPersonalityTestInfo();
  const personalityFactors = personalityValuesMap?.items.map((item) => {
    if (!item.description || !item.code)
      return {
        code: '',
        Name: '',
        Adjectives: '',
        Facets: '',
      };
    const desc = JSON.parse(item.description?.replace(' "Adjectives"', ',  "Adjectives"')) as {
      Name: string;
      Adjectives: string;
      Facets: string;
    };
    return {
      code: item.code,
      ...desc,
    };
  });

  const personality = personalityValuesMap?.items?.find((item) => item.code === candidate?.personality_archetype);

  if (!personality || !personality?.description) return null;

  const personalityDescription = parsePersonalityDescription(personality.description);

  if (!personalityFactors) return <span>Cannot render graph. Personality factors not found</span>;

  return (
    <div>
      <PersonalityBreakdownCard
        code={personalityCode}
        archetype={personalityDescription.name}
        facets={personalityDescription.facets}
        adjectives={personalityDescription.defaultAdjectives}
      />
      <PersonalityChart
        data={personalityData}
        data2={dummyPersonalityData}
        factors={personalityFactors}
      />
    </div>
  );
};
