import { useEffect, useState } from 'react';
import { TSelectedRolesFormData } from '@/store/pipeline-store';
import { UilCodeBranch, UilTimes } from '@iconscout/react-unicons';
import { useNavigate } from '@tanstack/react-router';
import { createPortal } from 'react-dom';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import RolesSelectComboBox, { TRoleOption } from '@/components/ui/roles-search-combobox';
import { Tag } from '@/components/ui/tag';

import { useGetAllRolesByBusinessIdV2 } from '@/hooks/business-roles';
import { useGetCurrentDashboard } from '@/hooks/user';

import { getPipelineTableUrl } from '@/utils/urls';

import { PORTAL_IDS } from '@/constants/portal-ids';

export function RoleSelectModal({
  onSubmit,
  businessId,
}: {
  businessId: number;
  onSubmit: (selectedRoles: TSelectedRolesFormData) => void;
}) {
  const dashboard = useGetCurrentDashboard();

  const [roleQuery, setRoleQuery] = useState<string>('');

  const { data: roles, isFetching: isRolesLoading } = useGetAllRolesByBusinessIdV2({
    params: {
      path: {
        businessId: businessId.toString() as string,
      },
      query: {
        limit: '10',
        offset: '0',
        status: 'active',
        order: 'asc',
        sort: 'title',
        q: roleQuery,
      },
    },

    reactQueryOptions: {
      enabled: !!businessId,
      queryKey: [roleQuery],
    },
  });

  const [selected, setSelected] = useState<TRoleOption[]>([]);

  const removeSelectedRole = (roleId: number) => {
    setSelected(selected.filter((role) => role.id !== roleId));
  };

  const navigate = useNavigate();

  const handleSelectedRoles = () => {
    onSubmit(selected?.map((role) => ({ id: role?.id, title: role?.title })) as TSelectedRolesFormData);
  };
  const [portalNode, setPortalNode] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setPortalNode(document.getElementById(PORTAL_IDS.pipelineRoles));
  }, []);

  if (!portalNode) return null;

  return createPortal(
    <div className="absolute z-50 flex h-dvh w-full items-center justify-center bg-primary-dark-2">
      <Card className="w-[524px] p-8">
        <CardHeader>
          <CardTitle className="flex items-center gap-4 pb-4 text-2xl font-semibold leading-loose">
            <div className="inline-flex h-8 w-8 items-center justify-center rounded-lg border border-primary-dark-10 shadow">
              <UilCodeBranch className="text-primary-dark-100" />
            </div>
            <span>Pipeline</span>
          </CardTitle>
          <CardDescription className="py-4 text-base font-medium leading-normal text-primary-dark-60">
            Please select the role/s you would like to view in the pipeline.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Label htmlFor="role-search">Search role</Label>
          <RolesSelectComboBox
            id="role-search"
            placeholder="Search"
            options={roles?.items.map((s) => ({ value: s.id, label: s.title })) || []}
            selected={selected}
            onSelect={setSelected}
            isLoading={isRolesLoading}
            onQueryChange={(query) => setRoleQuery(query)}
          />
          {selected && (
            <div className="flex flex-wrap gap-2 pt-2">
              {selected.length > 0 &&
                selected.map((role) => (
                  <Tag
                    key={role.id}
                    className="h-[2.75rem] cursor-pointer bg-primary-white-100 px-4 py-3 text-sm font-semibold leading-tight text-primary-dark-100"
                    hideDot
                  >
                    <span> {role.title}</span>
                    <UilTimes
                      onClick={() => removeSelectedRole(role.id)}
                      className="size-4 text-primary-dark-40"
                    />
                  </Tag>
                ))}
            </div>
          )}
        </CardContent>
        <CardFooter className="flex justify-end gap-4 pt-8">
          <Button
            variant={'tertiary'}
            onClick={() => {
              // return;
              // !TODO - implement this after the issue with header not showing up in table.
              navigate({ to: getPipelineTableUrl({ dashboard }) });
            }}
          >
            Show all
          </Button>
          <Button
            onClick={() => handleSelectedRoles()}
            disabled={!selected?.length}
          >
            Search role
          </Button>
        </CardFooter>
      </Card>
    </div>,
    portalNode
  );
}
