import { useState } from 'react';
import { useCreateRoleStore } from '@/store/create-role-store';
import { UilInfoCircle } from '@iconscout/react-unicons';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import Spinner from '@/components/ui/spinner';

import { PersonalityChart } from '@/components/quiz/charts/personality-chart';

import useCreateRoleStepper from '@/hooks/useCreateRoleStepper';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { PersonalityArchetypeCode } from '@/services/candidate';

import { parsePersonalityDescription } from '@/utils/string';

import { PERSONALITY_CODE_TO_EMOJI_MAP } from '@/constants/candidate';
import { VALUES_GROUP } from '@/constants/values-map';

const PersonalityQuizResults = () => {
  const { data, setStepData } = useCreateRoleStore();
  const { goToStep } = useCreateRoleStepper();
  const { data: personalityValuesMap, isLoading: isPersonalityFactorLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        value_group: VALUES_GROUP.PERSONALITY_FACTORS,
      },
    },
  });

  const [showScoreBreakdown, setShowScoreBreakdown] = useState(false);

  if (isPersonalityFactorLoading)
    return (
      <div className="flex h-full w-full flex-1 items-center justify-center">
        <Spinner size="lg" />
      </div>
    );

  const personality = personalityValuesMap?.items?.find((item) => item.code === data?.personalityTraits[0]);

  if (!personality || !personality?.description) return null;
  const personalityDescription = parsePersonalityDescription(personality.description);
  const personalityChartData = data?.personalityTraits.reduce(
    (acc, code, index) => {
      const personality = personalityValuesMap?.items?.find((item) => item.code === code);
      if (personality) {
        acc[code] = data.personalityTraits.length - index;
      }
      return acc;
    },
    {} as Record<PersonalityArchetypeCode, number>
  );
  const personalityFactors = personalityValuesMap?.items.map((item) => {
    if (!item.description || !item.code)
      return {
        code: '',
        Name: '',
        Adjectives: '',
        Facets: '',
      };
    const desc = JSON.parse(item.description?.replace(' "Adjectives"', ',  "Adjectives"')) as {
      Name: string;
      Adjectives: string;
      Facets: string;
    };
    return {
      code: item.code,
      ...desc,
    };
  });

  return (
    <Card className="space-y-14 p-8">
      <CardHeader className="flex flex-row items-start justify-between">
        <span className="text-7xl 3xl:text-8xl">{PERSONALITY_CODE_TO_EMOJI_MAP[data?.personalityTraits[0]]}</span>
        <div className="flex gap-4">
          <Button
            variant="tertiary"
            onClick={() => setShowScoreBreakdown(!showScoreBreakdown)}
          >
            {showScoreBreakdown ? 'Hide' : 'Show'} breakdown
          </Button>
          <Button
            variant="tertiary"
            onClick={() => {
              setStepData({
                ...data,
                personalityTraits: [],
              });
              goToStep(4, '/personality-quiz');
            }}
          >
            Retake quiz
          </Button>
        </div>
      </CardHeader>
      <CardContent className="flex flex-col gap-6 2xl:text-lg 3xl:text-xl">
        <div className="flex flex-col gap-1 font-semibold leading-[30px] text-primary-dark-100">
          <h3 className="text-sm font-semibold uppercase leading-tight text-primary-dark-40 after:content-[':']">
            ARCHETYPE
          </h3>
          {personalityDescription.name}
        </div>
        <div className="flex flex-col gap-1 font-semibold leading-[30px] text-primary-dark-100">
          <h3 className="text-sm font-semibold uppercase leading-tight text-primary-dark-40 after:content-[':']">
            FACETS
          </h3>
          {personalityDescription.facets.join(', ')}
        </div>
        <div className="flex flex-col gap-1 font-semibold leading-[30px] text-primary-dark-100">
          <h3 className="text-sm font-semibold uppercase leading-tight text-primary-dark-40 after:content-[':']">
            ADJECTIVES
          </h3>
          {personalityDescription.defaultAdjectives}
        </div>
      </CardContent>
      {showScoreBreakdown && personalityFactors && (
        <CardContent className="flex flex-col gap-4">
          <h1 className="text-2xl font-semibold">Score breakdown</h1>
          <div className="flex w-max rounded-lg bg-primary-blue-5 px-2 py-1 text-base font-medium leading-normal text-primary-blue-100">
            <UilInfoCircle
              width={18}
              height={18}
              className="my-auto mr-2"
            />
            <span>Hover over the tags to find out more</span>
          </div>
          <div className="mx-auto my-4 h-full w-full max-w-[880px]">
            <PersonalityChart
              data={personalityChartData}
              factors={personalityFactors}
            />
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default PersonalityQuizResults;
