import * as React from 'react';
import { VariantProps } from 'class-variance-authority';
import TextareaAutosize from 'react-textarea-autosize';

import { cn } from '@/lib/utils';

import { inputVariants } from './input';

interface TextAreaProps
  extends React.ComponentPropsWithoutRef<typeof TextareaAutosize>,
    VariantProps<typeof inputVariants> {
  error?: boolean;
}

const Textarea = React.forwardRef<React.ElementRef<typeof TextareaAutosize>, TextAreaProps>(
  ({ className, error, minRows = 5, ...props }, ref) => {
    return (
      <>
        <TextareaAutosize
          className={cn(
            inputVariants({ variant: error ? 'error' : 'default' }),
            'h-full resize-none scrollbar',
            className
          )}
          ref={ref}
          {...props}
          minRows={minRows}
          contextMenu="true"
        />
        {props?.maxLength && (
          <div className="m-0 items-center text-sm text-primary-dark-60">
            {formatNumber(props?.value ? props.maxLength - props.value.toString().length : props.maxLength)} characters
            remaining
          </div>
        )}
      </>
    );
  }
);
Textarea.displayName = 'Textarea';

export { Textarea };

function formatNumber(num: number) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
