import { useState } from 'react';

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { toast } from '@/components/ui/toaster';

import { useGetCurrentBusiness } from '@/hooks/business';
import { useUpdateJobPost } from '@/hooks/business-roles';

import { JOB_POST_STATUS } from '@/constants/post';

const CloseRoleModal = ({
  roleId,
  open,
  onOpenChange,
}: {
  roleId: number;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}) => {
  const [notifyAllCandidates, setNotifyAllCandidates] = useState(false);
  const { data: currentBusiness } = useGetCurrentBusiness({});
  const { mutate: updateJobPost, isPending } = useUpdateJobPost({
    params: { path: { businessId: currentBusiness?.id.toString() ?? '', postId: roleId.toString() } },
  });
  const onCloseClick = () => {
    updateJobPost(
      {
        params: { path: { businessId: currentBusiness?.id.toString() ?? '', postId: roleId.toString() } },
        body: { status: JOB_POST_STATUS.CLOSED },
      },
      {
        onSuccess: () => {
          toast.success('Job post closed');
          setNotifyAllCandidates(false);
          onOpenChange(false);
        },
        onError: () => {
          toast.error('Failed to close job post');
        },
      }
    );
  };

  return (
    <AlertDialog
      open={open}
      onOpenChange={(open) => {
        setNotifyAllCandidates(false);
        onOpenChange(open);
      }}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle iconVariant="alert">Close role</AlertDialogTitle>
          <AlertDialogDescription>
            By selecting to close the role you will be removing all scheduled interviews and candidates that have
            applied.
            <div className="mt-8">
              <Checkbox
                checked={notifyAllCandidates}
                onCheckedChange={(checked) => {
                  setNotifyAllCandidates(checked as boolean);
                }}
                label="Notify all candidates"
              />
            </div>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            variant="destructive"
            onClick={onCloseClick}
            disabled={isPending}
            loading={isPending}
            loadingText="Closing..."
          >
            Close role
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CloseRoleModal;
