import { Tag, TDotVariant } from '@/components/ui/tag';

import { TOfferStatus } from '@/services/candidate';

export const statusToTagVariantMap: Record<NonNullable<TOfferStatus>, TDotVariant> = {
  ACCEPTED: 'success',
  REJECTED: 'alert',
  PENDING: 'warn',
  WITHDRAWN: 'warn',
};

export const statusToLabelMap: Record<NonNullable<TOfferStatus>, string> = {
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  PENDING: 'Pending',
  WITHDRAWN: 'Withdrawn',
};

const OfferStatusTag = ({ status }: { status: NonNullable<TOfferStatus> }) => {
  const variant = statusToTagVariantMap[status];
  const label = statusToLabelMap[status];

  return (
    <Tag
      variant={variant}
      size="sm"
    >
      {label}
    </Tag>
  );
};

export default OfferStatusTag;
