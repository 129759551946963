import { createFileRoute } from '@tanstack/react-router';

import { Card } from '@/components/ui/card';
import Heading from '@/components/ui/typography/heading';

import BusinessBiosExtrasForm from '@/components/forms/business/business-bios-extras-form';
import SettingContainer from '@/components/settings/setting-container';

import { useGetCurrentUserPermissions } from '@/hooks/permission';

export const Route = createFileRoute('/_authenticated/business/_dashboard/settings/company/extras')({
  component: CompanyBiosExtrasCard,
});

function CompanyBiosExtrasCard() {
  const { data: permissions } = useGetCurrentUserPermissions();

  return (
    <SettingContainer>
      <Heading
        title="Bio & extras"
        subtitle="Update your bio & cover image."
      />
      <Card>
        <BusinessBiosExtrasForm isDisabled={Boolean(!permissions?.manage_company_settings)} />
      </Card>
    </SettingContainer>
  );
}
