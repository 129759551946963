import { createFileRoute } from '@tanstack/react-router';

import { QuizInfoCard, QuizInfoCardProps } from '@/components/quiz/quiz-info-card';

export const Route = createFileRoute('/_authenticated/candidate/quiz/')({
  component: CandidateQuiz,
});

const options: QuizInfoCardProps[] = [
  {
    name: 'personality',
    title: 'Personality Quiz',
    description: 'The personality quiz is designed to uncover your unique traits and preferences.',
    timeTaken: 2,
  },
  {
    name: 'culture',
    title: 'Culture Quiz',
    description: 'The culture quiz helps identify how well your align with a companies values and environment.',
    timeTaken: 2,
  },
];

function CandidateQuiz() {
  return (
    <main className="flex h-svh w-svw justify-center bg-primary-dark-2 px-8 py-20">
      <div className="flex max-w-[1080px] flex-col gap-12">
        <div className="flex flex-col gap-8 py-2">
          <h1 className="text-3xl font-semibold leading-10 text-primary-dark-100">Time for a quiz!</h1>
          <div className="flex flex-col gap-6 text-base font-medium leading-normal text-primary-dark-60">
            <p>
              Please take our brief Personality & Culture Questionnaires to help us match you more accurately with what
              companies are looking for.
            </p>
            <p>It won&apos;t take long, and you might even discover something new about yourself!</p>
          </div>
        </div>
        <div className="flex flex-row justify-between md:w-full md:flex-col md:gap-10 lg:flex-row lg:gap-4">
          {options.map((option) => (
            <QuizInfoCard
              {...option}
              key={option.name}
            />
          ))}
        </div>
      </div>
    </main>
  );
}
