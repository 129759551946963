import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  PERMISSION_ENDPOINTS,
  TPutBusinessPartnerPermissionsRequest,
  TPutBusinessUserPermissionsRequest,
  updateBusinessMemberPermissions,
  updateBusinessPartnerPermissions,
} from '@/services/permission';

import { UseMutationOptions } from '../types';

export function useUpdateBusinessTeamMemberPermissions({
  reactQueryOptions,
  params,
}: UseMutationOptions<TPutBusinessUserPermissionsRequest>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: updateBusinessMemberPermissions,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [PERMISSION_ENDPOINTS.BUSINESS_USER_PERMISSIONS, params.path.businessId, params.path.memberId],
      });
      queryClient.invalidateQueries({
        queryKey: [PERMISSION_ENDPOINTS.BUSINESS_USER_PERMISSIONS, params.path.businessId],
      });
    },
  });
}

export function useUpdateBusinessPartnerPermissions({
  reactQueryOptions,
  params,
}: UseMutationOptions<TPutBusinessPartnerPermissionsRequest>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: updateBusinessPartnerPermissions,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [PERMISSION_ENDPOINTS.BUSINESS_PARTNER_PERMISSIONS, params.path.businessId, params.path.partnerId],
      });
      queryClient.invalidateQueries({
        queryKey: [PERMISSION_ENDPOINTS.BUSINESS_PARTNER_PERMISSIONS, params.path.businessId],
      });
    },
  });
}
