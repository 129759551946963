import { z } from 'zod';

export const teamMemberPermissionsSchema = z.object({
  team: z.array(z.string()).optional(),
  account_type: z.string().optional(),
});

export type TTeamMemberPermissionsFormData = z.infer<typeof teamMemberPermissionsSchema>;

/**
 * Business User Permission
 */
export const businessUserPermissionSchema = z.object({
  manage_roles: z.boolean(),
  manage_pipelines: z.boolean(),
  manage_team_members: z.boolean(),
  manage_partners: z.boolean(),
  manage_company_settings: z.boolean(),
});

export type BusinessUserPermissionSchema = z.infer<typeof businessUserPermissionSchema>;

/**
 * Business Partner Permission
 */
export const businessPartnerPermissionSchema = z.object({
  manage_roles: z.boolean(),
  manage_pipelines: z.boolean(),
});

export type BusinessPartnerPermissionSchema = z.infer<typeof businessPartnerPermissionSchema>;
export type BusinessPartnerPermissionUpdateSchema = z.infer<typeof businessPartnerPermissionSchema>;

/**
 * Partner User Permission
 */
export const partnerUserPermissionSchema = z.object({
  manage_roles: z.boolean(),
  manage_pipelines: z.boolean(),
});

export type PartnerUserPermissionSchema = z.infer<typeof partnerUserPermissionSchema>;
