import React, { useState } from 'react';
import { UilArrowDown, UilArrowUp } from '@iconscout/react-unicons';

import { Button } from '@/components/ui/button';

interface ShowMoreParagraphProps {
  text: string;
  maxLength?: number;
}

const ShowMoreParagraph: React.FC<ShowMoreParagraphProps> = ({ text, maxLength = 100 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const displayText = isExpanded ? text : text.slice(0, maxLength);
  const showMoreButton = text.length > maxLength && !isExpanded;

  return (
    <div>
      {displayText
        .split('\n')
        .filter((line) => line.trim() !== '')
        .map((line, index) => (
          <p key={index}>
            {index > 0 && <br />}
            {line}
            {!isExpanded &&
              index === displayText.split('\n').filter((l) => l.trim() !== '').length - 1 &&
              text.length > maxLength &&
              '...'}
          </p>
        ))}
      {(showMoreButton || isExpanded) && (
        <Button
          onClick={toggleExpand}
          variant="link"
          size="sm"
        >
          {isExpanded ? 'See less' : 'See more'}
          {isExpanded ? (
            <UilArrowUp className={`size-[18px] transition-all duration-300`} />
          ) : (
            <UilArrowDown className={`size-[18px] transition-all duration-300`} />
          )}
        </Button>
      )}
    </div>
  );
};

export default ShowMoreParagraph;
