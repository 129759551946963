import { useState } from 'react';
import { UilArrowLeft, UilInfoCircle } from '@iconscout/react-unicons';
import { createFileRoute, redirect, useNavigate } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';

import FeaturedIcon from '@/components/icons/featured-icon';
import { PersonalityChart } from '@/components/quiz/charts/personality-chart';
import LoadingCard from '@/components/quiz/loading';
import PersonalityBreakDownCard from '@/components/quiz/personality/personality-breakdown-card';
import PersonalityResultCard from '@/components/quiz/personality/result-card';

import { useGetCurrentCandidate } from '@/hooks/candidate';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import {
  CANDIDATE_ENDPOINTS,
  getCurrentCandidate,
  hasCandidateCompletedCultureQuiz,
  hasCandidateCompletedPersonalityQuiz,
  PersonalityArchetypeCode,
} from '@/services/candidate';

import { parsePersonalityDescription } from '@/utils/string';

import { VALUES_GROUP } from '@/constants/values-map';

export const Route = createFileRoute('/_authenticated/candidate/quiz/personality/result')({
  component: PersonalityQuizResult,
  beforeLoad: async ({ context }) => {
    const queryClient = context.queryClient;

    const candidate = await queryClient.fetchQuery({
      queryFn: getCurrentCandidate,
      queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE],
      staleTime: Infinity,
    });

    if (!hasCandidateCompletedPersonalityQuiz(candidate)) {
      throw redirect({ to: '/candidate/quiz/culture' });
    }
  },
});

function PersonalityQuizResult() {
  const { data: candidate, isLoading: isCandidateLoading } = useGetCurrentCandidate({});
  const { data: personalityValuesMap, isLoading: isPersonalityFactorLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        value_group: VALUES_GROUP.PERSONALITY_FACTORS,
      },
    },
  });
  const navigate = useNavigate();
  const [viewBreakDown, setViewBreakDown] = useState(false);

  if (isPersonalityFactorLoading || isCandidateLoading) return <LoadingCard />;
  const personalityCode = candidate?.personality_archetype as PersonalityArchetypeCode;

  const personality = personalityValuesMap?.items?.find((item) => item.code === candidate?.personality_archetype);

  if (!personality || !personality?.description) return null;

  const personalityDescription = parsePersonalityDescription(personality.description);
  const isOtherQuizCompleted = hasCandidateCompletedCultureQuiz(candidate);

  const personalityData = JSON.parse(candidate?.personality_test_info || '{}');
  const personalityFactors = personalityValuesMap?.items.map((item) => {
    if (!item.description || !item.code)
      return {
        code: '',
        Name: '',
        Adjectives: '',
        Facets: '',
      };
    const desc = JSON.parse(item.description?.replace(' "Adjectives"', ',  "Adjectives"')) as {
      Name: string;
      Adjectives: string;
      Facets: string;
    };
    return {
      code: item.code,
      ...desc,
    };
  });

  return (
    <>
      {!viewBreakDown ? (
        // Default view
        <div className="px-8 py-20">
          <div className="mx-auto flex max-w-[1080px] flex-col gap-12">
            <section className="flex flex-col gap-8">
              <div>
                <FeaturedIcon variant="success" />
              </div>
              <h1 className="text-2xl font-semibold leading-loose text-primary-dark-100">
                Well done on completing the Personality quiz!
              </h1>
              <p className="text-base font-medium leading-normal text-primary-dark-60">Let’s see how you went.</p>
            </section>
            <main>
              <PersonalityResultCard
                code={personalityCode}
                archetype={personalityDescription.name}
                facets={personalityDescription.facets}
                adjectives={personalityDescription.defaultAdjectives}
                onClick={() => setViewBreakDown(true)}
              />
            </main>
            <section className="flex justify-between">
              <Button
                size="lg"
                variant="tertiary"
                onClick={() => navigate({ to: '/candidate/quiz/personality' })}
              >
                Retake the quiz
              </Button>

              <Button
                size="lg"
                onClick={() => navigate({ to: isOtherQuizCompleted ? '/candidate' : '/candidate/quiz/culture' })}
              >
                {isOtherQuizCompleted ? 'Continue' : 'Next quiz'}
              </Button>
            </section>
          </div>
        </div>
      ) : (
        // Breakdown view
        <div className="mx-auto max-w-[1980px] pb-14">
          <div className="p-8">
            <Button
              size="lg"
              variant="tertiary"
              onClick={() => setViewBreakDown(false)}
            >
              <UilArrowLeft size={18} />
              Back
            </Button>
          </div>
          <main className="grid grid-cols-[2fr_4fr] justify-between gap-8 px-8 py-2">
            <div className="h-max">
              <PersonalityBreakDownCard
                code={personalityCode}
                archetype={personalityDescription.name}
                facets={personalityDescription.facets}
                adjectives={personalityDescription.defaultAdjectives}
              />
            </div>
            <Card className="flex max-h-[56rem] min-h-[84dvh] w-full shrink-0 flex-col gap-10 rounded-2xl bg-primary-white-100 px-6 py-12 shadow-2xl">
              <section className="flex flex-col gap-4">
                <h1 className="text-[32px] font-semibold not-italic leading-10 text-primary-dark-100">
                  Score breakdown
                </h1>
                <div className="flex w-max rounded-lg bg-primary-blue-5 px-2 py-1 text-base font-medium leading-normal text-primary-blue-100">
                  <UilInfoCircle
                    width={18}
                    height={18}
                    className="my-auto mr-2"
                  />
                  <span>Hover over the tags to find out more</span>
                </div>
              </section>
              {personalityFactors ? (
                <div className="flex flex-1 items-center justify-center">
                  <PersonalityChart
                    data={personalityData}
                    factors={personalityFactors}
                  />
                </div>
              ) : (
                <span>Cannot render graph. Personality factors not found</span>
              )}
            </Card>
          </main>
        </div>
      )}
    </>
  );
}
