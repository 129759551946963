import { z } from 'zod';

export const cancelMeetingSchema = z.object({
  reason: z.string({
    required_error: 'Reason for rescheduling is required',
  }),
  description: z.string().optional(),
});

export type TCancelMeetingFormData = z.infer<typeof cancelMeetingSchema>;
