import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  BUSINESS_ENDPOINTS,
  businessInviteSetup,
  inviteBusinessPartner,
  inviteBusinessTeamMember,
  removeBusinessPartner,
  removeBusinessTeamMember,
  resendInviteBusinessPartner,
  resendInviteBusinessTeamMember,
  resetBusinessTeamMemberPassword,
  TBusinessInviteSetupRequest,
  TInviteBusinessPartnerRequest,
  TInviteBusinessTeamMemberRequest,
  TRemoveBusinessPartnerRequest,
  TRemoveBusinessTeamMemberRequest,
  TResendInviteBusinessPartnerRequest,
  TResendInviteBusinessTeamMemberRequest,
  TResetBusinessTeamMemberPasswordRequest,
  TUpdateBusinessPartnerRequest,
  TUpdateBusinessTeamMembersDetailsRequest,
  TUpdateCurrentBusinessRequest,
  updateBusinessPartner,
  updateBusinessTeamMember,
  updateCurrentBusiness,
} from '@/services/business';

import { UseMutationOptions } from '../types';

export function useUpdateCurrentBusiness({ reactQueryOptions }: UseMutationOptions<TUpdateCurrentBusinessRequest>) {
  const queryClient = useQueryClient();

  return useMutation({
    ...reactQueryOptions,
    mutationFn: updateCurrentBusiness,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [BUSINESS_ENDPOINTS.GET_CURRENT_BUSINESS],
      });
    },
  });
}

// Team Members

export function useUpdateBusinessTeamMember({
  reactQueryOptions,
  params,
}: UseMutationOptions<TUpdateBusinessTeamMembersDetailsRequest>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: updateBusinessTeamMember,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [BUSINESS_ENDPOINTS.GET_BUSINESS_TEAM_MEMBERS_DETAILS, params.path.businessId, params.path.memberId],
      });
      queryClient.invalidateQueries({
        queryKey: [BUSINESS_ENDPOINTS.GET_BUSINESS_TEAM_MEMBERS, params.path.businessId],
      });
    },
  });
}

export function useInviteBusinessTeamMember({
  reactQueryOptions,
  params,
}: UseMutationOptions<TInviteBusinessTeamMemberRequest>) {
  const queryClient = useQueryClient();

  return useMutation({
    ...reactQueryOptions,
    mutationFn: inviteBusinessTeamMember,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [BUSINESS_ENDPOINTS.GET_BUSINESS_TEAM_MEMBERS, params.path.businessId],
      });
    },
  });
}

export function useResendInviteBusinessTeamMember({
  reactQueryOptions,
}: UseMutationOptions<TResendInviteBusinessTeamMemberRequest>) {
  return useMutation({
    ...reactQueryOptions,
    mutationFn: resendInviteBusinessTeamMember,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
    },
  });
}

export function useResetBusinessTeamMemberPassword({
  reactQueryOptions,
}: UseMutationOptions<TResetBusinessTeamMemberPasswordRequest>) {
  return useMutation({
    ...reactQueryOptions,
    mutationFn: resetBusinessTeamMemberPassword,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
    },
  });
}

export function useRemoveBusinessTeamMember({
  reactQueryOptions,
  params,
}: UseMutationOptions<TRemoveBusinessTeamMemberRequest>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: removeBusinessTeamMember,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [BUSINESS_ENDPOINTS.GET_BUSINESS_TEAM_MEMBERS, params.path.businessId],
      });
    },
  });
}
// Team Partners

export function useInviteBusinessPartner({
  reactQueryOptions,
  params,
}: UseMutationOptions<TInviteBusinessPartnerRequest>) {
  const queryClient = useQueryClient();

  return useMutation({
    ...reactQueryOptions,
    mutationFn: inviteBusinessPartner,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [BUSINESS_ENDPOINTS.GET_BUSINESS_PARTNERS, params.path.businessId],
      });
    },
  });
}

export function useUpdateBusinessPartner({
  reactQueryOptions,
  params,
}: UseMutationOptions<TUpdateBusinessPartnerRequest>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: updateBusinessPartner,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [BUSINESS_ENDPOINTS.GET_BUSINESS_PARTNERS_DETAILS, params.path.businessId, params.path.partnerId],
      });
      queryClient.invalidateQueries({
        queryKey: [BUSINESS_ENDPOINTS.GET_BUSINESS_PARTNERS, params.path.businessId],
      });
    },
  });
}

export function useRemoveBusinessPartner({
  reactQueryOptions,
  params,
}: UseMutationOptions<TRemoveBusinessPartnerRequest>) {
  const queryClient = useQueryClient();
  return useMutation({
    ...reactQueryOptions,
    mutationFn: removeBusinessPartner,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [BUSINESS_ENDPOINTS.GET_BUSINESS_PARTNERS, params.path.businessId],
      });
    },
  });
}

export function useResendInviteBusinessPartner({
  reactQueryOptions,
}: UseMutationOptions<TResendInviteBusinessPartnerRequest>) {
  return useMutation({
    ...reactQueryOptions,
    mutationFn: resendInviteBusinessPartner,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
    },
  });
}

// Business Invitations setup

export function useBusinessInviteSetup({ reactQueryOptions }: UseMutationOptions<TBusinessInviteSetupRequest>) {
  return useMutation({
    ...reactQueryOptions,
    mutationFn: businessInviteSetup,
  });
}
