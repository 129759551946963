import { useRef, useState } from 'react';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { UilAngleDown, UilCheck, UilTimes } from '@iconscout/react-unicons';

import { cn } from '@/lib/utils';

import { Badge } from './badge';
import { inputVariants } from './input';
import Spinner from './spinner';

interface IComboBoxProps {
  options: string[];
  selected: string[];
  onSelect: (value: string[]) => void;
  placeholder?: string;
  error?: boolean;
  className?: string;
  isLoading?: boolean;
  onQueryChange: (query: string) => void;
}

export default function ComboBoxMultipleSelect({
  selected,
  onSelect,
  options,
  className,
  error,
  onQueryChange,
  isLoading,
  placeholder,
}: IComboBoxProps) {
  const [value, setValue] = useState('');
  const isSelected = (value: string) => {
    if (!value) return false;
    return selected.includes(value.trim());
  };
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSelect = (value: string) => {
    if (!value) return;
    const isAlreadySelected = selected.includes(value);

    const newValues = isAlreadySelected ? selected.filter((v) => v !== value) : [...selected, value];
    onSelect(newValues);
    setValue('');
    removeFocusFromInput();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);
    onQueryChange(value);
  };

  const removeFocusFromInput = () => {
    if (inputRef.current) {
      setTimeout(() => inputRef.current?.blur(), 0);
    }
  };

  return (
    <div>
      <Combobox
        as="div"
        onChange={handleSelect}
        onClose={() => {
          onQueryChange('');
          removeFocusFromInput();
        }}
        immediate
      >
        <div className="relative">
          <ComboboxInput
            aria-label="Assignee"
            onChange={handleChange}
            className={cn(inputVariants({ variant: error ? 'error' : 'default' }), className)}
            placeholder={placeholder || 'Start typing here...'}
            value={value}
            ref={inputRef}
          />
          <ComboboxButton className="group peer absolute inset-y-0 right-0 top-1/2 mr-2 flex -translate-y-1/2 cursor-pointer items-center justify-center px-2.5 transition-all [&[data-open]>svg]:rotate-180">
            <UilAngleDown
              className={cn(
                'pointer-events-none size-6 transform text-primary-dark-60 transition-transform duration-200'
              )}
            />
          </ComboboxButton>
        </div>

        <ComboboxOptions
          anchor={{
            to: 'bottom',
            gap: 4,
          }}
          transition
          className="z-50 w-[var(--input-width)] rounded-lg border border-primary-dark-10 bg-white shadow-lg outline-none scrollbar data-[open]:animate-in data-[close]:animate-out data-[close]:fade-out-0 data-[open]:fade-in-0 data-[close]:zoom-out-95 data-[open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:border-slate-800 dark:bg-slate-950 dark:text-slate-50"
        >
          {isLoading ? (
            <div className="flex min-h-20 w-full items-center justify-center text-center">
              <Spinner />
            </div>
          ) : options.length ? (
            options?.map((option) => (
              <ComboboxOption
                key={option}
                value={option}
                className={cn(
                  'group flex items-center justify-between px-4 py-3 font-medium text-primary-dark-60 outline-none data-[focus]:bg-primary-dark-10 data-[selected]:bg-primary-dark-5 data-[focus]:text-primary-dark-100 data-[selected]:text-primary-dark-100',
                  isSelected(option) && 'bg-primary-dark-5 text-primary-dark-100'
                )}
              >
                <span>{option}</span>
                <span>{isSelected(option) && <UilCheck className="size-5 text-primary-blue-100" />}</span>
              </ComboboxOption>
            ))
          ) : (
            <div className="flex min-h-20 w-full items-center justify-center text-center">
              <span>No results found</span>
            </div>
          )}
        </ComboboxOptions>
      </Combobox>
      {selected?.length > 0 && (
        <div className="mt-2 flex flex-row flex-wrap gap-2">
          {selected.map((s) => (
            <Badge
              key={s}
              variant="success"
              hideDot
              onClick={() => handleSelect(s)}
              className="cursor-pointer"
            >
              <span>{s}</span>
              <UilTimes className="mt-0.5 size-3.5 text-primary-success-80" />
            </Badge>
          ))}
        </div>
      )}
    </div>
  );
}
