import client, { TRequestOptions } from '@/lib/api';

import { VALUES_MAP_ENDPOINTS } from './values-map.endpoints';
import { TAllValuesMapRequest } from './values-map.types';

const { GET_ALL_VALUE_MAPS } = VALUES_MAP_ENDPOINTS;

export const getValuesMapByGroup = async ({ signal, params }: TRequestOptions<TAllValuesMapRequest>) => {
  const { data } = await client.GET(GET_ALL_VALUE_MAPS, {
    signal,
    params,
  });

  return data?.data;
};
