import { useEffect } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';

import { inputVariants } from '@/components/ui/input';

import { cn } from '@/lib/utils';

import { extensions } from './extensions';
import EditorToolbar from './rich-text-toolbar';

interface RichTextEditorProps {
  defaultValue?: string;
  value: string;
  onChange: (value: string) => void;
  error?: boolean;
}
const RichTextEditor = ({ error, value, onChange, defaultValue }: RichTextEditorProps) => {
  const editor = useEditor({
    extensions,
    content: value,
    editorProps: {
      attributes: {
        class: cn(inputVariants({ variant: error ? 'error' : 'default' }), 'text-sm h-full block min-h-[7.5rem]'),
      },
    },
    onUpdate: ({ editor }) => {
      if (editor.state.doc.textContent.trim().length === 0) return onChange('');
      onChange(editor.getHTML());
    },
  });

  useEffect(() => {
    if (!editor) return;
    editor.commands.setContent(defaultValue || '');
  }, [defaultValue, editor]);

  if (!editor) return null;

  return (
    <div className="flex flex-col gap-2">
      <EditorContent editor={editor} />
      <EditorToolbar editor={editor} />
    </div>
  );
};

export default RichTextEditor;
