import { createFileRoute } from '@tanstack/react-router';

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';

import useAuth from '@/hooks/useAuth';

import EXTERNAL_URLS from '@/constants/external-urls';

export const Route = createFileRoute('/_authenticated/business/no-linked-business')({
  component: Error500,
});

function Error500() {
  const { logout } = useAuth();

  return (
    <div className="w-svh h-svh bg-slate-100">
      <AlertDialog defaultOpen={true}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle
              iconVariant="alert"
              showCloseButton={false}
            >
              Oops! Something Went Wrong
            </AlertDialogTitle>
            <AlertDialogDescription className="text-primary-dark-80">
              It looks like you&apos;re not linked to any business yet. Please link your business to continue.
              <br />
              <br />
              You can sign out and try again later.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <a
              href={EXTERNAL_URLS.SUPPORT_PORTAL}
              target="_blank"
              rel="noreferrer"
              className="flex cursor-pointer items-center gap-2 rounded-lg font-semibold transition-colors hover:bg-primary-dark-5"
            >
              <Button
                variant="tertiary"
                size={'lg'}
              >
                Report issue
              </Button>
            </a>

            <Button
              variant="destructive"
              onClick={logout}
              size={'lg'}
            >
              Sign out
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
