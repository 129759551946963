import React from 'react';

import Avatar from '@/components/ui/avatar';
import { Card } from '@/components/ui/card';

import { cn } from '@/lib/utils';

import RolesPlaceHolderImg from '@/assets/roles-placeholder.jpeg';

import { HamburgerIcon } from '../icons/hamburger-icon';
import LocationTag from '../tags/location-tag';
import MatchPercentLabel from '../tags/match-percent-label';
import { Tag } from '../ui/tag';

export interface IJobCardProps {
  companyName: string;
  location: string;
  companyAvatar?: string;
  role: string;
  roleAvailability: string[];
  backdrop?: string;
  blur?: boolean;
}

const JobCard: React.FC<IJobCardProps> = ({
  companyName,
  companyAvatar,
  location,
  role,
  roleAvailability,
  backdrop,
  blur,
}) => {
  return (
    <div className="relative">
      {blur && <div className="absolute z-50 h-full w-full backdrop-blur-xs"></div>}

      <Card className={cn('cursor-pointer space-y-4 p-4 shadow-md', !blur ? 'w-[490px]' : 'w-[390px]')}>
        <img
          src={backdrop || RolesPlaceHolderImg}
          alt="roles placeholder image"
          className="h-[10.313rem] w-full rounded-lg object-cover"
        />
        <div className="flex items-start justify-between py-4">
          <div className="flex items-center gap-x-4">
            <Avatar
              size={'xl'}
              fallbackType="company"
              src={companyAvatar}
            />
            <div className="flex flex-col gap-2">
              <h6 className="font-semibold">{companyName}</h6>
              <div className="flex items-center gap-2">
                <MatchPercentLabel percent={80} />
              </div>
            </div>
            <div></div>
          </div>
          <HamburgerIcon />
        </div>
        <div className="space-y-2">
          <h6 className="font-semibold">{role}</h6>
          <div className="flex items-center gap-2">
            <LocationTag location={location} />
            {roleAvailability.map((r, i) => (
              <Tag
                variant={'primary'}
                key={r + i}
              >
                {r}
              </Tag>
            ))}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default JobCard;
