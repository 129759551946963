import Fireworks from 'react-canvas-confetti/dist/presets/fireworks';
import { createPortal } from 'react-dom';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';

const OfferSentModal = ({ open, onOpenChange }: { open: boolean; onOpenChange: (open: boolean) => void }) => {
  return (
    <>
      <AlertDialog
        open={open}
        onOpenChange={onOpenChange}
      >
        {open &&
          createPortal(
            <Fireworks
              autorun={{ speed: 3, duration: 2000 }}
              className="fixed left-0 top-0 z-[99] h-full w-full"
            />,
            document.body
          )}

        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle iconVariant="success">Congratulations!</AlertDialogTitle>
            <AlertDialogDescription>
              Your offer has been sent! Make sure you check your notifications for any updates.
            </AlertDialogDescription>
          </AlertDialogHeader>

          <AlertDialogFooter>
            <AlertDialogAction>Thanks</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default OfferSentModal;
