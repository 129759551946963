import { cn } from '@/lib/utils';

interface IFalgProps {
  className?: string;
}
export const AU: React.FC<IFalgProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 28 20"
      className={cn('h-4 w-5', className)}
    >
      <rect
        width="28"
        height="20"
        fill="#fff"
        rx="2"
      ></rect>
      <mask
        id="mask0_248_76413"
        style={{ maskType: 'luminance' }}
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <rect
          width="28"
          height="20"
          fill="#fff"
          rx="2"
        ></rect>
      </mask>
      <g mask="url(#mask0_248_76413)">
        <path
          fill="#0A17A7"
          d="M0 0H28V20H0z"
        ></path>
        <path
          fill="#FF2E3B"
          stroke="#fff"
          strokeWidth="0.667"
          d="M0-.333h-.901l.684.586 4.55 3.9v1.009L-.194 8.395l-.14.1v1.44l.51-.319L5.43 6.333h1.13l4.523 3.23a.554.554 0 00.682-.87L7.667 5.18V4.172l4.387-3.134a.667.667 0 00.28-.543V-.6l-.51.318L6.57 3h-1.13L.86-.271.772-.333H0z"
        ></path>
        <path
          fill="url(#paint0_linear_248_76413)"
          fillRule="evenodd"
          d="M0 3.333V6h4.667v3.333c0 .369.298.667.666.667h1.334a.667.667 0 00.666-.667V6H12a.667.667 0 00.667-.667V4A.667.667 0 0012 3.333H7.333V0H4.667v3.333H0z"
          clipRule="evenodd"
        ></path>
        <path
          fill="url(#paint1_linear_248_76413)"
          fillRule="evenodd"
          d="M0 4h5.333V0h1.334v4H12v1.333H6.667v4H5.333v-4H0V4z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M6 16.333l-1.176.618.225-1.309-.951-.927 1.314-.19L6 13.332l.588 1.191 1.314.191-.95.927.224 1.31L6 16.332zM20 17.333l-.943.276.276-.942-.276-.943L20 16l.943-.276-.277.943.277.942-.943-.276zM20 4.667l-.943.276.276-.943-.276-.943.943.276.943-.276-.277.943.277.943L20 4.667zM24 8.667l-.943.276.276-.943-.276-.943.943.276.943-.276-.277.943.277.943L24 8.667zM16 10l-.943.276.276-.943-.276-.942.943.276.943-.276-.277.942.277.943L16 10zM22 11.667l-.471.138.138-.472-.138-.471L22 11l.472-.138-.139.471.139.472-.472-.138z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_248_76413"
          x1="0"
          x2="0"
          y1="0"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop
            offset="1"
            stopColor="#F0F0F0"
          ></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_248_76413"
          x1="0"
          x2="0"
          y1="0"
          y2="9.333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2E3B"></stop>
          <stop
            offset="1"
            stopColor="#FC0D1B"
          ></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
