import client, { TRequestOptions } from '@/lib/api';

import { GLOBAL_SEARCH_ENDPOINTS } from './global-search.endpoints';
import { TGlobalSearchRequest } from './global-search.types';

export const getGlobalSearchResults = async ({ signal, params }: TRequestOptions<TGlobalSearchRequest>) => {
  const { data } = await client.GET(GLOBAL_SEARCH_ENDPOINTS.SEARCH, {
    signal,
    params,
  });

  return data?.data;
};
