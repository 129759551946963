import * as React from 'react';
import { UilAngleDown, UilCheck } from '@iconscout/react-unicons';
import * as RPNInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';

import { Button } from '@/components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command';
import { Input, InputProps } from '@/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';

import { cn } from '@/lib/utils';

import { ScrollArea } from './scroll-area';

type PhoneInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> &
  Omit<RPNInput.Props<typeof RPNInput.default>, 'onChange'> & {
    onChange?: (value: RPNInput.Value) => void;
    error?: boolean;
  };

const PhoneInput: React.ForwardRefExoticComponent<PhoneInputProps> = React.forwardRef<
  React.ElementRef<typeof RPNInput.default>,
  PhoneInputProps
>(({ className, onChange, ...props }, ref) => {
  return (
    <RPNInput.default
      ref={ref}
      className={cn('relative', className)}
      flagComponent={FlagComponent}
      countrySelectComponent={CountrySelect}
      inputComponent={InputComponent}
      placeholder="Enter your phone number"
      defaultCountry="AU"
      international
      /**
       * Handles the onChange event.
       *
       * react-phone-number-input might trigger the onChange event as undefined
       * when a valid phone number is not entered. To prevent this,
       * the value is coerced to an empty string.
       *
       * @param {E164Number | undefined} value - The entered value
       */
      onChange={(value) => value && onChange?.(value)}
      {...props}
    />
  );
});
PhoneInput.displayName = 'PhoneInput';

const InputComponent = React.forwardRef<HTMLInputElement, InputProps>(({ className, ...props }, ref) => (
  <Input
    className={cn('pl-[90px]', className)}
    {...props}
    ref={ref}
  />
));
InputComponent.displayName = 'InputComponent';

type CountrySelectOption = { label: string; value: RPNInput.Country };

type CountrySelectProps = {
  disabled?: boolean;
  value: RPNInput.Country;
  onChange: (value: RPNInput.Country) => void;
  options: CountrySelectOption[];
};

const CountrySelect = ({ disabled, value, onChange, options }: CountrySelectProps) => {
  const handleSelect = React.useCallback(
    (country: RPNInput.Country) => {
      onChange(country);
    },
    [onChange]
  );

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          type="button"
          variant={'tertiary'}
          className={cn(
            'absolute left-1 top-1 z-10 flex h-10 gap-2 rounded-e-none rounded-s-lg border-none bg-transparent px-3 shadow-none',
            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
          )}
          disabled={disabled}
        >
          <FlagComponent
            country={value}
            countryName={value}
          />
          <UilAngleDown className={cn('-mr-2 h-6 w-6 text-primary-dark-60', disabled ? 'hidden' : 'opacity-100')} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-0">
        <Command className="">
          <CommandList>
            <ScrollArea className="h-72">
              <CommandInput
                placeholder="Search country..."
                className="p-2 pb-0.5"
              />
              <CommandEmpty>No country found.</CommandEmpty>
              <CommandGroup>
                {options
                  .filter((x) => x.value)
                  .map((option) => (
                    <CommandItem
                      className="gap-2"
                      key={option.value}
                      onSelect={() => handleSelect(option.value)}
                    >
                      <FlagComponent
                        country={option.value}
                        countryName={option.label}
                        showFlag
                      />
                      <span className="flex-1 text-sm">{option.label}</span>
                      {option.value && (
                        <span className="text-foreground/50 text-sm">
                          {`+${RPNInput.getCountryCallingCode(option.value)}`}
                        </span>
                      )}
                      <UilCheck
                        className={cn(
                          'ml-auto size-5 text-primary-blue-100',
                          option.value === value ? 'opacity-100' : 'opacity-0'
                        )}
                      />
                    </CommandItem>
                  ))}
              </CommandGroup>
            </ScrollArea>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
interface FlagComponentProps extends RPNInput.FlagProps {
  showFlag?: boolean;
}

const FlagComponent = ({ country, countryName, showFlag }: FlagComponentProps) => {
  const Flag = flags[country];

  return (
    <span className={cn('bg-foreground/20 flex min-w-6 overflow-hidden rounded-sm text-base', showFlag && 'h-4 w-6')}>
      {showFlag ? Flag && <Flag title={countryName} /> : countryName}
    </span>
  );
};
FlagComponent.displayName = 'FlagComponent';

export { PhoneInput };
