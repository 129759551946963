import { useState } from 'react';
import { UilSuitcase, UilTrashAlt } from '@iconscout/react-unicons';

import { Button } from '@/components/ui/button';

import { TBusiness } from '@/services/business/business.types';
import { TCandidateForPipeline } from '@/services/pipeline';

import { ROLE_CANDIDATE_STATUS } from '@/utils/application-status';

import NextStepModal from './next-step-modal';
import OfferSentModal from './offer-sent-modal';
import RejectCandidateModal from './reject-candidate-modal';

const SidePanelCta = ({ candidate, business }: { candidate: TCandidateForPipeline; business: TBusiness }) => {
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openNextStepModal, setOpenNextStepModal] = useState(false);
  const [openOfferSentModal, setOpenOfferSentModal] = useState(false);

  return (
    <>
      {candidate.metadata?.candidate_status &&
        candidate.metadata?.candidate_status !== ROLE_CANDIDATE_STATUS.OFFER &&
        candidate.metadata?.candidate_status !== ROLE_CANDIDATE_STATUS.HIRED && (
          <div className="flex flex-col justify-center gap-4">
            <Button
              className="flex-grow"
              onClick={() => setOpenNextStepModal(true)}
            >
              <UilSuitcase className="size-[1.25rem]" /> Next Steps
            </Button>

            <Button
              variant="secondary-destructive"
              className="flex-grow"
              onClick={() => setOpenRejectModal(true)}
            >
              <UilTrashAlt className="size-[1.25rem]" /> Reject
            </Button>
          </div>
        )}
      <RejectCandidateModal
        open={openRejectModal}
        onOpenChange={setOpenRejectModal}
        // candidateId={candidateId}
        // postId={data[0].application_meta!.post_id!}
      />
      <NextStepModal
        candidate={candidate}
        business={business}
        originalCandidateStatus={candidate.metadata?.candidate_status}
        open={openNextStepModal}
        onOpenChange={setOpenNextStepModal}
        onInterviewSuccess={() => {
          setOpenNextStepModal(false);
        }}
        onOfferSuccess={() => {
          setOpenNextStepModal(false);
          setTimeout(() => {
            setOpenOfferSentModal(true);
          }, 200);
        }}
        onInterviewCancel={() => {
          setOpenNextStepModal(false);
        }}
        onOfferCancel={() => {
          setOpenNextStepModal(false);
        }}
      />
      <OfferSentModal
        open={openOfferSentModal}
        onOpenChange={setOpenOfferSentModal}
      />
    </>
  );
};

export default SidePanelCta;
