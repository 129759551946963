import { memo, useEffect } from 'react';
import { useNotificationPanelStore } from '@/store/notification-panel-store';
import { UilBell, UilCalendarAlt, UilSuitcaseAlt } from '@iconscout/react-unicons';
import { createFileRoute, Outlet, redirect, useLocation } from '@tanstack/react-router';

import Separator from '@/components/ui/separator';

import RolesSearch from '@/components/dashboard/candidate/roles-search';
import { UilHomeAlt } from '@/components/icons/UilHomeAlt';
import NavLink, { INavLinkProps } from '@/components/navigation/nav-link';
import ProfileNavLink from '@/components/navigation/profile-nav-link';
import SideBarContainer from '@/components/navigation/sidebar-container';
import SupportNavLink from '@/components/navigation/support-nav-link';
import { Notification, NotificationCount } from '@/components/notification/notification-panel';

import {
  CANDIDATE_ENDPOINTS,
  getCandidateOnbaordingStatus,
  getCurrentCandidate,
  hasCandidateCompletedCultureQuiz,
  hasCandidateCompletedPersonalityQuiz,
} from '@/services/candidate';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard')({
  component: memo(CandidateDashboardLayout), // CandidateDashboardLayout,
  beforeLoad: async ({ context }) => {
    const queryClient = context.queryClient;

    const candidate = await queryClient.fetchQuery({
      queryFn: getCurrentCandidate,
      queryKey: [CANDIDATE_ENDPOINTS.GET_CURRENT_CANDIDATE],
      staleTime: Infinity,
    });

    const onboardingStatus = getCandidateOnbaordingStatus(candidate);

    if (onboardingStatus === 0) {
      throw redirect({ to: '/candidate/onboarding' });
    }

    if (onboardingStatus < 9) {
      throw redirect({ to: `/candidate/onboarding/step${onboardingStatus}` });
    }

    if (!hasCandidateCompletedCultureQuiz(candidate) || !hasCandidateCompletedPersonalityQuiz(candidate)) {
      throw redirect({ to: '/candidate/quiz' });
    }
  },
});

const navItems: INavLinkProps[] = [
  {
    icon: <UilHomeAlt className="default-icon" />,
    href: '/candidate',
    label: 'Home',
  },
  {
    icon: <UilBell className="default-icon" />,
    label: 'Notifications',
    onClick: () => {},
  },
  {
    icon: <UilSuitcaseAlt className="default-icon" />,
    href: '/candidate/roles',
    label: 'Roles',
    exact: false,
  },
  {
    icon: <UilCalendarAlt className="default-icon" />,
    href: '/candidate/calendar',
    label: 'Calendar',
  },
];

function CandidateDashboardLayout() {
  const location = useLocation();
  const { showNotifications, setShowNotifications } = useNotificationPanelStore();

  useEffect(() => {
    setShowNotifications(false);
  }, [location.pathname, setShowNotifications]);

  return (
    <main className="flex">
      <SideBarContainer>
        <div className="flex h-full flex-col justify-between">
          <div className="space-y-8">
            <RolesSearch />
            <nav className="flex flex-col gap-2">
              {navItems.map((item) => (
                <NavLink
                  key={item.label}
                  {...item}
                  onClick={item.onClick ? () => setShowNotifications(!showNotifications) : undefined}
                  active={item.onClick && showNotifications}
                />
              ))}
            </nav>
          </div>
          <div>
            <SupportNavLink />
            <Separator size="sm" />
            <ProfileNavLink />
          </div>
        </div>
      </SideBarContainer>
      <Notification />
      <NotificationCount onClick={() => setShowNotifications(!showNotifications)} />
      {/* Add id and position:relative here for the children to be able to open portals */}
      <div
        className="relative flex-1 justify-center p-8 pb-20"
        id="dashboard-container"
      >
        <Outlet />
      </div>
    </main>
  );
}
