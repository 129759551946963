import { paths } from '@/lib/api/v1';

export const NOTIFICATION_ENDPOINTS = {
  GET_USER_NOTIFICATIONS: '/v2/notifications/me',
  MARK_ALL_NOTIFICATIONS_AS_READ: '/v2/notifications/mark-all-as-read',
  MARK_NOTIFICATION_AS_READ: '/v2/notifications/{notificationId}/mark-as-read',
  MARK_NOTIFICATION_AS_UNREAD: '/v2/notifications/{notificationId}/mark-as-unread',
} satisfies Record<string, keyof paths>;

export const NOTIFICATION_SETTINGS_ENDPOINTS = {
  GET_USER_NOTIFICATION_SETTINGS: '/v2/notifications/settings/me',
  DISABLE_USER_NOTIFICATION_SETTING: '/v2/notifications/settings/disable',
  ENABLE_USER_NOTIFICATION_SETTING: '/v2/notifications/settings/enable',
  UPDATE_USER_NOTIFICATION_PLATFORM: '/v2/notifications/settings/platform',
} satisfies Record<string, keyof paths>;
