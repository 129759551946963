import { createFileRoute } from '@tanstack/react-router';

import { Card } from '@/components/ui/card';
import Heading from '@/components/ui/typography/heading';

import ChangePasswordForm from '@/components/forms/change-password-form';
import SettingContainer from '@/components/settings/setting-container';

export const Route = createFileRoute('/_authenticated/partner/_dashboard/settings/password')({
  component: ChangePasswordCard,
});

function ChangePasswordCard() {
  return (
    <SettingContainer>
      <Heading
        title="Personal details"
        subtitle="Update your personal details and profile photo."
      />
      <Card>
        <ChangePasswordForm />
      </Card>
    </SettingContainer>
  );
}
