import { createFileRoute } from '@tanstack/react-router';

import { Card } from '@/components/ui/card';
import Heading from '@/components/ui/typography/heading';

import BusinessProfileDetailsForm from '@/components/forms/business/business-details-form';
import SettingContainer from '@/components/settings/setting-container';

import { useGetCurrentUserPermissions } from '@/hooks/permission';

export const Route = createFileRoute('/_authenticated/business/_dashboard/settings/company/details')({
  component: CompanyDetailsCard,
});

function CompanyDetailsCard() {
  const { data: permissions } = useGetCurrentUserPermissions();

  return (
    <SettingContainer>
      <Heading
        title="Business details"
        subtitle="Update your business type details."
      />
      <Card>
        <BusinessProfileDetailsForm isDisabled={Boolean(!permissions?.manage_company_settings)} />
      </Card>
    </SettingContainer>
  );
}
