import { EditorContent, useEditor } from '@tiptap/react';

import { cn } from '@/lib/utils';

import { extensions } from './extensions';

interface ViewerProps {
  content: string;
  className?: string;
}

const RichTextViewer = ({ content, className }: ViewerProps) => {
  const editor = useEditor({
    extensions,
    content,
    editable: false,
  });

  if (!editor) return null;

  return (
    <article className={cn('', className)}>
      <EditorContent
        editor={editor}
        readOnly={true}
      />
    </article>
  );
};

export default RichTextViewer;
