import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useParams } from '@tanstack/react-router';
import Fireworks from 'react-canvas-confetti/dist/presets/fireworks';
import { createPortal } from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { PasswordInput } from '@/components/ui/password-input';

import { Logo } from '@/components/logo';

import { useResetPassword } from '@/hooks/auth';

import { createPasswordSchema, TCreatePasswordFormData } from '@/validation-schemas/invitation-password-schema';

export const ResetPasswordForm = ({ onSuccess }: { onSuccess?: () => void }) => {
  const { token } = useParams({ from: '/reset-password/$token' });
  const [isSuccess, setIsSuccess] = useState(false);
  const { mutate: resetPassword, isPending } = useResetPassword({
    params: { path: { token } },
    reactQueryOptions: {
      onSuccess: () => {
        setIsSuccess(true);
      },
    },
  });

  const form = useForm<TCreatePasswordFormData>({
    resolver: zodResolver(createPasswordSchema),
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
    mode: 'onChange',
  });

  const onSubmit = (data: TCreatePasswordFormData) => {
    resetPassword({
      params: { path: { token } },
      body: {
        password: data.newPassword,
      },
    });
  };

  return (
    <FormProvider {...form}>
      <Form
        className="space-y-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="w-full pb-8">
          <Logo
            variant="full"
            className="mx-auto"
          />
        </div>
        <div className="flex w-full flex-col gap-4 py-4 text-center">
          <span className="text-2xl font-semibold leading-loose text-primary-dark-100">Reset your password</span>
          <span className="text-base font-medium leading-normal text-primary-dark-60">
            Enter your new password below.
          </span>
        </div>
        <FormField
          control={form.control}
          name="newPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel>New password</FormLabel>
              <FormControl>
                <PasswordInput
                  {...field}
                  placeholder="Enter new password"
                  error={!!form.formState.errors?.newPassword}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="confirmPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Confirm password</FormLabel>
              <FormControl>
                <PasswordInput
                  {...field}
                  placeholder="Re-enter new password"
                  error={!!form.formState.errors?.confirmPassword}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <Button
          className="w-full"
          type="submit"
          size={'sm'}
          loading={isPending}
          loadingText="Resetting password"
        >
          Reset Password
        </Button>
      </Form>
      <AlertDialog open={isSuccess}>
        <AlertDialogContent className="gap-6">
          {createPortal(
            <Fireworks
              autorun={{ speed: 3, duration: 900 }}
              className="fixed left-0 top-0 z-[99] h-full w-full"
            />,
            document.body
          )}
          <AlertDialogHeader>
            <AlertDialogTitle
              showCloseButton={false}
              iconVariant="success"
            >
              <span>Password Reset Successful!</span>
            </AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogDescription>
            You have successfully reset your password.
            <br />
            <br />
            You can now log in with your new password.
          </AlertDialogDescription>
          <AlertDialogFooter>
            <AlertDialogAction onClick={onSuccess}>Continue</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </FormProvider>
  );
};
