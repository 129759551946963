import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { INTERVIEW_PLATFORM_ID, PAY_TYPE } from '@/constants/post';

import {
  TFinalFormData,
  TStep1FormData,
  TStep2FormData,
  TStep3FormData,
  TStep4FormData,
  TStep5FormData,
  TStep6FormData,
  TStep7FormData,
} from '@/validation-schemas/business/create-role-schema';

type TCreateRoleStore = {
  data: TFinalFormData;
  setStepData: <
    T extends
      | TStep1FormData
      | TStep2FormData
      | TStep3FormData
      | TStep4FormData
      | TStep5FormData
      | TStep6FormData
      | TStep7FormData,
  >(
    data: T
  ) => void;
  setFinalData: (data: TFinalFormData) => void;
  resetData: () => void;
};

const initialData: TFinalFormData = {
  referenceNumber: '',
  coverImageUrl: '',
  roleTitle: '',
  roleType: '',
  hiringMultiple: false,
  description: '',
  location: '',
  benefits: [],
  employmentType: '',
  workplaceType: '',
  payType: PAY_TYPE.DAY_RATE,
  amountFrom: null,
  amountTo: null,
  payTypeCurrency: 'AUD',
  startDate: null,
  endDate: null,
  domainExperience: [],
  technicalSkills: [],
  softSkills: [],
  roleOwner: {
    id: '',
    fullName: '',
    imageUrl: '',
  },
  members: [],
  interviewPlatform: INTERVIEW_PLATFORM_ID.GOOGLE_MEET,
  // interviewTemplate: '',
  hasPreScreenInterview: false,
  requestReferences: false,
  stages: [
    {
      name: '',
      interviewType: '',
      duration: null,
    },
  ],
  partners: [],
  personalityTraits: [],
  cultureFactors: [],
};

export const useCreateRoleStore = create<TCreateRoleStore>()(
  persist(
    (set) => ({
      data: initialData,
      setStepData: <
        T extends
          | TStep1FormData
          | TStep2FormData
          | TStep3FormData
          | TStep4FormData
          | TStep5FormData
          | TStep6FormData
          | TStep7FormData,
      >(
        data: T
      ) => {
        set((state) => ({ ...state, data: { ...state.data, ...data } }));
      },
      setFinalData: (data: TFinalFormData) => set({ data }),
      resetData: () => set({ data: initialData }),
    }),
    {
      name: 'create-role-store',
    }
  )
);
