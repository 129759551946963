import { createFileRoute } from '@tanstack/react-router';

import EducationForm from '@/components/forms/candidate/education-form';

import useStepper from '@/hooks/useStepper';

export const Route = createFileRoute('/_authenticated/candidate/onboarding/_steps/step5')({
  component: Step5,
});

function Step5() {
  const { goToNextStep } = useStepper();

  return (
    <EducationForm
      onSuccess={goToNextStep}
      isOnboarding
    />
  );
}
