import { createFileRoute, redirect } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

const loginSearchSchema = z.object({
  redirect: z.string().optional(),
});

export const Route = createFileRoute('/login')({
  component: Login,
  validateSearch: zodSearchValidator(loginSearchSchema),
  beforeLoad: ({ context, search }) => {
    const { isAuthenticated, loginWithRedirect } = context.auth;

    if (isAuthenticated) {
      throw redirect({ to: search?.redirect || '/' });
    } else {
      throw loginWithRedirect({
        //return to original intended route after login
        appState: { returnTo: window.location.href },
        authorizationParams: {
          screen_hint: 'login',
        },
      });
    }
  },
});

function Login() {
  return <div>Hello /login!</div>;
}
