import Avatar, { AvatarProps } from '@/components/ui/avatar';

import LocationTag from '@/components/tags/location-tag';

import { cn } from '@/lib/utils';

interface IProps {
  src?: string | null;
  name?: string | null;
  location?: string | null;
  id?: string;
  avatarSize?: AvatarProps['size'];
  className?: string;
}

const BusinessAvatarWithLabel: React.FC<IProps> = ({ src, name, location, avatarSize = 'xl', className }) => {
  return (
    <div className={cn('flex items-center gap-2', className)}>
      <Avatar
        fallbackType="company"
        src={src}
        size={avatarSize}
      />
      <div className="space-y-1">
        <p className="font-semibold">{name}</p>
        {location && <LocationTag location={location} />}
      </div>
    </div>
  );
};

export default BusinessAvatarWithLabel;
