import { memo, Suspense } from 'react';
import { createFileRoute, Outlet } from '@tanstack/react-router';

import FormLoader from '@/components/forms/form-loader';
import OnboardingHeader from '@/components/onboarding/header';

export const Route = createFileRoute('/_authenticated/partner/onboarding/_steps')({
  component: memo(OnboardingStepsLayout),
});

function OnboardingStepsLayout() {
  return (
    <section
      className="relative min-h-screen pb-[120px]"
      id="steps-container"
    >
      <div className="mx-auto flex w-full max-w-[880px] flex-col">
        <OnboardingHeader domain="partner" />
        <main className="h-full w-full flex-grow p-8">
          <Suspense fallback={<FormLoader />}>
            <Outlet />
          </Suspense>
        </main>
      </div>
    </section>
  );
}
