import React, { useState } from 'react';
import { DndContext, DragEndEvent, pointerWithin } from '@dnd-kit/core';
import { createFileRoute, useNavigate } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';

import { UilDragAlt } from '@/components/icons/UilDragAlt';
import { Draggable } from '@/components/quiz/culture/draggable';
import { Droppable } from '@/components/quiz/culture/droppable';
import LoadingCard from '@/components/quiz/loading';

import { useUpdateCurrentCandidate } from '@/hooks/candidate';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { CultureName } from '@/services/candidate';

import { parseCultureDescription, prepareCultureQuizData } from '@/utils/string';

import { cn } from '@/lib/utils';

import { CULTURE_TO_EMOJI_MAP } from '@/constants/candidate';
import { CULTURE_QUIZ_OPTIONS } from '@/constants/quiz';
import { VALUES_GROUP } from '@/constants/values-map';

export const Route = createFileRoute('/_authenticated/candidate/quiz/culture/')({
  component: CultureQuiz,
});

const CultureCard: React.FC<{ name: CultureName; description: string }> = ({ name, description }) => {
  return (
    <Draggable id={name}>
      <Card className="w-full shrink-0 border-none px-8 py-4">
        <CardContent className="h-full text-xl font-semibold text-primary-dark-100 2xl:text-2xl">
          <div className="inline-flex h-full w-full items-center justify-between">
            <span>
              {CULTURE_TO_EMOJI_MAP[name]} {description}
            </span>
            <span>
              <UilDragAlt className="h-full w-8 text-primary-dark-60" />
            </span>
          </div>
        </CardContent>
      </Card>
    </Draggable>
  );
};

const Dropzone: React.FC<{ rank: number; children?: React.ReactNode; isInitial?: boolean }> = ({
  rank,
  children,
  isInitial = false,
}) => {
  const hasChildren = React.Children.count(children) > 0;
  return (
    <Droppable id={`droppable${rank}`}>
      <div
        className={cn(
          'flex h-full w-full items-center rounded-lg border-2 border-primary-dark-10 bg-primary-white-100',
          !hasChildren && !isInitial && 'border-dashed border-primary-blue-60',
          !hasChildren && isInitial && 'border-dashed border-primary-dark-10'
        )}
      >
        {!hasChildren ? (
          <Card className="flex h-full w-full items-center justify-center px-8 py-4">
            <CardContent className="text-center text-xl font-semibold text-primary-blue-100 2xl:text-2xl">
              {!isInitial ? rank : null}
            </CardContent>
          </Card>
        ) : (
          children
        )}
      </div>
    </Droppable>
  );
};

function CultureQuiz() {
  const [zones, setZones] = useState<{
    initial: CultureName[];
    ranks: (CultureName | null)[];
  }>({
    initial: CULTURE_QUIZ_OPTIONS.map((option) => option.name),
    ranks: [null, null, null, null],
  });

  const { data: cultureValueMap, isLoading: isCultureValueMapLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        value_group: VALUES_GROUP.CULTURE_FACTORS,
      },
    },
  });
  const navigate = useNavigate();
  const { mutateAsync: updateCandidate, isPending: isUpdateCandidatePending } = useUpdateCurrentCandidate({
    reactQueryOptions: {
      onSuccess: () => navigate({ to: '/candidate/quiz/culture/result' }),
    },
  });

  const parsedCultureValueMap = cultureValueMap?.items?.map((item) =>
    parseCultureDescription(item.description as string)
  );

  if (isCultureValueMapLoading) return <LoadingCard />;

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (!over) return;

    const activeId = active.id as CultureName;
    const overId = over.id as string;

    setZones((prev) => {
      const newZones = { ...prev };

      // Find the original zone of the active item
      let originalZone = 'initial';

      newZones.ranks.forEach((item, index) => {
        if (item === activeId) {
          originalZone = `droppable${index + 1}`;
        }
      });
      if (overId === originalZone) return newZones;

      // Remove the item from its original zone
      newZones.initial = newZones.initial.filter((item) => item !== activeId);
      newZones.ranks = newZones.ranks.map((item) => (item === activeId ? null : item));

      // Add the item to the new zone
      if (overId === 'initial') {
        newZones.initial.push(activeId as CultureName);
      } else {
        const rankIndex = parseInt(overId.replace('droppable', '')) - 1;
        if (newZones.ranks[rankIndex] === null) {
          newZones.ranks[rankIndex] = activeId;
        } else {
          // If the target drop zone is not empty, revert to original zone
          if (originalZone === 'initial') {
            newZones.initial.push(activeId as CultureName);
          } else {
            const originalIndex = parseInt(originalZone.replace('droppable', '')) - 1;
            newZones.ranks[originalIndex] = activeId;
          }
        }
      }

      return newZones;
    });
  }

  const canSubmit = () => {
    return !zones.ranks.every((rank) => rank !== null);
  };

  const handleSubmit = () => {
    const data = zones.ranks.map((name) => CULTURE_QUIZ_OPTIONS.find((o) => o.name === name));
    const length = data.length;
    const details = data.map((option, index) => {
      const culture = parsedCultureValueMap?.find((detail) => detail.name === option?.name);
      return {
        name: option?.name as CultureName,
        measures: culture?.measures || '',
        rank: index + 1,
        score: length - index,
      };
    });
    const parsed = prepareCultureQuizData(details);
    updateCandidate({ body: { culture: parsed } });
  };

  return (
    <div className="px-8">
      <section className="mx-auto flex max-w-[67.5rem] flex-col gap-6 py-8 2xl:gap-8 2xl:py-12 3xl:py-16">
        <h1 className="text-xl font-semibold leading-tight text-primary-dark-100 2xl:text-2xl 2xl:leading-normal 3xl:leading-loose">
          Considering the type of workplace where you will thrive, please rank the following workplace cultures from
          most to least suitable for you.
        </h1>
        <p className="text-base font-medium leading-normal text-primary-dark-60">
          Please click and hold to move the cards into your preferred order.
        </p>
        <div>
          <Button
            size="lg"
            onClick={handleSubmit}
            disabled={canSubmit()}
            loading={isUpdateCandidatePending}
            className="min-w-32"
          >
            Submit
          </Button>
        </div>
      </section>
      <main className="mx-auto flex max-w-[100rem] justify-between gap-4 pb-4 2xl:gap-6 2xl:py-6 3xl:gap-8 3xl:py-8">
        <DndContext
          onDragEnd={handleDragEnd}
          collisionDetection={pointerWithin}
        >
          <div className="flex w-[52rem] grow flex-col gap-4 2xl:gap-6 3xl:gap-8">
            <Droppable
              id="initial"
              className="grid grid-rows-4 gap-4 2xl:gap-6 3xl:gap-8"
            >
              {Array.from({ length: CULTURE_QUIZ_OPTIONS.length }, (_, index) => (
                <Dropzone
                  key={index}
                  rank={index + 1}
                  isInitial
                >
                  {zones.initial[index] ? (
                    <CultureCard
                      key={zones.initial[index]}
                      name={zones.initial[index] as CultureName}
                      description={
                        CULTURE_QUIZ_OPTIONS.find((option) => option.name === zones.initial[index])?.description ?? ''
                      }
                    />
                  ) : null}
                </Dropzone>
              ))}
            </Droppable>
          </div>
          <div className="grid w-[52rem] grid-rows-4 gap-4 2xl:gap-6 3xl:gap-8">
            {Array.from({ length: CULTURE_QUIZ_OPTIONS.length }, (_, index) => (
              <Dropzone
                key={index}
                rank={index + 1}
              >
                {zones.ranks[index] ? (
                  <CultureCard
                    key={zones.ranks[index]}
                    name={zones.ranks[index] as CultureName}
                    description={
                      CULTURE_QUIZ_OPTIONS.find((option) => option.name === zones.ranks[index])?.description ?? ''
                    }
                  />
                ) : null}
              </Dropzone>
            ))}
          </div>
        </DndContext>
      </main>
    </div>
  );
}
