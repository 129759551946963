import { useFormContext } from 'react-hook-form';

import { Checkbox } from '@/components/ui/checkbox';
import { FormControl, FormError, FormField, FormItem, FormLabel } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Select } from '@/components/ui/select-new';
import Separator from '@/components/ui/separator';
import Heading from '@/components/ui/typography/heading';

import { CoverImageUploader } from '@/components/cover-image-uploader';
import RichTextEditor from '@/components/rich-text/rich-text-editor';
import SettingContainer from '@/components/settings/setting-container';

import { useGetCurrentBusinessSuspense } from '@/hooks/business';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { getOptionsForSelectFromValuesMap } from '@/utils/string';

import { VALUES_GROUP } from '@/constants/values-map';

import { TStep1FormData } from '@/validation-schemas/business/create-role-schema';

const Step1Fields = () => {
  const { data: currentBusiness } = useGetCurrentBusinessSuspense({});
  const { data: roleTypeData, isLoading: isRolesLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        value_group: VALUES_GROUP.ROLES,
      },
    },
  });

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<TStep1FormData>();

  const currentFile = watch('coverImageUrl');

  const handleFileUpload = (url: string) => {
    setValue('coverImageUrl', url, { shouldDirty: true });
  };
  return (
    <>
      <SettingContainer>
        <Heading
          title="Reference number"
          subtitle="Add your Work Order, Purchase Order or Job ID."
        />
        <FormField
          control={control}
          name="referenceNumber"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Reference Number</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="Enter reference number"
                  error={!!errors?.referenceNumber?.message}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </SettingContainer>
      <Separator />
      <SettingContainer>
        <Heading
          title="Cover image"
          subtitle="Show candidates what it’s like to work in your organisation."
        />
        <FormField
          control={control}
          name="coverImageUrl"
          render={() => (
            <FormItem>
              <FormControl>
                <CoverImageUploader
                  keyPrefix={`business/${currentBusiness?.id}/`}
                  onFileUpload={handleFileUpload}
                  currentFile={currentFile}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </SettingContainer>
      <Separator />
      <SettingContainer>
        <Heading
          title="Role title"
          subtitle="Add your role title or select a title from the dropdown."
        />
        <FormField
          control={control}
          name="roleTitle"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Role Title</FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="Enter role title"
                  error={!!errors?.roleTitle?.message}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </SettingContainer>
      <Separator />
      <SettingContainer>
        <Heading
          title="Role family"
          subtitle="Select one or multiple options for this role."
        />
        <FormField
          name="roleType"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Role family</FormLabel>
              <FormControl>
                <Select
                  selected={field.value}
                  options={getOptionsForSelectFromValuesMap(roleTypeData)}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.roleType}
                  isLoading={isRolesLoading}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </SettingContainer>
      <Separator />
      <SettingContainer>
        <Heading
          title="About the role"
          subtitle="Describe the role or project you are hiring for in detail."
        />
        <FormField
          control={control}
          name="description"
          render={({ field, formState }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <RichTextEditor
                  value={field.value}
                  onChange={field.onChange}
                  error={!!errors?.description?.message}
                  defaultValue={formState.defaultValues?.description}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </SettingContainer>
      <Separator />
      <SettingContainer>
        <Heading
          title="Hiring multiple candidates?"
          subtitle="This will be displayed on the role for candidates to see."
        />
        <FormField
          control={control}
          name="hiringMultiple"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div className="flex h-[3.75rem] items-center rounded-lg border border-primary-dark-20 px-4">
                  <Checkbox
                    id="hiringMultiple"
                    checked={!!field.value}
                    onCheckedChange={field.onChange}
                    label="Yes, I am hiring multiple candidates for this role"
                  />
                </div>
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </SettingContainer>
      <Separator />
    </>
  );
};

export default Step1Fields;
