import { UilCalendarAlt, UilSuitcaseAlt, UilUsdCircle } from '@iconscout/react-unicons';
import { createFileRoute } from '@tanstack/react-router';
import { formatDate } from 'date-fns';

import { Card } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { Tag } from '@/components/ui/tag';

import BusinessAvatarWithLabel from '@/components/business-avatar-with-label';
import NoData from '@/components/no-data';
import OfferStatusTag from '@/components/tags/offer-status-tag';

import { useGetCurrentCandidateOffers } from '@/hooks/candidate';

import { TCurrentCandidateOffer } from '@/services/candidate/candidate.types';

import { formatCurrency } from '@/utils/currency';

import { PAY_TYPE } from '@/constants/post';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/(roles-pages)/roles/offers')({
  component: Offers,
});

function Offers() {
  const { data, isPending } = useGetCurrentCandidateOffers({
    params: {
      query: {
        limit: '50',
        offset: '0',
      },
    },
    reactQueryOptions: {
      queryKey: ['offers'],
    },
  });

  if (data?.items.length === 0) {
    return (
      <NoData
        title="You haven’t received any offers yet"
        description={
          'You haven’t received any offers for any roles yet.\nKeep applying, one is right around the corner!'
        }
      />
    );
  }

  const getOfferedSalary = (role: TCurrentCandidateOffer) => {
    if (!role.job_post.pay_type || !role.pay_currency) {
      return '-';
    }

    if (role.job_post.pay_type === PAY_TYPE.HOURLY_RATE && role.pay_hourly_rate) {
      return `${formatCurrency(role.pay_hourly_rate, role.pay_currency)}/hour`;
    }

    if (role.job_post.pay_type === PAY_TYPE.ANNUAL_SALARY && role.pay_annual_rate) {
      return `${formatCurrency(role.pay_annual_rate, role.pay_currency)}/year`;
    }

    if (role.job_post.pay_type === PAY_TYPE.DAY_RATE && role.pay_day_rate) {
      return `${formatCurrency(role.pay_day_rate, role.pay_currency)}/day`;
    }

    return '-';
  };

  if (isPending)
    return (
      <div className="space-y-6">
        {new Array(3).fill(null).map((_, index) => (
          <Skeleton
            key={index}
            className="h-[14.09375rem] 3xl:h-[12rem]"
          />
        ))}
      </div>
    );

  return (
    <div className="space-y-4">
      {data?.items?.map((role) => (
        <Card
          key={role.id}
          className="flex flex-col gap-y-6 rounded-2xl px-8 py-6"
        >
          <div className="inline-flex justify-between">
            <BusinessAvatarWithLabel
              src={role?.business.logo_url}
              name={role?.business?.name}
              location={role?.job_post?.city || role?.job_post?.location}
              avatarSize={'xl'}
            />
            <span>
              <OfferStatusTag status={role.status} />
            </span>
          </div>
          <div className="grid grid-cols-[2fr_3fr_1fr] gap-10">
            <div className="space-y-2">
              <div className="inline-flex items-center gap-2">
                <UilSuitcaseAlt className="!text-primary-dark-40 default-icon-sm" />
                <span className="font-semibold text-primary-dark-40">Role</span>
              </div>
              <div className="flex flex-wrap items-center gap-2">
                <span className="font-semibold">{role.job_post?.title}</span>
                {role.job_post.workplace_type && <Tag variant={'primary'}>{role.job_post.workplace_type}</Tag>}
                {role.job_post.employment_type && <Tag variant={'primary'}>{role.job_post.employment_type}</Tag>}
              </div>
            </div>
            <div className="flex justify-between gap-4">
              <div className="flex flex-col space-y-2">
                <div className="inline-flex items-center gap-2">
                  <UilUsdCircle className="!text-primary-dark-40 default-icon-sm" />
                  <span className="font-semibold text-primary-dark-40">Rate({role.pay_currency || 'AUD'})</span>
                </div>
                <span className="font-semibold">{getOfferedSalary(role)}</span>
              </div>
              <div className="flex flex-col space-y-2">
                <div className="inline-flex items-center gap-2">
                  <UilCalendarAlt className="!text-primary-dark-40 default-icon-sm" />
                  <span className="font-semibold text-primary-dark-40">Start Date</span>
                </div>
                <span className="font-semibold">
                  {role.start_date ? formatDate(role.start_date, 'dd MMM, yyyy') : '-'}
                </span>
              </div>
              <div className="flex flex-col space-y-2">
                <div className="inline-flex items-center gap-2">
                  <UilCalendarAlt className="!text-primary-dark-40 default-icon-sm" />
                  <span className="font-semibold text-primary-dark-40">Est. End date</span>
                </div>
                <span className="font-semibold">{role.end_date ? formatDate(role.end_date, 'dd MMM, yyyy') : '-'}</span>
              </div>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
}
