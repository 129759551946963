import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { UilBold, UilItalic, UilLinkH, UilListUl, UilUnderline } from '@iconscout/react-unicons';
import { type Editor } from '@tiptap/react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Toggle } from '@/components/ui/toggle';
import { ToggleGroup } from '@/components/ui/toggle-group';

interface EditorToolbarProps {
  editor: Editor;
}

const linkFormSchema = z.object({
  link: z.string().url().or(z.literal('')),
});

type LinkFormValues = z.infer<typeof linkFormSchema>;

const LinkModal = ({
  open,
  onOpenChange,
  url,
  onSave,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  url?: string;
  onSave: (link: string) => void;
}) => {
  const form = useForm<LinkFormValues>({
    resolver: zodResolver(linkFormSchema),
    values: {
      link: url || '',
    },
  });

  const handleSave = (data: LinkFormValues) => {
    onSave(data.link);
    form.reset({ link: '' });
  };

  return (
    <AlertDialog
      open={open}
      onOpenChange={onOpenChange}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle> {url ? 'Edit link' : 'Add a link'}</AlertDialogTitle>
          <AlertDialogDescription className="flex flex-col gap-2">
            <FormProvider {...form}>
              <Form>
                <FormField
                  control={form.control}
                  name="link"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Link</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          error={!!form.formState.errors.link}
                        />
                      </FormControl>
                      <FormError />
                    </FormItem>
                  )}
                />
              </Form>
            </FormProvider>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel
            onClick={() => {
              form.reset({ link: url || '' });
              onOpenChange(false);
            }}
          >
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction onClick={form.handleSubmit(handleSave)}>Save</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const EditorToolbar = ({ editor }: EditorToolbarProps) => {
  const [linkModalOpen, setLinkModalOpen] = useState(false);

  const handleSaveLink = (link: string) => {
    if (!link) {
      editor.chain().focus().unsetLink().run();
    } else {
      editor.chain().focus().setLink({ href: link }).run();
    }
    setLinkModalOpen(false);
  };

  return (
    <div
      className="max-w-fit rounded-lg border border-primary-dark-20 px-2 py-1.5 shadow-xs"
      aria-label="Formatting options"
    >
      <ToggleGroup
        className="flex flex-row items-center"
        type="multiple"
      >
        <Toggle
          onPressedChange={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          pressed={editor.isActive('bold')}
          variant="toggle"
        >
          <UilBold className="size-6" />
        </Toggle>

        <Toggle
          onPressedChange={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          pressed={editor.isActive('italic')}
          value="italic"
          variant="toggle"
        >
          <UilItalic className="size-6" />
        </Toggle>

        <Toggle
          onPressedChange={() => editor.chain().focus().toggleUnderline().run()}
          disabled={!editor.can().chain().focus().toggleUnderline().run()}
          pressed={editor.isActive('underline')}
          value="underline"
          variant="toggle"
        >
          <UilUnderline className="size-6" />
        </Toggle>

        <Toggle
          onPressedChange={() => setLinkModalOpen(true)}
          pressed={editor.isActive('link')}
          value="link"
          variant="toggle"
        >
          <UilLinkH className="size-6" />
        </Toggle>

        <Toggle
          onPressedChange={() => editor.chain().focus().toggleBulletList().run()}
          pressed={editor.isActive('bulletList')}
          variant="toggle"
          value="bulletList"
        >
          <UilListUl className="size-6" />
        </Toggle>

        <LinkModal
          open={linkModalOpen}
          onOpenChange={setLinkModalOpen}
          url={editor.getAttributes('link').href}
          onSave={handleSaveLink}
        />
      </ToggleGroup>
    </div>
  );
};

export default EditorToolbar;
