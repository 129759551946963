import { Suspense } from 'react';
// import { UilEyeSlash } from '@iconscout/react-unicons';
import { createFileRoute, Outlet } from '@tanstack/react-router';

// import {
//   AlertDialog,
//   AlertDialogAction,
//   AlertDialogCancel,
//   AlertDialogContent,
//   AlertDialogDescription,
//   AlertDialogFooter,
//   AlertDialogHeader,
//   AlertDialogTitle,
//   AlertDialogTrigger,
// } from '@/components/ui/alert-dialog';
import Avatar from '@/components/ui/avatar';

// import { Button } from '@/components/ui/button';
// import { Card } from '@/components/ui/card';

import BackButton from '@/components/back-button';
import FormLoader from '@/components/forms/form-loader';
import TabNav, { ITabNavItem } from '@/components/navigation/tab-nav';

import { useGetBusinessTeamMembersDetailsSuspense } from '@/hooks/business';

export const Route = createFileRoute('/_authenticated/business/_dashboard/partners/members/$partnerId/$teamMemberId')({
  component: TeamMermberProfileLayout,
});

// function EnableDisableButton() {
//   return (
//     <AlertDialog>
//       <AlertDialogTrigger asChild>
//         <Button
//           size="sm"
//           variant="tertiary"
//         >
//           <UilEyeSlash />
//           Disable
//         </Button>
//       </AlertDialogTrigger>
//       <AlertDialogContent>
//         <AlertDialogHeader>
//           <AlertDialogTitle className="flex items-center space-x-4">
//             <Card className="flex h-8 w-8 items-center justify-center p-0">
//               <UilEyeSlash />
//             </Card>
//             <span>Disable</span>
//           </AlertDialogTitle>
//           <AlertDialogDescription>
//             Disabling a partner will temporarily disable their access to your company page until you decide to enable
//             them.
//           </AlertDialogDescription>
//         </AlertDialogHeader>

//         <AlertDialogFooter>
//           <AlertDialogCancel>Cancel</AlertDialogCancel>
//           <AlertDialogAction>Disable</AlertDialogAction>
//         </AlertDialogFooter>
//       </AlertDialogContent>
//     </AlertDialog>
//   );
// }

function TeamMemberHeader() {
  const { teamMemberId: memberId, partnerId: businessId } = Route.useParams();
  const { data: memberProfile } = useGetBusinessTeamMembersDetailsSuspense({
    params: {
      path: {
        businessId: businessId.toString() as string,
        memberId: memberId,
      },
    },
    reactQueryOptions: {
      enabled: !!memberId && !!businessId,
    },
  });

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <BackButton backRoute={'/business/partners/$partnerId/profile'} />
        <div className="flex space-x-6">{/* <EnableDisableButton /> */}</div>
      </div>
      <div className="flex w-full items-center gap-x-4 py-8">
        <Avatar
          fallbackType="user"
          src={null}
          size="3xl"
        />
        <div>
          <h1 className="text-3xl font-semibold">{`${memberProfile?.first_name} ${memberProfile?.last_name}`}</h1>
          <p className="text-sm text-primary-dark-60">{memberProfile?.email}</p>
        </div>
      </div>
    </div>
  );
}

function TeamMermberProfileLayout() {
  const navItems: ITabNavItem[] = [
    {
      label: 'Profile',
      href: `/business/partners/members/$partnerId/$teamMemberId/profile`,
    },
    {
      label: 'Permissions',
      href: `/business/partners/members/$partnerId/$teamMemberId/permissions`,
    },
  ];
  return (
    <div>
      <TeamMemberHeader />
      <TabNav
        navItems={navItems}
        className="my-8"
      />
      <Suspense fallback={<FormLoader />}>
        <Outlet />
      </Suspense>
    </div>
  );
}
