import { useMutation, useQueryClient } from '@tanstack/react-query';

import { applyForARole, POST_ENDPOINTS, TApplyForAPostRequest } from '@/services/post';

import { UseMutationOptions } from '../types';

export function useApplyForARole({ reactQueryOptions, params }: UseMutationOptions<TApplyForAPostRequest>) {
  const queryClient = useQueryClient();

  return useMutation({
    ...reactQueryOptions,
    mutationFn: applyForARole,
    onSuccess: () => {
      reactQueryOptions?.onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: [POST_ENDPOINTS.APPLY_FOR_A_ROLE, params.path.id],
      });
    },
  });
}
