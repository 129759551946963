import { UilPen } from '@iconscout/react-unicons';
import { useNavigate } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { Card, CardTitle } from '@/components/ui/card';

import TeamMemberTag from '@/components/tags/team-member-tag';

import { TJobPost } from '@/services/business-roles/business-roles.types';

const TeamMembers = ({ teamMembers, roleId }: { teamMembers: TJobPost['members']; roleId: number }) => {
  const navigate = useNavigate();

  if (!teamMembers.length) return null;

  return (
    <Card className="space-y-6 p-8">
      <CardTitle className="flex justify-between leading-normal">
        Team Members
        <Button
          variant="tertiary"
          className="p-3"
          onClick={() =>
            navigate({
              to: `/business/create-role/step6`,
              search: { id: roleId, mode: 'edit', backRoute: window.location.pathname },
              mask: {
                to: '/business/create-role',
                search: { id: roleId, mode: 'edit' },
                unmaskOnReload: false,
              },
            })
          }
        >
          <UilPen className="!text-primary-dark-100 default-icon-sm" />
        </Button>
      </CardTitle>
      <div className="flex flex-wrap gap-2">
        {teamMembers?.map((member) => (
          <TeamMemberTag
            key={member.id}
            name={`${member.first_name} ${member.last_name}`}
            imageUrl={member.image_url}
          />
        ))}
      </div>
    </Card>
  );
};

export default TeamMembers;
