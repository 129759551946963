import { useBlocker } from '@tanstack/react-router';
import { useFormState } from 'react-hook-form';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';

interface IFormNavigationBlockerProps {
  description?: string;
  disabled?: boolean;
}
const FormNavigationBlocker: React.FC<IFormNavigationBlockerProps> = ({
  description = 'You have made changes to your account without saving them.',
  disabled,
}) => {
  const formStateContext = useFormState();
  const { reset, status, proceed } = useBlocker({
    condition: formStateContext.isDirty && !disabled,
  });

  if (!formStateContext) {
    throw new Error('useFormField should be used within <FormProvider>');
  }

  return (
    <AlertDialog
      open={status === 'blocked'}
      onOpenChange={reset}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle iconVariant="warning">Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            {description}
            <br />
            <br />
            If you select continue all of these changes will be lost.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={reset}>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={proceed}>Continue</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default FormNavigationBlocker;
