import { useEffect, useMemo } from 'react';
import { usePartnerStore } from '@/store/partner-store';

import { useGetAllPartnerBusinesses, useGetCurrentBusiness } from '@/hooks/business';

import { TBusiness } from '@/services/business';

import Avatar from '../ui/avatar';
import { OptionType, Select } from '../ui/select-new';

export function BusinessSwitch() {
  const { data: partnerStore, setData: setPartnerStoreData } = usePartnerStore();
  const { data: currentPartner } = useGetCurrentBusiness({});
  const { data: businesses } = useGetAllPartnerBusinesses({
    params: {
      path: {
        businessId: currentPartner?.id.toString() as string,
      },
    },
    reactQueryOptions: {
      enabled: !!currentPartner,
    },
  });

  const options: OptionType<TBusiness>[] = useMemo(() => {
    return (businesses?.items || []).map((item) => ({
      value: item.id?.toString() || '',
      label: item.name,
      metadata: item,
    }));
  }, [businesses]);

  const selectedBusiness = useMemo(() => {
    return options.find((item) => item.value === partnerStore?.selectedBusiness?.toString());
  }, [partnerStore, options]);

  const onChange = (value: string) => {
    setPartnerStoreData({ ...partnerStore, selectedBusiness: value, businessOptions: options });
  };

  const businessItem = (item: OptionType<TBusiness>) => {
    return (
      <div className="flex cursor-pointer items-center justify-start gap-2">
        <Avatar
          fallbackType="company"
          src={item?.metadata?.logo_url}
          size="sm"
        />
        <div className="max-w-[8.5rem] 3xl:max-w-[10.5rem]">
          <p className="text-md truncate text-left">{item?.label}</p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!selectedBusiness && options.length) {
      setPartnerStoreData({ ...partnerStore, selectedBusiness: options?.[0].value });
    }
  }, [options, selectedBusiness]);

  return (
    <Select<TBusiness>
      placeholder="Select business"
      options={options}
      itemComponent={businessItem}
      selected={selectedBusiness?.value || null}
      onChange={onChange}
    />
  );
}
