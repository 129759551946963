import React, { useEffect, useState } from 'react';
import { UilTimes } from '@iconscout/react-unicons';

import { Input } from '@/components/ui/input';

import { Badge } from './badge';

type IProps = {
  onUpdate: (tags: string[]) => void;
  values: string[];
  placeholder?: string;
};

export function CreatableInput({ placeholder, values, onUpdate }: IProps) {
  const [tags, setTags] = useState<string[]>(values || []);
  const [newTag, setNewTag] = useState('');

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && newTag.trim() !== '') {
      e.preventDefault();
      setTags([...tags, newTag.trim()]);
      setNewTag('');
    }
  };

  const handleRemoveTag = (index: number) => {
    const updatedTags = [...tags];
    updatedTags.splice(index, 1);
    setTags(updatedTags);
  };

  useEffect(() => {
    onUpdate(tags);
  }, [tags]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex flex-col gap-2">
      <div className="">
        <Input
          type="text"
          placeholder={placeholder || 'Add a tag'}
          value={newTag}
          onChange={(e) => setNewTag(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </div>
      {tags.length > 0 && (
        <div className="flex flex-wrap gap-2">
          {tags.map((tag, index) => (
            <Badge
              key={index}
              variant="success"
              hideDot
              onClick={() => handleRemoveTag(index)}
              className="cursor-pointer"
            >
              <span>{tag}</span>
              <UilTimes className="mt-0.5 size-3.5 text-primary-success-80" />
            </Badge>
          ))}
        </div>
      )}
    </div>
  );
}
