import { paths } from '@/lib/api/v1';

export const POST_ENDPOINTS = {
  GET_POST_BY_ID: '/v1/posts/{id}',
  GET_POST_BY_ID_FOR_CANDIDATE: '/v1/posts/{id}/candidates/{userId}',
  GET_CURRENT_POST_MATCHES: '/v1/posts/my-matches',
  GET_EVENTS_BY_POST_ID: '/v1/posts/{postId}/events',
  APPLY_FOR_A_ROLE: '/v1/posts/{id}/apply',
  GET_ALL_CANDIDATES_FOR_A_POST: '/v1/posts/{id}/candidates',
  GET_CANDIDATE_FOR_A_POST: '/v1/posts/{id}/candidates/{userId}',
  GET_POST_PUBLICLY: '/v1/public/job-posts/{postId}',
} satisfies Record<string, keyof paths>;
