import { Button } from '@/components/ui/button';
import { Card, CardContent, CardTitle } from '@/components/ui/card';

import BusinessAvatarWithLabel from '@/components/business-avatar-with-label';
import CultureCard from '@/components/culture-card';
import PersonalityCard from '@/components/personality-card';
import MatchPercentLabel from '@/components/tags/match-percent-label';

import { useGetCurrentCandidate } from '@/hooks/candidate';
import { useGetCandidateMeetingForPost } from '@/hooks/meetings';

import { parseJobPostCultureProfile, parseJobPostPersonalityProfile } from '@/services/business-roles';
import { TCandidateJobPost } from '@/services/candidate';

import { ROLE_CANDIDATE_STATUS } from '@/utils/application-status';
import { getArrayFromString } from '@/utils/format';
import { getMatchingItemsFromTwoArrays } from '@/utils/string';

import { CTA } from './cta';
import MatchPercentInfo from './match-percent-info';
import MatchingSkills from './matching-skills';

export function SidePanel({
  post,
  showPersonalityBreakdown,
  showCultureBreakdown,
  togglePersonalityBreakdown,
  toggleCultureBreakdown,
}: {
  post: TCandidateJobPost;
  showPersonalityBreakdown: boolean;
  showCultureBreakdown: boolean;
  togglePersonalityBreakdown: () => void;
  toggleCultureBreakdown: () => void;
}) {
  const candidateStatus = post?.metadata?.candidate_status;
  const { data: meeting } = useGetCandidateMeetingForPost({
    params: {
      path: {
        post_id: post.id,
      },
    },
    reactQueryOptions: {
      enabled:
        candidateStatus === ROLE_CANDIDATE_STATUS.INTERVIEWING ||
        candidateStatus === ROLE_CANDIDATE_STATUS.SHORTLIST_CANDIDATE,
    },
  });

  const personalityProfile = post.personality_profile ? parseJobPostPersonalityProfile(post.personality_profile) : [];
  const highestRankPersonality = personalityProfile.find((item) => item.rank === 1);

  const cultureProfile = post.culture_profile ? parseJobPostCultureProfile(post.culture_profile) : [];
  const highestRankCulture = cultureProfile.find((item) => item.rank === 1);

  const { data: candidate } = useGetCurrentCandidate({});

  const showBreakdown = showCultureBreakdown || showPersonalityBreakdown;

  const candidateSkills = candidate?.technical_skills ? getArrayFromString(candidate?.technical_skills) : [];
  const roleSkills = post.skills ? getArrayFromString(post.skills) : [];

  const hasMatchingSkills = getMatchingItemsFromTwoArrays(candidateSkills, roleSkills).length > 0;

  const showMatchingSection = hasMatchingSkills || highestRankCulture || highestRankPersonality;

  return (
    <div className="flex w-full flex-col space-y-8">
      {!showBreakdown && (
        <Card className="flex w-full flex-col space-y-5 bg-primary-white-100">
          <div className="space-y-6">
            <BusinessAvatarWithLabel
              src={post.business.logo_url}
              name={post.business.name}
              avatarSize="lg"
              className="gap-4 text-xl 3xl:text-2xl"
            />
            <div className="space-y-2">
              <div className="flex items-center gap-2 font-semibold text-primary-dark-40">
                Match score <MatchPercentInfo />
              </div>
              <MatchPercentLabel percent={post.metadata?.match_percentage || 0} />
            </div>
          </div>
          <CTA
            candidateStatus={candidateStatus}
            post={post}
            meeting={meeting}
          />
        </Card>
      )}
      {showMatchingSection && (
        <Card className="flex flex-col space-y-6">
          <CardTitle>Matching</CardTitle>
          {post.skills && candidateSkills && !showBreakdown && (
            <MatchingSkills
              candidateSkills={candidateSkills}
              roleSkills={roleSkills}
            />
          )}
          <CardContent className="space-y-8">
            {highestRankPersonality && (
              <div className="flex flex-col space-y-2">
                <p className="font-semibold">Desired personality</p>
                <PersonalityCard personalityCode={highestRankPersonality.code} />
                <Button
                  variant="tertiary"
                  onClick={togglePersonalityBreakdown}
                >
                  {showPersonalityBreakdown ? 'Hide Results' : 'Show Results'}
                </Button>
              </div>
            )}
            {highestRankCulture && (
              <div className="flex flex-col space-y-2">
                <p className="font-semibold">Team culture</p>
                <CultureCard cultureName={highestRankCulture.name} />
                <Button
                  variant="tertiary"
                  onClick={toggleCultureBreakdown}
                >
                  {showCultureBreakdown ? 'Hide Results' : 'Show Results'}
                </Button>
              </div>
            )}
          </CardContent>
        </Card>
      )}
    </div>
  );
}
