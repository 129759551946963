import { useMemo, useState } from 'react';

import { useDebounce } from './useDebounce';

type IUseSearchableProps<T> = {
  searchKey?: string;
  searchValue?: string;
  limit?: number;
  data: T[];
};

export const useSearchable = <T>({ searchKey, searchValue, data, limit }: IUseSearchableProps<T>) => {
  const [searchText, setSearchText] = useState(searchValue || '');
  const debounceSearchText = useDebounce(searchText, 300);

  const getObjItem = (obj: Record<string, unknown>, key: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return key.split('.').reduce((acc, key) => acc[key], obj);
  };

  const filteredData = useMemo(() => {
    if (typeof data[0] === 'object') {
      return data
        .filter((item) => {
          return getObjItem(item as Record<string, unknown>, searchKey || '')
            .toString()
            .toLowerCase()
            .includes(searchText?.toLowerCase() || '');
        })
        .slice(0, limit);
    }
    return data
      .filter((item) => {
        return (item as unknown as string)
          .toString()
          .toLowerCase()
          .includes(debounceSearchText?.toLowerCase() || '');
      })
      .slice(0, limit);
  }, [debounceSearchText, data, limit, searchKey, searchText]);

  return {
    searchText,
    setSearchText,
    filteredData,
  };
};
