import { useDraggable } from '@dnd-kit/core';

import { CultureName } from '@/services/candidate';

import { cn } from '@/lib/utils';

import { CULTURE_CLASS_NAME_MAP, CULTURE_TO_EMOJI_MAP } from '@/constants/candidate';
import { CULTURE_NAMES_TO_DESCRIPTIONS } from '@/constants/quiz';

import BaseQuizCardWrapper from './base-quiz-card';

interface DraggableCultureCardProps {
  cultureName: CultureName;
  type?: 'initial' | 'final';
}
const DraggableCultureCard = ({ cultureName, type }: DraggableCultureCardProps) => {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: cultureName,
    data: {
      type,
    },
  });

  return (
    <BaseQuizCardWrapper
      className={cn(
        'flex items-center gap-6 outline outline-[1.5px] 2xl:gap-8',
        isDragging ? 'opacity-0' : CULTURE_CLASS_NAME_MAP[cultureName]
      )}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      {!isDragging && (
        <>
          <span className="text-[3.6rem] 2xl:text-[4.25rem] 3xl:text-[6.375rem]">
            {CULTURE_TO_EMOJI_MAP[cultureName]}
          </span>
          <span className="font-semibold 2xl:text-lg 3xl:text-xl">{CULTURE_NAMES_TO_DESCRIPTIONS[cultureName]}</span>
        </>
      )}
    </BaseQuizCardWrapper>
  );
};

export default DraggableCultureCard;
