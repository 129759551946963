import { createFileRoute } from '@tanstack/react-router';

import Heading from '@/components/ui/typography/heading';

import TeamTable from '@/components/business/team/team-table';
import FullPageLoader from '@/components/full-page-loader';

import { useGetBusinessTeamMembers, useGetCurrentBusiness } from '@/hooks/business';
import useFilters from '@/hooks/useFilters';

import { Filters } from '@/services/types';

import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '@/constants/table';

export const Route = createFileRoute('/_authenticated/business/_dashboard/team/')({
  component: Team,
});

function Team() {
  const { data: business, isPending: isBusinessPending } = useGetCurrentBusiness({});

  const { filters, setFilters } = useFilters<Filters>(Route.id);
  const paginationState = {
    pageIndex: filters.pageIndex ?? DEFAULT_PAGE_INDEX,
    pageSize: filters.pageSize ?? DEFAULT_PAGE_SIZE,
  };

  const { data, isPending } = useGetBusinessTeamMembers({
    params: {
      path: {
        businessId: business?.id.toString() as string,
      },
      query: {
        sort: 'first_name',
        limit: paginationState.pageSize.toString(),
        offset: (paginationState.pageIndex * paginationState.pageSize).toString(),
        q: filters.query,
      },
    },
    reactQueryOptions: {
      enabled: !!business,
      queryKey: [filters],
    },
  });

  if (isBusinessPending) return <FullPageLoader className="w-full" />;

  return (
    <main className="space-y-6">
      <Heading
        size="page"
        title="Team"
      />
      <TeamTable
        data={data}
        filters={filters}
        setFilters={setFilters}
        paginationState={paginationState}
        isPending={isPending}
      />
    </main>
  );
}
