import { createFileRoute } from '@tanstack/react-router';

import BusinessProfileInfoForm from '@/components/forms/business/business-info-form';

import useStepper from '@/hooks/useStepper';

export const Route = createFileRoute('/_authenticated/business/onboarding/_steps/step1')({
  component: Step1,
});

function Step1() {
  const { goToNextStep } = useStepper('business');

  return (
    <BusinessProfileInfoForm
      onSuccess={goToNextStep}
      isOnboarding
    />
  );
}
