import { RegisteredRouter, RouteIds, useNavigate, useSearch } from '@tanstack/react-router';

import { cleanEmptyParams } from '@/utils/string';

export default function useFilters<T>(routeId: RouteIds<RegisteredRouter['routeTree']>) {
  const navigate = useNavigate();

  const filters: T = useSearch({
    from: routeId,
  });

  const setFilters = (partialFilters: Partial<T>) =>
    navigate({
      search: (prev) => cleanEmptyParams({ ...prev, ...partialFilters }),
      resetScroll: false,
    });

  const resetFilters = () => navigate({ search: {} });

  return { filters, setFilters, resetFilters };
}
