import { memo } from 'react';
import { createFileRoute, Outlet } from '@tanstack/react-router';

import CreateRoleNavigation from '@/components/roles/business/create-role/create-role-navigation';
import SaveAsDraftButton from '@/components/roles/business/create-role/save-as-draft-button';

export const Route = createFileRoute('/_authenticated/business/create-role/step5')({
  component: memo(CreateRoleStep5Layout),
});

function CreateRoleStep5Layout() {
  return (
    <>
      <SaveAsDraftButton />
      <Outlet />
      <CreateRoleNavigation />
    </>
  );
}
