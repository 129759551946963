import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { UilAngleDown, UilCheck } from '@iconscout/react-unicons';

import { cn } from '@/lib/utils';

import { inputVariants } from './input';

interface IOption {
  value: string;
  label: string;
}

interface IComboBoxProps {
  options: IOption[];
  selected: IOption | null;
  onSelect: (value: string) => void;
  placeholder?: string;
  error?: boolean;
  className?: string;
  isLoading?: boolean;
  onQueryChange: (query: string) => void;
  disableAlphabeticalSorting?: boolean;
  searchTerm?: string;
}

export default function ComboBoxInModal({
  selected,
  onSelect,
  options,
  className,
  error,
  onQueryChange,
  isLoading,
  placeholder,
  disableAlphabeticalSorting,
  searchTerm,
}: IComboBoxProps) {
  const isSelected = (value: string) => {
    return value === selected?.value;
  };

  const sortedOptions = disableAlphabeticalSorting
    ? options
    : [...options].sort((a, b) => {
        const valueA = typeof a.value === 'string' ? a.value : '';
        const valueB = typeof b.value === 'string' ? b.value : '';
        return valueA.localeCompare(valueB);
      });

  const filteredOptions = searchTerm
    ? sortedOptions.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()))
    : sortedOptions;

  return (
    <Combobox
      value={selected}
      onChange={(option) => onSelect(option?.value || '')}
      onClose={() => onQueryChange('')}
      immediate
    >
      <div className="relative">
        <div className="relative">
          <ComboboxInput
            aria-label="Assignee"
            displayValue={(option: IOption) => option?.label}
            onChange={(event) => onQueryChange(event.target.value)}
            className={cn(inputVariants({ variant: error ? 'error' : 'default' }), className, 'pr-12')}
            placeholder={placeholder || 'Start typing here...'}
          />
          <ComboboxButton className="group peer absolute inset-y-0 right-0 top-1/2 mr-2 flex -translate-y-1/2 cursor-pointer items-center justify-center px-2.5 transition-all [&[data-open]>svg]:rotate-180">
            <UilAngleDown
              className={cn(
                'pointer-events-none size-6 transform text-primary-dark-60 transition-transform duration-200'
              )}
            />
          </ComboboxButton>
        </div>

        <ComboboxOptions
          transition
          className="absolute top-14 z-50 max-h-64 w-full overflow-y-auto rounded-lg border border-primary-dark-10 bg-white shadow-lg outline-none scrollbar data-[open]:animate-in data-[close]:animate-out data-[close]:fade-out-0 data-[open]:fade-in-0 data-[close]:zoom-out-95 data-[open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:border-slate-800 dark:bg-slate-950 dark:text-slate-50 3xl:max-h-80"
        >
          {isLoading ? (
            <div className="flex min-h-20 w-full items-center justify-center">Loading data</div>
          ) : filteredOptions.length ? (
            filteredOptions?.map((option) => (
              <ComboboxOption
                key={option.value}
                value={option}
                className={cn(
                  'group flex cursor-pointer items-center justify-between px-4 py-3 font-medium text-primary-dark-60 outline-none data-[focus]:bg-primary-dark-10 data-[selected]:bg-primary-dark-5 data-[focus]:text-primary-dark-100 data-[selected]:text-primary-dark-100',
                  isSelected(option.value) && 'bg-primary-dark-5 text-primary-dark-100'
                )}
              >
                <span>{option.label}</span>
                {isSelected(option.value) && <UilCheck className="size-5 text-primary-blue-100" />}
              </ComboboxOption>
            ))
          ) : (
            <div className="flex min-h-20 w-full items-center justify-center">
              <p className="text-center">No results found</p>
            </div>
          )}
        </ComboboxOptions>
      </div>
    </Combobox>
  );
}
