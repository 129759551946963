import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

const posthogKey = import.meta.env.VITE_POSTHOG_KEY;
const posthogHost = import.meta.env.VITE_POSTHOG_HOST;

if (typeof window !== 'undefined') {
  posthog.init(posthogKey, {
    api_host: posthogHost,
    // Disable in localhost or any other development environment
    // To ensure this only set Posthog envs in production
    loaded: (posthog) => {
      if (import.meta.env.DEV || !posthogKey) posthog.opt_out_capturing();
    },
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
      },
    },
  });
}

export function PHProvider({ children }: { children: React.ReactNode }) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
