import { useCreateRoleStore } from '@/store/create-role-store';
import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';

import { FormProvider } from '@/components/ui/form';
import Heading from '@/components/ui/typography/heading';

import CreateRoleFooter from '@/components/roles/business/create-role/create-role-footer';
import PersonalityQuizResults from '@/components/roles/business/create-role/quiz/personality-quiz-results';
import SaveChangesButton from '@/components/roles/business/create-role/save-changes-button';

import { step4Schema, TStep4FormData } from '@/validation-schemas/business/create-role-schema';

export const Route = createFileRoute('/_authenticated/business/create-role/step4/results')({
  component: Results,
});

function Results() {
  const { data } = useCreateRoleStore();

  const form = useForm<TStep4FormData>({
    resolver: zodResolver(step4Schema),
    values: {
      personalityTraits: data?.personalityTraits,
    },
  });
  return (
    <div className="flex h-full flex-col justify-between gap-8">
      <div className="space-y-8">
        <Heading
          title="Personality quiz results"
          size="title"
          className="text-center"
        />
        <PersonalityQuizResults />
      </div>
      <FormProvider {...form}>
        <CreateRoleFooter onSubmit={() => {}} />
        <SaveChangesButton />
      </FormProvider>
    </div>
  );
}
