import { memo } from 'react';
import { usePipelineStore } from '@/store/pipeline-store';
import { createFileRoute } from '@tanstack/react-router';

import PipelineFilter from '@/components/pipeline/business/business-pipeline-filter';
import BusinessPipelineTable from '@/components/pipeline/business/business-pipeline-table';

import { useGetCurrentBusiness } from '@/hooks/business';
import { useGetAllCandidatesForPipeline } from '@/hooks/pipeline';
import useFilters from '@/hooks/useFilters';

import { TCandidateForPipelineSort, TPipelineCandidateFilters } from '@/services/pipeline';

import { sortByToQuery } from '@/utils/table-sort-mapper';

import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '@/constants/table';

export const Route = createFileRoute('/_authenticated/business/_dashboard/pipeline/table')({
  component: memo(PipelineTable),
});

function PipelineTable() {
  const { data: selectedRoles } = usePipelineStore();
  const selectedRolesIds = selectedRoles.map((item) => item.id);
  const { data: business } = useGetCurrentBusiness({});
  const { filters, setFilters } = useFilters<TPipelineCandidateFilters>(Route.id);

  const paginationState = {
    pageIndex: filters.pageIndex ?? DEFAULT_PAGE_INDEX,
    pageSize: filters.pageSize ?? DEFAULT_PAGE_SIZE,
  };

  const sortQuery = sortByToQuery<TCandidateForPipelineSort>(filters.sortBy);

  const { data, isFetching: isLoading } = useGetAllCandidatesForPipeline({
    params: {
      path: {
        businessId: business?.id.toString() as string,
      },
      query: {
        q: filters.query,
        limit: paginationState.pageSize.toString(),
        offset: (paginationState.pageIndex * paginationState.pageSize).toString(),
        job_post_ids: selectedRolesIds.join(','),
        status: filters.status,
        ...(sortQuery && {
          sort: sortQuery.id,
          order: sortQuery.order,
        }),
      },
    },
    reactQueryOptions: {
      queryKey: [filters, selectedRolesIds],
      enabled: !!business,
    },
  });

  return (
    <div className="px-8">
      <PipelineFilter
        filters={filters}
        setFilters={setFilters}
      />
      <BusinessPipelineTable
        data={{
          items: data?.items || [],
          total: data?.total || 0,
          metadata: data?.metadata || {
            total_applied: data?.metadata?.total_applied || 0,
            total_shortlisted: data?.metadata?.total_shortlisted || 0,
            total_candidates: data?.metadata?.total_candidates || 0,
            total_hired: data?.metadata?.total_hired || 0,
            total_interviewing: data?.metadata?.total_interviewing || 0,
            total_offered: data?.metadata?.total_offered || 0,
            total_rejected: data?.metadata?.total_rejected || 0,
          },
        }}
        isLoading={isLoading}
        setFilters={setFilters}
        filters={filters}
        paginationState={paginationState}
      />
    </div>
  );
}
