import React from 'react';

import { Card, CardContent } from '@/components/ui/card';

import { PersonalityArchetypeCode } from '@/services/candidate';

import { PERSONALITY_CODE_TO_EMOJI_MAP } from '@/constants/candidate';

export interface IPersonalityQuizCardProps {
  code: PersonalityArchetypeCode;
  description: string;
  onClick: (code: PersonalityArchetypeCode) => void;
}

const PersonalityQuizCard: React.FC<IPersonalityQuizCardProps> = ({ code, description, onClick }) => {
  return (
    <Card
      onClick={() => onClick(code)}
      className="w-[480px] cursor-pointer bg-primary-white-100 px-8 py-4 shadow-2xl hover:shadow-none hover:ring-4 hover:ring-primary-dark-10"
    >
      <CardContent className="flex flex-col">
        <div className="pt-10 text-[102px]">{PERSONALITY_CODE_TO_EMOJI_MAP[code]}</div>

        <p className="self-stretch text-2xl font-semibold leading-loose text-primary-dark-100">{description}</p>
      </CardContent>
    </Card>
  );
};

export default PersonalityQuizCard;
