import { UilTimes } from '@iconscout/react-unicons';
import { toast as sonnerToast, Toaster as SonnerToaster } from 'sonner';

import FeaturedIcon from '@/components/icons/featured-icon';

import { Button } from './button';
import { Card } from './card';

type TToastVariant = 'error' | 'success' | 'warning';
type TToastOptions = {
  title: string;
  description?: string;
};

interface IToast {
  variant: TToastVariant;
  t: string | number;
  message?: string;
  options?: TToastOptions;
}

const variantToIconMap: Record<TToastVariant, JSX.Element> = {
  success: <FeaturedIcon variant="success" />,
  warning: <FeaturedIcon variant="warning" />,
  error: <FeaturedIcon variant="alert" />,
};

const Toast: React.FC<IToast> = ({ variant, message, options, t }) => {
  return (
    <Card className="flex w-[356px] items-center gap-x-2.5 rounded-xl p-2.5 shadow-xl">
      {variantToIconMap[variant]}
      <div className="flex flex-1 items-center gap-x-4">
        <div className="flex-1 text-sm font-medium">
          <p className="text-primary-dark-100">{message || options?.title}</p>
          {options?.description && <p className="text-primary-dark-60">{options?.description}</p>}
        </div>
        <Button
          variant={'ghost'}
          className="p-0"
          onClick={() => sonnerToast.dismiss(t)}
        >
          <UilTimes className="size-6 text-primary-dark-60" />
        </Button>
      </div>
    </Card>
  );
};

const toast: Record<TToastVariant, (message?: string, options?: TToastOptions) => void> = {
  success: (message, options) =>
    sonnerToast.custom((t) => (
      <Toast
        variant="success"
        message={message}
        options={options}
        t={t}
      />
    )),
  error: (message, options) =>
    sonnerToast.custom((t) => (
      <Toast
        variant="error"
        message={message}
        options={options}
        t={t}
      />
    )),
  warning: (message, options) =>
    sonnerToast.custom((t) => (
      <Toast
        variant="warning"
        message={message}
        options={options}
        t={t}
      />
    )),
};
const Toaster = () => {
  return (
    <SonnerToaster
      position="top-right"
      expand
    />
  );
};

export { Toaster, toast };
