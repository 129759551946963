import type { SVGProps } from 'react';

export function UilDragAlt(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <circle
        cx="12"
        cy="8"
        r="3"
        fill="currentColor"
      />
      <circle
        cx="20"
        cy="8"
        r="3"
        fill="currentColor"
      />
      <circle
        cx="12"
        cy="16"
        r="3"
        fill="currentColor"
      />
      <circle
        cx="20"
        cy="16"
        r="3"
        fill="currentColor"
      />
      <circle
        cx="12"
        cy="24"
        r="3"
        fill="currentColor"
      />
      <circle
        cx="20"
        cy="24"
        r="3"
        fill="currentColor"
      />
    </svg>
  );
}
