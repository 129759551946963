import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
// import { parse, parseISO } from 'date-fns';
import { useForm } from 'react-hook-form';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Select } from '@/components/ui/select-new';
import { Textarea } from '@/components/ui/textarea';

import TimeSlotBooker from '@/components/time-slot-booker';

import { useGetMeetingTimeSlots, useRescheduleMeeting } from '@/hooks/meetings';
import useAuth from '@/hooks/useAuth';

import { TMeetings } from '@/services/meetings';
import { USER_TYPE } from '@/services/user';

import { MEETING_REASON_OPTIONS_BUSINESS, MEETING_REASON_OPTIONS_CANDIDATE } from '@/constants/meeting';

// import { useGetPostEventsById } from '@/hooks/post';

import { rescheduleMeetingSchema, TRescheduleMeetingFormData } from '@/validation-schemas/reschedule-meeting-schema';

const RescheduleForm: React.FC<{ onSubmit: (data: TRescheduleMeetingFormData) => void }> = ({ onSubmit }) => {
  const { user } = useAuth();
  const isCandidate = user?.app_user_type === USER_TYPE.CANDIDATE;
  const form = useForm<TRescheduleMeetingFormData>({
    resolver: zodResolver(rescheduleMeetingSchema),
  });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  return (
    <FormProvider {...form}>
      <Form className="space-y-4">
        <FormField
          name="reason"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Reason</FormLabel>
              <FormControl>
                <Select
                  options={isCandidate ? MEETING_REASON_OPTIONS_CANDIDATE : MEETING_REASON_OPTIONS_BUSINESS}
                  selected={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={!!errors?.reason?.message}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="description"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Enter description here..."
                  {...field}
                  error={!!errors?.description?.message}
                  rows={4}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>
      <AlertDialogFooter>
        <AlertDialogCancel>Cancel</AlertDialogCancel>
        <Button
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Continue
        </Button>
      </AlertDialogFooter>
    </FormProvider>
  );
};

const RescheduleButton: React.FC<{ meeting: TMeetings[number]; disabled?: boolean; companyName: string }> = ({
  disabled,
  companyName,
  meeting,
}) => {
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<TRescheduleMeetingFormData>();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<Date | null>(null);
  const oldScheduledSlot = meeting.start_time ? [new Date(meeting.start_time)] : [];

  const { data } = useGetMeetingTimeSlots({
    params: {
      path: {
        meeting_id: meeting.id,
      },
    },
  });

  const { mutate: rescheduleMeeting, isPending } = useRescheduleMeeting({
    reactQueryOptions: {
      onSuccess: () => {
        setOpen(false);
      },
    },
  });

  const onBookNowClick = () => {
    if (!selectedTimeSlot || !formData) return;
    rescheduleMeeting({
      body: {
        reason: formData?.reason,
        description: formData?.description || '',
        meeting_id: meeting.id,
        start_time: selectedTimeSlot.toISOString(),
      },
    });
  };

  const renderContent = () => {
    if (step === 1) {
      return (
        <RescheduleForm
          onSubmit={(data) => {
            setFormData(data);
            setStep((prev) => prev + 1);
          }}
        />
      );
    }
    if (step === 2) {
      return (
        <TimeSlotBooker
          events={data}
          selectedSlot={selectedTimeSlot}
          onSlotSelect={setSelectedTimeSlot}
          interval={Number(meeting.stage_duration)}
          disabledSlots={oldScheduledSlot}
        />
      );
    }
    return null;
  };

  const renderDescription = () => {
    if (step === 1) {
      return 'Please tell us why you would you can’t make this interview time?';
    }
    if (step === 2) {
      return 'We understand that sometimes life gets in the way but please be cautious rescheduling more than once.';
    }
    if (step === 3) {
      return (
        <>
          <p>You have successfully rescheduled your interview with {companyName}! We wish you the best of luck.</p>
          <br />
          <p>Make sure to check your notifications for any updates!</p>
        </>
      );
    }
    return null;
  };

  const renderTitle = () => {
    if (step === 1) {
      return 'Reschedule';
    }
    if (step === 2) {
      return 'Book a new slot';
    }
    if (step === 3) {
      return 'Rescheduled';
    }
    return null;
  };

  const renderFooter = () => {
    if (step === 1) return;

    if (step === 2) {
      return (
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            onClick={onBookNowClick}
            disabled={!selectedTimeSlot}
            loading={isPending}
          >
            Book now
          </Button>
        </AlertDialogFooter>
      );
    }

    return (
      <AlertDialogFooter>
        <AlertDialogAction>Continue</AlertDialogAction>
      </AlertDialogFooter>
    );
  };

  return (
    <AlertDialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        setSelectedTimeSlot(null);
        setTimeout(() => setStep(1), 200);
      }}
    >
      <AlertDialogTrigger asChild>
        <Button
          variant="tertiary"
          disabled={disabled}
          className="w-full"
        >
          Reschedule
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="gap-6">
        <AlertDialogHeader>
          <AlertDialogTitle iconVariant={step > 1 ? 'success' : null}>{renderTitle()}</AlertDialogTitle>
          <AlertDialogDescription className="pb-0">{renderDescription()}</AlertDialogDescription>
        </AlertDialogHeader>
        {renderContent()}
        {renderFooter()}
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default RescheduleButton;
