import { cn } from '@/lib/utils';

import FullLogo from '@/assets/full-logo.svg';
import MiniLogo from '@/assets/mini-logo.svg';

interface IProps {
  className?: string;
  variant?: 'full' | 'mini';
}

export const Logo: React.FC<IProps> = ({ className, variant = 'full' }) => {
  if (variant === 'mini') {
    return (
      <div className="inline-block rounded-lg border border-primary-dark-10 bg-primary-white-100 px-[15px] py-[14.5px] shadow-sm">
        <img
          src={MiniLogo}
          alt="logo"
          className={cn('h-[35px] w-[34px]', className)}
        />
      </div>
    );
  }

  return (
    <img
      src={FullLogo}
      alt="logo"
      className={cn('w-48', className)}
    />
  );
};
