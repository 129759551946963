import { UilLifeRing } from '@iconscout/react-unicons';

import EXTERNAL_URLS from '@/constants/external-urls';

// import NavLink from './nav-link';

const SupportNavLink = () => {
  return (
    // <NavLink
    //   href="/candidate/support"
    //   icon={<UilLifeRing className="default-icon" />}
    //   label="Support"
    // />
    <a
      href={EXTERNAL_URLS.SUPPORT_PORTAL}
      target="_blank"
      rel="noreferrer"
      className="flex cursor-pointer items-center gap-2 rounded-lg px-4 py-2.5 font-semibold transition-colors hover:bg-primary-dark-5"
    >
      <UilLifeRing className="default-icon" />
      <span className="mt-0.5">{'Support'}</span>
    </a>
  );
};

export default SupportNavLink;
