export const currencyList = [
  {
    label: 'AUD',
    description: 'AUD',
    icon: '$',
  },
  {
    label: 'USD',
    description: 'USD',
    icon: '$',
  },
  {
    label: 'EUR',
    description: 'EUR',
    icon: '€',
  },
  {
    label: 'GBP',
    description: 'GBP',
    icon: '£',
  },
];

export const formatCurrency = (value: number | null = null, currency: string = 'AUD') => {
  if (value === null) return '-';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

export const parseCurrency = (value: string) => {
  const parsedValue = parseFloat(value.replace(/[^0-9]+/g, ''));
  return isNaN(parsedValue) ? null : parsedValue;
};
