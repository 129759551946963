import { FormControl, FormItem, FormLabel } from '@/components/ui/form';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';

import { cn } from '@/lib/utils';

import { PAY_TYPES } from '@/constants/post';

interface PayTypeRadioGroupProps {
  value?: string;
  onChange: (value: string) => void;
}
const PayTypeRadioGroup = ({ value, onChange }: PayTypeRadioGroupProps) => {
  return (
    <RadioGroup
      className="flex justify-start gap-4"
      value={value}
      onValueChange={onChange}
    >
      {PAY_TYPES.map((payType) => (
        <FormItem key={payType.id}>
          <FormLabel
            className={cn(
              'flex h-full max-w-[19.5rem] cursor-pointer items-center justify-between gap-2 self-stretch rounded-lg border border-primary-dark-20 p-4 ring-offset-white 2xl:p-6 3xl:p-8',
              {
                'border-2 border-primary-blue-100 ring-4 ring-primary-blue-10': value === payType.id,
              }
            )}
          >
            <div className="flex items-center gap-4">
              <FormControl>
                <RadioGroupItem value={payType.id} />
              </FormControl>
              <span className="font-medium 3xl:text-lg">{payType.name}</span>
            </div>
          </FormLabel>
        </FormItem>
      ))}
    </RadioGroup>
  );
};

export default PayTypeRadioGroup;
