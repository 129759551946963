import { createFileRoute, Link } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';

import FullPageLoader from '@/components/full-page-loader';
import FeaturedIcon from '@/components/icons/featured-icon';
import { Logo } from '@/components/logo';
import RoleDetails from '@/components/roles/business/role-details/role-details';

import { useGetPostByIdPublicly } from '@/hooks/post';

type TRoleReferrer = {
  referrer?: string;
};

export const Route = createFileRoute('/role/$roleId')({
  component: RoleDetail,
  validateSearch: (search: Record<string, unknown>): TRoleReferrer => {
    const referrer = search.referrer as string | undefined;
    return {
      referrer,
    };
  },
});

function RoleDetail() {
  const { roleId } = Route.useParams();

  const { data: post, isLoading } = useGetPostByIdPublicly({
    params: { path: { postId: roleId } },
    reactQueryOptions: {
      enabled: !!roleId,
    },
  });

  if (isLoading || !post) {
    return <FullPageLoader />;
  }

  return (
    <div className="px-8 py-12">
      <main className="flex space-x-8">
        <section className="w-[480px] max-w-[780px] flex-shrink-0 xl:w-[480px] 2xl:w-[640px] 3xl:w-[780px]">
          <SidePanel />
        </section>
        <section className="flex flex-1 flex-col space-y-8">
          {/* <TopPanel post={post as TPostPublic} />
          <PublicRoleDetails post={post as TPostPublic} /> */}
          <RoleDetails role={post} />
        </section>
      </main>
    </div>
  );
}

// function TopPanel({ post }: { post: TPostPublic }) {
//   const city = post.city || post.business.city;
//   const country = post.country || post.business.country;

//   const legacyLocation = post.location || post.business.location;

//   const locationString = city && country ? `${city}, ${country}` : legacyLocation;

//   const { payRange } = getPayRangeFromRole(post);

//   return (
//     <Card className="flex w-full flex-col space-y-5 bg-primary-white-100 p-8 shadow-md">
//       <div className="inline-flex flex-row items-center space-x-4">
//         <Avatar
//           fallbackType="company"
//           src={post.business.logo_url}
//           size="lg"
//         />
//         <h1 className="text-2xl font-semibold leading-loose text-primary-dark-100 lg:text-base xl:text-lg 2xl:text-xl 3xl:text-2xl">
//           {post.business.name}
//         </h1>
//       </div>

//       <div className="flex flex-col space-y-2">
//         <div className="flex flex-row items-center text-primary-dark-40">
//           <h2 className="text-base font-semibold leading-normal">Role</h2>
//         </div>
//         <div className="flex space-x-2">
//           <p className="text-md font-semibold leading-7">{post.title}</p>
//           <div className="flex flex-wrap space-x-2">
//             <Tag
//               size="md"
//               variant="primary"
//             >
//               {post.remote_work === REMOTE_WORK_OPTIONS.YES ? 'Remote' : 'On-site'}
//             </Tag>
//             <Tag
//               size="md"
//               variant="primary"
//             >
//               {post.employment_type}
//             </Tag>
//           </div>
//         </div>
//       </div>
//       <div className="grid grid-cols-3 grid-rows-2">
//         <div className="">
//           <div className="flex flex-row items-center text-primary-dark-40">
//             <h2 className="text-base font-semibold leading-normal">Pay range (Base)</h2>
//           </div>
//         </div>
//         <div className="">
//           <div className="flex flex-row items-center text-primary-dark-40">
//             <h2 className="text-base font-semibold leading-normal">Location</h2>
//           </div>
//         </div>
//         <div className="">
//           <div className="flex flex-row items-center text-primary-dark-40">
//             <h2 className="text-base font-semibold leading-normal">Industry</h2>
//           </div>
//         </div>
//         <div className="">
//           <p className="text-md font-semibold leading-7">
//             {post.pay_type && post.pay_currency
//               ? `${PAY_TYPES_MAP[post.pay_type as PAY_TYPE].name} • ${payRange}`
//               : '-'}
//           </p>
//         </div>
//         <div className="">
//           <div>
//             <Tag
//               hideDot
//               size="md"
//             >
//               <AU />
//               {locationString}
//             </Tag>
//           </div>
//         </div>
//         <div className="">
//           <div>
//             <Tag
//               size="md"
//               hideDot
//             >
//               {post.business.industry}
//             </Tag>
//           </div>
//         </div>
//       </div>
//     </Card>
//   );
// }

function SidePanel() {
  const { roleId } = Route.useParams();
  const search = Route.useSearch();

  return (
    <div className="inline-flex flex-col items-start justify-start">
      <Logo
        variant="full"
        className="mb-2"
      />

      <div className="flex flex-col items-start justify-center gap-4 self-stretch py-4">
        <div className="self-stretch text-base font-medium leading-normal text-primary-dark-60">
          Thank you for your interest in this position.
          <br />
          <br />
          We’re using Upplft to match candidates with roles based not only on their capabilities but also on their
          alignment with our culture and values.
        </div>
      </div>
      <div className="flex flex-col space-y-4 py-4">
        <div className="flex h-10 items-center space-x-4">
          <FeaturedIcon
            variant={'success'}
            size={'sm'}
          />
          <span className="text-base font-medium leading-normal text-primary-dark-60">
            Match roles based on personality, values and culture alignment
          </span>
        </div>
        <div className="flex h-10 items-center space-x-4">
          <FeaturedIcon
            variant={'success'}
            size={'sm'}
          />
          <span className="text-base font-medium leading-normal text-primary-dark-60">
            Gain insights into what a company is really looking for
          </span>
        </div>
        <div className="flex h-10 items-center space-x-4">
          <FeaturedIcon
            variant={'success'}
            size={'sm'}
          />
          <span className="text-base font-medium leading-normal text-primary-dark-60">
            No arduous CV uploads, we making applying for roles super simple
          </span>
        </div>
      </div>
      <div className="flex flex-col items-start justify-center space-y-6 self-stretch py-4">
        <div className="self-stretch text-lg font-semibold leading-7 text-primary-dark-100">
          Don’t have an Upplft account? Sign up or sign in to apply
        </div>
        <div className="flex w-full flex-col space-y-6">
          <Button>
            <Link
              className="w-full"
              to={`/signup`}
            >
              Sign up
            </Link>
          </Button>
          <Button variant={'tertiary'}>
            <Link
              className="w-full"
              to={`/candidate/role/$roleId`}
              params={{ roleId }}
              search={search}
            >
              Sign in to Apply
            </Link>
          </Button>
        </div>
      </div>
    </div>
  );
}
