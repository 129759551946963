import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';

import { Card } from '@/components/ui/card';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import Separator from '@/components/ui/separator';

import BusinessTeamTableForPartner from '@/components/business/partner/team-member-table';

import { useGetBusinessPartnersDetails, useGetBusinessTeamMembers, useGetCurrentBusiness } from '@/hooks/business';
import useFilters from '@/hooks/useFilters';

import { Filters } from '@/services/types';

import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '@/constants/table';

import { businessProfileInfoSchema, TBusinessProfileInfoFormData } from '@/validation-schemas/business/business-info';

export const Route = createFileRoute('/_authenticated/business/_dashboard/partners/$partnerId/profile')({
  component: BusinessProfile,
});

function BusinessProfile() {
  return (
    <>
      <Separator />
      <BusinessDetailsSection />
      <Separator />
      <TeamMembersSection />
    </>
  );
}

function BusinessDetailsSection() {
  return (
    <div className="flex w-full space-x-8">
      <div className="w-[420px] flex-shrink-0 lg:max-w-[320px] xl:max-w-[360px] 2xl:max-w-[400px] 3xl:max-w-[460px]">
        <h3 className="text-xl font-semibold leading-[30px] text-primary-dark-100">Business details</h3>
        <p className="text-base font-medium leading-normal text-primary-dark-60">Partner business details</p>
      </div>
      <div className="grow">
        <Card className="p-8">
          <BusinessDetailsForm />
        </Card>
      </div>
    </div>
  );
}

interface IProfileDetailsFormProps {
  defaultValues?: TBusinessProfileInfoFormData;
}

function BusinessDetailsForm({ defaultValues }: IProfileDetailsFormProps) {
  const { data: business } = useGetCurrentBusiness({});
  const { partnerId } = Route.useParams();
  const { data: partnerProfile } = useGetBusinessPartnersDetails({
    params: {
      path: {
        businessId: business?.id.toString() as string,
        partnerId: partnerId,
      },
    },
    reactQueryOptions: {
      enabled: !!partnerId && !!business,
    },
  });
  const form = useForm<TBusinessProfileInfoFormData>({
    resolver: zodResolver(businessProfileInfoSchema),
    defaultValues: {
      name: partnerProfile?.name,
      business_abn: partnerProfile?.business_abn ?? '',
      country: partnerProfile?.country ?? '',
      city: partnerProfile?.city ?? '',
    },
    values: defaultValues,
  });

  const { control, reset } = form;

  useEffect(() => {
    if (partnerProfile) {
      reset({
        name: partnerProfile?.name,
        business_abn: partnerProfile?.business_abn ?? '',
        country: partnerProfile?.country ?? '',
        city: partnerProfile?.city ?? '',
      });
    }
  }, [partnerProfile, reset]);

  return (
    <FormProvider {...form}>
      <Form>
        <FormField
          control={control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Business name</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter your first name"
                  {...field}
                  disabled
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="business_abn"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Business number (ABN or ACN)</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter your last name"
                  {...field}
                  disabled
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="country"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Country</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter your email"
                  {...field}
                  disabled
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="city"
          render={({ field }) => (
            <FormItem>
              <FormLabel>City</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter your email"
                  {...field}
                  disabled
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>
    </FormProvider>
  );
}

function TeamMembersSection() {
  const { filters, setFilters } = useFilters<Filters>(Route.id);
  const { partnerId } = Route.useParams();

  const paginationState = {
    pageIndex: filters.pageIndex ?? DEFAULT_PAGE_INDEX,
    pageSize: filters.pageSize ?? DEFAULT_PAGE_SIZE,
  };

  const { data: businessMembers, isPending } = useGetBusinessTeamMembers({
    params: {
      path: {
        businessId: partnerId.toString() as string,
      },
      query: {
        sort: 'first_name',
        limit: paginationState.pageSize.toString(),
        offset: (paginationState.pageIndex * paginationState.pageSize).toString(),
        q: filters.query,
      },
    },
    reactQueryOptions: {
      enabled: !!partnerId,
      queryKey: [filters],
    },
  });
  return (
    <div className="flex w-full space-x-8">
      <div className="w-[420px] flex-shrink-0 lg:max-w-[320px] xl:max-w-[360px] 2xl:max-w-[400px] 3xl:max-w-[460px]">
        <h3 className="text-xl font-semibold leading-[30px] text-primary-dark-100">Team members</h3>
        <p className="text-base font-medium leading-normal text-primary-dark-60">Team members personal details</p>
      </div>
      <div className="grow">
        <BusinessTeamTableForPartner
          partnerId={partnerId}
          data={businessMembers}
          filters={filters}
          setFilters={setFilters}
          paginationState={paginationState}
          isPending={isPending}
        />
      </div>
    </div>
  );
}
