export enum ROLE_CANDIDATE_STATUS {
  APPLIED = 'APPLIED', // status = 0, action_status = 0
  SHORTLIST_CANDIDATE = 'SHORTLIST_CANDIDATE', // status = 1, action_status = 1
  REJECT_CANDIDATE = 'REJECT_CANDIDATE', // status = 2, action_status = 2
  REJECT_CONNECTION = 'REJECT_CONNECTION', // status = 2, action_status = 3
  MEETING_SCHEDULED = 'MEETING_SCHEDULED', // status = 1, action_status = 4
  ACCEPT_CONNECTION = 'ACCEPT_CONNECTION', // status = 1, action_status = 5
  MEETING_COMPLETED = 'MEETING_COMPLETED', // status = 2, action_status = 5
  INTERVIEWING = 'INTERVIEWING', // status = 1, action_status = 6
  OFFER = 'OFFER', // status = 1, action_status = 7
  HIRED = 'HIRED', // status = 1, action_status = 8
}

export function findCandidateStatus(status: number, actionStatus: string): ROLE_CANDIDATE_STATUS | null {
  const statusMap: { [key: string]: { status: number; actionStatus: string } } = {
    APPLIED: { status: 0, actionStatus: '0' },
    SHORTLIST_CANDIDATE: { status: 1, actionStatus: '1' },
    REJECT_CANDIDATE: { status: 2, actionStatus: '2' },
    REJECT_CONNECTION: { status: 2, actionStatus: '3' },
    MEETING_SCHEDULED: { status: 1, actionStatus: '4' },
    ACCEPT_CONNECTION: { status: 1, actionStatus: '5' },
    MEETING_COMPLETED: { status: 2, actionStatus: '5' },
    INTERVIEWING: { status: 1, actionStatus: '6' },
    OFFER: { status: 1, actionStatus: '7' },
    HIRED: { status: 1, actionStatus: '8' },
  };

  for (const [key, value] of Object.entries(statusMap)) {
    if (value.status === status && value.actionStatus === actionStatus) {
      return ROLE_CANDIDATE_STATUS[key as keyof typeof ROLE_CANDIDATE_STATUS];
    }
  }

  return null; // Return null if no match is found
}
