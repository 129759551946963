import { createFileRoute, Link } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';

import FeaturedIcon from '@/components/icons/featured-icon';

import { reactivateAccount } from '@/services/user';

export const Route = createFileRoute('/_account/reactivate/$token')({
  loader: async ({ params }) => {
    const isReactivated = await reactivateAccount({ body: { token: params.token } })
      .then((res) => {
        if (res.data?.success) {
          return true;
        }
      })
      .catch(() => {
        return false;
      });
    return { isReactivated };
  },
  component: ReactivationScreen,
});

function ReactivationScreen() {
  const { isReactivated } = Route.useLoaderData();
  if (isReactivated) {
    return (
      <div className="w-svh relative h-svh bg-slate-100 px-8 py-32">
        <div className="mx-auto flex w-full max-w-[1080px] flex-col gap-y-8">
          <FeaturedIcon
            variant="success"
            size="lg"
            className="flex"
          />
          <div className="pl-1 text-2xl font-semibold text-primary-dark-100">Account reactivated!</div>
          <div className="text-primary-dark-60">
            Your account has been successfully reactivated. You can now log in to your account.
          </div>
          <div>
            <Button>
              <Link to="/">Go to login</Link>
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-svh relative h-svh bg-slate-100 px-8 py-32">
        <div className="mx-auto flex w-full max-w-[1080px] flex-col gap-y-8">
          <FeaturedIcon
            variant="warning"
            size="lg"
            className="flex"
          />
          <div className="pl-1 text-2xl font-semibold text-primary-dark-100">Account reactivation failed</div>
          <div className="text-primary-dark-60">
            Your token is either invalid or expired. Please try again or contact support.
          </div>
          <div>
            <Button>
              <Link to="/">Go to login</Link>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
