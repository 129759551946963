import { useNavigate } from '@tanstack/react-router';
import { ColumnDef, PaginationState } from '@tanstack/react-table';

import { Button } from '@/components/ui/button';
import { DataTable } from '@/components/ui/data-table';

import LocationTag from '@/components/tags/location-tag';
import { MatchStatusTag } from '@/components/tags/match-status-tag';

import { BusinessRolesFilters, TBusinessRolesV2, TBusinessRolesV2Response } from '@/services/business-roles';
import { TCandidateJobStatus } from '@/services/candidate';

import { getEmptyBusinessRolesDescription } from '@/utils/role';
import { getFullName } from '@/utils/string';
import { sortByToState, stateToSortBy } from '@/utils/table-sort-mapper';

import { ProfileBubbles } from '../profile-bubbles';
import { RolesTableActionButton } from './roles-table-action-button';

interface IBusinessRolesTableProps {
  data?: TBusinessRolesV2Response;
  isPending: boolean;
  filters: BusinessRolesFilters;
  setFilters: (filters: BusinessRolesFilters) => void;
  paginationState: PaginationState;
  tabNavs?: React.ReactNode;
}

export default function PartnerBusinessRolesTable({
  data,
  isPending,
  setFilters,
  filters,
  paginationState,
  tabNavs,
}: IBusinessRolesTableProps) {
  const navigate = useNavigate();
  const sortingState = sortByToState(filters.sortBy);
  const columnDefs: ColumnDef<TBusinessRolesV2[0]>[] = [
    {
      accessorKey: 'title',
      id: 'title',
      header: 'Role',
      cell: ({ getValue }) => <span className="text-sm font-semibold">{getValue<string>()}</span>,
    },
    {
      header: 'Status',
      accessorKey: 'status',
      id: 'status',
      enableSorting: false,
      cell: ({ getValue }) => {
        return <MatchStatusTag jobPostStatus={getValue<TCandidateJobStatus>()} />;
      },
    },

    {
      accessorKey: 'location',
      header: 'Location',
      cell: ({ row }) => {
        return (
          <>
            {row.original.city && (
              <LocationTag
                location={row.original.city}
                useCountryCode
              />
            )}
          </>
        );
      },
    },
    {
      accessorKey: 'team',
      header: 'Team',
      cell: ({ row }) => {
        const bubbles = [
          // Owner
          ...(row.original?.owner
            ? [
                {
                  id: row.original?.owner?.id,
                  name: getFullName(row.original?.owner) || 'Role Owner',
                  image_url: row.original?.owner?.image_url,
                },
              ]
            : []),
          // Members
          ...row.original.members.map((member) => ({
            id: member?.id,
            name: getFullName(member) || 'Role Member',
            image_url: member.image_url,
          })),
          // Partners
          ...row.original.partners.map((partner) => ({
            id: partner?.id,
            name: partner.name || 'Role Partner',
            image_url: partner.logo_url,
            fallbackType: 'company',
          })),
        ];
        return (
          <div className="flex">
            <ProfileBubbles
              bubbles={bubbles}
              maxBubbles={4}
            />
          </div>
        );
      },
    },
    {
      id: 'id',
      cell: () => {
        return (
          <Button
            variant="link"
            onClick={() => navigate({ to: `/business/pipeline` })}
          >
            View pipeline
          </Button>
        );
      },
    },
    {
      accessorKey: 'more',
      header: '',
      cell: ({ row }) => {
        return <RolesTableActionButton id={row.original.id?.toString() || ''} />;
      },
    },
  ];

  return (
    <DataTable
      columns={columnDefs}
      data={data?.items || []}
      isLoading={isPending}
      tabNavs={tabNavs}
      pagination={{
        state: paginationState,
        options: {
          rowCount: data?.total || 0,
          onPaginationChange: (pagination) => {
            setFilters(typeof pagination === 'function' ? pagination(paginationState) : pagination);
          },
        },
      }}
      search={{
        value: filters.query || '',
        onChange: (value) => {
          setFilters({ ...filters, query: value });
        },
      }}
      noData={getEmptyBusinessRolesDescription(filters.status)}
      sorting={{
        state: sortingState,
        onSortingChange: (updaterOrValue) => {
          const newSortingState = typeof updaterOrValue === 'function' ? updaterOrValue(sortingState) : updaterOrValue;
          return setFilters({ sortBy: stateToSortBy(newSortingState) });
        },
      }}
    />
  );
}
