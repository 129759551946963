import { UilEyeSlash, UilUserCircle } from '@iconscout/react-unicons';
import { useNavigate } from '@tanstack/react-router';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownTriggerButton,
} from '@/components/ui/dropdown-menu';

interface PartnerTeamMembersActionButtonProps {
  partnerId: string;
  memberId: string;
}

export function PartnerTeamMembersActionButton({ partnerId, memberId }: PartnerTeamMembersActionButtonProps) {
  const navigate = useNavigate();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <DropdownTriggerButton />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem
          onClick={() =>
            navigate({
              to: `/business/partners/members/$partnerId/$teamMemberId/profile`,
              params: {
                partnerId: partnerId,
                teamMemberId: memberId,
              },
            })
          }
        >
          <UilUserCircle className="text-primary-dark-60" /> <span>View Profile</span>
        </DropdownMenuItem>
        <DropdownMenuItem disabled>
          <UilEyeSlash className="text-primary-dark-60" /> <span>Disable</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
