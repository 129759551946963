import { useQuery } from '@tanstack/react-query';

import EXTERNAL_URLS from '@/constants/external-urls';

const GET_CITIES_QUERY_KEY = 'GET_CITIES_QUERY_KEY';

type TCity = string;

export type TCitiesResponse = {
  error: boolean;
  msg: string;
  data: TCity[];
};

type TCitiesRequest = {
  country: string;
};

export const useCountrySpace = () => {
  const fetchCities = async (country: string) => {
    const response = await fetch(`${EXTERNAL_URLS.COUNTRIES_AND_CITIES_API_URL}/cities`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        country,
      } as TCitiesRequest),
    });
    const data: TCitiesResponse = await response.json();

    // sort by city name
    data.data.sort((a, b) => {
      return a.localeCompare(b);
    });

    return data;
  };

  return {
    fetchCities,
  };
};

export function useCityList(country?: string) {
  const { fetchCities } = useCountrySpace();
  let enabled = true;
  if (country === 'Australia') {
    enabled = false;
  }
  if (country === 'New Zealand') {
    enabled = false;
  }

  return useQuery({
    queryKey: [GET_CITIES_QUERY_KEY, country],
    queryFn: () =>
      country ? fetchCities(country as string) : Promise.resolve({ error: true, msg: 'Country is required', data: [] }),
    staleTime: Infinity,
    enabled: Boolean(enabled),
  });
}
