import { createFileRoute } from '@tanstack/react-router';

import { Card } from '@/components/ui/card';
import Heading from '@/components/ui/typography/heading';

import ChangePasswordForm from '@/components/forms/change-password-form';
import SettingContainer from '@/components/settings/setting-container';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/settings/password')({
  component: Password,
});

function Password() {
  return (
    <SettingContainer>
      <Heading
        title="Change Password"
        subtitle="Please enter your current password to change password."
      />
      <Card className="p-8">
        <ChangePasswordForm />{' '}
      </Card>
    </SettingContainer>
  );
}
