import { Components, Event } from 'react-big-calendar';

import { getGMTOffset } from '@/utils/dateTime';

import DateHeader from '../components/date-header';
import DayHeader from '../components/day-header';
import MonthHeader from '../components/month-header';
import ToolBar from '../components/toolbar';
import WeekHeader from '../components/week-header';

const commonComponents: Components<Event, object> = {
  toolbar: ToolBar,
  week: {
    header: WeekHeader,
  },
  month: {
    header: MonthHeader,
    dateHeader: DateHeader,
  },
  timeGutterHeader: () => <>{getGMTOffset()}</>,
  day: {
    header: DayHeader,
  },
};

export default commonComponents;
