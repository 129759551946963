import { UilStar } from '@iconscout/react-unicons';

import { cn } from '@/lib/utils';

interface IFavoriteButtonProps {
  isFavorite: boolean;
  onClick: () => void;
}

const FavoriteButton: React.FC<IFavoriteButtonProps> = ({ onClick, isFavorite = false }) => {
  /**
   * Note: @sajag-upplft Solid icons are not free, what should we do here to show filled star icon?
   */
  return (
    <UilStar
      onClick={onClick}
      className={cn('cursor-pointer transition-all duration-300 default-icon-sm', {
        'fill-secondary-yellow-100': isFavorite,
      })}
    />
  );
};

export default FavoriteButton;
