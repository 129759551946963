import { useCreateRoleStore } from '@/store/create-role-store';
import { UilPlus } from '@iconscout/react-unicons';
import { Link } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';

import { useGetCurrentUserPermissions } from '@/hooks/permission';

const CreateRoleButton = () => {
  const { resetData } = useCreateRoleStore();
  const { data: permissions } = useGetCurrentUserPermissions();

  const isCreateRoleButtonDisabled = !permissions?.manage_roles;

  return (
    <Link
      to={'/business/create-role'}
      search={{ mode: 'create' }}
      disabled={isCreateRoleButtonDisabled}
      onClick={resetData}
    >
      <Button
        disabled={isCreateRoleButtonDisabled}
        variant="default"
      >
        <span>Create role</span>
        <UilPlus className="size-[1.25rem] text-primary-white-100" />
      </Button>
    </Link>
  );
};

export default CreateRoleButton;
