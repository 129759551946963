import { CultureName, PersonalityArchetypeCode } from '@/services/candidate';

export const CULTURE_QUIZ_OPTIONS = [
  {
    name: CultureName.CLAN,
    description: 'Consistent, efficient, controlled, optimised',
  },
  {
    name: CultureName.ADHOCRACY,
    description: 'Creative, adaptable, agile, innovative',
  },
  {
    name: CultureName.MARKET,
    description: 'Competitive, productive, ambitious, goal focused',
  },
  {
    name: CultureName.HIERARCHY,
    description: 'Collaborative, empowering, participatory, cohesive',
  },
];

type IPersonalityOption = {
  code: PersonalityArchetypeCode;
  description: string;
};

interface IPersonalityQuizOptionPair {
  option1: IPersonalityOption;
  option2: IPersonalityOption;
}

export const PERSONALITY_QUIZ_OPTIONS: IPersonalityQuizOptionPair[] = [
  {
    option1: {
      code: PersonalityArchetypeCode.H,
      description: 'Sincere, honest, modest',
    },
    option2: {
      code: PersonalityArchetypeCode.E,
      description: 'Emotional, sentimental, vulnerable',
    },
  },
  {
    option1: {
      code: PersonalityArchetypeCode.H,
      description: 'Sincere, honest, modest',
    },
    option2: {
      code: PersonalityArchetypeCode.X,
      description: 'Outgoing, lively, sociable',
    },
  },
  {
    option1: {
      code: PersonalityArchetypeCode.H,
      description: 'Sincere, honest, modest',
    },
    option2: {
      code: PersonalityArchetypeCode.A,
      description: 'Patient, tolerable, flexible',
    },
  },
  {
    option1: {
      code: PersonalityArchetypeCode.H,
      description: 'Sincere, honest, modest',
    },
    option2: {
      code: PersonalityArchetypeCode.C,
      description: 'Organised, diligent, thorough',
    },
  },
  {
    option1: {
      code: PersonalityArchetypeCode.H,
      description: 'Sincere, honest, modest',
    },
    option2: {
      code: PersonalityArchetypeCode.O,
      description: 'Creative, unconventional, innovative',
    },
  },
  {
    option1: {
      code: PersonalityArchetypeCode.E,
      description: 'Emotional, sentimental, vulnerable',
    },
    option2: {
      code: PersonalityArchetypeCode.X,
      description: 'Outgoing, lively, sociable',
    },
  },
  {
    option1: {
      code: PersonalityArchetypeCode.E,
      description: 'Emotional, sentimental, vulnerable',
    },
    option2: {
      code: PersonalityArchetypeCode.A,
      description: 'Patient, tolerable, flexible',
    },
  },
  {
    option1: {
      code: PersonalityArchetypeCode.E,
      description: 'Emotional, sentimental, vulnerable',
    },
    option2: {
      code: PersonalityArchetypeCode.C,
      description: 'Organised, diligent, thorough',
    },
  },
  {
    option1: {
      code: PersonalityArchetypeCode.E,
      description: 'Emotional, sentimental, vulnerable',
    },
    option2: {
      code: PersonalityArchetypeCode.O,
      description: 'Creative, unconventional, innovative',
    },
  },
  {
    option1: {
      code: PersonalityArchetypeCode.X,
      description: 'Outgoing, lively, sociable',
    },
    option2: {
      code: PersonalityArchetypeCode.A,
      description: 'Patient, tolerable, flexible',
    },
  },
  {
    option1: {
      code: PersonalityArchetypeCode.X,
      description: 'Outgoing, lively, sociable',
    },
    option2: {
      code: PersonalityArchetypeCode.C,
      description: 'Organised, diligent, thorough',
    },
  },
  {
    option1: {
      code: PersonalityArchetypeCode.X,
      description: 'Outgoing, lively, sociable',
    },
    option2: {
      code: PersonalityArchetypeCode.O,
      description: 'Creative, unconventional, innovative',
    },
  },
  {
    option1: {
      code: PersonalityArchetypeCode.A,
      description: 'Patient, tolerable, flexible',
    },
    option2: {
      code: PersonalityArchetypeCode.C,
      description: 'Organised, diligent, thorough',
    },
  },
  {
    option1: {
      code: PersonalityArchetypeCode.A,
      description: 'Patient, tolerable, flexible',
    },
    option2: {
      code: PersonalityArchetypeCode.O,
      description: 'Creative, unconventional, innovative',
    },
  },
  {
    option1: {
      code: PersonalityArchetypeCode.C,
      description: 'Organised, diligent, thorough',
    },
    option2: {
      code: PersonalityArchetypeCode.O,
      description: 'Creative, unconventional, innovative',
    },
  },
];

export const PERSONALITY_ARCHETYPES_TO_DESCRIPTIONS: Record<PersonalityArchetypeCode, string> = {
  [PersonalityArchetypeCode.H]: 'Sincere, honest, loyal, humble',
  [PersonalityArchetypeCode.E]: 'Patient, tolerant, agreeable',
  [PersonalityArchetypeCode.X]: 'Brave, independent, stable',
  [PersonalityArchetypeCode.A]: 'Creative, unconventional, innovative',
  [PersonalityArchetypeCode.C]: 'Organised, diligent, thorough',
  [PersonalityArchetypeCode.O]: 'Outgoing, lively, sociable',
};

export const CULTURE_NAMES_TO_DESCRIPTIONS: Record<CultureName, string> = {
  [CultureName.CLAN]: 'Consistent, efficient, controlled, optimised',
  [CultureName.ADHOCRACY]: 'Creative, adaptable, agile, innovative',
  [CultureName.MARKET]: 'Competitive, productive, ambitious, goal focused',
  [CultureName.HIERARCHY]: 'Collaborative, empowering, participatory, cohesive',
};
