import { z } from 'zod';

export const createPasswordSchema = z
  .object({
    newPassword: z
      .string()
      .min(8, 'Password must be at least 8 characters long')
      .refine(
        (value) => {
          const lowerCase = /[a-z]/.test(value);
          const upperCase = /[A-Z]/.test(value);
          const numbers = /\d/.test(value);
          const specialCharacters = /[!@#$%^&*]/.test(value);

          const passedCategories = [lowerCase, upperCase, numbers, specialCharacters].filter(Boolean).length;
          return passedCategories >= 3;
        },
        {
          message:
            'Password must contain at least 3 of the following: lower case letters, upper case letters, numbers, special characters',
        }
      ),
    confirmPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: 'Passwords do not match',
    path: ['confirmPassword'],
  });

export type TCreatePasswordFormData = z.infer<typeof createPasswordSchema>;
