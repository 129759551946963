import React from 'react';
import { useDroppable } from '@dnd-kit/core';

import { cn } from '@/lib/utils';

export const Droppable: React.FC<{ id: string; children: React.ReactNode; className?: string }> = ({
  id,
  children,
  className,
}) => {
  const { setNodeRef, isOver, active } = useDroppable({
    id,
  });
  const isDragging = active !== null;
  return (
    <div
      ref={setNodeRef}
      className={cn(
        'h-full w-full rounded-lg border',
        isDragging && isOver ? 'border-primary-blue-40' : 'border-transparent',
        className
      )}
    >
      {children}
    </div>
  );
};
