import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { UilSearch } from '@iconscout/react-unicons';

import { cn } from '@/lib/utils';

import { inputVariants } from './input';

interface IProps {
  className?: string;
  placeholder?: string;
  value: string | undefined;
  onChange: (value: string) => void;
  debounce?: number;
}

const Search = forwardRef<HTMLInputElement, IProps>(
  ({ className, placeholder = 'Search', onChange, value = '', debounce = 700 }, ref) => {
    const [displayedValue, setDisplayedValue] = useState(value);

    const inputRef = useRef<HTMLInputElement | null>(null);

    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    useEffect(() => {
      setDisplayedValue(value);
    }, [value]);

    useEffect(() => {
      const handler = setTimeout(() => {
        if (inputRef?.current?.value !== value) {
          onChange(inputRef?.current?.value || '');
        }
      }, debounce);

      return () => {
        clearTimeout(handler);
      };
    }, [displayedValue, onChange, debounce, value]);

    return (
      <div className="relative">
        <input
          className={cn(inputVariants({ variant: 'default' }), className, 'pl-12')}
          placeholder={placeholder}
          value={displayedValue || ''}
          onChange={(e) => setDisplayedValue(e.target.value)}
          ref={inputRef}
        />
        <UilSearch className="absolute left-[1.12375rem] top-1/4 size-6 text-primary-dark-60" />
      </div>
    );
  }
);

Search.displayName = 'Search';

export default Search;
