import { useState } from 'react';
import { UilArrowLeft } from '@iconscout/react-unicons';
import { createFileRoute, useRouter } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';

import FullPageLoader from '@/components/full-page-loader';
import FeaturedIcon from '@/components/icons/featured-icon';
import RoleDetails from '@/components/roles/business/role-details/role-details';
import { CultureMatch } from '@/components/roles/details/new-culture-breakdown-card';
import { PersonalityMatch } from '@/components/roles/details/new-personality-breakdown';
import { SidePanel } from '@/components/roles/details/side-panel';

import { useGetCurrentCandidateSingleJobDetails } from '@/hooks/candidate';

import { parseJobPostCultureProfile, parseJobPostPersonalityProfile } from '@/services/business-roles';
import { TCandidateJobPost } from '@/services/candidate';

type TRoleReferrer = {
  referrer?: string;
};

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/(roles-pages)/role/$roleId')({
  component: RoleDetail,
  validateSearch: (search: Record<string, unknown>): TRoleReferrer => {
    const referrer = search.referrer as string | undefined;
    return {
      referrer,
    };
  },
});

function RoleDetail() {
  const { roleId } = Route.useParams();
  const router = useRouter();
  const [showPersonalityBreakdown, setShowPersonalityBreakdown] = useState(false);
  const [showCultureBreakdown, setShowCultureBreakdown] = useState(false);

  const { data, isLoading } = useGetCurrentCandidateSingleJobDetails({
    params: {
      path: {
        postId: roleId,
      },
    },
    reactQueryOptions: {
      enabled: Boolean(roleId),
    },
  });

  if (isLoading) return <FullPageLoader className="w-full" />;

  if (!data) return <NoPostDetail />;

  const togglePersonalityBreakdown = () => {
    setShowPersonalityBreakdown(!showPersonalityBreakdown);
    setShowCultureBreakdown(false);
    window.scrollTo(0, 0);
  };

  const toggleCultureBreakdown = () => {
    setShowCultureBreakdown(!showCultureBreakdown);
    setShowPersonalityBreakdown(false);
    window.scrollTo(0, 0);
  };

  const renderDetails = () => {
    if (showPersonalityBreakdown && data.personality_profile) {
      return (
        <PersonalityMatch
          rolePersonalityProfile={parseJobPostPersonalityProfile(data.personality_profile)}
          showCandidateData
        />
      );
    }
    if (showCultureBreakdown && data.culture_profile) {
      return (
        <CultureMatch
          roleCultureProfile={parseJobPostCultureProfile(data.culture_profile)}
          showCandidateData
        />
      );
    }
    return <RoleDetails role={data as TCandidateJobPost} />;
  };

  return (
    <div>
      <section className="w-full pb-8">
        <Button
          variant="tertiary"
          onClick={() => {
            if (showPersonalityBreakdown || showCultureBreakdown) {
              setShowPersonalityBreakdown(false);
              setShowCultureBreakdown(false);
            } else {
              router.history.back();
            }
          }}
        >
          <UilArrowLeft size={18} />
          Back
        </Button>
      </section>
      <main className="flex space-x-8">
        <div className="sticky top-8 h-[calc(100vh-48px)] w-full max-w-[320px] flex-shrink-0 xl:max-w-[340px] 2xl:max-w-[380px] 3xl:max-w-[460px]">
          <ScrollArea className="h-full">
            <SidePanel
              post={data as TCandidateJobPost}
              showPersonalityBreakdown={showPersonalityBreakdown}
              showCultureBreakdown={showCultureBreakdown}
              togglePersonalityBreakdown={togglePersonalityBreakdown}
              toggleCultureBreakdown={toggleCultureBreakdown}
            />
          </ScrollArea>
        </div>
        <section className="flex flex-1 flex-col space-y-8">{renderDetails()}</section>
      </main>
    </div>
  );
}

const NoPostDetail = () => {
  return (
    <div className="flex min-h-[525px] items-center justify-center text-center">
      <div className="mt-32 flex max-w-md flex-col items-center space-y-4">
        <FeaturedIcon
          variant="warning"
          size="lg"
        />
        <div className="space-y-2">
          <h4 className="font-semibold">Post not found</h4>
          <p className="text-xs text-primary-dark-60">The post you are looking for does not exist.</p>
        </div>
      </div>
    </div>
  );
};
