import { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { UilTrashAlt } from '@iconscout/react-unicons';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Select } from '@/components/ui/select-new';
import { Textarea } from '@/components/ui/textarea';

import { useGetCurrentBusiness, useRemoveBusinessPartner } from '@/hooks/business';

export function RemovePartnerButton({
  id,
  hideTrigger = false,
  triggerOpen = false,
  onOpenChange,
  onSubmit,
}: {
  id: string;
  hideTrigger?: boolean;
  triggerOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  onSubmit?: () => void;
}) {
  const [open, setOpen] = useState(false);
  const { data: business } = useGetCurrentBusiness({});

  const { mutate: removeMember, isPending } = useRemoveBusinessPartner({
    params: {
      path: {
        businessId: business?.id?.toString() ?? '',
        partnerId: id,
      },
    },
    reactQueryOptions: {
      onSuccess: () => {
        onSubmit?.();
        handleOpenChange(false);
      },
    },
  });

  const removeUserSchema = z.object({
    reason: z.string().min(1, 'Reason is required'),
    description: z.string().nullable().optional(),
  });

  type RemoveUserFormData = z.infer<typeof removeUserSchema>;

  const form = useForm<RemoveUserFormData>({
    resolver: zodResolver(removeUserSchema),
    defaultValues: {
      reason: '',
      description: '',
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = form;

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
    onOpenChange?.(newOpen);
    if (!newOpen) {
      reset({ reason: '', description: '' });
    }
  };

  useEffect(() => {
    if (triggerOpen) {
      handleOpenChange(true);
    }
  }, [triggerOpen]);

  const handleSubmitForm = () => {
    removeMember({
      params: {
        path: {
          businessId: business?.id?.toString() ?? '',
          partnerId: id,
        },
      },
    });
  };

  return (
    <AlertDialog
      open={open}
      onOpenChange={handleOpenChange}
    >
      {!hideTrigger && (
        <AlertDialogTrigger asChild>
          <Button
            size="sm"
            variant="destructive"
          >
            <UilTrashAlt />
            Remove
          </Button>
        </AlertDialogTrigger>
      )}

      <AlertDialogContent>
        <FormProvider {...form}>
          <AlertDialogHeader>
            <AlertDialogTitle className="flex items-center space-x-4">
              <Card className="flex h-8 w-8 items-center justify-center p-0">
                <UilTrashAlt />
              </Card>
              <span>Remove partner</span>
            </AlertDialogTitle>
            <AlertDialogDescription>Please tell us why you would like to remove this partner.</AlertDialogDescription>
          </AlertDialogHeader>
          <Form className="space-y-4">
            <FormField
              name="reason"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Reason</FormLabel>
                  <FormControl>
                    <Select
                      options={['Low ratings or feedback', 'Other (enter a description)'].map((s) => ({
                        value: s,
                        label: s,
                      }))}
                      selected={field.value}
                      onChange={(value) => field.onChange(value)}
                      error={!!errors?.reason?.message}
                    />
                  </FormControl>
                  {errors.reason && <FormError>{errors.reason.message}</FormError>}
                </FormItem>
              )}
            />
            <FormField
              name="description"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Enter description here..."
                      {...field}
                      value={field.value || ''}
                      error={!!errors?.description?.message}
                      rows={4}
                    />
                  </FormControl>
                  {errors.description && <FormError>{errors.description.message}</FormError>}
                </FormItem>
              )}
            />
          </Form>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              variant="destructive"
              onClick={handleSubmit(handleSubmitForm)}
              disabled={isPending}
            >
              Remove
            </AlertDialogAction>
          </AlertDialogFooter>
        </FormProvider>
      </AlertDialogContent>
    </AlertDialog>
  );
}
