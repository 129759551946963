import { createFileRoute } from '@tanstack/react-router';

import { Card } from '@/components/ui/card';
import Heading from '@/components/ui/typography/heading';

import PartnerSocialsBioForm from '@/components/forms/partner/partner-socials-bio-form';
import SettingContainer from '@/components/settings/setting-container';

export const Route = createFileRoute('/_authenticated/partner/_dashboard/settings/company/socials')({
  component: CompanySocialsCard,
});

function CompanySocialsCard() {
  return (
    <SettingContainer>
      <Heading
        title="Website & socials"
        subtitle="Update your website & social links."
      />
      <Card>
        <PartnerSocialsBioForm />
      </Card>
    </SettingContainer>
  );
}
