import { UilEnvelope } from '@iconscout/react-unicons';
import { createFileRoute } from '@tanstack/react-router';

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import Heading from '@/components/ui/typography/heading';

import SettingContainer from '@/components/settings/setting-container';

import EXTERNAL_URLS from '@/constants/external-urls';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/settings/faqs')({
  component: FAQs,
});

const faqs = [
  {
    question: 'How am I matched to roles?',

    answer:
      'You are matched to roles based on the information provided during onboarding & in your profile, such as your skills, experience, and preferences. Our AI-powered matching algorithm identifies opportunities that align with your qualifications and career goals.',
  },
  {
    question: 'Can I update my profile?',

    answer:
      'Yes, you can update your profile at any time. Simply log in to your account, go to the profile section, and make the necessary changes. Keeping your profile up-to-date ensures you receive the best matches.',
  },
  {
    question: 'Is my personal information secure?',

    answer:
      'Absolutely. We take data security very seriously and use industry-standard encryption to protect your personal information. We also adhere to strict privacy policies to ensure your data is only used for matching you with suitable job opportunities.',
  },
  {
    question: 'Can my current employer see my profile?',

    answer:
      'No, your current employer will not be able to see your profile. We take your privacy seriously and have measures in place to prevent your profile from being visible to your current employer.',
  },
  {
    question: 'How can I change my password or email?',

    answer:
      'To change your password or email, go to your profile. There, you will find options to update your password, email, and other account details.',
  },
  {
    question: 'Can I turn my availability on and off?',

    answer:
      'Yes, you can manage your availability settings from your profile. If you are currently unavailable for new opportunities, you can turn off your availability. You can turn it back on whenever you are ready to start receiving matches again.',
  },
  {
    question: 'How will I know if I have been matched to a role?',

    answer:
      'You will receive a notification via email and on your dashboard when you have been matched to a role. Be sure to check your notifications regularly to stay updated on new matches.',
  },
  {
    question: 'How can I apply for roles?',

    answer: 'You can apply directly for a role using a provided link, or if you have been matched to a role.',
  },
  {
    question: 'How can I keep track of my applications?',

    answer: 'All roles you have applied for will be visible under the ‘Roles’ section.',
  },
  {
    question: 'Can I apply for multiple roles at once?',

    answer:
      'Yes, you can apply for multiple roles at the same time. Our platform allows you to explore and apply for various opportunities that match your profile and interests.',
  },
  {
    question: 'How do I know the status of my application?',

    answer:
      'You can check the status of your application on your dashboard under the ‘Roles’ section. We also send email notifications to keep you informed about any updates or changes to your application status.',
  },
  {
    question: 'Where can I find my interview details?',

    answer:
      'Interview details will be sent to you via email and can also be found under the ‘Roles’ section and your Calendar. Each role you are interviewing for will include the date, time, and any other relevant information.',
  },
  {
    question: 'How do I deactivate my account?',

    answer:
      'If you wish to deactivate your account, you can do so by going through your profile. There, you will find the option to deactivate your account. Please note that deactivating your account will remove your profile from our platform and stop all notifications and matches.',
  },
  {
    question: 'What should I do if I encounter an issue?',

    answer:
      "If you encounter any issues, please contact our support team through the 'Support' section on our platform. We are here to assist you and resolve any problems you may face.",
  },
];

function FAQs() {
  return (
    <SettingContainer>
      <div className="flex flex-col gap-4">
        <Heading
          title="FAQs"
          subtitle="Didn’t find what you were looking for?"
        />
        <a
          href={EXTERNAL_URLS.SUPPORT_PORTAL}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            variant="tertiary"
            className="flex gap-2"
          >
            <UilEnvelope className="h-[18px] w-[18px]" />
            <span>Contact us</span>
          </Button>
        </a>
      </div>
      <div className="space-y-8">
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            type="single"
            collapsible
            className="w-full"
          >
            <AccordionItem value={faq.question}>
              <AccordionTrigger>{faq.question}</AccordionTrigger>
              <AccordionContent>{faq.answer}</AccordionContent>
            </AccordionItem>
          </Accordion>
        ))}
      </div>
    </SettingContainer>
  );
}
