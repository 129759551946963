import { UilSuitcase, UilTrashAlt, UilUserCircle } from '@iconscout/react-unicons';
import { useNavigate } from '@tanstack/react-router';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownTriggerButton,
} from '@/components/ui/dropdown-menu';

import { useGetCurrentUserPermissions } from '@/hooks/permission';

interface IProps {
  candidateId: number;
  postId: number;
  onRejectClick: (candidateId: number) => void;
  onNextStepClick: (candidateId: number) => void;
}

const PipelineActionButton: React.FC<IProps> = ({ candidateId, postId, onRejectClick, onNextStepClick }) => {
  const navigate = useNavigate();
  const { data: permissions } = useGetCurrentUserPermissions();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <DropdownTriggerButton />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem
          onClick={() => navigate({ to: `/business/pipeline-candidate-profile/${postId}/${candidateId}/profile` })}
        >
          <UilUserCircle className="default-icon-sm" /> Profile
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => onNextStepClick(candidateId)}
          disabled={!permissions?.manage_pipelines}
        >
          <UilSuitcase className="default-icon-sm" />
          Next Steps
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => onRejectClick(candidateId)}
          disabled={!permissions?.manage_pipelines}
        >
          <UilTrashAlt className="default-icon-sm" />
          Reject
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default PipelineActionButton;
