import { CultureName, PersonalityArchetypeCode } from '@/services/candidate';

export const CANDIDATE_OPEN_TO_WORK = {
  YES: '1',
  NO: '0',
};

export const PERSONALITY_CODE_TO_EMOJI_MAP: Record<PersonalityArchetypeCode, string> = {
  X: '🦁',
  H: '🐶',
  O: '🐬',
  A: '🐙',
  E: '🐢',
  C: '🐜',
};

export const CULTURE_TO_EMOJI_MAP: Record<CultureName, string> = {
  Clan: '🐺',
  Adhocracy: '🦄',
  Market: '🦅',
  Hierarchy: '🐝',
};

export const getRandomPersonalityTestInfo = () => {
  const personalityTestInfo = {
    H: Math.floor(Math.random() * 9),
    E: Math.floor(Math.random() * 9),
    X: Math.floor(Math.random() * 9),
    C: Math.floor(Math.random() * 9),
    A: Math.floor(Math.random() * 9),
    O: Math.floor(Math.random() * 9),
  };
  return personalityTestInfo;
};

export const PERSONALITY_ClASS_MAP: Record<PersonalityArchetypeCode, string> = {
  X: 'bg-primary-warning-5 border-primary-warning-40 outline-primary-warning-40',
  H: 'bg-secondary-yellow-5 border-secondary-yellow-40 outline-secondary-yellow-40',
  O: 'bg-primary-blue-5 border-primary-blue-40 outline-primary-blue-40',
  A: 'bg-secondary-red-5 border-secondary-red-40 outline-secondary-red-40',
  E: 'bg-secondary-green-5 border-secondary-green-40 outline-secondary-green-40',
  C: 'bg-secondary-purple-5 border-secondary-purple-40 outline-secondary-purple-40',
};

export const CULTURE_CLASS_NAME_MAP: Record<CultureName, string> = {
  Clan: 'bg-primary-blue-5 border-primary-blue-40 outline-primary-blue-40',
  Adhocracy: 'bg-secondary-purple-5 border-secondary-purple-40 outline-secondary-purple-40',
  Market: 'bg-secondary-green-5 border-secondary-green-40 outline-secondary-green-40',
  Hierarchy: 'bg-secondary-yellow-5 border-secondary-yellow-40 outline-secondary-yellow-40',
};
