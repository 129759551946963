import { z } from 'zod';

export const invitePartnerSchema = z.object({
  account_type: z.string().min(1, { message: 'Account type is required' }),
  email: z.string().email({ message: 'Email is required' }),
  categories: z.array(z.string()).optional(),
  name: z.string().min(1, { message: 'Business Name is required' }),
});

export type TInvitePartnerFormData = z.infer<typeof invitePartnerSchema>;
