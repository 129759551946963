import { createFileRoute } from '@tanstack/react-router';

import { Card } from '@/components/ui/card';
import Heading from '@/components/ui/typography/heading';

import BusinessSocialsForm from '@/components/forms/business/business-socials-form';
import SettingContainer from '@/components/settings/setting-container';

import { useGetCurrentUserPermissions } from '@/hooks/permission';

export const Route = createFileRoute('/_authenticated/business/_dashboard/settings/company/socials')({
  component: CompanySocialsCard,
});

function CompanySocialsCard() {
  const { data: permissions } = useGetCurrentUserPermissions();

  return (
    <SettingContainer>
      <Heading
        title="Website & socials"
        subtitle="Update your website & social links."
      />
      <Card>
        <BusinessSocialsForm isDisabled={Boolean(!permissions?.manage_company_settings)} />
      </Card>
    </SettingContainer>
  );
}
