import { z } from 'zod';

// Regular expression to validate URLs in the format "subdomain.example.com" or "example.com"
const urlRegex = /^(?!https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/;

export const partnerSocialsBioSchema = z.object({
  website: z
    .string()
    .regex(urlRegex, 'Website must be in the format of www.example.com')
    .or(z.literal(''))
    .optional()
    .nullable(),
  linkedin_url: z
    .string()
    .regex(urlRegex, 'LinkedIn URL must be in the format of www.example.com')
    .or(z.literal(''))
    .optional()
    .nullish(),
  bio: z.string().min(1, 'Your bio is required').max(1000, 'Your bio can not be longer than 1000 characters.'),
});

export type TPartnerSocialsBioFormData = z.infer<typeof partnerSocialsBioSchema>;
