import { createFileRoute } from '@tanstack/react-router';

import { Card } from '@/components/ui/card';
import Heading from '@/components/ui/typography/heading';

import BusinessProfileInfoForm from '@/components/forms/business/business-info-form';
import SettingContainer from '@/components/settings/setting-container';

export const Route = createFileRoute('/_authenticated/partner/_dashboard/settings/company/')({
  component: CompanyInfoCard,
});

function CompanyInfoCard() {
  return (
    <SettingContainer>
      <Heading
        title="Business details"
        subtitle="Update your business details and profile photo."
      />
      <Card>
        <BusinessProfileInfoForm />
      </Card>
    </SettingContainer>
  );
}
