import { useEffect, useState } from 'react';
import { createFileRoute } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';

import { ExperienceCard } from '@/components/onboarding/experience-card';
import { ExperienceForm } from '@/components/onboarding/experience-form';
import OnboardingFooter from '@/components/onboarding/footer';

import { useGetCurrentCandidateSuspense, useUpdateCurrentCandidate } from '@/hooks/candidate';
import useStepper from '@/hooks/useStepper';
import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { getExperiencesFromAPI, getFormattedExperiencesForAPI } from '@/utils/format';

import { VALUES_GROUP } from '@/constants/values-map';

import { TExperienceFormData } from '@/validation-schemas/experience-schema';

export const Route = createFileRoute('/_authenticated/candidate/onboarding/_steps/step3')({
  component: Step3,
});

function Step3() {
  const { data } = useGetCurrentCandidateSuspense({});
  const { data: rolesList } = useGetValuesMapByGroup({
    params: {
      query: {
        limit: '100',
        offset: '0',
        value_group: VALUES_GROUP.ROLES,
      },
    },
  });

  const { goToNextStep } = useStepper();
  const { mutateAsync: updateCandidate, isPending: isUpdatingCandidate } = useUpdateCurrentCandidate({
    reactQueryOptions: { onSuccess: goToNextStep },
  });

  const [experiences, setExperiences] = useState<TExperienceFormData[]>([]);
  const [currentFormIndex, setCurrentFormIndex] = useState<number | null>(null);

  useEffect(() => {
    if (data) {
      const {
        experience_1_compname,
        experience_1_start_date,
        experience_1_role,
        experience_1_end_date,
        experience_1_is_current,
        experience_2_compname,
        experience_2_start_date,
        experience_2_role,
        experience_2_end_date,
        experience_2_is_current,
        experience_3_compname,
        experience_3_start_date,
        experience_3_role,
        experience_3_end_date,
        experience_3_is_current,
      } = data;

      const experiencesFromAPI = getExperiencesFromAPI({
        experience_1_compname,
        experience_1_start_date,
        experience_1_role,
        experience_1_end_date,
        experience_1_is_current,
        experience_2_compname,
        experience_2_start_date,
        experience_2_role,
        experience_2_end_date,
        experience_2_is_current,
        experience_3_compname,
        experience_3_start_date,
        experience_3_role,
        experience_3_end_date,
        experience_3_is_current,
      });
      setExperiences(experiencesFromAPI);
      setCurrentFormIndex(experiencesFromAPI.length === 0 ? 0 : null);
    }
  }, [data]);

  const handleCancel = () => {
    setCurrentFormIndex(null);
  };

  const saveExperience = (index: number, data: TExperienceFormData) => {
    setExperiences((prevExperiences) => {
      const updatedExperiences = [...prevExperiences];
      if (index >= updatedExperiences.length) {
        updatedExperiences.push(data);
      } else {
        updatedExperiences[index] = data;
      }
      return updatedExperiences;
    });
    setCurrentFormIndex(null);
  };

  const onSubmit = async () => {
    const formattedExperiences = getFormattedExperiencesForAPI(experiences);
    await updateCandidate({ body: formattedExperiences });
  };

  return (
    <div className="flex flex-col gap-2">
      {experiences.map((experience, index) =>
        currentFormIndex === index ? (
          <ExperienceForm
            key={index}
            isLoading={false}
            index={index}
            data={experience}
            roles={
              rolesList?.items.map((s) => s.description).filter((s): s is string => s !== undefined && s !== null) || []
            }
            save={saveExperience}
            onCancel={handleCancel}
            className={currentFormIndex === 0 ? 'mb-8' : 'my-8'}
          />
        ) : (
          <ExperienceCard
            key={index}
            {...experience}
            onEdit={() => setCurrentFormIndex(index)}
          />
        )
      )}
      {currentFormIndex === experiences.length && (
        <ExperienceForm
          key={currentFormIndex}
          isLoading={false}
          index={currentFormIndex}
          data={{} as TExperienceFormData}
          roles={
            rolesList?.items.map((s) => s.description).filter((s): s is string => s !== undefined && s !== null) || []
          }
          save={saveExperience}
          onCancel={handleCancel}
          className={currentFormIndex === 0 ? 'mb-8' : 'my-8'}
        />
      )}
      {experiences.length < 3 && currentFormIndex === null && (
        <div className="flex w-full justify-end">
          <Button
            onClick={() => setCurrentFormIndex(experiences.length)}
            variant="link"
            className="flex w-auto font-semibold text-primary-blue-100"
          >
            + Add work experience
          </Button>
        </div>
      )}
      <OnboardingFooter
        isLoading={isUpdatingCandidate}
        isDisabled={experiences.length < 1}
        handleNext={onSubmit}
      />
    </div>
  );
}

export default Step3;
