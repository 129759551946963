import { useMatchRoute, useNavigate, useSearch } from '@tanstack/react-router';

import Search from '@/components/ui/search';

import { cleanEmptyParams } from '@/utils/string';

const RolesSearch = () => {
  const navigate = useNavigate();
  const matchRoute = useMatchRoute();
  const { query } = useSearch({
    strict: false,
  });

  const value = matchRoute({ to: '/candidate/roles' }) ? query : '';

  return (
    <Search
      value={value}
      onChange={(value) =>
        navigate({ to: '/candidate/roles', search: (prev) => cleanEmptyParams({ ...prev, query: value }) })
      }
    />
  );
};

export default RolesSearch;
