const candidateStepDetails = [
  {
    title: 'Getting started',
    description: 'What type of work are you looking for?',
  },
  {
    title: 'Getting started',
    description: 'What are your expectations?',
  },
  {
    title: 'Experience',
    description: 'What are your last 3 roles?',
    info: 'Please add at least one.',
  },
  {
    title: 'Experience',
    description: 'What kind of businesses have you worked in?',
  },
  {
    title: 'Education',
    description: 'What level of education do you have?',
  },
  {
    title: 'Profile',
    description: 'Upload a photo of yourself to stand out with clients.',
  },
  {
    title: 'Bio & extras',
    description: 'Tell us about yourself! Highlight your passions, skills, experiences, achievements and values.',
  },
  {
    title: 'Bio & extras',
    description: 'Tell us about yourself! Upload your CV and and your website or social links.',
  },
];

const businessStepDetails = [
  {
    title: 'Getting started',
    description: 'Please provide your business details',
  },
  {
    title: 'Business details',
    description: 'Tell us more about your business',
  },
  {
    title: 'Website & socials',
    description: 'Please provide your website and social links',
  },
  {
    title: 'Bio & extras',
    description: 'Tell us more about your business',
  },
];

const partnerStepDetails = [
  {
    title: 'Getting started',
    description: 'Please provide your business details',
  },
  {
    title: 'Website & bio',
    description: 'Please provide your website and a bio',
  },
];

export function getStepDetails(
  domain: 'candidate' | 'business' | 'partner'
): { title: string; description: string; info?: string }[] {
  if (domain === 'business') return businessStepDetails;
  if (domain === 'partner') return partnerStepDetails;
  return candidateStepDetails;
}
