import { createFileRoute } from '@tanstack/react-router';

import ProfileFrom from '@/components/forms/candidate/profile-form';

import useStepper from '@/hooks/useStepper';

export const Route = createFileRoute('/_authenticated/candidate/onboarding/_steps/step6')({
  component: Step6,
});

function Step6() {
  const { goToNextStep } = useStepper();

  return (
    <ProfileFrom
      onSuccess={goToNextStep}
      isOnboarding
    />
  );
}
