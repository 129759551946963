import { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute } from '@tanstack/react-router';
import { useForm } from 'react-hook-form';

import { Card } from '@/components/ui/card';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { PhoneInput } from '@/components/ui/phone-input';

import { useGetBusinessTeamMembersDetails, useGetCurrentBusiness } from '@/hooks/business';

import { profileDetailsSchema, TProfileDetailsFormData } from '@/validation-schemas/personal-details.schema';

export const Route = createFileRoute('/_authenticated/partner/_dashboard/team/$profile/')({
  component: TeamProfile,
});

function TeamProfile() {
  return (
    <div className="flex w-full space-x-8">
      <div className="w-[420px] flex-shrink-0 lg:max-w-[320px] xl:max-w-[360px] 2xl:max-w-[400px] 3xl:max-w-[460px]">
        <h3 className="text-xl font-semibold leading-[30px] text-primary-dark-100">Personal</h3>
        <p className="text-base font-medium leading-normal text-primary-dark-60">Team members personal details</p>
      </div>
      <div className="grow">
        <Card className="p-8">
          <TeamProfileForm />
        </Card>
      </div>
    </div>
  );
}

export function TeamProfileForm() {
  const { data: business } = useGetCurrentBusiness({});
  const { profile: memberId } = Route.useParams();
  const { data: memberProfile } = useGetBusinessTeamMembersDetails({
    params: {
      path: {
        businessId: business?.id.toString() as string,
        memberId: memberId,
      },
    },
    reactQueryOptions: {
      enabled: !!memberId && !!business,
    },
  });
  const form = useForm<TProfileDetailsFormData>({
    resolver: zodResolver(profileDetailsSchema),
    defaultValues: {
      first_name: memberProfile?.first_name ?? '',
      last_name: memberProfile?.last_name ?? '',
      email: memberProfile?.email ?? '',
      primary_phone: memberProfile?.primary_phone ?? '',
    },
  });

  const {
    control,
    formState: { errors },
  } = form;

  useEffect(() => {
    if (memberProfile) {
      form.setValue('first_name', memberProfile?.first_name ?? '');
      form.setValue('last_name', memberProfile?.last_name ?? '');
      form.setValue('email', memberProfile?.email ?? '');
      form.setValue('primary_phone', memberProfile?.primary_phone ?? '');
    }
  }, [memberProfile]);

  return (
    <FormProvider {...form}>
      <Form>
        <div className="flex items-center gap-x-8">
          <FormField
            control={control}
            name="first_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>First name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter your first name"
                    {...field}
                    disabled
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="last_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Last name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter your last name"
                    {...field}
                    disabled
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter your email"
                  {...field}
                  disabled
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="primary_phone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Phone Number</FormLabel>
              <FormControl>
                <PhoneInput
                  {...field}
                  error={!!errors?.primary_phone?.message}
                  disabled
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>
    </FormProvider>
  );
}
