import { createFileRoute } from '@tanstack/react-router';

import BusinessSocialsForm from '@/components/forms/business/business-socials-form';

import useStepper from '@/hooks/useStepper';

export const Route = createFileRoute('/_authenticated/business/onboarding/_steps/step3')({
  component: Step3,
});

function Step3() {
  const { goToNextStep } = useStepper('business');

  return (
    <BusinessSocialsForm
      onSuccess={goToNextStep}
      isOnboarding
    />
  );
}
