import { createFileRoute } from '@tanstack/react-router';

import { Card, CardContent, CardTitle } from '@/components/ui/card';

import BackButton from '@/components/back-button';
import FullPageLoader from '@/components/full-page-loader';
import RoleDetails from '@/components/roles/business/role-details/role-details';
import RoleDetailsSidePanel from '@/components/roles/business/role-details/roles-details-side-panel';

import { useGetCurrentPartnerBusiness } from '@/hooks/business';
import { useGetJobPostById } from '@/hooks/business-roles';

export const Route = createFileRoute('/_authenticated/partner/_dashboard/role/$roleId')({
  component: RolePage,
});

function RolePage() {
  const { roleId } = Route.useParams();
  const { data: business, isPending: isBusinessPending } = useGetCurrentPartnerBusiness();

  const { data: role, isPending } = useGetJobPostById({
    params: {
      path: {
        businessId: business!.id.toString(),
        postId: roleId,
      },
    },
    reactQueryOptions: {
      enabled: !!business,
    },
  });

  if (isPending || isBusinessPending) return <FullPageLoader className="w-full" />;

  if (!role)
    return (
      <Card className="w-full">
        <CardContent>
          <CardTitle>Role not found</CardTitle>
        </CardContent>
      </Card>
    );

  return (
    <main className="space-y-6">
      <BackButton backRoute={'/business/roles'} />
      <div className="flex gap-8">
        <section className="w-full max-w-[360px] flex-shrink-0 2xl:max-w-[380px] 3xl:max-w-[460px]">
          <RoleDetailsSidePanel role={role} />
        </section>
        <section className="flex-grow">
          <RoleDetails role={role} />
        </section>
      </div>
    </main>
  );
}
