import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { PhoneInput } from '@/components/ui/phone-input';

export const twoFASetupSchema = z
  .object({
    phoneNumber: z.string().min(1, 'Phone number is required'),
  })
  .refine((data) => isValidPhoneNumber(data.phoneNumber), {
    message: 'Invalid phone number',
    path: ['phoneNumber'],
  });

export type TTwoFASetupFormData = z.infer<typeof twoFASetupSchema>;

const TwoFASetupForm = ({
  onSuccess,
  defaultPhoneNumber,
}: {
  onSuccess: (data: TTwoFASetupFormData) => void;
  defaultPhoneNumber?: string;
}) => {
  const form = useForm<TTwoFASetupFormData>({
    resolver: zodResolver(twoFASetupSchema),
    defaultValues: {
      phoneNumber: defaultPhoneNumber || '',
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = form;

  const onSubmit = (data: TTwoFASetupFormData) => {
    console.log(data);
    onSuccess(data);
  };

  return (
    <div className="space-y-4">
      <div>
        <h3 className="text-2xl font-semibold leading-loose text-primary-dark-100">Set up two-factor authentication</h3>
        <p className="text-primary-dark-60">
          We take security seriously. Enter in your phone number to secure your account.
        </p>
      </div>

      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormField
            control={control}
            name="phoneNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Phone Number</FormLabel>
                <FormControl>
                  <PhoneInput
                    {...field}
                    error={!!errors?.phoneNumber}
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
          <Button
            type="submit"
            className="w-full"
            size="md"
          >
            Continue
          </Button>
        </Form>
      </FormProvider>
    </div>
  );
};

export default TwoFASetupForm;
