import { z } from 'zod';

export const businessProfileDetailsSchema = z.object({
  business_type: z.string().min(1, 'This is required'),
  industry: z.string().min(1, 'This is required'),
  company_values: z
    .array(z.string())
    .min(1, 'Please select at least one value')
    .max(10, 'Company values must be 10 items or less'),
});

export type TBusinessProfileDetailsFormData = z.infer<typeof businessProfileDetailsSchema>;
