import { z } from 'zod';

// Regular expression to validate URLs in the format "subdomain.example.com" or "example.com"
const urlRegex = /^(?!https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/;

export const businessSocialsSchema = z.object({
  website: z
    .string()
    .regex(urlRegex, 'Website must be in the format of www.example.com')
    .or(z.literal(''))
    .optional()
    .nullable(),
  linkedin_url: z
    .string()
    .regex(urlRegex, 'LinkedIn URL must be in the format of www.example.com')
    .or(z.literal(''))
    .optional()
    .nullish(),
  twitter_url: z
    .string()
    .regex(urlRegex, 'Twitter URL must be in the format of www.example.com')
    .or(z.literal(''))
    .optional()
    .nullish(),
  facebook_url: z
    .string()
    .regex(urlRegex, 'Facebook URL must be in the format of www.example.com')
    .or(z.literal(''))
    .optional()
    .nullish(),
  tiktok_url: z
    .string()
    .regex(urlRegex, 'TikTok URL must be in the format of www.example.com')
    .or(z.literal(''))
    .optional()
    .nullish(),
  instagram_url: z
    .string()
    .regex(urlRegex, 'Instagram URL must be in the format of www.example.com')
    .or(z.literal(''))
    .optional()
    .nullish(),
});

export type TBusinessSocialsFormData = z.infer<typeof businessSocialsSchema>;
