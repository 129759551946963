import { paths } from '@/lib/api/v1';

export const USER_ENDPOINTS = {
  GET_CURRENT_USER: '/v1/users/me',
  UPDATE_PASSWORD: '/v1/users/me/change-password',
  UPDATE_CURRENT_USER: '/v1/users/me',
  DEACTIVATE_ACCOUNT: '/v1/users/me/deactivate',
  SEND_REACTIVATION_EMAIL: '/v1/users/me/send-reactivation-email',
  REACTIVATE_ACCOUNT: '/v1/users/me/reactivate',
} satisfies Record<string, keyof paths>;
