import { createFileRoute } from '@tanstack/react-router';

import ProfileFrom from '@/components/forms/candidate/profile-form';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/settings/profile/profile')({
  component: Profile,
});

function Profile() {
  return <ProfileFrom />;
}
