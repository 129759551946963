import { UilTimes } from '@iconscout/react-unicons';

import Avatar from '@/components/ui/avatar';
import { Tag } from '@/components/ui/tag';

import { cn } from '@/lib/utils';

interface IProps {
  name: string;
  imageUrl?: string | null;
  onCrossClick?: () => void;
}

const TeamMemberTag: React.FC<IProps> = ({ name, imageUrl, onCrossClick }) => {
  return (
    <Tag
      hideDot
      className={cn(onCrossClick && 'cursor-pointer')}
      onClick={onCrossClick}
    >
      <Avatar
        src={imageUrl}
        size="xxs"
      />
      <span>{name}</span>
      {onCrossClick && (
        <UilTimes
          className="text-primary-dark-60"
          size={14}
        />
      )}
    </Tag>
  );
};

export default TeamMemberTag;
