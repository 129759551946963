import { usePipelineStore } from '@/store/pipeline-store';
import { createFileRoute } from '@tanstack/react-router';

import BusinessPipelineBoard from '@/components/pipeline/business/business-pipeline-board';
import { RoleSelectModal } from '@/components/pipeline/business/role-select-modal';

import { useGetCurrentBusiness } from '@/hooks/business';
import { useGetCurrentUserPermissions } from '@/hooks/permission';
import { useGetAllCandidatesForPipelineBoard } from '@/hooks/pipeline';

export const Route = createFileRoute('/_authenticated/business/_dashboard/pipeline/')({
  component: Pipeline,
});

function Pipeline() {
  const { data: selectedRoles, setData: setSelectedRoles } = usePipelineStore();

  const selectedRolesIds = selectedRoles.map((item) => item.id);
  const { data: business } = useGetCurrentBusiness({});
  const { data: permissions } = useGetCurrentUserPermissions();
  const { data, isLoading } = useGetAllCandidatesForPipelineBoard({
    params: {
      path: {
        businessId: business?.id.toString() as string,
      },
      query: {
        limit: '0',
        offset: '0',
        job_post_ids: selectedRolesIds.join(','),
      },
    },
    reactQueryOptions: {
      queryKey: selectedRolesIds,
      enabled: !!business && selectedRolesIds.length > 0,
    },
  });

  if (selectedRoles?.length === 0 && business?.id) {
    return (
      <RoleSelectModal
        businessId={business?.id}
        onSubmit={setSelectedRoles}
      />
    );
  }

  return (
    <BusinessPipelineBoard
      isViewOnly={!permissions?.manage_pipelines}
      className="flex-1"
      candidates={data?.items || []}
      isLoading={isLoading}
      queryKey1={selectedRolesIds}
    />
  );
}
