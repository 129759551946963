import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { SubmitHandler, useForm } from 'react-hook-form';

import Avatar from '@/components/ui/avatar';
import DatePicker from '@/components/ui/date-picker';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { PhoneInput } from '@/components/ui/phone-input';
import { toast } from '@/components/ui/toaster';

import FileUploader from '@/components/file-uploader';

import { useGetCurrentUser, useUpdateCurrentUser } from '@/hooks/user';

import { castToStringOrNull } from '@/utils/string';

import { businessUserInfoSchema, TBusinessUserInfoFormData } from '@/validation-schemas/business/business-user-schema';

import FormFooter from '../../form-footer';
import FormNavigationBlocker from '../../form-navigation-blocker';

interface IBusinessUserInfoFormProps {
  defaultValues?: TBusinessUserInfoFormData;
}
const BusinessUserInfoForm: React.FC<IBusinessUserInfoFormProps> = ({ defaultValues }) => {
  const { data: user } = useGetCurrentUser({});
  const form = useForm<TBusinessUserInfoFormData>({
    resolver: zodResolver(businessUserInfoSchema),
    defaultValues: {
      first_name: user?.first_name ?? '',
      last_name: user?.last_name ?? '',
      image_url: user?.image_url ?? '',
      email: user?.email ?? '',
      primary_phone: user?.primary_phone ?? '',
      dob: new Date(user?.dob as string),
    },
    values: defaultValues,
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = form;
  const { mutateAsync: updateUser, isPending: isSubmitting } = useUpdateCurrentUser({});

  const handleFileUpload = (url: string) => {
    setValue('image_url', url, { shouldDirty: true });
  };

  const onSubmit: SubmitHandler<TBusinessUserInfoFormData> = async (data) => {
    try {
      const { dob, ...rest } = data;
      await updateUser({
        body: {
          ...rest,
          dob: format(dob, 'yyyy-MM-dd'),
          secondary_phone: castToStringOrNull(user?.secondary_phone),
          title: user?.title as string,
          gender: user?.gender,
          username: user?.email as string,
        },
      });
      form.reset(data);
    } catch (error) {
      toast.error('Error updating user!');
    }
  };

  return (
    <FormProvider {...form}>
      <Form>
        <div className="flex h-[70px] items-center gap-4">
          <Avatar
            src={getValues('image_url')}
            size="xl"
          />
          <div className="flex flex-col gap-4">
            <FileUploader
              acceptedFileTypes={['image/jpeg', 'image/png']}
              keyPrefix={`user/${user?.id}`}
              onFileUpload={handleFileUpload}
            />
            <p className="text-xs font-medium leading-[18px] text-primary-dark-60">
              Upload a JPEG or PNG file no bigger than 6mb
            </p>
          </div>
        </div>
        <div className="flex items-center gap-x-8">
          <FormField
            control={control}
            name="first_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>First name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter your first name"
                    {...field}
                    error={!!errors?.first_name?.message}
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="last_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Last name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter your last name"
                    {...field}
                    error={!!errors?.last_name?.message}
                  />
                </FormControl>
                <FormError />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter your email"
                  {...field}
                  error={!!errors?.email?.message}
                  disabled
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="primary_phone"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Phone Number</FormLabel>
              <FormControl>
                <PhoneInput
                  {...field}
                  error={!!errors?.primary_phone?.message}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="dob"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Date of Birth</FormLabel>
              <FormControl>
                <DatePicker
                  selected={field.value}
                  onChange={field.onChange}
                  showYearDropdown
                  error={!!errors?.dob?.message}
                  maxDate={new Date()}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />

        <FormNavigationBlocker />
      </Form>
      <FormFooter
        onSubmit={handleSubmit(onSubmit)}
        isSubmitting={isSubmitting}
      />
    </FormProvider>
  );
};

export default BusinessUserInfoForm;
