import React from 'react';

import { Progress } from '@/components/ui/progress';

import { Logo } from '@/components/logo';

import useStepper from '@/hooks/useStepper';

import { getStepDetails } from '@/constants/onboarding';

const OnboardingHeader: React.FC<{ domain?: 'candidate' | 'business' | 'partner' }> = ({
  domain = 'candidate',
}: {
  domain?: 'candidate' | 'business' | 'partner';
}) => {
  const { currentStep } = useStepper(domain);
  const stepDetails = getStepDetails(domain);
  if (currentStep === 0) {
    return null;
  }

  return (
    <section className="w-full items-center justify-center">
      <div className="w-full py-6 3xl:py-8">
        <Progress value={((currentStep - 1) / (stepDetails.length - 1)) * 100} />
      </div>
      <div className="flex w-full flex-col px-8">
        <div className="py-2">
          <Logo variant="mini" />
        </div>
        <div className="flex w-full flex-row items-center justify-between py-4 3xl:pt-8">
          <h1 className="text-2xl font-semibold leading-loose text-primary-dark-100">
            {stepDetails[currentStep - 1].title}
          </h1>
          <span className="text-base font-semibold leading-normal text-primary-dark-60">
            {currentStep} of {stepDetails.length}
          </span>
        </div>
        <div className="py-2 text-base font-medium leading-normal text-primary-dark-60 3xl:py-4">
          {stepDetails[currentStep - 1].description}
        </div>
        {stepDetails[currentStep - 1]?.info && (
          <div className="py-1 text-sm font-medium leading-tight text-primary-dark-60 3xl:py-2">
            {stepDetails[currentStep - 1]?.info}
          </div>
        )}
      </div>
    </section>
  );
};

export default OnboardingHeader;
