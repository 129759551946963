import { useState } from 'react';
import { createFileRoute, redirect } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';

import FeaturedIcon from '@/components/icons/featured-icon';

import useAuth from '@/hooks/useAuth';
import { useSendReactivationEmail } from '@/hooks/user';

import { getCurrentUser, USER_ENDPOINTS } from '@/services/user';

export const Route = createFileRoute('/_account/deactivated')({
  component: DeactivatedScreen,
  beforeLoad: async ({ location, context }) => {
    const { isAuthenticated } = context.auth;
    const queryClient = context.queryClient;

    if (!isAuthenticated) {
      throw redirect({
        to: '/login',
        search: {
          // preserve original intended route
          redirect: location.href,
        },
      });
    }
    try {
      const user = await queryClient.fetchQuery({
        queryFn: getCurrentUser,
        queryKey: [USER_ENDPOINTS.GET_CURRENT_USER],
        staleTime: Infinity,
      });
      // Check if user account is decativated
      if (user?.status !== 4) {
        return redirect({
          to: '/',
        });
      }
    } catch (err) {
      throw redirect({
        to: '/error-critical',
        search: {
          // preserve original intended route
          redirect: location.href,
        },
      });
    }
  },
});

function DeactivatedScreen() {
  const { logout } = useAuth();
  const [isEmailSent, setIsEmailSent] = useState(false);
  const { mutateAsync: sendReactivateEmail, isPending } = useSendReactivationEmail({
    reactQueryOptions: { onSuccess: () => setIsEmailSent(true) },
  });
  return (
    <div className="w-svh relative h-svh bg-slate-100">
      <div className="mx-auto flex h-full flex-col">
        <div className="absolute flex h-full w-full">
          <div className="m-auto flex">
            {!isEmailSent ? (
              <Card className="border-none p-8 shadow-md">
                <CardHeader className="pb-4">
                  <CardTitle className="flex flex-row items-center gap-x-4">
                    <FeaturedIcon variant="warning" />
                    <div className="text-2xl font-semibold">Account deactivated</div>
                  </CardTitle>
                </CardHeader>
                <CardContent className="max-w-[460px] py-4">
                  <p className="font-medium text-primary-dark-60">Your account was deactivated.</p>
                  <br />
                  <p className="font-medium text-primary-dark-60">
                    If you would like to reactivate your account, please select ‘Reactivate account’ and we will send
                    you an email with an reactivation link.
                  </p>
                </CardContent>
                <CardFooter className="flex justify-end gap-x-4">
                  <Button
                    variant="tertiary"
                    onClick={logout}
                  >
                    Sign out
                  </Button>
                  <Button
                    onClick={() => sendReactivateEmail()}
                    loading={isPending}
                  >
                    Reactivate account
                  </Button>
                </CardFooter>
              </Card>
            ) : (
              <Card className="border-none p-8 shadow-md">
                <CardHeader className="pb-4">
                  <CardTitle className="flex flex-row items-center gap-x-4">
                    <FeaturedIcon variant="success" />
                    Reactivation link sent!
                  </CardTitle>
                </CardHeader>
                <CardContent className="max-w-[500px] py-4">
                  <p className="font-medium text-primary-dark-60">
                    Please click the link in the email to reactivate your account.
                  </p>
                </CardContent>
                {/* <CardFooter className="flex justify-end gap-x-4">
                  <Button
                    variant="tertiary"
                    onClick={logout}
                  >
                    Sign out
                  </Button>
                  <Button> Resend</Button>
                </CardFooter> */}
              </Card>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
