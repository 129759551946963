import { z } from 'zod';

export const expectationsFormSchema = z
  .object({
    expectation_currency: z.string(),
    expectation_annual_salary_from: z.coerce.number().min(0).nullable(),
    expectation_annual_salary_to: z.coerce.number().min(0).nullable(),
    expectation_day_rate_from: z.coerce.number().min(1),
    expectation_day_rate_to: z.coerce.number().min(8),
    expectation_hourly_rate_from: z.coerce.number().min(1),
    expectation_hourly_rate_to: z.coerce.number().min(8),
    other_expectations: z.array(z.string()),
  })
  .superRefine((data, ctx) => {
    // Check if day rate and hourly rate pairs are filled
    if (!data.expectation_day_rate_from || !data.expectation_day_rate_to) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Both "Day rate from" and "Day rate to" must be filled.',
        path: ['expectation_day_rate_from'],
      });
    }
    if (!data.expectation_hourly_rate_from || !data.expectation_hourly_rate_to) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Both "Hourly rate from" and "Hourly rate to" must be filled.',
        path: ['expectation_hourly_rate_from'],
      });
    }

    // Check if annual salary is filled as a pair (if provided)
    if (
      (data.expectation_annual_salary_from && !data.expectation_annual_salary_to) ||
      (!data.expectation_annual_salary_from && data.expectation_annual_salary_to)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Both "Annual salary from" and "Annual salary to" must be filled if one is provided.',
        path: ['expectation_annual_salary_from'],
      });
    }

    // Check if "to" values are greater than "from" values
    if (
      data.expectation_annual_salary_from &&
      data.expectation_annual_salary_to &&
      data.expectation_annual_salary_from > data.expectation_annual_salary_to
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '"Annual salary to" must be greater than "Annual salary from".',
        path: ['expectation_annual_salary_to'],
      });
    }
    if (data.expectation_day_rate_from > data.expectation_day_rate_to) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '"Day rate to" must be greater than "Day rate from".',
        path: ['expectation_day_rate_to'],
      });
    }
    if (data.expectation_hourly_rate_from > data.expectation_hourly_rate_to) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: '"Hourly rate to" must be greater than "Hourly rate from".',
        path: ['expectation_hourly_rate_to'],
      });
    }

    // Check for other expectations
    if (data.other_expectations.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Please fill in at least one additional expectation.',
        path: ['other_expectations'],
      });
    }
  });

export type TExpectationsFormData = z.infer<typeof expectationsFormSchema>;
