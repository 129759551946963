import { memo, useEffect } from 'react';
import { useCreateRoleStore } from '@/store/create-role-store';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import { z } from 'zod';

import { Card } from '@/components/ui/card';
import Heading from '@/components/ui/typography/heading';

import FullPageLoader from '@/components/full-page-loader';
import ExitButton from '@/components/roles/business/create-role/exit-button';

import { useGetCurrentBusinessSuspense } from '@/hooks/business';
import { useGetJobPostById } from '@/hooks/business-roles';
import useCreateRoleStepper from '@/hooks/useCreateRoleStepper';

import { mapRoleAPIDataToFormData } from '@/services/business-roles';

import { PORTAL_IDS } from '@/constants/portal-ids';

const roleSearchSchema = z.object({
  id: z.number().optional(),
  mode: z.enum(['create', 'edit', 'draft']).optional().default('create'),
  backRoute: z.string().optional(),
});

export const Route = createFileRoute('/_authenticated/business/create-role')({
  component: memo(CreateRoleLayout),
  validateSearch: (searchParams) => roleSearchSchema.parse(searchParams),
});

function CreateRoleLayout() {
  const { mode, id } = Route.useSearch();
  const { data, setFinalData } = useCreateRoleStore();
  const { currentStep } = useCreateRoleStepper();
  const { data: currentBusiness } = useGetCurrentBusinessSuspense({});

  const { data: role, isPending } = useGetJobPostById({
    params: {
      path: {
        businessId: currentBusiness!.id.toString(),
        postId: id?.toString() || '',
      },
    },
    reactQueryOptions: {
      enabled: !!currentBusiness && !!id,
    },
  });

  useEffect(() => {
    if (mode === 'create' && !id) {
      if (currentBusiness) {
        setFinalData({
          ...data,
          coverImageUrl: currentBusiness.cover_url || '',
        });
      }
    }
    if ((mode === 'edit' || mode === 'draft') && id && role) {
      setFinalData({
        ...mapRoleAPIDataToFormData(role),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBusiness, mode, id, role]);

  if (isPending && mode !== 'create') return <FullPageLoader className="w-full" />;

  return (
    <main className="w-full">
      <header className="sticky top-0 z-50 flex justify-between bg-primary-dark-2 p-8">
        <Heading
          title={mode === 'create' ? 'Create Role' : 'Edit Role'}
          size="page"
        />
        <div className="flex items-center gap-4">
          <div id={PORTAL_IDS.saveAsDraft}></div>
          {currentStep < 8 && <ExitButton />}
          <div id={PORTAL_IDS.createRole}></div>
        </div>
      </header>

      <div className="flex gap-x-8 px-8 pb-8">
        <div
          className="sticky top-[6.75rem] h-full w-[13rem] xl:w-[16rem] 2xl:w-[16.5rem] 3xl:w-[17.5rem]"
          id={PORTAL_IDS.createRoleNavigation}
        ></div>
        <Card className="w-full p-8">
          <Outlet />
        </Card>
      </div>
    </main>
  );
}
