import React, { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { UilTimes } from '@iconscout/react-unicons';
import { createPortal } from 'react-dom';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import Search from '@/components/ui/search';
import { Sheet, SheetContent, SheetFooter, SheetHeader, SheetTitle } from '@/components/ui/sheet';

import FilterButton from '@/components/filter/filter-button';

import { TCandidatePostsFilters, TGetCurrentCandidateAppliedSort } from '@/services/candidate';
import { SortParams } from '@/services/types';

import { truncateString } from '@/utils/string';
import { ORDER, sortByToQuery } from '@/utils/table-sort-mapper';

import { PORTAL_IDS } from '@/constants/portal-ids';

const formSchema = z.object({
  search: z.string().optional(),
  businessName: z.enum(['asc', 'desc']).nullable(),
  matchPercentage: z.enum(['asc', 'desc']).nullable(),
});

type TFormData = z.infer<typeof formSchema>;

interface IRolesFilterFormProps {
  values: TFormData;
  onSubmit: (values: TFormData) => void;
  onClose: () => void;
  hideSearch?: boolean;
}
const RolesFilterForm: React.FC<IRolesFilterFormProps> = ({ values, onSubmit, onClose, hideSearch }) => {
  const form = useForm<TFormData>({
    resolver: zodResolver(formSchema),
    values,
  });

  const { control, setValue, handleSubmit } = form;

  return (
    <FormProvider {...form}>
      <div className="flex flex-grow flex-col justify-between">
        <Form
          className="flex-grow px-6 py-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          {!hideSearch && (
            <FormField
              control={control}
              name="search"
              render={({ field }) => (
                <Search
                  {...field}
                  debounce={0}
                />
              )}
            />
          )}
          <FormField
            control={form.control}
            name="businessName"
            render={({ field }) => (
              <FormItem className="space-y-3">
                <FormLabel className="text-lg font-semibold">Company</FormLabel>
                <FormControl>
                  <RadioGroup
                    defaultValue={field.value as string | undefined}
                    value={field.value as string | undefined}
                    className="flex gap-x-1"
                    onValueChange={(value: ORDER) => {
                      field.onChange(value);
                      setValue('matchPercentage', null);
                    }}
                  >
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="asc" />
                      </FormControl>
                      <FormLabel className="text-lg">A to Z</FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="desc" />
                      </FormControl>
                      <FormLabel className="text-lg">Z to A</FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="matchPercentage"
            render={({ field }) => (
              <FormItem className="space-y-3">
                <FormLabel className="text-lg font-semibold">Match</FormLabel>
                <FormControl>
                  <RadioGroup
                    defaultValue={field.value as string | undefined}
                    value={field.value as string | undefined}
                    className="flex flex-col gap-y-1"
                    onValueChange={(value: ORDER) => {
                      field.onChange(value);
                      setValue('businessName', null);
                    }}
                  >
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="desc" />
                      </FormControl>
                      <FormLabel className="text-lg">High to Low</FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="asc" />
                      </FormControl>
                      <FormLabel className="text-lg">Low to High</FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
              </FormItem>
            )}
          />
        </Form>
        <SheetFooter className="flex justify-end border-t border-primary-dark-10">
          <Button
            variant="tertiary"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Apply
          </Button>
        </SheetFooter>
      </div>
    </FormProvider>
  );
};

const getLabelFromFilter = (key: keyof TCandidatePostsFilters, value: string) => {
  if (key === 'query') return truncateString(value);

  if (key === 'sortBy') {
    const sortQuery = sortByToQuery<TGetCurrentCandidateAppliedSort>(value as SortParams['sortBy']);
    if (sortQuery?.id === 'business.name') {
      return sortQuery.order === 'asc' ? 'A to Z' : 'Z to A';
    }

    if (sortQuery?.id === 'metadata.match_percentage') {
      return sortQuery.order === 'asc' ? 'Low to High' : 'High to Low';
    }
  }

  return null;
};
const AppliedFilters: React.FC<{
  filters: TCandidatePostsFilters;
  setFilters: (filters: TCandidatePostsFilters) => void;
}> = ({ filters, setFilters }) => {
  const [portalNode, setPortalNode] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setPortalNode(document.getElementById(PORTAL_IDS.rolesApplied));
  }, []);

  if (!portalNode) return null;

  const renderFilters = () => {
    return Object.entries(filters).map(([key, value]) => {
      if (!value) return null;

      return (
        <Button
          key={key}
          variant="tertiary"
          onClick={() => {
            setFilters({ ...filters, [key]: undefined });
          }}
        >
          <span>{getLabelFromFilter(key as keyof TCandidatePostsFilters, value.toString())}</span>
          <UilTimes className="size-[1.125rem] text-primary-dark-100" />
        </Button>
      );
    });
  };

  return createPortal(
    <div className="flex items-center gap-4 border-r border-primary-dark-10 pr-4">{renderFilters()}</div>,
    portalNode
  );
};

interface RolesFilterProps {
  usePortalForAppliedFilters?: boolean;
  hideSearch?: boolean;
  filters: TCandidatePostsFilters;
  setFilters: (filters: TCandidatePostsFilters) => void;
}

const RolesFilter: React.FC<RolesFilterProps> = ({ filters, setFilters, hideSearch }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Sheet
        open={open}
        onOpenChange={setOpen}
      >
        <FilterButton onClick={() => setOpen(true)} />
        <SheetContent className="flex flex-col gap-0">
          <SheetHeader>
            <SheetTitle>Filter</SheetTitle>
          </SheetHeader>
          <RolesFilterForm
            values={{
              search: filters.query,
              businessName: filters.sortBy?.startsWith('business.name') ? sortByToQuery(filters.sortBy)!.order : null,
              matchPercentage: filters.sortBy?.startsWith('metadata.match_percentage')
                ? sortByToQuery(filters.sortBy)!.order
                : null,
            }}
            onSubmit={(values) => {
              setFilters({
                query: values.search,
                sortBy: values.businessName
                  ? `business.name.${values.businessName}`
                  : values.matchPercentage
                    ? `metadata.match_percentage.${values.matchPercentage}`
                    : undefined,
              });
              setOpen(false);
            }}
            onClose={() => setOpen(false)}
            hideSearch={hideSearch}
          />
        </SheetContent>
      </Sheet>
      <AppliedFilters
        filters={filters}
        setFilters={setFilters}
      />
    </>
  );
};

export default RolesFilter;
