import { createFileRoute, useNavigate } from '@tanstack/react-router';
import Fireworks from 'react-canvas-confetti/dist/presets/fireworks';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardFooter, CardTitle } from '@/components/ui/card';

import FeaturedIcon from '@/components/icons/featured-icon';

export const Route = createFileRoute('/_authenticated/candidate/onboarding/completed')({
  component: OnboardingComponent,
});

function OnboardingComponent() {
  const navigate = useNavigate();

  return (
    <main className="flex h-svh w-full items-center justify-center">
      <Fireworks autorun={{ speed: 3, duration: 6000 }} />
      <Card className="max-w-[524px] rounded-2xl p-8 shadow-2xl">
        <CardTitle className="flex items-center gap-3.5">
          <FeaturedIcon variant="success" />
          <span className="text-2xl">Well done</span>
        </CardTitle>
        <CardContent className="py-4 text-primary-dark-60">
          You have successfully completed your onboarding! <br />
          <br /> In order to receive accurate matches, we recommend taking our Personality & Culture questionnaires.
        </CardContent>
        <CardFooter>
          <Button onClick={() => navigate({ to: '/candidate/quiz' })}>Do the quiz</Button>
        </CardFooter>
      </Card>
    </main>
  );
}
