import { min } from 'date-fns';

import { TimeSlot } from '@/components/time-slot-booker';

export const getUserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getGMTOffset = () => {
  const now = new Date();
  const offsetMinutes = now.getTimezoneOffset();

  // Convert minutes to hours and minutes
  const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
  const offsetMins = Math.abs(offsetMinutes) % 60;

  // Determine if the offset is positive or negative
  const sign = offsetMinutes <= 0 ? '+' : '-';

  // Format the offset as GMT±HH:MM
  const formattedOffset = `GMT${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMins).padStart(2, '0')}`;

  return formattedOffset;
};

/**
 * Finds the earliest date from an array of TimeSlot objects.
 * @param events - Array of TimeSlot objects
 * @returns The earliest date found or null if no valid dates are present
 */
export function findEarliestDate(events: TimeSlot[]): Date {
  if (events.length === 0) return new Date();

  // Extract all start and end times into a single array
  const allDates = events.flatMap(({ startTime, endTime }) => [startTime, endTime]);

  // Filter out any invalid Date objects (if any)
  const validDates = allDates.filter((date) => date instanceof Date && !isNaN(date.getTime()));

  // Find the earliest date using date-fns' min function
  return validDates.length > 0 ? min(validDates) : new Date();
}
