export const VALUES_GROUP = {
  // Dashboard
  LOOKING_FOR: 'LOOKING_FOR',
  // Step 1 of Onboarding
  WORK_TYPE: 'LOOKING_FOR',
  ROLES: 'ROLES',
  BUSINESS_TYPES: 'SIZE',
  //   Step 2 of onboarding
  SLAARIES: 'SALARY',
  DAY_RATES: 'DAY_RATE',
  HOUR_RATES: 'HOUR_RATE',
  OTHER_EXPECTATIONS: 'INCENTIVES',
  //   Step 3 of onboarding
  // Step 4 of onboarding
  BUSINESS_TYPE: 'SIZE',
  INDUSTRY_TYPE: 'INDUSTRY',
  TECHNICAL_SKILLS: 'SKILLS',
  DOMAIN: 'DOMAIN',
  DOMAIN_EXPERIENCE: 'DOMAIN_EXP',
  // Step 5 of onboarding
  EDUCATION: 'EDUCATION',
  QUALIFICATION: 'QUALIFICATION',
  LANGUAGES: 'LANGUAGES',
  ENGLISH_PROFECIENCY: 'LANGUAGE_PROFICIENCY',
  // Step 6 of onboarding
  COUNTRY: 'COUNTRY',
  CITY: 'LOCATION',
  RESIDENCY: 'RESIDENCY',
  // Step 7 of onboarding
  SOFT_SKILLS: 'SOFT_SKILLS',
  CANDIDATE_VALUES: 'VALUES',
  AVAILABILITY: 'AVAILABILITY',
  BENEFITS: 'INCENTIVES',
  BUSINESS: 'BUSINESS',
  COMPANY_CULTURE: 'COMP_CULTURE',
  COMPANY_VALUES: 'COMP_VALUES',
  CULTURE_FACTORS: 'CULTURE_FACTORS',
  PERSONALITY_FACTORS: 'PERSONALITY_FACTORS',
  // Business pipeline
  REJECT_REASONS: 'REJECT_REASON',
  // Create role
  LOCATION: 'LOCATION',
};
