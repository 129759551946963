import { DeleteObjectCommand, GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';

const REGION = import.meta.env.VITE_S3_REGION;
const BUCKET_NAME = import.meta.env.VITE_S3_BUCKET;

const s3Client = new S3Client({
  region: REGION,
  credentials: {
    accessKeyId: import.meta.env.VITE_S3_ACCESS_KEY_ID,
    secretAccessKey: import.meta.env.VITE_S3_SECRET_ACCESS_KEY,
  },
});

export const uploadFile = async (file: File, key: string): Promise<string> => {
  const params = {
    Bucket: BUCKET_NAME,
    Key: key,
    Body: file,
  };
  try {
    const upload = new Upload({
      client: s3Client,
      params,
    });
    await upload.done();
    return key;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

export const getFileUrl = async (key: string): Promise<string> => {
  const params = {
    Bucket: BUCKET_NAME,
    Key: key,
  };
  try {
    const command = new GetObjectCommand(params);
    const url = await getSignedUrl(s3Client, command, { expiresIn: 3600 }); // URL expires in 1 hour
    return url;
  } catch (error) {
    console.error('Error getting file URL:', error);
    throw error;
  }
};

export const deleteFile = async (key: string): Promise<string> => {
  const params = {
    Bucket: BUCKET_NAME,
    Key: key,
  };
  try {
    const command = new DeleteObjectCommand(params);
    await s3Client.send(command);
    return key;
  } catch (error) {
    console.error('Error deleting file:', error);
    throw error;
  }
};
