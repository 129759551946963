import { zodResolver } from '@hookform/resolvers/zod';
import { UilLink } from '@iconscout/react-unicons';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { toast } from '@/components/ui/toaster';

import OnboardingFooter from '@/components/onboarding/footer';

import { useGetCurrentBusinessSuspense, useUpdateCurrentBusiness } from '@/hooks/business';

import { getFormattedDataForAPI } from '@/utils/format';

import {
  partnerSocialsBioSchema,
  TPartnerSocialsBioFormData,
} from '@/validation-schemas/partner/partner-social-bio-schema';

import LinkedInLogo from '@/assets/brands/linkedin.svg';

import FormFooter from '../form-footer';
import FormNavigationBlocker from '../form-navigation-blocker';
import { IFormProps } from '../types';

function getNullorValue(value: string | null | undefined): string {
  if (!value) {
    return '';
  }
  return value;
}

const PartnerSocialsBioForm: React.FC<IFormProps> = ({ onSuccess, isOnboarding }) => {
  const { data: currentBusiness } = useGetCurrentBusinessSuspense({});

  const { mutateAsync: updateBusiness, isPending: isUpdatingBusiness } = useUpdateCurrentBusiness({
    reactQueryOptions: {
      onSuccess: () => {
        if (!isOnboarding) {
          toast.success('Account updated successfully!');
        }
        onSuccess?.();
      },
    },
  });

  const form = useForm<TPartnerSocialsBioFormData>({
    resolver: zodResolver(partnerSocialsBioSchema),
    defaultValues: {
      website: currentBusiness?.website || '',
      linkedin_url: currentBusiness?.linkedin_url || '',
      bio: currentBusiness?.bio || '',
    },
    values: {
      website: currentBusiness?.website || '',
      linkedin_url: currentBusiness?.linkedin_url || '',
      bio: currentBusiness?.bio || '',
    },
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = form;

  const onSubmit: SubmitHandler<TPartnerSocialsBioFormData> = (data) => {
    const formattedData = getFormattedDataForAPI(data);
    updateBusiness({ body: formattedData });
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          name="website"
          control={control}
          render={({ field }) => (
            <FormItem>
              <div className="flex gap-x-2">
                <FormLabel>
                  <div className="flex h-full w-12 items-center justify-center rounded-lg border border-primary-dark-10 bg-primary-white-100 p-2">
                    <UilLink />
                  </div>
                </FormLabel>
                <FormControl>
                  <Input
                    className="w-full"
                    placeholder="www.upplft.com"
                    {...field}
                    value={getNullorValue(field.value)}
                    error={!!errors.website?.message}
                    prefix="https://"
                  />
                </FormControl>
              </div>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="linkedin_url"
          control={control}
          render={({ field }) => (
            <FormItem>
              <div className="flex gap-x-2">
                <FormLabel>
                  <div className="flex h-full w-12 items-center justify-center rounded-lg border border-primary-dark-10 bg-primary-white-100 p-2">
                    <img
                      src={LinkedInLogo}
                      alt="Linkedin Icon"
                    />
                  </div>
                </FormLabel>
                <FormControl>
                  <Input
                    className="w-full"
                    placeholder="www.upplft.com"
                    {...field}
                    value={getNullorValue(field.value)}
                    error={!!errors.linkedin_url?.message}
                    prefix="https://"
                  />
                </FormControl>
              </div>
              <FormError />
            </FormItem>
          )}
        />
        <FormField
          name="bio"
          control={control}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Bio</FormLabel>
              <FormControl>
                <Textarea
                  placeholder="Enter description here"
                  {...field}
                  error={!!errors?.bio?.message}
                  rows={4}
                  maxLength={1000}
                />
              </FormControl>
              <FormError />
            </FormItem>
          )}
        />
      </Form>
      {isOnboarding ? (
        <OnboardingFooter
          handleNext={handleSubmit(onSubmit)}
          domain="partner"
          isLoading={isUpdatingBusiness}
        />
      ) : (
        <>
          <FormNavigationBlocker />
          <FormFooter
            onSubmit={handleSubmit(onSubmit)}
            isSubmitting={isUpdatingBusiness}
          />
        </>
      )}
    </FormProvider>
  );
};
export default PartnerSocialsBioForm;
