import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

import { useGetCurrentUser } from '@/hooks/user';

export const SentryObserver = () => {
  /**
   * TODO: Validate if user is set null when logged out (set cache to null on logout)
   */
  const { data: user } = useGetCurrentUser({});

  useEffect(() => {
    if (user) {
      Sentry.setUser(user);
    } else {
      Sentry.setUser(null);
    }
  }, [user]);

  return null;
};
