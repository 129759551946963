import { useQuery } from '@tanstack/react-query';

import {
  getBusinessMemberPermissions,
  getBusinessPartnerPermissions,
  TGetBusinessPartnerPermissionsRequest,
  TGetBusinessUserPermissionsRequest,
} from '@/services/permission';
import { USER_TYPE } from '@/services/user';

import { useGetCurrentBusiness, useGetCurrentPartnerBusiness } from '../business';
import { type UseQueryOptions } from '../types';
import { useGetCurrentUser } from '../user';

export function useGetBusinessMemberPermissions({
  reactQueryOptions,
  params,
}: UseQueryOptions<TGetBusinessUserPermissionsRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [params.path.businessId, params.path.memberId],
    queryFn: ({ signal }) => getBusinessMemberPermissions({ signal, params }),
    staleTime: Infinity,
  });
}

export function useGetBusinessPartnerPermissions({
  reactQueryOptions,
  params,
}: UseQueryOptions<TGetBusinessPartnerPermissionsRequest>) {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [params.path.businessId, params.path.partnerId],
    queryFn: ({ signal }) => getBusinessPartnerPermissions({ signal, params }),
    staleTime: Infinity,
  });
}

export function useGetCurrentUserPermissions() {
  const { data: user } = useGetCurrentUser({});
  const { data: business } = useGetCurrentBusiness({});
  const { data: partnerBusiness } = useGetCurrentPartnerBusiness();

  const businessId = user?.user_type === USER_TYPE.PARTNER ? partnerBusiness?.id : business?.id;

  return useGetBusinessMemberPermissions({
    params: {
      path: {
        businessId: businessId?.toString() as string,
        memberId: user?.id?.toString() as string,
      },
    },
    reactQueryOptions: {
      enabled: !!user && !!businessId,
    },
  });
}

export function useGetCurrentBusinessPermissions() {
  const { data: user } = useGetCurrentUser({});
  const { data: business } = useGetCurrentBusiness({});

  const businessId = business?.id;

  return useGetBusinessMemberPermissions({
    params: {
      path: {
        businessId: businessId?.toString() as string,
        memberId: user?.id?.toString() as string,
      },
    },
    reactQueryOptions: {
      enabled: !!user && !!businessId,
    },
  });
}

export function useGetCurrentPartnerBusinessPermissions() {
  const { data: user } = useGetCurrentUser({});
  const { data: partnerBusiness } = useGetCurrentPartnerBusiness();

  const businessId = partnerBusiness?.id;

  return useGetBusinessMemberPermissions({
    params: {
      path: {
        businessId: businessId?.toString() as string,
        memberId: user?.id?.toString() as string,
      },
    },
    reactQueryOptions: {
      enabled: !!user && !!businessId,
    },
  });
}
