import { createFileRoute } from '@tanstack/react-router';

import { Switch } from '@/components/ui/switch';
import Heading from '@/components/ui/typography/heading';

import SettingContainer from '@/components/settings/setting-container';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/settings/notifications')({
  component: Notifications,
});

function Notifications() {
  return (
    <div className="flex flex-col space-y-16">
      <NotificationSettings />
      <Product />
      <Roles />
      <Pipeline />
      <Calendar />
      <Team />
      <Partners />
    </div>
  );
}

function Partners() {
  return (
    <SettingContainer>
      <Heading
        title="Partners"
        subtitle="These are notifications for when there is any activity regarding your partners."
      />
      <div className="flex flex-col space-y-4">
        <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
          <Switch defaultChecked={false} />
          <span>Invitations</span>
        </div>
        <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
          <Switch defaultChecked={false} />
          <span>Notes</span>
        </div>
        <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
          <Switch defaultChecked={false} />
          <span>Requests</span>
        </div>
      </div>
    </SettingContainer>
  );
}

function Team() {
  return (
    <SettingContainer>
      <Heading
        title="Team"
        subtitle="These are notifications for when there is any activity regarding your team."
      />
      <div className="flex flex-col space-y-4">
        <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
          <Switch defaultChecked={false} />
          <span>Invitations</span>
        </div>
        <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
          <Switch defaultChecked={false} />
          <span>Notes</span>
        </div>
        <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
          <Switch defaultChecked={false} />
          <span>Requests</span>
        </div>
      </div>
    </SettingContainer>
  );
}

function Calendar() {
  return (
    <SettingContainer>
      <Heading
        title="Calendar"
        subtitle="These are notifications for when there is any activity regarding your calendar."
      />
      <div className="flex flex-col space-y-4">
        <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
          <Switch defaultChecked={false} />
          <span>Interviews</span>
        </div>
      </div>
    </SettingContainer>
  );
}

function Pipeline() {
  return (
    <SettingContainer>
      <Heading
        title="Pipeline"
        subtitle="These are notifications for when there is any activity regarding your pipeline."
      />
      <div className="flex flex-col space-y-4">
        <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
          <Switch defaultChecked={false} />
          <span>Applications</span>
        </div>
        <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
          <Switch defaultChecked={false} />
          <span>Shortlisted</span>
        </div>
        <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
          <Switch defaultChecked={false} />
          <span>Interviews</span>
        </div>
        <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
          <Switch defaultChecked={false} />
          <span>Hired</span>
        </div>
      </div>
    </SettingContainer>
  );
}

function Roles() {
  return (
    <SettingContainer>
      <Heading
        title="Roles"
        subtitle="These are notifications for when there is any activity regarding your roles."
      />
      <div className="flex flex-col space-y-4">
        <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
          <Switch defaultChecked={false} />
          <span>Published</span>
        </div>
        <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
          <Switch defaultChecked={false} />
          <span>Paused</span>
        </div>
        <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
          <Switch defaultChecked={false} />
          <span>Closed</span>
        </div>
      </div>
    </SettingContainer>
  );
}
function Product() {
  return (
    <SettingContainer>
      <Heading
        title="Product"
        subtitle="These are notifications for when there is any product updates or known bugs."
      />
      <div className="flex flex-col space-y-4">
        <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
          <Switch defaultChecked={false} />
          <span>Features</span>
        </div>
        <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
          <Switch defaultChecked={false} />
          <span>Updates</span>
        </div>
        <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
          <Switch defaultChecked={false} />
          <span>Bugs</span>
        </div>
      </div>
    </SettingContainer>
  );
}

function NotificationSettings() {
  return (
    <SettingContainer>
      <Heading
        title="Notification settings"
        subtitle="These are your overarching notification settings. 

Please be aware that we may send you important notifications regardless of your settings."
      />
      <div className="flex flex-col space-y-4">
        <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
          <Switch defaultChecked={false} />
          <span>Platform</span>
        </div>
        <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
          <Switch defaultChecked={false} />
          <span>Email</span>
        </div>
        <div className="flex items-center space-x-4 text-lg font-semibold leading-7 text-primary-dark-100">
          <Switch defaultChecked={false} />
          <span>SMS</span>
        </div>
      </div>
    </SettingContainer>
  );
}
