import { paths } from '@/lib/api/v1';

import { Filters } from '../types';
import { PIPELINE_ENDPOINTS } from './pipeline.endpoints';

export type TGetAllCandidatesForPipelineRequest = paths[typeof PIPELINE_ENDPOINTS.GET_CANDIDATES_LIST_FOR_ROLES]['get'];
export type TGetAllCandidatesForPipelineResponse =
  TGetAllCandidatesForPipelineRequest['responses']['200']['content']['application/json']['data'];
export type TGetAllCandidatesForPipelineResponseForBoard = {
  items: (TGetAllCandidatesForPipelineResponse['items'][0] & { cardId: string })[];
  metadata: TGetAllCandidatesForPipelineResponse['metadata'];
  total: TGetAllCandidatesForPipelineResponse['total'];
};
export type TCandidateForPipeline = TGetAllCandidatesForPipelineResponse['items'][0];

export type TCandidateForPipelineBoard = TCandidateForPipeline & {
  cardId: string;
};
export type TCandidateForPipelineStatus = NonNullable<
  TGetAllCandidatesForPipelineRequest['parameters']['query']
>['status'];
export type TCandidateForPipelineSort = NonNullable<TGetAllCandidatesForPipelineRequest['parameters']['query']>['sort'];
export type TPipelineCandidateFilters = Partial<Filters & { status: TCandidateForPipelineStatus }>;

/**
 * Pipeline Actions
 */
export type TPostCandidateMakeOfferRequest = paths[typeof PIPELINE_ENDPOINTS.POST_OFFER_CANDIDATE]['post'];
export type TPostCandidateReinstateRequest = paths[typeof PIPELINE_ENDPOINTS.POST_REINSTATE_CANDIDATE]['post'];
export type TPostCandidateShortlistRequest = paths[typeof PIPELINE_ENDPOINTS.POST_SHORTLIST_CANDIDATE]['post'];
export type TPostCandidateMoveToInterviewRequest =
  paths[typeof PIPELINE_ENDPOINTS.POST_MOVE_TO_INTERVIEW_STAGE]['post'];
export type TPostCandidateHireRequest = paths[typeof PIPELINE_ENDPOINTS.POST_HIRE_CANDIDATE]['post'];
export type TPostCandidateRejectRequest = paths[typeof PIPELINE_ENDPOINTS.POST_REJECT_CANDIDATE]['post'];
export type TPostCandidateFavoriteRequest = paths[typeof PIPELINE_ENDPOINTS.POST_FAVORITE_CANDIDATE]['post'];
export type TPostCandidateUnFavoriteRequest = paths[typeof PIPELINE_ENDPOINTS.POST_UNFAVORITE_CANDIDATE]['post'];

export type TGetCandidateDetailsRequest = paths[typeof PIPELINE_ENDPOINTS.GET_CANDIDATE_DETAILS]['get'];
export type TCandidateDetails = TGetCandidateDetailsRequest['responses']['200']['content']['application/json']['data'];

export enum ROLE_PAY_RATE_TYPE {
  DAY_RATE = 'DAY_RATE',
  HOURLY_RATE = 'HOURLY_RATE',
  ANNUAL_SALARY = 'ANNUAL_SALARY',
}

export const ROLE_PAY_RATE_TYPE_OPTIONS = [
  { value: ROLE_PAY_RATE_TYPE.DAY_RATE, label: 'Day Rate' },
  { value: ROLE_PAY_RATE_TYPE.HOURLY_RATE, label: 'Hourly Rate' },
  { value: ROLE_PAY_RATE_TYPE.ANNUAL_SALARY, label: 'Annual Salary' },
];
