import { useDroppable } from '@dnd-kit/core';

import { cn } from '@/lib/utils';

import BaseQuizCardWrapper from './base-quiz-card';

interface DroppableContainerProps {
  id: string;
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
  type: 'initial' | 'final';
}

const DroppableContainer = ({ title, subtitle, children, id, type }: DroppableContainerProps) => {
  const { setNodeRef, isOver } = useDroppable({
    id,
    data: {
      type,
    },
  });
  return (
    <BaseQuizCardWrapper
      ref={setNodeRef}
      className={cn(
        'relative border border-dashed border-primary-dark-40 bg-primary-dark-2 transition-shadow',
        isOver && 'shadow-lg',
        type === 'initial' && 'bg-white'
      )}
    >
      <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center gap-1 font-semibold text-primary-dark-40 2xl:gap-2">
        <span className="text-2xl 2xl:text-3xl 3xl:text-4xl">{title}</span>
        <span className="font-semibold 2xl:text-lg">{subtitle}</span>
      </div>
      <div className="absolute left-0 top-0 z-10 h-full w-full">{children}</div>
    </BaseQuizCardWrapper>
  );
};

export default DroppableContainer;
