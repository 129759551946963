import { UilColumns, UilWindowSection } from '@iconscout/react-unicons';

import TabNav, { ITabNavItem } from '@/components/navigation/tab-nav';

const tabs: ITabNavItem[] = [
  {
    label: 'Board',
    href: '/business/pipeline',
    icon: <UilColumns className="size-5 text-primary-dark-100" />,
  },
  {
    label: 'Table',
    href: '/business/pipeline/table',
    icon: <UilWindowSection className="size-5 text-primary-dark-100" />,
  },
];

const PipelineNav = () => {
  return <TabNav navItems={tabs} />;
};

export default PipelineNav;
