import { useMutation } from '@tanstack/react-query';

import { toast } from '@/components/ui/toaster';

import {
  resetPassword,
  sendSelfVerificatonEmail,
  TResetPasswordRequest,
  TSendSelfVerificatonEmailRequest,
} from '@/services/auth';

import { UseMutationOptions } from '../types';

export function useSendSelfEmailVerification({
  reactQueryOptions,
}: UseMutationOptions<TSendSelfVerificatonEmailRequest>) {
  return useMutation({
    ...reactQueryOptions,
    mutationFn: sendSelfVerificatonEmail,
    onSuccess: () => {
      toast.success('Verification email sent successfully');
      reactQueryOptions?.onSuccess?.();
    },
    onError: ({ message }) => {
      toast.error(message);
    },
  });
}

export function useResetPassword({ reactQueryOptions }: UseMutationOptions<TResetPasswordRequest>) {
  return useMutation({
    ...reactQueryOptions,
    mutationFn: resetPassword,
  });
}
