import { memo, useState } from 'react';
import { usePipelineStore } from '@/store/pipeline-store';
import { UilTimes } from '@iconscout/react-unicons';
import { createFileRoute, Outlet } from '@tanstack/react-router';

import RolesSelectComboBox from '@/components/ui/roles-search-combobox';
import { Tag } from '@/components/ui/tag';
import Heading from '@/components/ui/typography/heading';

import PipelineNav from '@/components/pipeline/partner/pipeline-nav';

import { useGetCurrentPartnerBusiness } from '@/hooks/business';
import { useGetAllRolesByBusinessIdV2 } from '@/hooks/business-roles';

import { PORTAL_IDS } from '@/constants/portal-ids';

export const Route = createFileRoute('/_authenticated/partner/_dashboard/pipeline')({
  component: memo(PipelineLayout),
});

function PipelineLayout() {
  const { data: selectedRoles, setData: setSelectedRoles } = usePipelineStore();

  const { data: business } = useGetCurrentPartnerBusiness();
  const [roleQuery, setRoleQuery] = useState<string>('');

  const { data: roles, isFetching: isRolesLoading } = useGetAllRolesByBusinessIdV2({
    params: {
      path: {
        businessId: business?.id.toString() as string,
      },
      query: {
        limit: '10',
        offset: '0',
        status: 'active',
        q: roleQuery,
      },
    },

    reactQueryOptions: {
      enabled: !!business,
      queryKey: [roleQuery],
    },
  });

  const handleSelectedRoles = (roleId: number) => {
    setSelectedRoles(selectedRoles.filter((role) => role.id !== roleId));
  };

  return (
    <main className="relative -mx-8 flex h-full flex-col space-y-4">
      <div
        id={PORTAL_IDS.pipelineRoles}
        className="relative w-full"
      ></div>
      <div className="space-y-6 border-b border-primary-dark-10 px-8">
        <Heading
          title="Pipeline"
          size="page"
        />
        <div className="flex items-center justify-between pb-4">
          <RolesSelectComboBox
            className="w-[20rem]"
            placeholder="Search role"
            options={roles?.items.map((s) => ({ value: s.id, label: s.title })) || []}
            selected={selectedRoles}
            onSelect={setSelectedRoles}
            isLoading={isRolesLoading}
            onQueryChange={(query) => setRoleQuery(query)}
          />

          {selectedRoles.length > 0 && (
            <div className="ml-4 grow border-l-2 border-primary-dark-10 pl-4">
              <div className="inline-flex flex-wrap gap-2">
                {selectedRoles.map((role) => (
                  <Tag
                    key={role.id}
                    className="h-[2.75rem] cursor-pointer bg-primary-white-100 px-4 py-3 text-sm font-semibold leading-tight text-primary-dark-100"
                    size={'lg'}
                    hideDot
                  >
                    <span> {role.title}</span>
                    <UilTimes
                      onClick={() => handleSelectedRoles(role.id)}
                      className="size-4 text-primary-dark-40"
                    />
                  </Tag>
                ))}
              </div>
            </div>
          )}
          <div className="flex justify-end gap-4">
            <div id={PORTAL_IDS.pipelineRolesFilter}></div>

            <PipelineNav />
          </div>
        </div>
      </div>
      <Outlet />
    </main>
  );
}
