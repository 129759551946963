import { Link, ToOptions } from '@tanstack/react-router';

import { cn } from '@/lib/utils';

import { PORTAL_IDS } from '@/constants/portal-ids';

export interface INavLinkProps {
  icon: React.ReactNode;
  href?: ToOptions['to'];
  label: string;
  exact?: boolean;
  active?: boolean;
  onClick?: () => void;
}

const NavLink: React.FC<INavLinkProps> = ({ active, icon, href, onClick, label, exact = true }) => {
  if (href) {
    return (
      <Link
        to={href}
        className="flex cursor-pointer items-center gap-2 rounded-lg px-4 py-2.5 font-semibold transition-colors hover:bg-primary-dark-5"
        activeProps={{
          className: 'bg-primary-dark-5 text-primary-dark-100',
        }}
        inactiveProps={{
          className: 'text-primary-dark-60',
        }}
        activeOptions={{
          exact,
        }}
      >
        <span>{icon}</span>
        <span className="mt-0.5">{label}</span>
      </Link>
    );
  }
  if (onClick) {
    return (
      <div
        className={cn(
          'flex cursor-pointer items-center gap-2 rounded-lg px-4 py-2.5 font-semibold transition-colors hover:bg-primary-dark-5',
          active ? 'bg-primary-dark-5 text-primary-dark-100' : 'text-primary-dark-60'
        )}
        onClick={onClick}
      >
        <span>{icon}</span>
        <span className="mt-0.5 flex flex-1 items-center justify-between">
          {label}
          <div id={PORTAL_IDS.notificationsCount} />
        </span>
      </div>
    );
  }
};

export default NavLink;
