import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Form, FormControl, FormError, FormField, FormItem, FormLabel, FormProvider } from '@/components/ui/form';
import { Select } from '@/components/ui/select-new';
import { Textarea } from '@/components/ui/textarea';

import { useGetValuesMapByGroup } from '@/hooks/values-map';

import { getOptionsForSelectFromValuesMap } from '@/utils/string';

import { VALUES_GROUP } from '@/constants/values-map';

const rejectCandidateFormSchema = z.object({
  reason: z.string().min(1, {
    message: 'Please select a reason for rejecting the candidate.',
  }),
  description: z.string().optional(),
});

export type TRejectCandidateForm = z.infer<typeof rejectCandidateFormSchema>;

const RejectCandidateModal = ({
  open,
  onOpenChange,
  // candidateId,
  // postId,
  onSuccess,
  onCancel,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  // candidateId: number;
  // postId: number;
  onSuccess?: (data: TRejectCandidateForm) => void;
  onCancel?: () => void;
}) => {
  const { data: rejectReasons, isLoading: isRejectReasonsLoading } = useGetValuesMapByGroup({
    params: {
      query: {
        value_group: VALUES_GROUP.REJECT_REASONS,
        limit: '100',
        offset: '0',
        sort: 'display_order',
        order: 'asc',
      },
    },
  });

  const form = useForm<TRejectCandidateForm>({
    resolver: zodResolver(rejectCandidateFormSchema),
    defaultValues: {
      reason: '',
      description: '',
    },
  });

  const onSubmit: SubmitHandler<TRejectCandidateForm> = (data) => {
    onOpenChange(false);
    onSuccess?.(data);
    form.reset();
  };

  return (
    <AlertDialog
      open={open}
      onOpenChange={(open) => {
        form.reset();
        if (!open) onCancel?.();
        onOpenChange(open);
      }}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle iconVariant="alert">Reject candidate</AlertDialogTitle>
          <AlertDialogDescription>Please tell us why you would like to reject this candidate.</AlertDialogDescription>
          <FormProvider {...form}>
            <Form className="space-y-4">
              <FormField
                control={form.control}
                name="reason"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Reason</FormLabel>
                    <FormControl>
                      <Select
                        options={getOptionsForSelectFromValuesMap(rejectReasons)}
                        selected={field.value}
                        onChange={field.onChange}
                        isLoading={isRejectReasonsLoading}
                        disableAlphabeticalSorting
                      />
                    </FormControl>
                    <FormError />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Textarea
                        {...field}
                        placeholder="Enter description here..."
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </Form>
          </FormProvider>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogCancel onClick={onCancel}>Cancel</AlertDialogCancel>
          <AlertDialogAction
            variant="destructive"
            onClick={form.handleSubmit(onSubmit)}
          >
            Reject
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default RejectCandidateModal;
