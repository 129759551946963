import { createFileRoute } from '@tanstack/react-router';

import { Card } from '@/components/ui/card';
import Heading from '@/components/ui/typography/heading';

import BusinessProfileInfoForm from '@/components/forms/business/business-info-form';
import SettingContainer from '@/components/settings/setting-container';

import { useGetCurrentUserPermissions } from '@/hooks/permission';

export const Route = createFileRoute('/_authenticated/business/_dashboard/settings/company/')({
  component: CompanyInfoCard,
});

function CompanyInfoCard() {
  const { data: permissions } = useGetCurrentUserPermissions();
  return (
    <SettingContainer>
      <Heading
        title="Business details"
        subtitle="Update your business details and profile photo."
      />
      <Card>
        <BusinessProfileInfoForm isDisabled={Boolean(!permissions?.manage_company_settings)} />
      </Card>
    </SettingContainer>
  );
}
