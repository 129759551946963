import { useNotificationPanelStore } from '@/store/notification-panel-store';
import { usePipelineStore } from '@/store/pipeline-store';
import { useNavigate } from '@tanstack/react-router';
import { formatDistanceToNowStrict } from 'date-fns/formatDistanceToNowStrict';

import { useMarkNotificationAsRead } from '@/hooks/notification';
import { useGetCurrentUser } from '@/hooks/user';

import { TUserNotification } from '@/services/notification';
import { USER_TYPE } from '@/services/user';

import { cn } from '@/lib/utils';

import { NOTIFICATION_EVENT_TYPES, NOTIFICATION_TOPIC } from '@/constants/notifications';

import FeaturedIcon from '../icons/featured-icon';

interface INotificationItemProps {
  notification: TUserNotification;
}

export function NotificationItem({ notification }: INotificationItemProps) {
  const navigate = useNavigate();
  const { setShowNotifications } = useNotificationPanelStore();
  const { setData: setSelectedRoles } = usePipelineStore();
  const { mutateAsync: markNotificationAsRead } = useMarkNotificationAsRead({
    params: {
      path: { notificationId: notification.id.toString() },
    },
  });

  const { data: currentUser } = useGetCurrentUser({});
  const handleNotificationClick = () => {
    markNotificationAsRead({ params: { path: { notificationId: notification.id.toString() } } });
    if (currentUser?.user_type === USER_TYPE.CANDIDATE) {
      if (notification.topic === NOTIFICATION_TOPIC.ROLES) {
        if (notification.event_type === NOTIFICATION_EVENT_TYPES.HIRED) {
          navigate({ to: '/candidate/roles/hired' });
          return;
        }
        if (notification.event_type === NOTIFICATION_EVENT_TYPES.OFFERED) {
          navigate({ to: '/candidate/roles/offers' });
          return;
        }
        navigate({ to: '/candidate/role/$roleId', params: { roleId: notification.metadata?.post_id as string } });
      }
    } else if (currentUser?.user_type === USER_TYPE.BUSINESS) {
      // set pipeline role ids
      if (
        notification.metadata &&
        notification.metadata?.post_id != undefined &&
        notification.content_payload &&
        notification.content_payload?.role_name != undefined
      ) {
        setSelectedRoles([
          {
            id: notification.metadata?.['post_id'] as number,
            title: notification.content_payload?.['role_name'] as string,
          },
        ]);
      }
      // navigate to pipeline
      navigate({ to: '/business/pipeline' });
    }
    setShowNotifications(false);
  };

  return (
    <div
      onClick={handleNotificationClick}
      className="inline-flex w-full cursor-pointer items-center justify-start gap-4 bg-primary-white-100 px-4 py-4 hover:bg-primary-dark-5"
    >
      <div
        className={cn('h-2.5 w-2.5 rounded-full bg-primary-blue-100', notification.is_read ? 'invisible' : 'visible')}
      />
      <div className="flex shrink grow basis-0 items-center justify-start gap-4">
        <FeaturedIcon variant="info" />
        <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-2">
          <div className="inline-flex items-start justify-start gap-2 self-stretch">
            <div className="shrink grow basis-0 text-base font-semibold leading-normal text-primary-dark-100">
              {notification.title || 'New Notification'}
            </div>
            <div className="text-sm font-medium leading-normal text-primary-dark-40">
              {formatDistanceToNowStrict(notification.created_at, { addSuffix: true })}
            </div>
          </div>
          <div className="self-stretch text-sm font-medium leading-tight text-primary-dark-60">
            {notification.content}
          </div>
        </div>
      </div>
    </div>
  );
}
