import { createFileRoute } from '@tanstack/react-router';

import BusinessProfileDetailsForm from '@/components/forms/business/business-details-form';

import useStepper from '@/hooks/useStepper';

export const Route = createFileRoute('/_authenticated/business/onboarding/_steps/step2')({
  component: Step2,
});

function Step2() {
  const { goToNextStep } = useStepper('business');

  return (
    <BusinessProfileDetailsForm
      onSuccess={goToNextStep}
      isOnboarding
    />
  );
}
