import { createFileRoute } from '@tanstack/react-router';

import { Card } from '@/components/ui/card';
import Separator from '@/components/ui/separator';
import Heading from '@/components/ui/typography/heading';

import BusinessUserInfoForm from '@/components/forms/business/user/business-user-info-form';
import SettingContainer from '@/components/settings/setting-container';
import SignOutCard from '@/components/settings/signout-card';

export const Route = createFileRoute('/_authenticated/partner/_dashboard/settings/')({
  component: MyDetails,
});

function MyDetails() {
  return (
    <div>
      <PersonalDetailsCard />
      <Separator />
      <SignOutCard />
    </div>
  );
}

function PersonalDetailsCard() {
  return (
    <SettingContainer>
      <Heading
        title="Personal details"
        subtitle="Update your personal details and profile photo."
      />
      <Card>
        <BusinessUserInfoForm />
      </Card>
    </SettingContainer>
  );
}
