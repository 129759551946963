import { useState } from 'react';
import { createFileRoute } from '@tanstack/react-router';

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Card, CardFooter } from '@/components/ui/card';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/dropdown-select';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';
import Heading from '@/components/ui/typography/heading';

import FeaturedIcon from '@/components/icons/featured-icon';
import SettingContainer from '@/components/settings/setting-container';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/support')({
  component: Support,
});

const SubmitButton = ({ onSubmit }: { onSubmit: () => void }) => {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button>Send request</Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="flex items-center justify-center gap-3.5">
            <FeaturedIcon variant="success" />
            <span>Request sent!</span>
          </AlertDialogTitle>
          <AlertDialogDescription>
            Thank you for submitting your request.
            <br /> <br />A member of our team will get back to you shortly.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction onClick={onSubmit}>Done</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

// todo: move to react-hook-form and use form components
function Support() {
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');

  return (
    <main className="space-y-8">
      <Heading
        title="Support"
        subtitle="Currently facing an issue, need help with something or you’d like to request a feature?
We are here to help!"
        size={'page'}
        className="max-w-[703px]"
      />
      <SettingContainer className="pt-4">
        <div className="space-y-24">
          <Heading
            title="Support"
            subtitle="Please select a subject related to your request."
          />
          <Heading
            title="Description"
            subtitle="Please describe in detail what you need help with."
          />
        </div>
        <Card>
          <div className="space-y-8">
            <div>
              <Label className="mb-1">Subject</Label>
              <Select
                value={subject}
                onValueChange={setSubject}
              >
                <SelectTrigger>
                  <SelectValue
                    placeholder="Select option"
                    className="placeholder-primary-dark-60"
                  />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="I found a bug">I found a bug</SelectItem>
                    <SelectItem value="I found a bug1">I found a bug</SelectItem>
                    <SelectItem value="I found a bug2">I found a bug</SelectItem>
                    <SelectItem value="I found a bug3">I found a bug</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <Textarea
              placeholder="Enter description here..."
              rows={10}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          <CardFooter>
            <SubmitButton
              onSubmit={() => {
                setSubject('');
                setDescription('');
              }}
            />
          </CardFooter>
        </Card>
      </SettingContainer>
    </main>
  );
}
