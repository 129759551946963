import { keepPreviousData, useQuery } from '@tanstack/react-query';

import {
  BUSINESS_ROLES_ENDPOINTS,
  getAllRolesByBusinessId,
  getAllRolesByBusinessIdV2,
  getJobPostById,
  getRoleDetailByBusinessIdPostIdV2,
  getRolesStatsByBusinessId,
  TGetAllBusinessRolesRequest,
  TGetAllBusinessRolesV2Request,
  TGetBusinessRolesStatsRequest,
  TGetJobPostByIdRequest,
  TGetRoleDetailByBusinessIdPostIdV2Request,
} from '@/services/business-roles';

import { UseQueryOptions } from '../../hooks/types';

export const useGetRolesStatsByBusinessId = ({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetBusinessRolesStatsRequest>) => {
  const { queryKey = [], ...rest } = reactQueryOptions;
  return useQuery({
    ...rest,
    queryKey: [BUSINESS_ROLES_ENDPOINTS.GET_ROLES_STATS, ...queryKey],
    queryFn: ({ signal }) => getRolesStatsByBusinessId({ signal, params }),
  });
};

export const useGetAllRolesByBusinessId = ({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetAllBusinessRolesRequest>) => {
  const { queryKey = [], ...rest } = reactQueryOptions;
  return useQuery({
    ...rest,
    queryKey: [BUSINESS_ROLES_ENDPOINTS.GET_ALL_ROLES_BY_BUSINESS_ID, ...queryKey],
    queryFn: ({ signal }) => getAllRolesByBusinessId({ signal, params }),
  });
};

export const useGetAllRolesByBusinessIdV2 = ({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetAllBusinessRolesV2Request>) => {
  const { queryKey = [], ...rest } = reactQueryOptions;
  return useQuery({
    ...rest,
    queryKey: [BUSINESS_ROLES_ENDPOINTS.GET_ALL_ROLES_BY_BUSINESS_ID_V2, ...queryKey],
    queryFn: ({ signal }) => getAllRolesByBusinessIdV2({ signal, params }),
    placeholderData: keepPreviousData,
  });
};

export const useGetRoleDetailByBusinessIdPostIdV2 = ({
  reactQueryOptions = {},
  params,
}: UseQueryOptions<TGetRoleDetailByBusinessIdPostIdV2Request>) => {
  const { queryKey = [], ...rest } = reactQueryOptions;
  return useQuery({
    ...rest,
    queryKey: [BUSINESS_ROLES_ENDPOINTS.GET_ROLE_DETAIL_BY_BUSINESS_ID_POST_ID_V2, ...queryKey],
    queryFn: ({ signal }) => getRoleDetailByBusinessIdPostIdV2({ signal, params }),
  });
};

export const useGetJobPostById = ({ reactQueryOptions = {}, params }: UseQueryOptions<TGetJobPostByIdRequest>) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: [BUSINESS_ROLES_ENDPOINTS.GET_JOB_POST_BY_ID, params.path.businessId, params.path.postId],
    queryFn: ({ signal }) => getJobPostById({ signal, params }),
  });
};
