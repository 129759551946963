import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

import { USER_TYPE } from '@/services/user';

export const Route = createFileRoute('/_authenticated/candidate')({
  component: CandidateLayout,
  beforeLoad: async ({ context }) => {
    const { user } = context.auth;
    //check role and redirect
    if (user?.app_user_type !== USER_TYPE.CANDIDATE) {
      throw redirect({ to: '/' });
    }
  },
});

function CandidateLayout() {
  return (
    <>
      <Outlet />
    </>
  );
}
