import { useFormContext } from 'react-hook-form';

import { Button } from '@/components/ui/button';

import useCreateRoleStepper from '@/hooks/useCreateRoleStepper';

interface CreateRoleFooterProps {
  onSubmit: () => void;
}

const CreateRoleFooter = ({ onSubmit }: CreateRoleFooterProps) => {
  const {
    formState: { isValid },
  } = useFormContext();

  const { isFirstStep, isLastStep, goToNextStep, goToPreviousStep } = useCreateRoleStepper();

  const handleGoToNextStep = () => {
    onSubmit();
    if (isValid) goToNextStep();
  };

  const handleGoToPreviousStep = () => {
    onSubmit();
    goToPreviousStep();
  };

  return (
    <div className="mt-8 flex justify-end gap-4">
      {!isFirstStep && (
        <Button
          variant="tertiary"
          onClick={handleGoToPreviousStep}
          className="w-[6rem]"
        >
          Previous
        </Button>
      )}
      <Button
        // disabled={!isValid}
        onClick={handleGoToNextStep}
        className="w-[6rem]"
      >
        {isLastStep ? 'Done' : 'Next'}
      </Button>
    </div>
  );
};

export default CreateRoleFooter;
