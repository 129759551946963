import { z } from 'zod';

export const additionalLanguageSchema = z.object({
  language: z.string().min(1, 'Language is required'),
  proficiency: z.string().min(1, 'Proficiency is required'),
});
export type TAdditionalLanguageFormData = z.infer<typeof additionalLanguageSchema>;

export const domainExperienceSchema = z.object({
  domain: z.string().min(1, 'Domain is required'),
  experience: z.string().min(1, 'Experience is required'),
});
export type TDomainExperienceFormData = z.infer<typeof domainExperienceSchema>;
