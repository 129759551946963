import { ReactNode, useState } from 'react';
import { UilAngleDown, UilCheck } from '@iconscout/react-unicons';

import { Command, CommandEmpty, CommandGroup, CommandItem, CommandList } from '@/components/ui/command';

import { cn } from '@/lib/utils';

import { inputVariants } from './input';
import { Popover, PopoverContent, PopoverTrigger } from './popover';

export type OptionType<T = unknown> = {
  value: string;
  label: string | ReactNode;
  description?: string;
  metadata?: T;
};

interface SelectProps<T> {
  options: OptionType<T>[];
  selected: string | null;
  onChange: (value: string) => void;
  placeholder?: string;
  error?: boolean;
  className?: string;
  isLoading?: boolean;
  disableAlphabeticalSorting?: boolean;
  disabled?: boolean;
  itemComponent?: (option: OptionType<T>) => ReactNode;
}

export function Select<T>({
  options,
  selected,
  onChange,
  placeholder = 'Select Option',
  error,
  isLoading,
  disableAlphabeticalSorting,
  itemComponent,
  ...props
}: SelectProps<T>) {
  const sortedOptions = disableAlphabeticalSorting
    ? options
    : [...options].sort((a, b) => {
        const valueA = typeof a.value === 'string' ? a.value : '';
        const valueB = typeof b.value === 'string' ? b.value : '';
        return valueA.localeCompare(valueB);
      });
  const [open, setIsOpen] = useState(false);
  const handleSelect = (value: string) => {
    onChange(value);
    setIsOpen(false);
  };

  const isSelected = (optionValue: string) => {
    return selected === optionValue;
  };

  const selectedLabel = options.find((option) => option.value === selected)?.label || placeholder;
  const selectedOption = options.find((option) => option.value === selected);

  const renderCustomOption = (option: OptionType<T>) => {
    if (itemComponent) {
      return itemComponent(option);
    }

    return option.label;
  };

  const renderOption = (option: OptionType<T>) => {
    return (
      <div className="flex flex-col space-y-1">
        <span>{option.label}</span>
        {option.description && (
          <span className="text-xs font-medium leading-[1.125rem] text-primary-dark-60">{option.description}</span>
        )}
      </div>
    );
  };

  return (
    <Command {...props}>
      <Popover
        open={open}
        onOpenChange={setIsOpen}
      >
        <PopoverTrigger
          disabled={props.disabled}
          asChild
        >
          <button
            className={cn(
              inputVariants({ variant: error ? 'error' : 'default' }),
              'relative w-full min-w-28 justify-between [&[data-state=open]>svg]:rotate-180',
              props.className
            )}
          >
            {itemComponent && selectedOption ? (
              renderCustomOption(selectedOption)
            ) : (
              <span
                className={cn(
                  'w-[90%] truncate text-start',
                  selected ? 'text-primary-dark-100' : 'text-primary-dark-60'
                )}
              >
                {selectedLabel}
              </span>
            )}
            <UilAngleDown
              className={cn(
                'pointer-events-none absolute right-4 size-6 text-primary-dark-60 transition-transform duration-200'
              )}
            />
          </button>
        </PopoverTrigger>
        <PopoverContent
          onOpenAutoFocus={(e) => e.preventDefault()}
          className="PopoverContent p-0"
          asChild
          sideOffset={8}
        >
          <CommandList className="max-w-full bg-primary-white-100">
            <CommandEmpty>{isLoading ? 'Loading...' : 'No results found'}.</CommandEmpty>
            <CommandGroup className="p-0">
              {sortedOptions.map((option) => (
                <CommandItem
                  className="inline-flex w-full items-center justify-between"
                  key={option.value}
                  onSelect={() => handleSelect(option.value)}
                  isSelected={isSelected(option.value)}
                >
                  {itemComponent ? renderCustomOption(option) : renderOption(option)}
                  <span>{isSelected(option.value) && <UilCheck className="size-5 text-primary-blue-100" />}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </PopoverContent>
      </Popover>
    </Command>
  );
}
