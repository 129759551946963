import { useEffect, useMemo, useRef, useState } from 'react';
import { useNotificationPanelStore } from '@/store/notification-panel-store';
import { AnimatePresence, motion } from 'framer-motion';
import { createPortal } from 'react-dom';

import { Button } from '@/components/ui/button';

import { useGetAllUserNotifications, useMarkAllNotificationsAsRead } from '@/hooks/notification';

import { cn } from '@/lib/utils';

import { NotificationTabFilterOptionEnum } from '@/constants/notifications';
import { PORTAL_IDS } from '@/constants/portal-ids';

import { NotificationList } from './notification-list';
import { NotificationNavBar } from './notification-tab';

export function NotificationCount({ onClick }: { onClick?: () => void }) {
  const { unreadNotifications, showNotifications, setUnreadNotifications } = useNotificationPanelStore();

  const [portalNode, setPortalNode] = useState<HTMLElement | null>(null);
  const { data: notifications, isPending } = useGetAllUserNotifications({
    params: {
      query: {
        limit: '0',
        offset: '0',
        // TODO: Uncomment this when the meeting feature is completed
        // notification_topic: currentTab === NotificationTabFilterOptionEnum.ALL ? undefined : currentTab,
      },
    },
    // reactQueryOptions: {
    //   queryKey: [currentTab],
    // },
    reactQueryOptions: {
      enabled: !showNotifications,
    },
  });
  useMemo(() => {
    setUnreadNotifications(notifications?.metadata.unread || 0);
  }, [showNotifications, notifications]);

  useEffect(() => {
    setPortalNode(document.getElementById(PORTAL_IDS.notificationsCount));
  }, []);
  if (!portalNode) return null;
  if (isPending) return null;

  return createPortal(
    unreadNotifications > 0 && (
      <div
        className="inline-flex h-6 items-start justify-start"
        onClick={onClick}
      >
        <div className="flex items-center justify-center gap-1 rounded-xl bg-primary-dark-10 px-2 py-0.5">
          <div className="text-sm font-medium leading-tight text-primary-dark-100">{unreadNotifications}</div>
        </div>
      </div>
    ),
    portalNode
  );
}

export function Notification() {
  const { showNotifications, unreadNotifications, setShowNotifications } = useNotificationPanelStore();
  const [currentTab, setCurrentTab] = useState<NotificationTabFilterOptionEnum>(NotificationTabFilterOptionEnum.ALL);
  const notificationRef = useRef<HTMLDivElement>(null);

  const { data: notifications, isPending } = useGetAllUserNotifications({
    params: {
      query: {
        limit: '0',
        offset: '0',
        // TODO: Uncomment this when the meeting feature is completed
        // notification_topic: currentTab === NotificationTabFilterOptionEnum.ALL ? undefined : currentTab,
      },
    },
    // reactQueryOptions: {
    //   queryKey: [currentTab],
    // },
    reactQueryOptions: {
      enabled: showNotifications,
    },
  });

  const { mutateAsync: markAllAsReadMutation } = useMarkAllNotificationsAsRead({});

  const handleTabChange = (tab: NotificationTabFilterOptionEnum) => {
    setCurrentTab(tab);
  };

  const handleMarkAllAsReadClick = async () => {
    await markAllAsReadMutation({});
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (document.getElementById('sidebar-container')?.contains(event.target as Node)) {
        return;
      }
      if (notificationRef.current && !notificationRef.current.contains(event.target as Node)) {
        setShowNotifications(false);
      }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' || event.key === ' ') {
        setShowNotifications(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowNotifications]);
  return (
    <AnimatePresence>
      {showNotifications && (
        <motion.div
          ref={notificationRef}
          initial={{ x: -480 }}
          animate={{ x: 0 }}
          exit={{ x: -480 }}
          transition={{ duration: 0.2 }}
          className={cn(
            'fixed left-[17.5rem] top-0 z-20 h-screen w-[29.5rem] border-r border-primary-dark-10 bg-primary-white-100 shadow-md 3xl:left-[19.625rem]'
          )}
        >
          <div className="flex h-full flex-col pt-8">
            <section className="flex flex-col space-y-8 px-4">
              <div className="flex items-center justify-between gap-4 self-stretch">
                <div className="shrink grow basis-0 text-xl font-semibold leading-[1.875rem] text-primary-dark-100">
                  Notifications
                </div>
                <Button
                  variant={'link'}
                  size={'sm'}
                  onClick={handleMarkAllAsReadClick}
                  className="p-0"
                  disabled={!unreadNotifications}
                >
                  Mark all as read
                </Button>
              </div>
              <div className="w-full">
                <NotificationNavBar
                  currentTab={currentTab}
                  handleTabChange={handleTabChange}
                />
              </div>
            </section>
            <NotificationList
              isLoading={isPending}
              notifications={notifications?.items || []}
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
