import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const tagVariants = cva(
  'inline-flex items-center justify-center gap-1 rounded-[0.375rem] border border-primary-dark-10 px-2 py-0.5 font-medium',
  {
    variants: {
      size: {
        md: 'text-md',
        sm: 'text-sm',
        lg: 'text-lg',
      },
    },
    defaultVariants: {
      size: 'sm',
    },
  }
);

export const dotVariants = cva('h-1.5 w-1.5 rounded-full', {
  variants: {
    variant: {
      default: 'bg-primary-dark-60',
      primary: 'bg-primary-blue-100',
      success: 'bg-primary-success-100',
      warn: 'bg-primary-warning-100',
      alert: 'bg-primary-alert-100',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export type TDotVariant = VariantProps<typeof dotVariants>['variant'];

export interface TagProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof tagVariants>,
    VariantProps<typeof dotVariants> {
  hideDot?: boolean;
}

function Tag({ size, className, variant, children, hideDot, ...props }: TagProps) {
  return (
    <div
      className={cn(tagVariants({ size }), className)}
      {...props}
    >
      {!hideDot && <div className={cn(dotVariants({ variant }))}></div>}
      <div className="inline-flex items-center justify-center gap-1">{children}</div>
    </div>
  );
}

export { Tag };
