import { createFileRoute, useNavigate } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';

import FeaturedIcon from '@/components/icons/featured-icon';
import { Logo } from '@/components/logo';
import JobCard, { IJobCardProps } from '@/components/onboarding/job-card';

import Role1 from '@/assets/roles/role1.png';
import Role2 from '@/assets/roles/role2.jpg';
import Role3 from '@/assets/roles/role3.jpg';

export const Route = createFileRoute('/_authenticated/candidate/onboarding/')({
  component: OnboardingComponent,
});

const jobItems: IJobCardProps[] = [
  {
    companyName: 'FIS',
    location: 'Sydney',
    role: 'Software Engineer',
    roleAvailability: ['On-site', 'Full-time'],
    backdrop: Role1,
  },
  {
    companyName: 'Relevance AI',
    location: 'Sydney',
    role: 'Software Engineer',
    roleAvailability: ['On-site', 'Full-time'],
    backdrop: Role2,
  },
  {
    companyName: 'Synechron',
    location: 'Sydney',
    role: 'Software Engineer',
    roleAvailability: ['On-site', 'Full-time'],
    backdrop: Role3,
  },
];

function OnboardingComponent() {
  const navigate = useNavigate();
  const handleNavigate = () => navigate({ to: '/candidate/onboarding/step1' });

  return (
    <main className="my-2 inline-flex w-full flex-col items-center justify-center 2xl:my-8">
      <section className="inline-flex w-full max-w-[880px] shrink grow basis-0 flex-col items-center justify-start">
        <div className="inline-flex items-center justify-center gap-2.5 self-stretch px-8 py-2 2xl:gap-2.5">
          <Logo variant="mini" />
        </div>
        <div className="my-2 inline-flex h-64 w-full flex-col items-center justify-center space-y-4 px-8 py-2 2xl:my-10 2xl:space-y-6 3xl:my-20 3xl:space-y-8">
          <div className="flex flex-col items-center justify-center space-y-2 self-stretch 2xl:space-y-4">
            <div className="text-center text-[32px] font-semibold leading-10 text-primary-dark-100">
              Welcome to Upplft!
            </div>
            <div className="self-stretch text-center text-base font-medium leading-normal text-primary-dark-60">
              Before you land your next role, we require you to build out your profile!
            </div>
          </div>
          <div className="inline-flex items-center justify-center gap-4 self-stretch py-2">
            <div className="flex items-center gap-3.5 text-base font-medium leading-normal text-primary-dark-60">
              <FeaturedIcon
                variant="success"
                size="sm"
              />{' '}
              <span>This will only take you 3 - 5 minutes</span>
            </div>
          </div>
          <div className="inline-flex items-start justify-start">
            <Button
              onClick={handleNavigate}
              className="h-14 rounded-lg px-5 py-4 font-semibold text-primary-white-100"
            >
              Get Started
            </Button>
          </div>
        </div>
      </section>
      <div></div>
      <section className="relative w-auto max-w-[100dvw] items-center justify-center overflow-hidden">
        <div className="absolute z-20 h-full w-full bg-gradient-to-r from-primary-white-100/100 via-transparent to-primary-white-100/100"></div>
        <div className="flex items-start justify-center gap-10">
          {jobItems.map((j, i) => (
            <JobCard
              key={i}
              {...j}
              blur={i !== 1}
            />
          ))}
        </div>
      </section>
    </main>
  );
}
