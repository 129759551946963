import { APP_DASHBOARD } from '@/services/user';

import { interpolate } from './string';

type DefaultPathParams = {
  dashboard: APP_DASHBOARD;
  [key: string]: unknown;
};

export const getRolesUrl = (params: DefaultPathParams) =>
  interpolate('/:dashboard/roles', { dashboard: params.dashboard });

export const getPipelineUrl = (params: DefaultPathParams) =>
  interpolate('/:dashboard/pipeline', { dashboard: params.dashboard });

export const getPipelineTableUrl = (params: DefaultPathParams) =>
  interpolate('/:dashboard/pipeline/table', { dashboard: params.dashboard });

export const getRoleDetailUrl = (params: DefaultPathParams) =>
  interpolate('/:dashboard/role/:roleId', { dashboard: params.dashboard, roleId: params.roleId });

/**
 * Get the referral URL for a role
 * @param params
 * @returns
 *
 * @example
 * getRoleReferralUrl({
 *  host: window.location.origin,
 *  roleId: 1,
 *  referrerToken: 'token'
 * });
 */
export const getRoleReferralUrl = (params: DefaultPathParams) =>
  interpolate(':host/role/:roleId?referrer=:referrerToken', {
    host: params.host,
    roleId: params.roleId,
    referrerToken: params.referrerToken,
  });
