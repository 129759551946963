import { useState } from 'react';
import { ColumnDef, PaginationState } from '@tanstack/react-table';

import Avatar from '@/components/ui/avatar';
import { DataTable } from '@/components/ui/data-table';
import { Tag } from '@/components/ui/tag';

import { TBusinessPartner, TGetBusinessPartnersResponse } from '@/services/business';
import { TCandidatePostsFilters } from '@/services/candidate';

import { getFullName } from '@/utils/string';

import { ProfileBubbles } from '../profile-bubbles';
import InvitePartnerButton from './invite-partner-button';
import { PartnersTableActionButton } from './partners-table-action-button';
import { RemovePartnerButton } from './remove-partner-modal';
import { ResendInviteButton } from './resend-invite-modal';

interface IPartnersTableProps {
  data?: TGetBusinessPartnersResponse;
  isPending?: boolean;
  filters: TCandidatePostsFilters;
  setFilters: (filters: TCandidatePostsFilters) => void;
  paginationState: PaginationState;
}

export default function PartnersTable({ data, isPending, paginationState, filters, setFilters }: IPartnersTableProps) {
  const [resendInviteOpen, setResendInviteOpen] = useState(false);
  const [removePartnerOpen, setRemovePartnerOpen] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState<{ id: string; email: string } | null>(null);

  const columnDefs: ColumnDef<TBusinessPartner>[] = [
    {
      accessorKey: 'business.name',
      id: 'business.name',
      header: 'Name',
      cell: ({ row }) => {
        return (
          <div className="flex cursor-pointer items-center gap-4">
            <Avatar
              fallbackType="company"
              src={row.original?.logo_url}
              size="md"
            />
            <span className="text-sm font-semibold">{row.original?.name}</span>
          </div>
        );
      },
    },
    {
      header: 'Status',
      accessorKey: 'status',
      id: 'status',
      cell: ({ row }) => {
        // TODO: calculate Invited status from joined_on field of owner (from members field-> type owner member)
        return (
          <Tag variant={row.original.status === 1 ? 'success' : 'alert'}>
            {row.original.status === 1 ? 'Active' : 'Inactive'}
          </Tag>
        );
      },
    },
    {
      accessorKey: 'metadata.categories',
      header: 'Categories',
      cell: ({ row }) => {
        return (
          <div className="flex flex-wrap gap-2">
            {row?.original?.metadata?.categories &&
              row?.original?.metadata?.categories.split(';').map((category) => (
                <Tag
                  key={category}
                  variant={'primary'}
                >
                  {category}
                </Tag>
              ))}
          </div>
        );
      },
    },
    {
      accessorKey: 'team',
      header: 'Team',
      cell: ({ row }) => {
        const bubbles = row.original.members.map((member) => ({
          id: member.id,
          name: getFullName(member),
          image_url: member.image_url,
        }));
        return <ProfileBubbles bubbles={bubbles} />;
      },
    },
    {
      id: 'actions',
      size: 24,
      cell: ({ row }) => (
        <div className="flex justify-end">
          <PartnersTableActionButton
            id={row.original.id.toString()}
            email={row.original.owner?.email?.toString() || ''}
            // onResetPassword={onResetPassword}
            onResendInvite={(id, email) => {
              setSelectedPartner({ id, email });
              setResendInviteOpen(true);
            }}
            onRemove={(id, email) => {
              setSelectedPartner({ id, email });
              setRemovePartnerOpen(true);
            }}
          />
        </div>
      ),
    },
  ];

  const handleResendInviteSubmit = () => {
    // Handle resend invite logic here
    setResendInviteOpen(false);
  };

  const handleRemovePartnerSubmit = () => {
    // Handle remove member logic here
    setRemovePartnerOpen(false);
  };

  return (
    <>
      <DataTable
        enableSelect
        columns={columnDefs}
        data={data?.items || []}
        title="Partners"
        isLoading={isPending}
        noData={{
          title: 'No partners available',
          description: "You haven't invited any partners yet.",
        }}
        search={{
          value: filters.query || '',
          onChange: (value) => {
            setFilters({ ...filters, query: value });
          },
        }}
        pagination={{
          state: paginationState,
          options: {
            rowCount: data?.total || 0,
            onPaginationChange: (pagination) => {
              setFilters(typeof pagination === 'function' ? pagination(paginationState) : pagination);
            },
          },
        }}
        headerButtons={<InvitePartnerButton />}
      />
      {selectedPartner && (
        <>
          <ResendInviteButton
            id={selectedPartner.id}
            email={selectedPartner.email}
            hideTrigger
            triggerOpen={resendInviteOpen}
            onOpenChange={setResendInviteOpen}
            onSubmit={handleResendInviteSubmit}
          />
          <RemovePartnerButton
            id={selectedPartner.id}
            hideTrigger
            triggerOpen={removePartnerOpen}
            onOpenChange={setRemovePartnerOpen}
            onSubmit={handleRemovePartnerSubmit}
          />
        </>
      )}
    </>
  );
}
