import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { addAccessTokenInterceptor } from '@/lib/api';

// Inject getAccessTokenSilently into global authInterceptor instance
const AuthInject: React.FC = () => {
  const { getAccessTokenSilently, logout } = useAuth0();

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently, logout);
  }, [getAccessTokenSilently, logout]);

  return null;
};

export default AuthInject;
