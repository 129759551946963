import { router } from '@/router';
import * as Sentry from '@sentry/react';

const SENTRY_DSN = 'https://74acd18cc5a11a5043b6a71d3d42da7b@o4507659772755968.ingest.de.sentry.io/4507672357765200';

export function configureSentry() {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
      }),
      Sentry.tanstackRouterBrowserTracingIntegration(router),
    ],

    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    // For finer control of sent transactions you can adjust this value, or
    // use tracesSampler
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [/^https:\/\/*\.upplft\.com\/v1/],
    enabled: import.meta.env.PROD,
  });
}
