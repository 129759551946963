import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { Button } from '@/components/ui/button';

import { PORTAL_IDS } from '@/constants/portal-ids';

const PublishRoleButton = ({
  onPublish,
  isLoading,
  label = 'Publish Role',
  usePortal = true,
}: {
  onPublish: () => void;
  isLoading: boolean;
  label?: string;
  usePortal?: boolean;
}) => {
  const [domNode, setDomNode] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const dashboardContainer = document.getElementById(PORTAL_IDS.createRole);
    setDomNode(dashboardContainer);
  }, []);

  if (!domNode) return null;

  const renderButton = () => (
    <Button
      disabled={isLoading}
      onClick={onPublish}
      type="submit"
      loading={isLoading}
      loadingText="Publishing"
    >
      {label}
    </Button>
  );

  return usePortal ? createPortal(renderButton(), domNode) : renderButton();
};

export default PublishRoleButton;
