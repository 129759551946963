import { useEffect } from 'react';
import hotjar from '@hotjar/browser';

const HotjarInject = () => {
  const hotjarVersion = 6;

  useEffect(() => {
    hotjar.init(import.meta.env.VITE_HOTJAR_ID, hotjarVersion);
  }, []);

  return null;
};

export default HotjarInject;
