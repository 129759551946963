import { SortingState } from '@tanstack/react-table';

import { SortParams } from '@/services/types';

export const stateToSortBy = (sorting: SortingState | undefined) => {
  if (!sorting || sorting.length == 0) return undefined;

  const sort = sorting[0];

  return `${sort.id}.${sort.desc ? 'desc' : 'asc'}` as const;
};

export const sortByToState = (sortBy: SortParams['sortBy'] | undefined) => {
  if (!sortBy) return [];

  const sortElementsArray = sortBy.split('.');
  const desc = sortElementsArray.pop();
  const id = sortElementsArray.join('.');

  return [{ id, desc: desc === 'desc' }];
};

export type ORDER = 'desc' | 'asc';

export const sortByToQuery = <T>(sortBy: SortParams['sortBy'] | undefined) => {
  if (!sortBy) return undefined;

  const sortElementsArray = sortBy.split('.');
  const order = sortElementsArray.pop() as ORDER;
  const id = sortElementsArray.join('.') as T;

  return {
    id,
    order,
  };
};
