import client, { TRequestOptions } from '@/lib/api';

import { BUSINESS_ROLES_ENDPOINTS } from './business-roles.endpoints';
import {
  TCreateJobPostRequest,
  TDeleteRoleRequest,
  TFavouriteCandidateRequest,
  TGenerateBusinessRoleReferralTokenRequest,
  TGetAllBusinessRolesRequest,
  TGetAllBusinessRolesV2Request,
  TGetBusinessRolesStatsRequest,
  TGetJobPostByIdRequest,
  TGetRoleDetailByBusinessIdPostIdV2Request,
  TUnfavouriteCandidateRequest,
  TUpdateJobPostRequest,
} from './business-roles.types';

export const getRolesStatsByBusinessId = async ({ signal, params }: TRequestOptions<TGetBusinessRolesStatsRequest>) => {
  const { data } = await client.GET(BUSINESS_ROLES_ENDPOINTS.GET_ROLES_STATS, {
    signal,
    params,
  });

  return data?.data;
};

export const getAllRolesByBusinessId = async ({ signal, params }: TRequestOptions<TGetAllBusinessRolesRequest>) => {
  const { data } = await client.GET(BUSINESS_ROLES_ENDPOINTS.GET_ALL_ROLES_BY_BUSINESS_ID, {
    signal,
    params,
  });

  return data?.data;
};

export const getAllRolesByBusinessIdV2 = async ({ signal, params }: TRequestOptions<TGetAllBusinessRolesV2Request>) => {
  const { data } = await client.GET(BUSINESS_ROLES_ENDPOINTS.GET_ALL_ROLES_BY_BUSINESS_ID_V2, {
    signal,
    params,
  });

  return data?.data;
};

export const getRoleDetailByBusinessIdPostIdV2 = async ({
  signal,
  params,
}: TRequestOptions<TGetRoleDetailByBusinessIdPostIdV2Request>) => {
  const { data } = await client.GET(BUSINESS_ROLES_ENDPOINTS.GET_ROLE_DETAIL_BY_BUSINESS_ID_POST_ID_V2, {
    signal,
    params,
  });

  return data?.data;
};

export const createJobPost = async ({ signal, params, body }: TRequestOptions<TCreateJobPostRequest>) => {
  const { data } = await client.POST(BUSINESS_ROLES_ENDPOINTS.CREATE_JOB_POST, { signal, params, body });

  return data?.data;
};

export const getJobPostById = async ({ signal, params }: TRequestOptions<TGetJobPostByIdRequest>) => {
  const { data } = await client.GET(BUSINESS_ROLES_ENDPOINTS.GET_JOB_POST_BY_ID, { signal, params });

  return data?.data;
};

export const updateJobPost = async ({ signal, params, body }: TRequestOptions<TUpdateJobPostRequest>) => {
  const { data } = await client.PUT(BUSINESS_ROLES_ENDPOINTS.UPDATE_JOB_POST, { signal, params, body });

  return data?.data;
};
export const favouriteCandidate = async ({ signal, params, body }: TRequestOptions<TFavouriteCandidateRequest>) => {
  const { data } = await client.POST(BUSINESS_ROLES_ENDPOINTS.FAVOURITE_CANDIDATE, { signal, params, body });

  return data;
};

export const unfavouriteCandidate = async ({ signal, params, body }: TRequestOptions<TUnfavouriteCandidateRequest>) => {
  const { data } = await client.POST(BUSINESS_ROLES_ENDPOINTS.UNFAVOURITE_CANDIDATE, { signal, params, body });

  return data;
};

export const generateRoleReferrerToken = async ({
  signal,
  params,
}: TRequestOptions<TGenerateBusinessRoleReferralTokenRequest>) => {
  const { data } = await client.POST(BUSINESS_ROLES_ENDPOINTS.GENERATE_ROLE_REFERRER_TOKEN, {
    signal,
    params,
  });

  return data?.data;
};

export const deleteRole = async ({ signal, params }: TRequestOptions<TDeleteRoleRequest>) => {
  const { data } = await client.DELETE(BUSINESS_ROLES_ENDPOINTS.DELETE_A_POST, { signal, params });

  return data?.success;
};
