/**
 * Copy text to clipboard.
 * @param promise \{Promise<TResponse>\} Promise that resolves to the text to copy.
 * @param getText \{(response: TResponse) => string\} Function that extracts the text from the response.
 * @returns void
 *
 * @example
 * ```ts
 * const url = 'https://example.com';
 * addToClipBoardFromPromise(Promise.resolve(url), (url) => url);
 * ```
 *
 * @example
 * ```ts
 * const url = 'https://example.com';
 * addToClipBoardFromPromise(Promise.resolve({ url }), (data) => data.url);
 * ```
 */
export const addToClipBoardFromPromise = <TResponse>(
  promise: Promise<TResponse>,
  getText: (response: TResponse) => string
) => {
  if (typeof ClipboardItem && navigator.clipboard.write) {
    // NOTE: Safari locks down the clipboard API to only work when triggered
    // by a direct user interaction. You can't use it async in a promise.
    // But! You can wrap the promise in a ClipboardItem, and give that to
    // the clipboard API.
    // Found this on https://developer.apple.com/forums/thread/691873
    const text = new ClipboardItem({
      'text/plain': promise.then(getText).then((text) => new Blob([text], { type: 'text/plain' })),
    });
    navigator.clipboard.write([text]);
  } else {
    // NOTE: Firefox has support for ClipboardItem and navigator.clipboard.write,
    // but those are behind `dom.events.asyncClipboard.clipboardItem` preference.
    // Good news is that other than Safari, Firefox does not care about
    // Clipboard API being used async in a Promise.
    promise.then(getText).then((text) => navigator.clipboard.writeText(text));
  }
};
