import React from 'react';
import { UilPen } from '@iconscout/react-unicons';
import { useNavigate } from '@tanstack/react-router';

import Avatar from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Card, CardTitle } from '@/components/ui/card';
import Separator from '@/components/ui/separator';

import TeamMemberTag from '@/components/tags/team-member-tag';

import { TJobPost } from '@/services/business-roles/business-roles.types';

interface IProps {
  partners: TJobPost['partners'];
  roleId: number;
}

const Partners = ({ partners, roleId }: IProps) => {
  const navigate = useNavigate();

  if (!partners.length) return null;

  return (
    <Card className="space-y-6 p-8">
      <CardTitle className="flex justify-between leading-normal">
        Partners
        <Button
          variant="tertiary"
          className="p-3"
          onClick={() =>
            navigate({
              to: `/business/create-role/step7`,
              search: { id: roleId, mode: 'edit', backRoute: window.location.pathname },
              mask: {
                to: '/business/create-role',
                search: { id: roleId, mode: 'edit' },
                unmaskOnReload: false,
              },
            })
          }
        >
          <UilPen className="!text-primary-dark-100 default-icon-sm" />
        </Button>
      </CardTitle>
      <div className="space-y-6">
        {partners.map((partner, index) => (
          <React.Fragment key={index}>
            {index > 0 && <Separator size="sm" />}
            <div className="space-y-4">
              <div className="flex items-center gap-2">
                <Avatar
                  fallbackType="company"
                  src={partner.logo_url}
                />
                <span className="text-sm font-semibold">{partner.name}</span>
              </div>
              <div>
                <div className="flex gap-2">
                  {partner.members.map((member) => (
                    <TeamMemberTag
                      key={member.id}
                      name={`${member.first_name} ${member.last_name}`}
                      imageUrl={member.image_url}
                    />
                  ))}
                </div>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </Card>
  );
};

export default Partners;
