import { UilHeadphonesAlt } from '@iconscout/react-unicons';
import { useSearch } from '@tanstack/react-router';

import { Button } from '@/components/ui/button';
import Separator from '@/components/ui/separator';

import { BookASlotButton } from '@/components/big-calendar/candidate/book-a-slot-button';

import { TCandidateJobPost, TCandidateMatchStatus } from '@/services/candidate';
import { TGetCandidateMeetingForPostResponse } from '@/services/meetings';

import { ROLE_CANDIDATE_STATUS } from '@/utils/application-status';

import ApplyRoleButton from './apply-role-button';

export function CTA({
  candidateStatus,
  post,
  meeting,
}: {
  candidateStatus?: TCandidateMatchStatus | null;
  post: TCandidateJobPost;
  meeting?: TGetCandidateMeetingForPostResponse;
}) {
  const { referrer } = useSearch({
    from: '/_authenticated/candidate/_dashboard/role/$roleId',
  });

  if (post.status === 1 && candidateStatus === null) {
    return (
      <>
        <Separator />

        <ApplyRoleButton
          post={post}
          referrer={referrer}
        />
      </>
    );
  }
  if (candidateStatus === ROLE_CANDIDATE_STATUS.SHORTLIST_CANDIDATE) {
    return (
      <div>
        <Separator size="md" />
        <div className="flex w-full flex-col space-y-4 2xl:flex-row 2xl:space-x-4 2xl:space-y-0">
          <Button
            size={'lg'}
            variant="tertiary"
            className="flex-1"
          >
            Decline
          </Button>
          <Button
            className="flex-1"
            size={'lg'}
          >
            Book a slot
          </Button>
        </div>
      </div>
    );
  }
  if (candidateStatus === ROLE_CANDIDATE_STATUS.INTERVIEWING && meeting && meeting.meeting_time_slots.length > 0) {
    //If meeting is scheduled, show the meeting details
    if (meeting.status === 'scheduled' && meeting.start_time && meeting.end_time) {
      const startTime = new Date(meeting.start_time);
      const endTime = new Date(meeting.end_time);
      const joinMeeting = () => {
        if (!meeting.meeting_link) return;
        window.open(meeting.meeting_link, '_blank', 'noopener,noreferrer');
      };
      return (
        <>
          <Separator />
          <div className="inline-flex flex-col items-start justify-start gap-8 rounded-lg border border-[#a0e2c3] bg-[#f3fbf8] p-4">
            <div className="inline-flex items-start justify-start gap-4 self-stretch">
              <div className="flex h-12 w-12 items-center justify-center gap-2.5 rounded-lg bg-[#12b76a] px-1 py-0.5">
                <UilHeadphonesAlt className="text-primary-white-100" />
              </div>
              <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-1.5">
                <div className="self-stretch text-base font-semibold leading-normal text-[#0c2149]">
                  {post.title} • {post.business.name}
                </div>
                <div className="text-xs font-medium leading-[18px] text-[#6d7a92]">
                  {formatDateRange(startTime, endTime)}
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col space-y-4 2xl:flex-row 2xl:space-x-4 2xl:space-y-0">
              <Button
                size={'lg'}
                variant="tertiary"
                className="flex-1"
              >
                Need help?
              </Button>
              <Button
                className="flex-1"
                size={'lg'}
                onClick={joinMeeting}
              >
                Join meeting
              </Button>
            </div>
          </div>
        </>
      );
    }
    //If meeting is not yet scheduled, show book a time slot
    return (
      <>
        <Separator />
        <div className="inline-flex flex-col items-start justify-start gap-8 rounded-lg border border-[#a0e2c3] bg-[#f3fbf8] p-4">
          <div className="inline-flex items-start justify-start gap-4 self-stretch">
            <div className="flex h-12 w-12 items-center justify-center gap-2.5 rounded-lg bg-[#12b76a] px-1 py-0.5">
              <UilHeadphonesAlt className="text-primary-white-100" />
            </div>
            <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-1.5">
              <div className="self-stretch text-base font-semibold leading-normal text-[#0c2149]">
                {meeting.stage_name} • {post.title}
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col space-y-4 2xl:flex-row 2xl:space-x-4 2xl:space-y-0">
            <BookASlotButton
              meeting_id={meeting.id}
              timeSlots={meeting.meeting_time_slots}
              interval={Number(meeting.stage_duration)}
            />
          </div>
        </div>
      </>
    );
  }
  if (candidateStatus === ROLE_CANDIDATE_STATUS.HIRED) {
    return (
      <>
        <Separator />
        <Button size="lg">Download contract</Button>
      </>
    );
  }
  return null;
}

function formatDateRange(startDate: Date, endDate: Date): string {
  const dayFormatter = new Intl.DateTimeFormat('en-US', { weekday: 'long' });
  const dateFormatter = new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric' });
  const timeFormatter = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

  const day = dayFormatter.format(startDate);
  const date = dateFormatter.format(startDate);
  const startTime = timeFormatter.format(startDate);
  const endTime = timeFormatter.format(endDate);

  return `${day}, ${date} • ${startTime} - ${endTime}`;
}
