import { CultureName } from '@/services/candidate';

import { cn } from '@/lib/utils';

import { CULTURE_CLASS_NAME_MAP, CULTURE_TO_EMOJI_MAP } from '@/constants/candidate';

const CultureCard = ({ cultureName }: { cultureName: CultureName }) => {
  return (
    <div
      className={cn(
        'flex items-center gap-x-4 rounded-lg border px-4 py-4 3xl:gap-x-8 3xl:px-8',
        CULTURE_CLASS_NAME_MAP[cultureName]
      )}
    >
      <span className="text-7xl 3xl:text-8xl">{CULTURE_TO_EMOJI_MAP[cultureName]}</span>
      <div className="space-y-1 font-semibold">
        <p className="text-sm text-primary-dark-40">Culture:</p>
        <p className="text-xl">{cultureName}</p>
      </div>
    </div>
  );
};

export default CultureCard;
