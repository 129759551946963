import { isValidPhoneNumber } from 'react-phone-number-input';
import { z } from 'zod';

export const businessUserInfoSchema = z
  .object({
    first_name: z.string().min(1, 'First name is required'),
    last_name: z.string().min(1, 'Last name is required'),
    image_url: z.string().optional(),
    email: z.string({ required_error: 'Email is required' }).email('Invalid email address'),
    dob: z
      .date({
        required_error: 'Date of birth is required',
        invalid_type_error: 'Date of birth is required',
      })
      .min(new Date('1900-01-01'), 'Invalid date of birth')
      .max(new Date(), 'Date of birth cannot be in the future'),
    primary_phone: z.string().min(1, 'Phone number is required'),
  })
  .refine((data) => isValidPhoneNumber(data.primary_phone), {
    message: 'Invalid phone number',
    path: ['primary_phone'],
  });

export type TBusinessUserInfoFormData = z.infer<typeof businessUserInfoSchema>;
