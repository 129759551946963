export enum MEETING_STATUS {
  draft = 'draft',
  pending = 'pending',
  scheduled = 'scheduled',
  cancelled = 'cancelled',
  completed = 'completed',
  rescheduled = 'rescheduled',
}

export type TMeetingStatus = keyof typeof MEETING_STATUS;

export const MEETING_REASON_OPTIONS_CANDIDATE = [
  { value: 'Personal reasons', label: 'Personal reasons' },
  { value: 'I have an appointment', label: 'I have an appointment' },
  { value: 'No longer interested in the role', label: 'No longer interested in the role' },
];

export const MEETING_REASON_OPTIONS_BUSINESS = [
  { value: 'Change in hiring strategy', label: 'Change in hiring strategy' },
  { value: 'Unexpected conflict', label: 'Unexpected conflict' },
  { value: 'Need more time for evaluation', label: 'Need more time for evaluation' },
];
