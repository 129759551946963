import { TPersonalityChartData } from '@/components/quiz/charts/personality-chart';

import {
  TCultureProfile,
  TGetAllBusinessRolesV2RequestStatus,
  TJobPost,
  TPersonalityProfile,
} from '@/services/business-roles';
import {
  TArchivedCandidateMatch,
  TCandidate,
  TCandidateCulture,
  TCandidateJobPost,
  TCurrentCandidateAppliedMatch,
} from '@/services/candidate';
import { TPostPublic } from '@/services/post';

import { PAY_TYPE } from '@/constants/post';

import { formatCurrency } from './currency';

export const getPayRangeFromRole = (
  post: TCandidateJobPost | TJobPost | TPostPublic | TCurrentCandidateAppliedMatch | TArchivedCandidateMatch
) => {
  const currency = post.pay_currency || 'AUD';
  const payType = post.pay_type;
  const payFrom =
    post.pay_type === PAY_TYPE.DAY_RATE
      ? post.pay_day_rate_from
      : post.pay_type === PAY_TYPE.HOURLY_RATE
        ? post.pay_hourly_rate_from
        : post.pay_annual_salary_from;

  const payTo =
    post.pay_type === PAY_TYPE.DAY_RATE
      ? post.pay_day_rate_to
      : post.pay_type === PAY_TYPE.HOURLY_RATE
        ? post.pay_hourly_rate_to
        : post.pay_annual_salary_to;

  const payRange = payTo
    ? `${formatCurrency(Number(payFrom), currency)} - ${formatCurrency(Number(payTo), currency)}`
    : formatCurrency(Number(payFrom), currency);

  return { payType, payFrom, payTo, payRange };
};

export const getPayTypeShortName = (payType: PAY_TYPE) => {
  if (payType === PAY_TYPE.DAY_RATE) return '/day';
  if (payType === PAY_TYPE.HOURLY_RATE) return '/hr';
  if (payType === PAY_TYPE.ANNUAL_SALARY) return '/year';
  return '';
};

export const getExpectationRangeForCandidate = (candidate: TCandidate, payType: PAY_TYPE) => {
  const currency = candidate?.expectation_currency || 'AUD';
  const expectationFrom =
    (payType === PAY_TYPE.DAY_RATE
      ? candidate?.expectation_day_rate_from
      : payType === PAY_TYPE.HOURLY_RATE
        ? candidate?.expectation_hourly_rate_from
        : candidate?.expectation_annual_salary_from) || 0;

  const expectationTo =
    (payType === PAY_TYPE.DAY_RATE
      ? candidate?.expectation_day_rate_to
      : payType === PAY_TYPE.HOURLY_RATE
        ? candidate?.expectation_hourly_rate_to
        : candidate?.expectation_annual_salary_to) || 0;

  const expectationRange = expectationTo
    ? `${formatCurrency(Number(expectationFrom), currency)} - ${formatCurrency(Number(expectationTo), currency)}`
    : formatCurrency(Number(expectationFrom), currency);

  return { expectationFrom, expectationTo, expectationRange };
};

export const getPersonalityChartData = (personalityProfile: TPersonalityProfile[]): TPersonalityChartData => {
  return personalityProfile.reduce((acc, item) => {
    acc[item.code] = 6 - item.rank;
    return acc;
  }, {} as TPersonalityChartData);
};

export const getCultureChartData = (cultureProfile: TCultureProfile[]): TCandidateCulture[] => {
  return cultureProfile.reduce((acc, item) => {
    acc.push({
      name: item.name,
      rank: item.rank,
      measures: '',
      score: 4 - item.rank,
    });
    return acc;
  }, [] as TCandidateCulture[]);
};

export const getEmptyBusinessRolesDescription = (status: TGetAllBusinessRolesV2RequestStatus) => {
  switch (status) {
    case 'hold':
      return {
        title: 'No paused roles available',
        description: "You haven't paused any roles yet.",
      };
    case 'closed':
      return {
        title: 'No closed roles available',
        description: "You haven't closed any roles yet.",
      };
    case 'draft':
      return {
        title: 'No draft roles available',
        description: "You haven't created any draft roles yet.",
      };
    default:
      return {
        title: 'No roles available',
        description: "You haven't created any roles yet.",
      };
  }
};
