import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UilAngleLeft, UilAngleRight, UilClockFive } from '@iconscout/react-unicons';
import {
  addDays,
  addMinutes,
  format,
  isEqual,
  isPast,
  isSameDay,
  isSameMonth,
  isSameWeek,
  isValid,
  setHours,
  setMinutes,
  startOfDay,
  startOfWeek,
} from 'date-fns';

import { Button } from '@/components/ui/button';
import DatePicker from '@/components/ui/date-picker';

import { cn } from '@/lib/utils';

export type TimeSlot = {
  start_time: string;
  end_time: string;
};

interface ITimeSlotBookerProps {
  events?: TimeSlot[];
  interval: number;
  selectedSlot?: Date | null;
  selectedSlots?: Date[] | null;
  onSlotSelect?: (slot: Date) => void;
  disabledSlots?: Date[];
  isSettingAvailability?: boolean;
  defaultSelectedDate?: Date | null;
}

const TimeSlotBooker: React.FC<ITimeSlotBookerProps> = ({
  events,
  selectedSlots,
  onSlotSelect,
  isSettingAvailability,
  interval,
  selectedSlot,
  disabledSlots,
  defaultSelectedDate,
}) => {
  const [currentWeekIndex, setCurrentWeekIndex] = useState(0);
  const [selectedDate, setSelectedDate] = useState<Date | null>(defaultSelectedDate || null);
  // Memoize the sorting and grouping logic using useCallback
  const groupEventsByWeek = useCallback(
    (events?: TimeSlot[]) => {
      //If no events or setting availability, generate an array of dates for each workday (Monday to Friday) for the next 2 week
      if (!events || isSettingAvailability) {
        const today = startOfDay(new Date());
        // Generate an array of workdays (Monday to Friday) for the next 2 weeks (10 next working days)
        const workDays: Date[] = [];
        let i = 1;
        for (let j = 0; j < 10; ) {
          const day = addDays(today, i);
          i++;
          if (day.getDay() !== 0 && day.getDay() !== 6) {
            workDays.push(day);
            j++;
          }
        }
        return workDays.reduce((acc, day) => {
          // Find the group index where the event belongs (if any)
          const weekStart = startOfWeek(day, { weekStartsOn: 1 });
          const group = acc.find((g) => startOfWeek(g[0], { weekStartsOn: 1 }).getTime() === weekStart.getTime());

          if (group) {
            group.push(day);
          } else {
            acc.push([day]);
          }

          return acc;
        }, [] as Date[][]);
      }

      // Filter out events with invalid dates and those in the past
      const validFutureEvents = events.filter(
        (event) =>
          isValid(new Date(event.start_time)) &&
          (new Date(event.start_time) >= startOfDay(new Date()) || !isPast(new Date(event.start_time)))
      );

      // Sort the events by the earliest startTime
      const sortedEvents = validFutureEvents.sort(
        (a, b) => new Date(a.start_time).getTime() - new Date(b.start_time).getTime()
      );

      // Group events by work week into an array
      return sortedEvents.reduce((acc, event) => {
        const weekStart = startOfWeek(new Date(event.start_time), { weekStartsOn: 1 }); // Week starts on Monday

        // Find the group index where the event belongs (if any)
        const group = acc.find((g) => startOfWeek(g[0], { weekStartsOn: 1 }).getTime() === weekStart.getTime());

        if (group) {
          group.push(new Date(event.start_time));
        } else {
          acc.push([new Date(event.start_time)]);
        }

        return acc;
      }, [] as Date[][]);
    },
    [isSettingAvailability]
  );

  // Use useMemo to memoize the grouped events and the includeDates array
  const groupedByWeek = useMemo(() => groupEventsByWeek(events), [events, groupEventsByWeek]);
  const includeDates = useMemo(() => groupedByWeek.map((group) => group[0]).filter(isValid), [groupedByWeek]);
  const currentWeek = groupedByWeek[currentWeekIndex];

  // If defaultSelectedDate is provided, set the current week index to the week that contains the defaultSelectedDate
  useEffect(() => {
    if (defaultSelectedDate) {
      const weekIndex = groupedByWeek.findIndex((week) => isSameWeek(week[0], defaultSelectedDate));
      setCurrentWeekIndex(weekIndex);
    }
  }, []);

  // Generate an array of dates for each workday (Monday to Friday) in the current week
  const daysInCurrentWeek = useMemo(() => {
    if (!currentWeek) return [];
    const weekStart = startOfWeek(currentWeek[0], { weekStartsOn: 1 });

    // Create an array of 5 days (Monday to Friday) and filter out invalid dates
    return Array.from({ length: 5 })
      .map((_, i) => addDays(weekStart, i))
      .filter(isValid); // Filter out any invalid dates
  }, [currentWeek]);

  const isDaySelected = (day: Date) => {
    if (!selectedDate) return false;
    return isSameDay(day, selectedDate);
  };

  const doesDayHaveEvent = useCallback(
    (day: Date) => {
      if (!currentWeek) return false;
      return currentWeek.some((event) => isSameDay(day, event) || isSameDay(day, event));
    },
    [currentWeek]
  );

  // Filter events for the selected day
  const eventsForSelectedDay = useMemo(() => {
    if (!selectedDate) return [];
    return currentWeek?.filter((event) => isSameDay(event, selectedDate)) || [];
  }, [selectedDate, currentWeek]);

  // Generate slot intervals for the selected day
  const timeSlots = useMemo(() => {
    if (isSettingAvailability && selectedDate) {
      // Generate slots from 9am to 5pm
      const start = setHours(setMinutes(selectedDate, 0), 9);
      const end = setHours(setMinutes(selectedDate, 0), 17);
      const slots: Date[] = [];
      let time = start;
      while (time <= end) {
        slots.push(time);
        time = addMinutes(time, interval);
      }
      return slots;
    }

    // If there are no events for the selected day, return an empty array
    if (eventsForSelectedDay.length === 0) return [];
    //filter out disabled slots
    const filteredEvents = eventsForSelectedDay.filter(
      (event) => !disabledSlots?.some((disabledSlot) => isEqual(disabledSlot, event))
    );
    return filteredEvents;
  }, [eventsForSelectedDay, selectedDate, interval, isSettingAvailability, disabledSlots]);

  // If there's no selected date, set it to the first day with events in the current week
  useMemo(() => {
    if (!selectedDate && daysInCurrentWeek.length > 0) {
      const firstDayWithEvents = daysInCurrentWeek.find(doesDayHaveEvent);
      if (firstDayWithEvents) {
        setSelectedDate(firstDayWithEvents);
      }
    }
  }, [selectedDate, daysInCurrentWeek, doesDayHaveEvent]);

  const isSlotSelected = (timeSlot: Date) => {
    if (selectedSlots) {
      return selectedSlots.some((slot) => isEqual(slot, timeSlot));
    }
    if (selectedSlot) {
      return isEqual(timeSlot, selectedSlot);
    }
    return false;
  };

  const onClickTimeSlot = (timeSlot: Date) => {
    if (!onSlotSelect) return;
    onSlotSelect(timeSlot);
  };

  // Handle DatePicker onChange to update the current week index
  const handleDatePickerChange = (date: Date | null) => {
    if (!date) return;

    // Find the corresponding week index
    const weekIndex = groupedByWeek.findIndex((week) => isSameMonth(week[0], date));

    if (weekIndex !== -1) {
      setCurrentWeekIndex(weekIndex);
      // setSelectedDate(null); // Reset selected date
      // onSlotSelect && onSlotSelect(null); // Reset selected slot
    }
  };

  const onPreviousClick = () => {
    setCurrentWeekIndex((prev) => Math.max(prev - 1, 0));
    setSelectedDate(null);
  };

  const onNextClick = () => {
    setCurrentWeekIndex((prev) => Math.min(prev + 1, groupedByWeek.length - 1));
    setSelectedDate(null);
  };

  return (
    <div>
      <div className="flex items-center justify-between pb-2">
        <DatePicker
          showMonthYearPicker
          useButton
          includeDates={includeDates}
          value={selectedDate?.toISOString()}
          onChange={handleDatePickerChange}
        />
        <div className="flex gap-2">
          <Button
            variant="tertiary"
            className="p-3"
            onClick={onPreviousClick}
            disabled={currentWeekIndex === 0}
          >
            <UilAngleLeft className="size-[1.125rem] text-primary-dark-100" />
          </Button>
          <Button
            variant="tertiary"
            className="p-3"
            onClick={onNextClick}
            disabled={currentWeekIndex === groupedByWeek.length - 1}
          >
            <UilAngleRight className="size-[1.125rem] text-primary-dark-100" />
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-5 justify-between gap-4 py-4">
        {daysInCurrentWeek.map((day, index) => (
          <div key={index}>
            <Button
              variant={'tertiary'}
              className={cn(
                'flex h-auto w-full flex-col gap-3 font-semibold',
                isDaySelected(day) && 'ring-4 ring-primary-dark-10'
              )}
              onClick={() => setSelectedDate(day)}
              autoFocus={isDaySelected(day)}
              disabled={!doesDayHaveEvent(day)}
            >
              <span className="text-sm">{format(day, 'eee')}</span>
              <span className="text-xl">{format(day, 'dd')}</span>
            </Button>
          </div>
        ))}
      </div>
      <div className="flex items-center gap-2 font-semibold">
        <UilClockFive className="size-[1.125rem]" />
        <span className="text-xs leading-[1.125rem]">{interval} minute meeting</span>
      </div>
      {selectedDate && (
        <div className="-mx-2 my-2 grid max-h-44 grid-cols-3 gap-4 overflow-y-auto px-2 py-2 scrollbar">
          {timeSlots.map((slot, index) => (
            <Button
              key={index}
              variant="tertiary"
              disabled={isPast(slot)}
              onClick={() => onClickTimeSlot(slot)}
              className={cn(
                isSlotSelected(slot) &&
                  'bg-primary-blue-100 text-white hover:enabled:bg-primary-blue-120 hover:enabled:text-white'
              )}
            >
              {format(slot, 'hh:mm a')}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

export default TimeSlotBooker;
