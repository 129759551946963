import { createFileRoute } from '@tanstack/react-router';

import ExpectationForm from '@/components/forms/candidate/expectations-form';

export const Route = createFileRoute('/_authenticated/candidate/_dashboard/settings/profile/expectations')({
  component: Expectations,
});

function Expectations() {
  return <ExpectationForm />;
}
